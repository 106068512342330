import React from 'react';
import { CardBody, Row, Col, Card, FormGroup, Button } from 'reactstrap';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import FormInput from '../../SharedComponents/FormHelpers/Controls/formInput';
import SelectFromTemplatesModal from '../Template/selectFromTemplatesModal';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import { post, put } from '../../Libraries/Ajax/httpService';
import LinkButton from '../../SharedComponents/linkButton';

class ManageAnnouncementCard extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: {
        label: 'Id',
        type: 'hidden',
      },
      content: {
        label: 'Content',
        type: 'text',
        id: 'autoGrow',
        scrollStartHeight: 300,
        placeholder: 'Enter Announcement',
        rules: {
          maxLength: 5000,
          required: props.statusId === 1,
        },
      },
      isDisabled: true,
      initialText: '',
      templateModal: false,
    };
  }

  componentDidMount() {
    this.setState({ isMount: true });
    let { content } = this.props;
    if (content) {
      this.setState({
        content: { ...this.state.content, value: content },
        initialText: content,
      });
    }
  }

  componentDidUpdate() {
    if (this.state.isMount && this.props.content) {
      this.updateContent();
    }
  }

  updateContent = () => {
    this.setState({
      content: { ...this.state.content, value: this.props.content },
      initialText: this.props.content,
      isMount: false,
    });
  };

  toast = new ToastNotification();

  onSaveButtonClick = (model) => {
    let { obituaryId, typeId, postId } = this.props;
    if (obituaryId && typeId) {
      model.obituaryId = obituaryId;
      model.typeId = typeId;
    }
    this.setState({ isLoaderActive: true });
    if (postId) {
      model.id = postId;
      put(`obituary-post/${postId}`, model).then((response) => {
        if (response.data && response.data.data && response.data.success) {
          this.setState({
            isLoaderActive: false,
            isDisabled: true,
            initialText: response.data.data.content,
          });
          this.toast.show('update_success', 'SUCCESS', 'Announcement updated successfully.');
        } else {
          this.toast.show('update_error', 'ERROR', 'Could not save changes');
        }
      });
    } else {
      post('obituary-post', model).then((response) => {
        if (response.data && response.data.data && response.data.success) {
          this.toast.show('create_success', 'SUCCESS', 'Announcement created successfully.');
          this.setState({
            isDisabled: true,
            initialText: response.data.data.content,
            isLoaderActive: false,
          });
          this.props.onSave();
        } else {
          this.toast.show('create_error', 'ERROR', 'Could not create announcement');
        }
      });
    }
  };

  onCancelButtonClick = () => {
    this.setState({
      isDisabled: true,
      content: { ...this.state.content, value: this.state.initialText },
    });
  };

  showTemplateModal = () => {
    this.setState((prevState) => ({ templateModal: !prevState.templateModal }));
  };

  setTemplateAsContent = (msg) => {
    this.setState((prevState) => ({
      content: {
        ...prevState.content,
        value: msg,
      },
    }));
    this.showTemplateModal();
  };

  render() {
    let { isDisabled, templateModal } = this.state;
    let { age, genderId } = this.props;
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <SubmitForm onSubmit={this.onSaveButtonClick} {...this.setFormProperties({ disableReactiveForm: true })}>
              <Row>
                <Col className="px-0 mx-2 mb-2" onClick={() => this.setState({ isDisabled: false })}>
                  <FormInput rows={5} as="textarea" disableLabel disableFormGroup {...this.setProperties(this.state.content)} />
                </Col>
              </Row>
              {!isDisabled && (
                <Row>
                  <Col xs={12}>
                    <div className="float-right d-block">
                      <LinkButton toString="templates" toDoFunction={this.showTemplateModal}>
                        Choose From Library
                      </LinkButton>
                    </div>
                  </Col>
                  <Col xs={12} className="mb-0 mt-3">
                    <FormGroup>
                      <div className="float-right">
                        <Button className="btn btn-success btn-sm ml-auto mr-1" type="submit">
                          <i className="dripicons-checkmark" />
                        </Button>
                        <Button className="btn btn-danger btn-sm" onClick={() => this.onCancelButtonClick()}>
                          <i className="dripicons-cross" />
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </SubmitForm>
          </CardBody>
        </Card>
        {templateModal && (
          <SelectFromTemplatesModal
            modal={templateModal}
            showModal={this.showTemplateModal}
            title="Message Templates"
            hide={false}
            cancelButtonName="Cancel"
            confirmButtonName="Use"
            cancelButtonClick={this.showTemplateModal}
            confirmButtonClick={this.setTemplateAsContent}
            renderapi={`template-message?isAll=true&statusId=1&typeId=8&genderId=${genderId}`}
            // isCardTemplate={false}
            templateType="message"
            age={age}
          />
        )}
      </React.Fragment>
    );
  }
}

export default ManageAnnouncementCard;
