import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Card, CardBody, FormGroup, Button } from 'reactstrap';
import { get, put } from '../../Libraries/Ajax/httpService';
import Loader from '../../Components/loader';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import { ContentItems } from '../../SharedComponents/ListComponents/manageComponent';
import DragAndDropComponent from '../../SharedComponents/FormHelpers/Controls/dragAndDrop';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';

class ViewPlaces extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleRow: {},
      image: {
        label: 'Image',
        type: 'text',
        placeholder: 'Enter image',
        name: 'image',
        fileUploadUrl: 'place/upload',
      },
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      get(`place/${this.props.match.params.id}`).then((response) => {
        this.setState({ singleRow: response.data?.data });
        this.mapJsonToState(response.data?.data);
      });
    }
    this.setState({ access: setComponentPrivilege('Manage Places') });
  }

  toast = new ToastNotification();

  onSaveButtonClick = (model) => {
    this.setState({ isLoaderActive: true });
    if (this.props.match.params.id) {
      put(`place/${this.props.match.params.id}`, model).then((response) => {
        this.setState({ isLoaderActive: false });
        if (response.data?.success) {
          this.toast.show('update_success', 'SUCCESS', response.data?.message ? response.data.message : 'Place successfully updated');
          this.props.history.push('/list-place');
        } else this.toast.show('update_error', 'ERROR', response.data?.message ? response.data.message : 'Something went wrong');
      });
    }
  };

  onCancelButtonClick = () => {
    this.props.history.push('/list-place');
  };

  validExtensions = ['jpeg', 'jpg', 'png'];

  render() {
    let { isLoaderActive, singleRow, access } = this.state;
    return (
      <>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          {singleRow && (
            <div className="app-l-heading-block">
              <Row className="align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">Place Details</h4>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/list-place">Place</Link>
                      </li>
                      <li className="breadcrumb-item active">Place Details</li>
                    </ol>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {singleRow && (
            <Container fluid className="px-0">
              <Card>
                <CardBody className="view-page">
                  {/* TopSection1 */}
                  <SubmitForm onSubmit={this.onSaveButtonClick} {...this.setFormProperties({ disableReactiveForm: true })}>
                    <Row>
                      <Col xs={12} md={6} lg={4} xl={4}>
                        <ContentItems title="Name"> {singleRow.name}</ContentItems>
                      </Col>
                      <Col xs={12} md={6} lg={4} xl={4}>
                        <ContentItems title="City"> {singleRow.city ? singleRow.city : '_____'}</ContentItems>
                      </Col>
                      <Col xs={12} md={6} lg={4} xl={4}>
                        <ContentItems title="State"> {singleRow.state ? singleRow.state : '_____'}</ContentItems>
                      </Col>
                      <Col xs={12} md={6} lg={4} xl={4}>
                        <ContentItems title="Country">{singleRow.country}</ContentItems>
                      </Col>
                      <Col xs={12} md={12}>
                        <ContentItems>
                          <DragAndDropComponent
                            viewOnly={!(access && access.edit)}
                            validExtensions={this.validExtensions}
                            mediaUrl={singleRow.mediaUrl ? singleRow.mediaUrl : ''}
                            customLabel="Image"
                            editFlag
                            allowCrop
                            aspectRatio={`${1 / 1}`}
                            {...this.setProperties(this.state.image)}
                          />
                        </ContentItems>
                      </Col>
                    </Row>
                    <FormGroup className="mb-0">
                      <div className="float-right d-md-block">
                        <br />
                        <Button type="submit" color="primary" className="mr-1">
                          Save
                        </Button>{' '}
                        <Button className="btn-secondary" type="reset" onClick={this.onCancelButtonClick}>
                          <i className="icon-close"> </i> <span> Cancel </span>
                        </Button>
                      </div>
                    </FormGroup>
                  </SubmitForm>
                </CardBody>{' '}
              </Card>
            </Container>
          )}
        </div>
      </>
    );
  }
}
export default ViewPlaces;
