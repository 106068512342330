import React from 'react';
import { Row, Col, Card, CardBody, Container, Button } from 'reactstrap';
import { get } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import Loader from '../../Components/loader';
import { ContentItems, DeleteRow } from '../../SharedComponents/ListComponents/manageComponent';
import { DataTable, THead, TBody, TRow, TCol } from '../../SharedComponents/Table/tableElements';
import { DataColHead, ViewDate } from '../../SharedComponents/ListComponents/components';

class ViewEvents extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      isLoaderActive: true,
    };
  }

  toast = new ToastNotification();

  componentDidMount() {
    let { post } = this.props;
    if (post) {
      let model = {
        obituaryPostId: post.id,
        take: 100,
        page: 0,
      };
      get('attend-slot', model).then((response) => {
        if (response.data.data && response.data.success) {
          this.setState({ apiResponse: response.data.data });
        } else {
          this.toast.show('fetch_error', 'ERROR', "Couldn't fetch slot details, please try later.");
        }
      });
      this.setState({ isLoaderActive: false });
    }
  }

  render() {
    let { post, setpredicates } = this.props;
    let { isLoaderActive, apiResponse } = this.state;
    return (
      <React.Fragment>
        {isLoaderActive === true ? <Loader /> : ''}
        <Container fluid className="px-0">
          <Row>
            <Col sm={12} md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Event Type">{post.headingType}</ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Venue">{post.venue.name}</ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Venue Address">{post.venue.location}</ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Event Date">
                        <ViewDate isTableView dateValue={post.startDate} />
                      </ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Start Time">{post.startTime.slice(0, 5)}</ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="End Time">{post.endTime.slice(0, 5)}</ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Created By">{post.createdByName}</ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Created Date">
                        <ViewDate isTableView dateValue={post.createdDate} />
                      </ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Last Modified Date">
                        <ViewDate isTableView dateValue={post.modifiedDate} />
                      </ContentItems>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={12}>
              <Card>
                <CardBody className="p-0 pt-3">
                  <DataTable>
                    <THead>
                      <TRow>
                        <DataColHead {...this.setheadproperties({ title: 'Start Time' })} />
                        <DataColHead {...this.setheadproperties({ title: 'End Time' })} />
                        <DataColHead {...this.setheadproperties({ title: 'Capacity' })} />
                      </TRow>
                    </THead>
                    <TBody>
                      {apiResponse && apiResponse.rows && apiResponse.rows.length > 0 ? (
                        apiResponse.rows.map((row, i) => (
                          <TRow key={`slot-${i}`}>
                            <TCol>{row.startTime.slice(0, 5)}</TCol>
                            <TCol>{row.endTime.slice(0, 5)}</TCol>
                            <TCol>{row.capacity}</TCol>
                          </TRow>
                        ))
                      ) : (
                        <TRow>No slots added</TRow>
                      )}
                    </TBody>
                  </DataTable>
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={12}>
              <div className="float-right">
                <DeleteRow
                  renderapi={`obituary-post/${post.id}`}
                  rowData={post}
                  deleteText="Delete"
                  isCardViewTrue="True"
                  setpredicates={setpredicates}
                  closeParentModal={this.props.deleteButtonClick}
                />{' '}
                <Button className="btn-secondary btn btn-primary" onClick={(e) => this.props.editButtonClick(e)}>
                  Edit
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default ViewEvents;
