import axios from 'axios';

import history from '../../Helpers/history';
import { setUser, loginMessage } from '../../Store/Auth/Login/actions';
import store from '../../Store/index';

const apiRoot = process.env.REACT_APP_API_URL;
const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const axiosInstance = axios.create({
  baseURL: apiRoot,
});
const requestHandler = (request) => {
  request.headers.Platform = 'Web';
  request.headers.fromadminportal = true;
  let loginState = store.getState().Login;
  if (loginState.userData) {
    let { accessToken, refreshToken } = loginState.userData;
    if (accessToken) request.headers.Authorization = `Bearer ${accessToken}`;
    if (refreshToken) request.headers.RefreshToken = refreshToken;
  }
  return request;
};

let onResponseFailed = (error) => {
  if (
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/forgot-Password' &&
    [401, 403].some((item) => (error.response ? item === error.response?.data.statusCode : false))
  ) {
    history.push('/logout');
    let type = '';
    let message = '';
    if (error.response?.data.message) {
      let {
        data: { message: msg, type: errType },
      } = error.response;
      message = msg;
      type = errType;
      if (type == 'session') store.dispatch(loginMessage(false, message));
    }
    return Promise.reject(new Error(message || 'Unauthorized'));
  }
  if (error.response && error.response.data && error.response.data.statusCode === '999') {
    let err = {
      status: '403',
      statusText: 'Forbidden',
      message: error.response.data.message,
    };
    return Promise.reject(err);
  }
  if (error.response && error.response.status && error.response.data) {
    let err = {
      status: error.response.status,
      statusText: error.response.statusText,
      message: error.response.data?.message ? error.response.data.message : 'Something went wrong',
    };
    return Promise.reject(err);
  }
  return Promise.reject(new Error(error.response?.data.message ? error.response.data.message : 'Something went wrong'));
  // if (error.request && error.request.status && (error.request.status === '400' || error.request.status === '404')) {
  //   return Promise.reject(new Error(error.response?.data?.message ? error.response.data.message : 'Bad Request'));
  // }
  // return Promise.reject(new Error(error.response?.data.message ? error.response.data.message : 'Something went wrong'));
};

const onResponseSuccess = (response) => {
  let loginState = store.getState().Login;
  let accessToken = '';
  let refreshToken = '';
  if (loginState.userData) ({ accessToken, refreshToken } = loginState.userData);
  let { accesstoken: jwtToken, refreshtoken: refresh } = response.headers;
  if (jwtToken != null && refresh !== null) {
    if (accessToken !== jwtToken && refreshToken !== refresh) {
      let updateData = {
        ...loginState.userData,
        accessToken: jwtToken,
        refreshToken: refresh,
      };
      localStorage.setItem('userData', JSON.stringify(updateData));
      store.dispatch(setUser(updateData));
    }
  }
  return response;
};

class HttpService {
  constructor() {
    if (axiosInstance.interceptors.request.handlers.length === 0) {
      axiosInstance.interceptors.request.use((request) => requestHandler(request));

      axiosInstance.interceptors.response.use(
        (response) => onResponseSuccess(response),
        (error) => onResponseFailed(error),
      );
    }
  }

  getWithHeader = (path, header) => {
    if (path) return axiosInstance.get(apiRoot + path, header, config);
    return null;
  };

  get = (path, model) => {
    if (path) return axiosInstance.get(apiRoot + path + (path.includes('?') ? '&date=' : '?date=') + new Date(), { ...config, params: model });
    return null;
  };

  post = (path, model) => {
    if (path) return axiosInstance.post(apiRoot + path, model, config);
    return null;
  };

  put = (path, model) => {
    if (path) return axiosInstance.put(apiRoot + path, model, config);
    return null;
  };

  remove = (path, model) => {
    if (path) return axiosInstance.delete(apiRoot + path, { data: model }, config);
    return null;
  };
}

export const http = new HttpService();
export const { getWithHeader } = new HttpService();
export const { get } = new HttpService();
export const { post } = new HttpService();
export const { put } = new HttpService();
export const { remove } = new HttpService();
