import React from 'react';
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { Row, Col } from 'reactstrap';
import { DataTable, TBody, THead, TCol, TRow, HeadingBlock, DataTableHolder } from '../../SharedComponents/Table/tableElements';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import Loader from '../../Components/loader';
import { StatusSwitch } from '../../SharedComponents/FormHelpers/Controls/statusSwitch';
import { ActionButton } from '../../SharedComponents/FormHelpers/Controls/actionButton';
import error404Img from '../../assets/images/errorImg.png';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';

class ListProductContainer extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleSelect: false,
      showLightBox: false,
      imageUrl: '',
      multiSelect: false,
      rowData: {},
      isLoaderActive: false,
      showDeleteConfirmation: false,
    };
  }

  componentDidMount() {
    this.setJsonUrl('product');
    this.setpredicates({ take: 10, page: 0 });
    this.setState({ access: setComponentPrivilege('Manage Products') });
  }

  render() {
    let { apiResponse, isLoaderActive, access } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              {isLoaderActive === true ? <Loader /> : ''}
              <HeadingBlock title="Product" />
            </Col>
            {access && access.create && (
              <Col sm={6}>
                <div className="float-right d-md-block">
                  <Link className="btn btn-primary waves-effect waves-light" to="manage-products" role="button">
                    Add Product
                  </Link>
                </div>
              </Col>
            )}
          </Row>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                    <div className="row">
                      <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                        <DataTable>
                          <THead>
                            <TRow>
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Image',
                                  width: 150,
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Name',
                                  width: 100,
                                  enableSearch: true,
                                  enableSort: true,
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Category',
                                  width: 50,
                                  sublist: 'Category',
                                  coltype: 'filter',
                                  filterapi: 'product-category?page=0&take=10',
                                  sublistFilterField: 'category',
                                  filterClassName: 'app-l-condensed-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Price',
                                  width: 80,
                                  enableSort: true,

                                  enableSearch: true,
                                  className: 'app-l-condensed-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Source Url',
                                  enableSearch: true,
                                  enableSort: true,
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Shop',
                                  width: 50,
                                  sublist: 'Shop',
                                  coltype: 'filter',
                                  filterapi: 'shop?sortField=name&sortOrder=ASC',
                                  sublistFilterField: 'shop',
                                  filterClassName: 'app-l-condensed-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Status',
                                  width: 50,
                                  sublist: 'Status',
                                  coltype: 'filter',
                                  filterapi: 'product-status',
                                  sublistFilterField: 'status',
                                  filterClassName: 'app-l-100-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Action',

                                  width: 120,
                                  className: 'action',
                                })}
                              />
                            </TRow>
                          </THead>
                          {this.state.showLightBox ? (
                            <Lightbox
                              mainSrc={this.state.imageUrl ? this.state.imageUrl : error404Img}
                              onImageLoadError={() => {
                                this.setState({ imageUrl: error404Img });
                              }}
                              clickOutsideToClose
                              enableZoom
                              onCloseRequest={() => this.setState({ showLightBox: false })}
                            />
                          ) : null}
                          <TBody>
                            {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                              ? apiResponse.rows.map((row, i) => (
                                  <TRow key={`${i}-productKey`}>
                                    <TCol>
                                      <a href="#imageLink">
                                        <img
                                          onClick={() => this.setState({ showLightBox: true, imageUrl: row.mediaUrl })}
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = error404Img;
                                          }}
                                          data-dz-thumbnail=""
                                          height="50"
                                          className="avatar-sm rounded bg-light"
                                          alt="avatar"
                                          src={row.mediaUrl ? row.mediaUrl : error404Img}
                                        />
                                      </a>
                                    </TCol>
                                    <TCol>{row.name}</TCol>
                                    <TCol>{row.categoryName}</TCol>
                                    <TCol style={{ whiteSpace: 'nowrap' }}>
                                      <i>€ </i>
                                      {row.price}
                                    </TCol>
                                    <TCol>
                                      <div className="sourceurl">
                                        <a
                                          href={
                                            row.sourceUrl && ['http://', 'https://'].some((item) => row.sourceUrl.includes(item))
                                              ? row.sourceUrl
                                              : `https://${row.sourceUrl}`
                                          }
                                          target="_new"
                                        >
                                          {row.sourceUrl}
                                        </a>
                                      </div>
                                    </TCol>
                                    <TCol>{row.shopName}</TCol>
                                    <TCol>
                                      {access && access.edit ? (
                                        <StatusSwitch
                                          isListIcon
                                          value={row.statusId}
                                          name="statusId"
                                          rowData={row}
                                          updateApi={`product/${row.id}`}
                                          valueObj={{ on: 1, off: 2 }}
                                          apiResponse={apiResponse}
                                          setParentState={this.setParentState}
                                        />
                                      ) : (
                                        row.statusTitle
                                      )}
                                    </TCol>
                                    <TCol>
                                      <div className="action-col">
                                        {access && access.edit && <ActionButton value="edit" rowId={row.id} url="manage-products/" />}
                                        <ActionButton value="view" rowId={row.id} url="view-products/" />
                                        {access && access.delete && (
                                          <ActionButton
                                            value="delete"
                                            rowId={row.id}
                                            url="list-products"
                                            setJsonUrl={this.setJsonUrl}
                                            setpredicates={this.setpredicates}
                                            api="product/"
                                          />
                                        )}
                                      </div>
                                    </TCol>
                                  </TRow>
                                ))
                              : null}
                          </TBody>
                        </DataTable>
                        {apiResponse.rows && apiResponse.rows.length === 0 && <div className="text-center no-content-block">No results found </div>}
                      </DataTableHolder>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ListProductContainer;
