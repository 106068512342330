import React from 'react';
import { Col, Container, Card, CardBody } from 'reactstrap';
import { put } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';

import LinkButton from '../../SharedComponents/linkButton';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import SelectFromTemplatesModal from '../Template/selectFromTemplatesModal';

class ManageBackgroundImage extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      templateModal: false,
      backgroundImageId: null,
      backgroundImageUrl: '',
      backgroundColourCode: '',
    };
  }

  toast = new ToastNotification();

  componentDidMount() {
    let { backgroundImageId, backgroundImageUrl, backgroundColourCode } = this.props;
    if (backgroundImageId) {
      this.setState({ backgroundImageId });
    }
    if (backgroundImageUrl) {
      this.setState({ backgroundImageUrl });
    }
    if (backgroundColourCode) {
      this.setState({ backgroundColourCode });
    }
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      templateModal: !prevState.templateModal,
    }));
  };

  setTemplateAsBackgroundImage = (template) => {
    if (template.id > 0) {
      this.toggleModal();
    }
    if (this.state.backgroundImageId !== template.id) {
      put(`obituary-profile/${this.props.obituaryId}`, { backgroundImageId: template.id })
        .then((response) => {
          if (response.data && response.data.data && response.data.success) {
            this.toast.show('update_success', 'SUCCESS', 'Background Image updated successfully');
            this.setState({
              backgroundImageId: template.id > 0 ? template.id : null,
              backgroundImageUrl: template.mediaUrl,
              backgroundColourCode: template.hexCode,
            });
            this.props.setObituaryProfileState(template.id > 0 ? template.id : null, template.mediaUrl, template.hexCode);
          } else {
            this.toast.show('update_error', 'ERROR', 'Could not update background image, please try later.');
          }
        })
        .catch(() => {});
    }
  };

  render() {
    let { templateModal, backgroundImageId, backgroundImageUrl, backgroundColourCode } = this.state;
    let { access } = this.props;
    return (
      <React.Fragment>
        <Col sm={12}>
          <div className="float-right d-block">
            {access && (access.create || access.edit) && (
              <LinkButton
                toString="manageBackground"
                className="btn btn-primary waves-effect waves-light"
                role="button"
                toDoFunction={this.toggleModal}
              >
                {backgroundImageId > 0 ? 'Change ' : 'Add '}Background
              </LinkButton>
            )}
            {access && (access.edit || access.delete) && backgroundImageId > 0 && (
              <LinkButton
                toString="deleteBackground"
                className="btn btn-danger waves-effect waves-light ml-2"
                role="button"
                toDoFunction={() => this.setTemplateAsBackgroundImage({ id: -10, mediaUrl: null, hexCode: null })}
              >
                Remove Background
              </LinkButton>
            )}
          </div>
        </Col>
        {backgroundImageId > 0 ? (
          <Col>
            <Card className="mt-4 mb-0">
              <Container className="px-0">
                {backgroundColourCode ? (
                  <div
                    style={{
                      background: backgroundColourCode,
                      padding: `${10}px`,
                      minWidth: `${100}%`,
                      maxHeight: `${100}%`,
                      height: `${179}px`,
                      objectFit: 'fill',
                      borderRadius: `${20}px`,
                    }}
                  />
                ) : (
                  <img src={backgroundImageUrl} alt="background" height="100%" width="100%" />
                )}
              </Container>
            </Card>
          </Col>
        ) : (
          <CardBody className="text-center">Background Image has not been set</CardBody>
        )}
        {templateModal && (
          <SelectFromTemplatesModal
            modal={templateModal}
            showModal={this.toggleModal}
            title="Background Image Templates"
            hide={false}
            cancelButtonName="Cancel"
            confirmButtonName="Use"
            cancelButtonClick={this.toggleModal}
            confirmButtonClick={this.setTemplateAsBackgroundImage}
            renderapi="background-template?isAll=true&statusId=1"
            templateType="background-image"
          />
        )}
      </React.Fragment>
    );
  }
}

export default ManageBackgroundImage;
