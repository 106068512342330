import React, { useEffect, useState } from 'react';
import { get, post, put } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import { DataTable, THead, TBody, TCol, TRow } from '../../SharedComponents/Table/tableElements';
import { DataColHead } from '../../SharedComponents/ListComponents/components';

const toast = new ToastNotification();

const ManageObituarySettings = (props) => {
  let { obituaryId, disabled } = props;
  let [obituarySettings, setObituarySettings] = useState({});

  useEffect(() => {
    if (Object.keys(obituarySettings).length === 0 && obituaryId > 0) {
      get('post-type?hasVisibilitySettings=1')
        .then((response) => {
          response.data = response.data.data;
          if (response.data && response.data.rows && response.data.rows.length > 0) {
            response.data.rows.forEach((row) => {
              if (/\bTribute\b/i.test(row.title)) {
                setObituarySettings((prevState) => ({
                  ...prevState,
                  Tribute: {
                    typeId: 1,
                    settingsId: -1,
                    isVisible: true,
                  },
                }));
              } else {
                setObituarySettings((prevState) => ({
                  ...prevState,
                  [row.title]: {
                    typeId: row.id,
                    settingsId: -1,
                    isVisible: true,
                  },
                }));
              }
            });
          }
        })
        .catch(() => {
          toast.show('fetch_error', 'ERROR', 'Could not fetch post type details, please try again later.');
        });
      get(`obituary-settings?obituaryId=${obituaryId}&isAll=true`)
        .then((response) => {
          response.data = response.data.data;
          if (response.data && response.data.rows && response.data.rows.length > 0) {
            response.data.rows.forEach((row) => {
              if (/\bTribute\b/i.test(row.typeTitle)) {
                setObituarySettings((prevState) => ({
                  ...prevState,
                  Tribute: {
                    typeId: 1,
                    settingsId: row.id,
                    isVisible: row.isVisible.data[0] != 0,
                  },
                }));
              } else {
                setObituarySettings((prevState) => ({
                  ...prevState,
                  [row.typeTitle]: {
                    typeId: row.typeId,
                    settingsId: row.id,
                    isVisible: row.isVisible.data[0] != 0,
                  },
                }));
              }
            });
          }
        })
        .catch(() => {
          toast.show('fetch_error', 'ERROR', 'Could not fetch visibility settings.');
        });
    }
  }, []);

  let handleOnChange = (typeTitle) => {
    let model = { ...obituarySettings[typeTitle] };
    model.isVisible = !model.isVisible;
    if (model.settingsId > 0) {
      put(`obituary-settings/${model.settingsId}`, { isVisible: model.isVisible }).then((response) => {
        if (response.data.data && response.data.success) {
          toast.show('update_success', 'SUCCESS', 'Visibility changed successfully.');
          setObituarySettings((prevState) => ({
            ...prevState,
            [typeTitle]: model,
          }));
        } else {
          toast.show('update_error', 'ERROR', 'Could not change visibility settings.');
        }
      });
    } else {
      let newModel = {
        obituaryId,
        typeId: model.typeId,
        isVisible: false,
      };
      post('obituary-settings', newModel)
        .then((response) => {
          if (response.data.data && response.data.success) {
            toast.show('create_success', 'SUCCESS', 'Visibility changed successfully.');
            setObituarySettings((prevState) => ({
              ...prevState,
              [typeTitle]: {
                ...model,
                settingsId: response.data.data.id,
              },
            }));
          } else {
            toast.show('create_error', 'ERROR', 'Could not change visibility settings.');
          }
        })
        .catch((err) => toast.show('create_fail', 'ERROR', err.message ? err.message : 'Visibility change failed.'));
    }
  };

  return (
    <React.Fragment>
      <p>Toggle visibility to set the post types to be displayed in this profile</p>
      <DataTable>
        <THead>
          <TRow>
            <DataColHead title="Post Types" haveResuls=" " style={{ textAlign: 'center' }} />
            <DataColHead title="Visibility" haveResuls=" " style={{ textAlign: 'center' }} />
          </TRow>
        </THead>
        <TBody>
          {Object.keys(obituarySettings).length > 0
            ? Object.keys(obituarySettings).map((key, i) => (
                <TRow key={`post-type-${i}`}>
                  <TCol>{key}</TCol>
                  <TCol>
                    <div className="__checkbox text-center">
                      <input
                        type="checkbox"
                        name="checkboxApproval"
                        id={`checkboxApproval${i}`}
                        checked={obituarySettings[key].isVisible}
                        onChange={() => handleOnChange(key)}
                        {...{ disabled }}
                      />
                      <label htmlFor={`checkboxApproval${i}`} />
                    </div>
                  </TCol>
                </TRow>
              ))
            : null}
        </TBody>
      </DataTable>
    </React.Fragment>
  );
};

export default ManageObituarySettings;
