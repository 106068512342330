import React from 'react';

import CustomModal from '../../SharedComponents/customModal';
import ManageUser from './manageUser';

const formRef = {};
export const onConfirmButtonClick = async (e) => {
  await formRef.onFormSubmit(e);
};
export default ({ modal, toggleModal, title, createdItemState, isRolesDisable, setParentState, isSingleNotObjectState }) => {
  formRef.onFormSubmit = {};
  return (
    <CustomModal
      modal={modal}
      toggleModal={toggleModal}
      title={title}
      buttonList={[{ name: 'Save', color: 'primary', onClick: onConfirmButtonClick }, { name: 'Cancel' }]}
      className="modal-lg"
      maxHeight={400}
    >
      {' '}
      <ManageUser
        formRef={formRef}
        isModal
        isRolesDisable={isRolesDisable}
        createdItemState={createdItemState}
        isSingleNotObjectState={isSingleNotObjectState}
        setParentState={setParentState}
      />
    </CustomModal>
  );
};
