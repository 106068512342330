import React from 'react';
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Row, Col, Table } from 'reactstrap';
import { DataTable, TBody, THead, TCol, TRow, HeadingBlock, DataTableHolder } from '../../SharedComponents/Table/tableElements';
import { DataColHead, ListPagination, ViewDate } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import Loader from '../../Components/loader';
// import { get } from '../../Libraries/Ajax/httpService';
import { StatusSwitch } from '../../SharedComponents/FormHelpers/Controls/statusSwitch';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';
import { DefaultImage } from '../User/defaultImage';
import error404Img from '../../assets/images/errorImg.png';

class ListReportedObituaries extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      activeArray: [],
      singleSelect: false,
      multiSelect: false,
      rowData: {},
      showLightBox: false,
      isLoaderActive: false,
      viewURL: 'view-orders/',
      viewProduct: 'view-products/',
      productList: [],
      obituaryList: [],
      statusId: {
        renderapi: 'delivery-status?page=0&take=10',
        placeholder: 'Enter status',
        className: 'mb-0',
        controlId: 'exampleForm.ControlSelect1',
        name: 'statusId',
        optionvalue: 'id',
        optiontext: 'title',
      },
      deliveryOptions: [],
      showModal: false,
      currentOrder: {},
    };
  }

  componentDidMount() {
    this.setJsonUrl('report-memorial/report-details');
    this.setpredicates({ take: 10, page: 0 });
    this.setState({ access: setComponentPrivilege('Reported Obituaries') });
  }

  onLightBoxClick = (row) => {
    this.setState({
      showLightBox: true,
      imageUrl: row.userImageUrl,
    });
  };

  onStatusChange = (e, rowData) => {
    let row = { ...rowData, statusId: parseInt(e.target.value) };
    let rows = this.state.apiResponse.rows.map((item) => {
      if (item.id === row.id) return row;
      return item;
    });
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      currentOrder: row,
      apiResponse: { ...this.state.apiResponse, rows },
    }));
  };

  onToggle = (reload) => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
    if (reload) this.setpredicates({});
  };

  onColClick = (e, id) => {
    this.props.history.push(`${id}`);
  };

  renderTable = (id) => {
    let { activeArray } = this.state;
    if (activeArray.includes(id)) {
      let newArray = activeArray.filter((element) => element != id);
      this.setState({ activeArray: newArray });
    } else {
      this.setState({
        toggleButton: !this.state.toggleButton,
        activeArray: [...activeArray, id],
      });
    }
  };

  render() {
    let { apiResponse, isLoaderActive, access, activeArray } = this.state;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              {isLoaderActive === true ? <Loader /> : ''}
              <HeadingBlock title="Reported Obituaries" />
            </Col>
            <Col sm={6} />
          </Row>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                    <div className="row">
                      <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                        <DataTable>
                          <THead>
                            <TRow>
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Obituary Profile',
                                  enableSearch: true,
                                  searchField: 'memorialName',
                                  // filterapi: 'report-memorial',
                                  enableSort: true,
                                  sortField: 'name',
                                  className: 'app-l-search-column',
                                })}
                                // </div>
                              />

                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Published Date',
                                  enableSort: true,
                                  sortField: 'publishedDate',
                                  width: 90,
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Profile Owner',
                                })}
                              />

                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Report Count ',
                                })}
                              />

                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Status',
                                  width: 70,
                                })}
                              />
                            </TRow>
                          </THead>
                          <TBody>
                            {this.state.showLightBox ? (
                              <Lightbox
                                mainSrc={this.state.imageUrl}
                                clickOutsideToClose
                                enableZoom
                                onCloseRequest={() => this.setState({ showLightBox: false })}
                              />
                            ) : null}
                            {this.state.showLightBox ? (
                              <Lightbox
                                mainSrc={this.state.imageUrl ? this.state.imageUrl : error404Img}
                                onImageLoadError={() => {
                                  this.setState({ imageUrl: error404Img });
                                }}
                                clickOutsideToClose
                                enableZoom
                                onCloseRequest={() => this.setState({ showLightBox: false })}
                              />
                            ) : null}
                            {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                              ? apiResponse.rows.map((row, i) => (
                                  <>
                                    <TRow key={`report-tribute-${i}`}>
                                      <TCol>
                                        <span className="arrow-pointer">
                                          <i
                                            onClick={() => {
                                              this.renderTable(row.id);
                                            }}
                                            className={activeArray.includes(row.id) ? 'fas fa-angle-down' : 'fas fa-angle-right'}
                                          />
                                          <Link
                                            to={{ pathname: `${process.env.REACT_APP_FRONTEND_URL}pages/view-memorial/${row.id}` }}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {' '}
                                            {row.name}{' '}
                                          </Link>
                                        </span>
                                      </TCol>

                                      <TCol>
                                        <div className="sourceurl">
                                          <ViewDate isTableView dateValue={row.publishedDate} />
                                        </div>
                                      </TCol>
                                      <TCol>
                                        <Link to={`/view-users/${row.ownerId}`} target="_blank" rel="noreferrer">
                                          {' '}
                                          {row.ownerName}{' '}
                                        </Link>
                                      </TCol>
                                      <TCol>{row.reportCount} </TCol>
                                      <TCol>
                                        {' '}
                                        {access && access.edit && (
                                          <StatusSwitch
                                            isListIcon
                                            value={row.isBlockedByAdmin}
                                            name="isBlockedByAdmin"
                                            rowData={row}
                                            updateApi={`obituary-profile/${row.id}`}
                                            apiResponse={apiResponse}
                                            setParentState={this.setParentState}
                                            valueObj={{ on: false, off: true }}
                                          />
                                        )}
                                        {!(access && access.edit) && (row.isBlockedByAdmin ? 'Disabled' : 'Enabled')}
                                      </TCol>
                                    </TRow>

                                    {row?.reportedObituaryArray && activeArray.includes(row.id) ? (
                                      <TRow>
                                        <TCol colSpan="5">
                                          {/* <DataTable> */}
                                          <Table>
                                            <THead>
                                              <DataColHead
                                                {...this.setheadproperties({
                                                  title: 'Reporting User',
                                                })}
                                              />

                                              <DataColHead
                                                {...this.setheadproperties({
                                                  title: 'Profile Picture',
                                                })}
                                              />
                                              <DataColHead
                                                {...this.setheadproperties({
                                                  title: 'Remark',
                                                })}
                                              />

                                              <DataColHead
                                                {...this.setheadproperties({
                                                  title: 'Date  ',
                                                })}
                                              />
                                            </THead>
                                            <TBody>
                                              {row.reportedObituaryArray.map((innerRow, j) => (
                                                <TRow key={j}>
                                                  <TCol>
                                                    <Link to={`/view-users/${innerRow.reportedUserId}`} target="_blank" rel="noreferrer">
                                                      {' '}
                                                      {innerRow.reportedUserName}{' '}
                                                    </Link>
                                                  </TCol>
                                                  <TCol>
                                                    <div>
                                                      {innerRow.userImageUrl ? (
                                                        <img
                                                          onClick={() => this.onLightBoxClick(innerRow)}
                                                          data-dz-thumbnail=""
                                                          height="50"
                                                          className="avatar-sm rounded bg-light"
                                                          alt="User "
                                                          src={innerRow.userImageUrl}
                                                        />
                                                      ) : (
                                                        <div className="default-image-container">
                                                          <DefaultImage rowData={innerRow} firstName="reportedUserName" />
                                                        </div>
                                                      )}
                                                    </div>
                                                  </TCol>
                                                  <TCol>{innerRow.remark}</TCol>
                                                  <TCol>
                                                    {' '}
                                                    <ViewDate isTableView dateValue={innerRow.createdDate} />
                                                  </TCol>
                                                </TRow>
                                              ))}
                                            </TBody>
                                          </Table>
                                        </TCol>
                                      </TRow>
                                    ) : null}
                                  </>
                                ))
                              : null}
                          </TBody>
                        </DataTable>
                        {apiResponse.rows && apiResponse.rows.length === 0 && <div className="text-center no-content-block">No results found </div>}
                      </DataTableHolder>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ListReportedObituaries;
