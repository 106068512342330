import React from 'react';
import { Container } from 'react-bootstrap';
import { Row, Col, Card, CardBody, FormGroup, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import FormInput from '../../SharedComponents/FormHelpers/Controls/formInput';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import { get, post, put } from '../../Libraries/Ajax/httpService';
import DragAndDrop from '../../SharedComponents/FormHelpers/Controls/dragAndDrop';
import Loader from '../../Components/loader';
import DropDown from '../../SharedComponents/FormHelpers/Controls/dropDown';
import { DeleteRow } from '../../SharedComponents/ListComponents/manageComponent';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';

import BackgroundTemplate from './backgroundTemplate';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';

class ManageTemplateMessages extends FormComponent {
  constructor(props) {
    super(props);
    this.setProperties = this.setProperties.bind(this);
    this.setFormProperties = this.setFormProperties.bind(this);
    this.state = {
      isSubmitted: true,
      singleRow: {},
      action: 'Add',
      editFlag: false,
      page: '',
      id: {
        label: 'Id',
        type: 'hidden',
      },
      genderId: {
        label: 'Gender',
        renderapi: 'gender?page=0&take=10',
        placeholder: 'Enter gender',
        className: 'mb-0',
        controlId: 'exampleForm.ControlSelect1',
        name: 'genderId',
        optionvalue: 'id',
        optiontext: 'title',
        rules: {},
      },
      typeId: {
        label: 'Type',
        renderapi: 'post-type?isImage=false&isAll=true',
        placeholder: 'Enter type',
        className: 'mb-0',
        controlId: 'exampleForm.ControlSelect1',
        name: 'typeId',
        optionvalue: 'id',
        optiontext: 'title',
        rules: {},
      },
      message: {
        label: 'Message Template',
        name: 'message',
        type: 'text',
        placeholder: 'Enter message',
        rules: {
          maxLength: 10000,
        },
      },
      ageTo: {
        relatedPropertyName: 'ageFrom',
        label: 'Age To',
        type: 'number',
        placeholder: 'Enter age to',
        allowZero: true,
        rules: {
          number: true,
          greaterNumber: true,
        },
      },
      ageFrom: {
        label: 'Age From',
        type: 'number',
        allowZero: true,
        placeholder: 'Enter age from',
        rules: {
          number: true,
        },
      },
      image: {
        label: 'Image',
        type: 'text',
        placeholder: 'Enter image',
        rules: {},
        name: 'image',
        fileUploadUrl: 'template-message/upload',
      },

      backgroundImage: {
        label: 'Background Image',
        type: 'text',
        placeholder: 'Enter image',
        rules: {},
        name: 'backgroundImage',
        fileUploadUrl: 'background-template/upload',
        errors: ['Background Image is required'],
      },
      colors: {},
    };
  }

  match(required) {
    required.forEach((field) => {
      if (Object.hasOwnProperty.call(this.state, field)) {
        this.setState({
          [field]: {
            ...this.state[field],
            rules: { ...this.state[field].rules, required: true },
          },
        });
      }
    });
  }

  componentDidMount() {
    let { value, id } = this.props.match.params;
    let page = '';
    if (value == 'message') {
      page = 'Message Templates';
      let required = ['message', 'ageFrom', 'ageTo', 'typeId', 'genderId'];
      this.match(required);
    } else if (value == 'background-image') {
      page = 'Background Images';
      let required = ['backgroundImage'];
      this.match(required);
    } else if (value == 'tribute-card') {
      page = 'Tribute Cards';
      let required = ['image'];
      this.match(required);
    }
    this.setState({ access: setComponentPrivilege(page) });

    if (id) {
      this.setState({ editFlag: true, action: 'Edit', isLoaderActive: true });

      if (value == 'background-image' || value == 'solid-colors') {
        get(`background-template/${this.props.match.params.id}`).then((response) => {
          let colorsValues = {
            nameColor: response.data.data.nameColor,
            dateColor: response.data.data.dateColor,
            locationColor: response.data.data.locationColor,
            locationIconColor: response.data.data.locationIconColor,
            lipIconColor: response.data.data.lipIconColor,
            tagColor: response.data.data.tagColor,
            solidColor: response.data.data.solidColor,
          };
          this.setState({ isLoaderActive: false, singleRow: response.data.data, colors: colorsValues });
        });
      } else {
        this.setState({ isLoaderActive: false });
        get(`template-message/${this.props.match.params.id}`).then((response) => {
          this.setState({ singleRow: response.data.data });
        });
      }
    }
  }

  titleName = (str) => {
    let words = str.match(/([^-]+)/g) || [];
    words.forEach((word, i) => {
      words[i] = word[0].toUpperCase() + word.slice(1);
    });
    return words.join(' ');
  };

  onDidUpdate = () => {
    this.setState({ page: this.props.match.params.value });
  };

  handleSetColorsValue = (colorsValue) => {
    this.setState({ colors: { ...colorsValue } });
  };

  componentDidUpdate() {
    let {
      match: { params },
    } = this.props;
    if (this.state.singleRow) {
      this.mapJsonToState(this.state.singleRow);
    }
    if (this.state.page !== this.props.match.params.value) {
      document.title = `Manage ${this.titleName(params.value)} | Memlo - Admin Panel`;
      this.onDidUpdate();
    }
  }

  validExtensions = ['jpeg', 'jpg', 'png'];

  toast = new ToastNotification();

  onCancelButtonClick = () => {
    this.props.history.push(`/list-template/${this.state.page}`);
  };

  onSaveButtonClick = (model) => {
    let { colors, page } = this.state;
    if (this.state.page == 'background-image' || this.state.page == 'solid-colors') {
      model = { ...model, ...colors };
      if (this.props.match.params.id) {
        put(`background-template/${this.props.match.params.id}`, model).then((response) => {
          this.setState({ isLoaderActive: false });
          if (response.data?.success) {
            this.toast.show(
              'update_success',
              'SUCCESS',
              response.data?.message ? response.data.message : `${this.titleName(page)} successfully updated`,
            );
            this.props.history.push(`/list-template/${this.state.page}`);
          } else this.toast.show('update_error', 'ERROR', response.data?.message ? response.data.message : 'Something went wrong');
        });
      } else {
        this.setState({ isLoaderActive: true });

        post('background-template', model).then((response) => {
          this.setState({ isLoaderActive: false });
          if (response.data?.success) {
            this.toast.show(
              'create_success',
              'SUCCESS',
              response.data?.message ? response.data.message : `${this.titleName(page)} successfully added`,
            );
            this.props.history.push(`/list-template/${this.state.page}`);
          } else this.toast.show('create_error', 'ERROR', response.data?.message ? response.data.message : 'Something went wrong');
        });
      }
    } else {
      if (this.state.page === 'tribute-card') {
        model.typeId = 2;
      }

      this.setState({ isLoaderActive: true });

      if (this.props.match.params.id) {
        put(`template-message/${this.props.match.params.id}`, model).then((response) => {
          this.setState({ isLoaderActive: false });
          if (response.data?.success) {
            this.toast.show('update_success', 'SUCCESS', response.data?.message ? response.data.message : 'Product successfully updated');
            this.props.history.push(`/list-template/${this.state.page}`);
          } else this.toast.show('update_error', 'ERROR', response.data?.message ? response.data.message : 'Something went wrong');
        });
      } else {
        post('template-message', model).then((response) => {
          this.setState({ isLoaderActive: false });
          if (response.data?.success) {
            this.toast.show('create_success', 'SUCCESS', response.data?.message ? response.data.message : 'Product successfully created');
            this.props.history.push(`/list-template/${this.state.page}`);
          } else this.toast.show('create_error', 'ERROR', response.data?.message ? response.data.message : 'Something went wrong');
        });
      }
    }
  };

  render() {
    let { editFlag, access, isLoaderActive, singleRow, page, colors, backgroundImage } = this.state;
    let { history } = this.props;
    let pageName = this.titleName(page);
    if (pageName === 'Message') pageName = 'Message Template';

    return (
      <React.Fragment>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18"> {pageName} Details</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/list-template/${page}`}> {pageName} </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {this.state.action} {pageName}
                  </li>
                </ol>
              </div>
            </Col>
            {this.state.editFlag && (
              <Col sm="6">
                <div className="float-right d-md-block">
                  {access && access.delete && (
                    <DeleteRow
                      renderapi={
                        page !== 'background-image' && page !== 'solid-colors'
                          ? `template-message/${this.props.match.params.id}`
                          : `background-template/${this.props.match.params.id}`
                      }
                      model={singleRow}
                      redirecturl={`/list-template/${page}`}
                      deleteText="Delete"
                      history={history}
                      isCardViewTrue="True"
                    />
                  )}{' '}
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Container fluid className="px-0">
              <div className="col-12">
                <Card>
                  <CardBody>
                    <SubmitForm onSubmit={this.onSaveButtonClick} {...this.setFormProperties({ disableReactiveForm: true })}>
                      <Row>
                        {page == 'message' && (
                          <>
                            <Col xs={12} md={6}>
                              <DropDown {...this.setProperties(this.state.genderId)} />
                            </Col>
                            <Col xs={12} md={6}>
                              <DropDown {...this.setProperties(this.state.typeId)} />
                            </Col>
                            <Col xs={12} md={6}>
                              <FormInput {...this.setProperties(this.state.ageFrom)} />
                            </Col>
                            <Col xs={12} md={6}>
                              <FormInput {...this.setProperties(this.state.ageTo)} />
                            </Col>
                            <Col xs={12} md={12}>
                              <FormInput rows={5} as="textarea" {...this.setProperties(this.state.message)} />
                            </Col>
                          </>
                        )}
                      </Row>
                      {page !== 'message' && page !== 'background-image' && page !== 'solid-colors' && (
                        <Row>
                          <div className="col-12">
                            <DragAndDrop
                              validExtensions={this.validExtensions}
                              mediaUrl={singleRow.mediaUrl}
                              editFlag={editFlag}
                              allowCrop
                              aspectRatio={`${1 / 1}`}
                              {...this.setProperties(this.state.image)}
                            />
                          </div>
                        </Row>
                      )}
                      {/* // NEWLY ADDED */}
                      {page !== 'message' && (page == 'background-image' || page == 'solid-colors') && (
                        <React.Fragment>
                          <p className="font-weight-bolder font-size-17 ">Font Color Settings</p>
                          <Row>
                            <BackgroundTemplate
                              setColorsValue={this.handleSetColorsValue}
                              editFlag={editFlag}
                              colors={colors}
                              backgroundImage={backgroundImage}
                              solidColor={page == 'solid-colors'}
                            />
                            {page !== 'solid-colors' && (
                              <div className="col-12">
                                <DragAndDrop
                                  validExtensions={this.validExtensions}
                                  mediaUrl={singleRow.mediaUrl}
                                  editFlag={editFlag}
                                  allowCrop
                                  aspectRatio={`${13 / 4}`}
                                  customLabel="Upload Background Image"
                                  {...this.setProperties(backgroundImage)}
                                />
                              </div>
                            )}
                          </Row>
                        </React.Fragment>
                      )}
                      <FormGroup className="mb-0">
                        <div className="float-right d-md-block">
                          <br />
                          <Button type="submit" color="primary" className="mr-1">
                            Save
                          </Button>{' '}
                          <Button className="btn-secondary" type="reset" onClick={this.onCancelButtonClick}>
                            <i className="icon-close"> </i> <span> Cancel </span>
                          </Button>
                        </div>
                      </FormGroup>
                    </SubmitForm>
                  </CardBody>
                </Card>
              </div>
            </Container>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
export default ManageTemplateMessages;
