import React, { Component } from 'react';
import { Switch, Router } from 'react-router-dom';
import { connect } from 'react-redux';

// Import Routes
import { authProtectedRoutes, publicRoutes } from './Routes';
import AppRoute from './Routes/route';

// layouts
import VerticalLayout from './Components/VerticalLayout';
import HorizontalLayout from './Components/HorizontalLayout';
import NonAuthLayout from './Components/nonAuthLayout';

import './assets/scss/theme.scss';
import history from './Helpers/history';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { routes: [] };
    this.getLayout = this.getLayout.bind(this);
  }

  /**
   * Returns the layout
   */
  componentDidMount() {
    this.setValidRoutes();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.userData?.user?.id != this.props.userData?.user?.id ||
      (prevProps.moduleAccessArray != this.props.moduleAccessArray &&
        this.props.moduleAccessArray &&
        Object.keys(this.props.moduleAccessArray).length > 0)
    ) {
      this.setValidRoutes();
    }
  }

  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  setValidRoutes = () => {
    let userAccess;
    let allAccessFlag;
    let validRoutes = [];
    let { isAllAccess, moduleAccessArray } = this.props;
    if (isAllAccess) {
      allAccessFlag = isAllAccess;
    }
    if (!allAccessFlag && moduleAccessArray) {
      userAccess = { ...moduleAccessArray };
    }
    if (allAccessFlag) {
      validRoutes = authProtectedRoutes;
      this.setState({ routes: authProtectedRoutes });
    } else if (userAccess) {
      validRoutes = authProtectedRoutes.filter((x) => {
        if (x && x.subModule && !Array.isArray(x.subModule)) {
          return x.subModule && userAccess[x.subModule] && userAccess[x.subModule].includes(x.permission);
        }
        if (x && x.subModule && Array.isArray(x.subModule)) {
          let isTrue = false;
          x.subModule.forEach((item) => {
            if (item && userAccess[item] && userAccess[item].includes(x.permission)) {
              isTrue = true;
            }
          });
          return isTrue;
        }
        return true;
      });
      this.setState({ routes: validRoutes });
    } else if (!publicRoutes.some((x) => x.path == window.location.pathname) && !window.location.pathname.includes('login')) {
      localStorage.setItem('nextUrl', window.location.pathname + window.location.search);
      history.push('/login');
    }
  };

  render() {
    let { defaultMenuUrl } = this.props;
    let Layout = this.getLayout();
    let { routes } = this.state;

    return (
      <React.Fragment>
        <Router history={history}>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute path={route.path} layout={NonAuthLayout} component={route.component} key={idx} isAuthProtected={false} />
            ))}
            {routes &&
              routes.length > 0 &&
              routes.map((route, idx) => {
                if (route.redirect) {
                  return <AppRoute path={route.path} layout={Layout} component={() => route.component(defaultMenuUrl)} key={idx} isAuthProtected />;
                }
                return <AppRoute path={route.path} layout={Layout} component={route.component} key={idx} isAuthProtected />;
              })}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  layout: state.Layout,
  defaultMenuUrl: state.MasterData.defaultMenuUrl,
  userData: state.Login.userData,
  isAllAccess: state.Login.isAllAccess,
  moduleAccessArray: state.Login.moduleAccessArray,
});

export default connect(mapStateToProps, null)(App);
