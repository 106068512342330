import React from 'react';
import { Button } from 'react-bootstrap';

export default ({ cancelButtonClick, children, title, cancelButtonName, confirmButtonName, confirmButtonClick, hide }) => (
  <div className="modal-open">
    <div
      // className="fade modal show"
      className="modal show"
      style={{ display: 'block', backgroundColor: 'rgb(0, 0, 0,0.58)' }}
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">{title}</h3>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer">
            <Button type="submit" variant="primary" onClick={(e) => (confirmButtonClick ? confirmButtonClick(e) : null)} hidden={!!hide}>
              {confirmButtonName}
            </Button>
            <Button type="button" onClick={(e) => cancelButtonClick(e)} variant="secondary">
              {cancelButtonName}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
);
