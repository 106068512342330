/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Card, CardBody, Button, Modal } from 'reactstrap';
import { Table } from 'react-bootstrap';
// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// To make rows collapsible
import 'bootstrap/js/src/collapse';
import {} from 'bootstrap';

import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';
import { DataTable, TBody, THead, TCol, TRow, HeadingBlock, DataTableHolder } from '../../SharedComponents/Table/tableElements';
import { DataColHead, ListPagination, ViewDate } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import Loader from '../../Components/loader';
import { StatusSwitch } from '../../SharedComponents/FormHelpers/Controls/statusSwitch';
import { ContentItems } from '../../SharedComponents/ListComponents/manageComponent';
import userpic from '../../assets/images/userpic.png';

class ListReportTributeContainer extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleSelect: false,
      multiSelect: false,
      isViewTributePopup: false,
      rowData: {},
      isLoaderActive: false,
      showDeleteConfirmation: false,
      toggleButton: false,
      viewURL: 'view-obituary-profiles/1',
      activeArray: [],
    };
  }

  componentDidMount() {
    this.setJsonUrl('report-tribute/report-details');
    this.setpredicates({ take: 10, page: 0 });
    this.setState({ access: setComponentPrivilege('Reported Tributes') });
  }

  renderTable = (id) => {
    let { activeArray } = this.state;
    if (activeArray.includes(id)) {
      let newArray = activeArray.filter((element) => element != id);
      this.setState({ activeArray: newArray });
    } else {
      this.setState({
        toggleButton: !this.state.toggleButton,
        activeArray: [...activeArray, id],
      });
    }
  };

  callTributeViewPopup = (model) => {
    this.setState({
      isViewTributePopup: true,
      singleRow: model,
    });
  };

  onCloseButtonClick = () => {
    this.setState({
      isViewTributePopup: false,
    });
  };

  showModal = () => {
    this.setState({
      isViewTributePopup: !this.state.isViewTributePopup,
    });
  };

  render() {
    let { apiResponse, isLoaderActive, activeArray, access, isViewTributePopup, singleRow } = this.state;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              {isLoaderActive === true ? <Loader /> : ''}
              <HeadingBlock title="Reported Tributes" />
            </Col>
          </Row>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                    <div className="row">
                      <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                        <DataTable>
                          <THead>
                            <TRow>
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Tribute post',
                                  width: 110,
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Obituary Profile',
                                })}
                              />

                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Owner',
                                })}
                              />

                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Report Count ',
                                })}
                              />

                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Status',
                                  width: 70,
                                })}
                              />
                            </TRow>
                          </THead>

                          <TBody>
                            {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                              ? apiResponse.rows.map((row, i) => (
                                  <>
                                    <TRow key={`report-tribute-${i}`}>
                                      <TCol>
                                        <span className="arrow-pointer">
                                          <i
                                            onClick={() => {
                                              this.renderTable(row.id);
                                            }}
                                            className={activeArray.includes(row.id) ? 'fas fa-angle-down' : 'fas fa-angle-right'}
                                          />
                                        </span>
                                        <Button className="app-c-link-btn2" type="button" onClick={() => this.callTributeViewPopup(row)}>
                                          {row.mediaUrl ? (
                                            row.mediaType && (row.mediaType === 'jpg' || row.mediaType === 'png' || row.mediaType === 'jpeg') ? (
                                              <div className="parentRow">
                                                <img
                                                  data-dz-thumbnail=""
                                                  height="50"
                                                  className="avatar-sm rounded bg-light mr-3"
                                                  alt={row.obituaryProfileName}
                                                  src={row.mediaUrl}
                                                />

                                                <div className="childRow">
                                                  <span className="truncate-text" title={row.content}>
                                                    {row.content}
                                                  </span>
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="parentRow">
                                                <video className="avatar-sm rounded bg-light mr-3">
                                                  <source src={row.mediaUrl} type="video/mp4" />

                                                  <track src="captions_en.vtt" kind="captions" label="english_captions" />
                                                </video>

                                                <div className="childRow">
                                                  <span className="truncate-text" title={row.content}>
                                                    {row.content}
                                                  </span>
                                                </div>
                                              </div>
                                            )
                                          ) : (
                                            <span className="truncate-text" style={{ width: '230px' }} title={row.content}>
                                              {row.content}
                                            </span>
                                          )}
                                          {/* </Link>
                                        <Button className="app-c-link-btn2" type="button" onClick={() => this.callTributeViewPopup()}> */}
                                          {/* Load More Responses */}
                                        </Button>
                                      </TCol>
                                      <TCol>
                                        <Link to={`/view-obituary-profiles/general/${row.obituaryId}`} target="_blank" rel="noreferrer">
                                          {row.obituaryName}
                                        </Link>
                                      </TCol>

                                      {/* <TCol>{row.createdByName}</TCol> */}
                                      <TCol>
                                        <Link to={`/view-users/${row.ownerId}`} target="_blank" rel="noreferrer">
                                          {' '}
                                          {row.ownerName}{' '}
                                        </Link>
                                      </TCol>
                                      <TCol>{row.reportCount} </TCol>
                                      <TCol>
                                        {' '}
                                        {access && access.edit && (
                                          <StatusSwitch
                                            isListIcon
                                            value={row.statusId}
                                            name="statusId"
                                            rowData={row}
                                            updateApi={`obituary-post/${row.id}`}
                                            apiResponse={apiResponse}
                                            setParentState={this.setParentState}
                                            valueObj={{ on: 1, off: 2 }}
                                          />
                                        )}
                                        {!(access && access.edit) && (row.statusId === 1 ? 'Enabled' : 'Disabled')}
                                      </TCol>
                                    </TRow>

                                    {row?.reportTribute && activeArray.includes(row.id) ? (
                                      <TRow>
                                        <TCol colSpan="5">
                                          <Table>
                                            <THead>
                                              <DataColHead
                                                {...this.setheadproperties({
                                                  title: 'Reporting User',
                                                })}
                                              />

                                              <DataColHead
                                                {...this.setheadproperties({
                                                  title: 'Remark',
                                                })}
                                              />

                                              <DataColHead
                                                {...this.setheadproperties({
                                                  title: 'Date  ',
                                                })}
                                              />
                                            </THead>
                                            <TBody>
                                              {row.reportTribute.map((innerRow, j) => (
                                                <TRow key={j}>
                                                  <TCol>
                                                    <Link to={`/view-users/${innerRow.reportedUserId}`} target="_blank" rel="noreferrer">
                                                      {' '}
                                                      {innerRow.reportedUserName}{' '}
                                                    </Link>
                                                  </TCol>
                                                  <TCol>{innerRow.remark}</TCol>
                                                  <TCol>
                                                    {' '}
                                                    <ViewDate isTableView dateValue={innerRow.createdDate} />
                                                  </TCol>
                                                </TRow>
                                              ))}
                                            </TBody>
                                          </Table>
                                        </TCol>
                                      </TRow>
                                    ) : null}
                                  </>
                                ))
                              : null}
                          </TBody>
                        </DataTable>
                        {apiResponse.rows && apiResponse.rows.length === 0 && <div className="text-center no-content-block">No results found </div>}
                      </DataTableHolder>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {isViewTributePopup && ( */}
        {/* <Container fluid> */}
        {singleRow && (
          <Modal className="modal-lg" isOpen={isViewTributePopup} toggle={this.showModal}>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                Obituary Post details
              </h5>
              <Button onClick={this.onCloseButtonClick} close data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </Button>
            </div>
            <div className="modal-body">
              <Container fluid className="px-0">
                <Card>
                  <CardBody>
                    {/* TopSection1 */}
                    <Row>
                      {singleRow.content && (
                        <Col xs={12} md={6} lg={4} xl={4}>
                          <ContentItems title="Content">
                            <p>{singleRow.content ? singleRow.content : '-'}</p>
                          </ContentItems>
                        </Col>
                      )}
                      <Col xs={12} md={6} lg={4} xl={4}>
                        <ContentItems title="Type"> {singleRow.typeTitle}</ContentItems>
                      </Col>

                      <Col xs={12} md={6} lg={4} xl={4}>
                        <ContentItems title="Obituary"> {singleRow.obituaryName}</ContentItems>
                      </Col>

                      <Col xs={12} md={6} lg={4} xl={4}>
                        <ContentItems title="Created By"> {singleRow.createdByName}</ContentItems>
                      </Col>
                      <Col xs={12} md={6} lg={4} xl={4}>
                        <ContentItems title="Created Date">
                          <ViewDate isTableView dateValue={singleRow.createdDate} />
                        </ContentItems>
                      </Col>
                      <Col xs={12} md={6} lg={4} xl={4}>
                        <ContentItems title="Report Count">{singleRow.reportCount}</ContentItems>
                      </Col>

                      {singleRow.mediaUrl && (
                        <Col xs={12} md={12} lg={12} xl={12}>
                          <ContentItems title={singleRow.typeId == 2 ? 'Card' : 'Media'} style={{ maxHeight: '150px', width: '50%' }}>
                            {['avi', 'mp4'].find((type) => singleRow.mediaUrl.split('.').pop() === type || singleRow.mediaType === type) !==
                            undefined ? (
                              <video data-dz-thumbnail="" width="100%" height="200px" preload="metadata" controls="controls">
                                <track kind="captions" />
                                <source src={`${singleRow.mediaUrl}#t=0.5`} type="video/mp4" />
                                <source src={`${singleRow.mediaUrl}#t=0.5`} type="video/avi" />
                              </video>
                            ) : (
                              <img
                                data-dz-thumbnail=""
                                height="200"
                                className="mw-100 rounded bg-light"
                                alt="obituary media"
                                href={singleRow.mediaUrl}
                                src={singleRow.mediaUrl ? singleRow.mediaUrl : userpic}
                              />
                            )}
                          </ContentItems>
                        </Col>
                      )}
                    </Row>
                    <Row />
                  </CardBody>{' '}
                </Card>
              </Container>
            </div>
          </Modal>
        )}
        {/* </Container> */}
        {/* )} */}
      </React.Fragment>
    );
  }
}

export default ListReportTributeContainer;
