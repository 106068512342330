import React from 'react';
import { Form } from 'react-bootstrap';
import { get } from '../../../Libraries/Ajax/httpService';
import FormComponent from '../Form/formComponent';
import ValidationMessage from '../Validation/validationMessage';

class RadioButton extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  isTouched = false;

  handleOnChange(e) {
    let properties = {
      ...this.props,
      value: e && e.target ? e.target.value : '',
    };
    if (properties.type == 'number') {
      properties.value = Number(properties.value);
    }
    if (properties.onChangeValidation) {
      properties.onChangeValidation(e, properties);
    }
  }

  componentDidMount() {
    let properties = { ...this.props };
    let res = [];
    if (properties.renderapi) {
      get(properties.renderapi).then((response) => {
        if (response.data && response.data.data && response.data.success) {
          res = response.data.data.rows;
        }
        let obj = {};
        obj[properties.name] = { ...properties, options: res };
        if (properties.setParentState) {
          properties.setParentState(obj);
        }
      });
    }
  }

  render() {
    let {
      errors: [errFromProp] = [],
      options,
      optionvalue,
      optiontext,
      name,
      value,
      disableLabel,
      label,
      rules,
      disableRequiredsymbol,
      disableFormGroup,
      isReadOnly,
    } = this.props;
    let message = '';
    if (errFromProp) {
      ({ message } = errFromProp);
    }
    return (
      <div className={disableFormGroup ? '' : 'form-group'}>
        {!disableLabel ? (
          <Form.Label>
            {label}
            {rules && rules.required && !disableRequiredsymbol ? <sup>*</sup> : ''}
          </Form.Label>
        ) : (
          ''
        )}
        <div className="form-input-radio">
          {options && options.length > 0
            ? options.map((option, i) => (
                <label className="radio__option" key={i} htmlFor={`${name}-${option[optionvalue]}`} onChange={(e) => this.handleOnChange(e)}>
                  <span className="radio__input">
                    <input
                      type="radio"
                      name={name}
                      id={`${name}-${option[optionvalue]}`}
                      value={option[optionvalue]}
                      checked={value == option[optionvalue]}
                      disabled={isReadOnly}
                    />
                    <span className="radio__control" />
                  </span>
                  <span className="radio__label">{option[optiontext]}</span>
                </label>
              ))
            : null}
        </div>
        {message ? <ValidationMessage {...{ message }} /> : <span className="app-c-validation-message" />}
      </div>
    );
  }
}

export default RadioButton;
