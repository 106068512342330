import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Card, CardBody } from 'reactstrap';
import { get } from '../../Libraries/Ajax/httpService';
import Loader from '../../Components/loader';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { ViewDate } from '../../SharedComponents/ListComponents/components';
import { DeleteRow, EditRow, ContentItems } from '../../SharedComponents/ListComponents/manageComponent';
import DragAndDropComponent from '../../SharedComponents/FormHelpers/Controls/dragAndDrop';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';

class ViewProducts extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleRow: null,
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      get(`product/${this.props.match.params.id}`).then((response) => {
        this.setState({ singleRow: response.data?.data });
      });
    }
    this.setState({ access: setComponentPrivilege('Manage Products') });
  }

  render() {
    let { isLoaderActive, singleRow, access } = this.state;
    let { history } = this.props;
    return (
      <>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          {singleRow && (
            <div className="app-l-heading-block">
              <Row className="align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">Product Details</h4>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/list-products">Product</Link>
                      </li>
                      <li className="breadcrumb-item active">Product Details</li>
                    </ol>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {singleRow && (
            <Container fluid className="px-0">
              <Card>
                <CardBody className="view-page">
                  {/* TopSection1 */}
                  <Row>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Name"> {singleRow.name}</ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Category"> {singleRow.categoryName}</ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Price">
                        <i>€</i> {singleRow.price}
                      </ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Shop"> {singleRow.shopName}</ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Created By"> {singleRow.createdByName}</ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Last Modified By"> {singleRow.modifiedByName}</ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Created Date">
                        {' '}
                        <ViewDate isTableView dateValue={singleRow.createdDate} />
                      </ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Last Modified Date">
                        <ViewDate isTableView dateValue={singleRow.modifiedDate} />
                      </ContentItems>
                    </Col>
                    <Col xs={12} md={12}>
                      <ContentItems title="Description">
                        <p className="text-entry">{singleRow.description}</p>
                      </ContentItems>
                    </Col>
                    {singleRow.sourceUrl && (
                      <Col xs={12} md={12}>
                        <ContentItems title="Source Url">
                          <a
                            href={
                              singleRow.sourceUrl && ['http://', 'https://'].some((item) => singleRow.sourceUrl.includes(item))
                                ? singleRow.sourceUrl
                                : `https://${singleRow.sourceUrl}`
                            }
                            target="_new"
                          >
                            {singleRow.sourceUrl}
                          </a>{' '}
                        </ContentItems>
                      </Col>
                    )}
                    <Col xs={12} md={12}>
                      <ContentItems title="Image">
                        <DragAndDropComponent viewOnly value={singleRow.image} mediaUrl={singleRow.mediaUrl} editFlag />
                      </ContentItems>
                    </Col>
                  </Row>
                </CardBody>{' '}
              </Card>
              <div>
                <Col>
                  <div className="float-right ">
                    {access && access.delete && (
                      <DeleteRow
                        renderapi={`product/${this.props.match.params.id}`}
                        model={singleRow}
                        redirecturl="/list-products"
                        deleteText="Delete"
                        history={history}
                        isCardViewTrue="True"
                      />
                    )}{' '}
                    {access && access.edit && (
                      <EditRow history={history} editText="Edit" redirecturl={`/manage-products/${this.props.match.params.id}`} rowData={singleRow} />
                    )}{' '}
                  </div>
                </Col>
              </div>
            </Container>
          )}
        </div>
      </>
    );
  }
}
export default ViewProducts;
