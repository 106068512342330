import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';

class PieChart extends Component {
  render() {
    let data = {
      labels: ['Desktops', 'Tablets'],
      datasets: [
        {
          data: [300, 180],
          backgroundColor: ['#02a499', '#ebeff2'],
          hoverBackgroundColor: ['#02a499', '#ebeff2'],
          hoverBorderColor: '#fff',
        },
      ],
    };

    let option = {
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            let dataset = data.datasets[tooltipItem.datasetIndex];
            let meta = dataset._meta[Object.keys(dataset._meta)[0]];
            let { total } = meta;
            let currentValue = dataset.data[tooltipItem.index];
            let percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
            return `${currentValue} (${percentage}%)`;
          },
          title(tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          },
        },
      },
    };

    return (
      <React.Fragment>
        <Pie width={600} height={215} data={data} options={option} />
      </React.Fragment>
    );
  }
}

export default PieChart;
