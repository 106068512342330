import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { DataTable, TBody, THead, TCol, TRow, HeadingBlock, DataTableHolder } from '../../SharedComponents/Table/tableElements';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import Loader from '../../Components/loader';
import { StatusSwitch } from '../../SharedComponents/FormHelpers/Controls/statusSwitch';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';
import error404Img from '../../assets/images/errorImg.png';

class ListPlaceContainer extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleSelect: false,
      multiSelect: false,
      showLightBox: false,
      rowData: {},
      isLoaderActive: false,
      showDeleteConfirmation: false,
    };
  }

  componentDidMount() {
    this.setJsonUrl('place');
    this.setpredicates({ take: 10, page: 0 });
    this.setState({ access: setComponentPrivilege('Manage places') });
  }

  render() {
    let { apiResponse, access, isLoaderActive } = this.state;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              {isLoaderActive === true ? <Loader /> : ''}
              <HeadingBlock title="Place" />
            </Col>
            {/* <Col sm={6}>
              <div className="float-right d-none      d-md-block">
                <Link
                  className="btn btn-primary waves-effect waves-light"
                  to="manage-shops"
                  role="button"
                >
                  Add Place
                </Link>
              </div>
            </Col> */}
          </Row>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                    <div className="row">
                      <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                        <DataTable>
                          <THead>
                            <TRow>
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Image',
                                  width: 100,
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Name',
                                  enableSearch: true,
                                  enableSort: true,
                                  searchField: 'name',
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'City',
                                  enableSearch: true,
                                  enableSort: true,

                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'State',
                                  enableSearch: true,
                                  enableSort: true,

                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Country',
                                  enableSearch: true,
                                  enableSort: true,

                                  className: 'app-l-search-column',
                                })}
                              />

                              {/* <DataColHead
                                {...this.setheadproperties({
                                  title: "Latitude",
                                  enableSearch: true,
                                  enableSort: true,

                                  className: "app-l-search-column",
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: "Longitude",
                                  enableSearch: true,
                                  enableSort: true,

                                  className: "app-l-search-column",
                                })}
                              /> */}
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Status',
                                  width: 50,
                                  sublist: 'Status',
                                  coltype: 'filter',
                                  filterapi: 'product-status',
                                  sublistFilterField: 'status',
                                  filterClassName: 'app-l-100-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Action',

                                  width: 50,
                                  className: 'action',
                                })}
                              />
                            </TRow>
                          </THead>
                          {this.state.showLightBox ? (
                            <Lightbox
                              mainSrc={this.state.imageUrl ? this.state.imageUrl : error404Img}
                              onImageLoadError={() => {
                                this.setState({ imageUrl: error404Img });
                              }}
                              clickOutsideToClose
                              enableZoom
                              onCloseRequest={() => this.setState({ showLightBox: false })}
                            />
                          ) : null}
                          <TBody>
                            {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                              ? apiResponse.rows.map((row) => (
                                  <TRow>
                                    <TCol>
                                      <a href="#imageLink">
                                        <img
                                          onClick={() => this.setState({ showLightBox: true, imageUrl: row.mediaUrl })}
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = error404Img;
                                          }}
                                          data-dz-thumbnail=""
                                          height="50"
                                          className="avatar-sm rounded bg-light"
                                          alt="avatar"
                                          src={row.mediaUrl ? row.mediaUrl : error404Img}
                                        />
                                      </a>
                                    </TCol>
                                    <TCol>{row.name}</TCol>
                                    <TCol>{row.city}</TCol>
                                    <TCol>{row.state}</TCol>
                                    <TCol>{row.country}</TCol>

                                    {/* <TCol>{row.latitude}</TCol>
                                    <TCol>{row.longitude}</TCol> */}
                                    <TCol>
                                      {access && access.edit ? (
                                        <StatusSwitch
                                          isListIcon
                                          value={row.statusId}
                                          name="statusId"
                                          rowData={row}
                                          updateApi={`place/${row.id}`}
                                          valueObj={{ on: 1, off: 2 }}
                                          apiResponse={apiResponse}
                                          setParentState={this.setParentState}
                                        />
                                      ) : (
                                        row.statusTitle
                                      )}
                                    </TCol>
                                    <TCol>
                                      <div className="action-col">
                                        <span>
                                          <Link to={`view-place/${row.id}`} title="edit">
                                            <i className="fas fa-edit" />
                                          </Link>
                                        </span>
                                      </div>
                                    </TCol>
                                  </TRow>
                                ))
                              : null}
                          </TBody>
                        </DataTable>
                        {apiResponse.rows && apiResponse.rows.length === 0 && <div className="text-center no-content-block">No results found </div>}
                      </DataTableHolder>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ListPlaceContainer;
