import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import SettingMenu from '../Shared/SettingMenu';

class IconIon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Ion Icons</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Veltrix</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/#">Icons</Link>
                  </li>
                  <li className="breadcrumb-item active">Ion Icons</li>
                </ol>
              </div>
            </Col>

            <Col sm="6">
              <div className="float-right d-md-block">
                <SettingMenu />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">Material style</h4>

                  <Row className="icon-demo-content">
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-add" /> ion ion-md-add
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-add-circle" /> ion ion-md-add-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-add-circle-outline" /> ion ion-md-add-circle-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-airplane" /> ion ion-md-airplane
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-alarm" /> ion ion-md-alarm
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-albums" /> ion ion-md-albums
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-alert" /> ion ion-md-alert
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-american-football" /> ion ion-md-american-football
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-analytics" /> ion ion-md-analytics
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-aperture" /> ion ion-md-aperture
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-apps" /> ion ion-md-apps
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-appstore" /> ion ion-md-appstore
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-archive" /> ion ion-md-archive
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-arrow-back" /> ion ion-md-arrow-back
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-arrow-down" /> ion ion-md-arrow-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-arrow-dropdown" /> ion ion-md-arrow-dropdown
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-arrow-dropdown-circle" /> ion ion-md-arrow-dropdown-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-arrow-dropleft" /> ion ion-md-arrow-dropleft
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-arrow-dropleft-circle" /> ion ion-md-arrow-dropleft-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-arrow-dropright" /> ion ion-md-arrow-dropright
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-arrow-dropright-circle" /> ion ion-md-arrow-dropright-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-arrow-dropup" /> ion ion-md-arrow-dropup
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-arrow-dropup-circle" /> ion ion-md-arrow-dropup-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-arrow-forward" /> ion ion-md-arrow-forward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-arrow-round-back" /> ion ion-md-arrow-round-back
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-arrow-round-down" /> ion ion-md-arrow-round-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-arrow-round-forward" /> ion ion-md-arrow-round-forward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-arrow-round-up" /> ion ion-md-arrow-round-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-arrow-up" /> ion ion-md-arrow-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-at" /> ion ion-md-at
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-attach" /> ion ion-md-attach
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-backspace" /> ion ion-md-backspace
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-barcode" /> ion ion-md-barcode
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-baseball" /> ion ion-md-baseball
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-basket" /> ion ion-md-basket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-basketball" /> ion ion-md-basketball
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-battery-charging" /> ion ion-md-battery-charging
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-battery-dead" /> ion ion-md-battery-dead
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-battery-full" /> ion ion-md-battery-full
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-beaker" /> ion ion-md-beaker
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-bed" /> ion ion-md-bed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-beer" /> ion ion-md-beer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-bicycle" /> ion ion-md-bicycle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-bluetooth" /> ion ion-md-bluetooth
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-boat" /> ion ion-md-boat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-body" /> ion ion-md-body
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-bonfire" /> ion ion-md-bonfire
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-book" /> ion ion-md-book
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-bookmark" /> ion ion-md-bookmark
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-bookmarks" /> ion ion-md-bookmarks
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-bowtie" /> ion ion-md-bowtie
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-briefcase" /> ion ion-md-briefcase
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-browsers" /> ion ion-md-browsers
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-brush" /> ion ion-md-brush
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-bug" /> ion ion-md-bug
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-build" /> ion ion-md-build
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-bulb" /> ion ion-md-bulb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-bus" /> ion ion-md-bus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-business" /> ion ion-md-business
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-cafe" /> ion ion-md-cafe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-calculator" /> ion ion-md-calculator
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-calendar" /> ion ion-md-calendar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-call" /> ion ion-md-call
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-camera" /> ion ion-md-camera
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-car" /> ion ion-md-car
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-card" /> ion ion-md-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-cart" /> ion ion-md-cart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-cash" /> ion ion-md-cash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-cellular" /> ion ion-md-cellular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-chatboxes" /> ion ion-md-chatboxes
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-chatbubbles" /> ion ion-md-chatbubbles
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-checkbox" /> ion ion-md-checkbox
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-checkbox-outline" /> ion ion-md-checkbox-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-checkmark" /> ion ion-md-checkmark
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-checkmark-circle" /> ion ion-md-checkmark-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-checkmark-circle-outline" /> ion ion-md-checkmark-circle-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-clipboard" /> ion ion-md-clipboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-clock" /> ion ion-md-clock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-close" /> ion ion-md-close
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-close-circle" /> ion ion-md-close-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-close-circle-outline" /> ion ion-md-close-circle-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-cloud" /> ion ion-md-cloud
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-cloud-circle" /> ion ion-md-cloud-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-cloud-done" /> ion ion-md-cloud-done
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-cloud-download" /> ion ion-md-cloud-download
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-cloud-outline" /> ion ion-md-cloud-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-cloud-upload" /> ion ion-md-cloud-upload
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-cloudy" /> ion ion-md-cloudy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-cloudy-night" /> ion ion-md-cloudy-night
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-code" /> ion ion-md-code
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-code-download" /> ion ion-md-code-download
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-code-working" /> ion ion-md-code-working
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-cog" /> ion ion-md-cog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-color-fill" /> ion ion-md-color-fill
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-color-filter" /> ion ion-md-color-filter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-color-palette" /> ion ion-md-color-palette
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-color-wand" /> ion ion-md-color-wand
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-compass" /> ion ion-md-compass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-construct" /> ion ion-md-construct
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-contact" /> ion ion-md-contact
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-contacts" /> ion ion-md-contacts
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-contract" /> ion ion-md-contract
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-contrast" /> ion ion-md-contrast
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-copy" /> ion ion-md-copy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-create" /> ion ion-md-create
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-crop" /> ion ion-md-crop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-cube" /> ion ion-md-cube
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-cut" /> ion ion-md-cut
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-desktop" /> ion ion-md-desktop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-disc" /> ion ion-md-disc
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-document" /> ion ion-md-document
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-done-all" /> ion ion-md-done-all
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-download" /> ion ion-md-download
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-easel" /> ion ion-md-easel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-egg" /> ion ion-md-egg
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-exit" /> ion ion-md-exit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-expand" /> ion ion-md-expand
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-eye" /> ion ion-md-eye
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-eye-off" /> ion ion-md-eye-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-fastforward" /> ion ion-md-fastforward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-female" /> ion ion-md-female
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-filing" /> ion ion-md-filing
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-film" /> ion ion-md-film
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-finger-print" /> ion ion-md-finger-print
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-fitness" /> ion ion-md-fitness
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-flag" /> ion ion-md-flag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-flame" /> ion ion-md-flame
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-flash" /> ion ion-md-flash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-flash-off" /> ion ion-md-flash-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-flashlight" /> ion ion-md-flashlight
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-flask" /> ion ion-md-flask
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-flower" /> ion ion-md-flower
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-folder" /> ion ion-md-folder
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-folder-open" /> ion ion-md-folder-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-football" /> ion ion-md-football
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-funnel" /> ion ion-md-funnel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-gift" /> ion ion-md-gift
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-git-branch" /> ion ion-md-git-branch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-git-commit" /> ion ion-md-git-commit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-git-compare" /> ion ion-md-git-compare
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-git-merge" /> ion ion-md-git-merge
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-git-network" /> ion ion-md-git-network
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-git-pull-request" /> ion ion-md-git-pull-request
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-glasses" /> ion ion-md-glasses
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-globe" /> ion ion-md-globe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-grid" /> ion ion-md-grid
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-hammer" /> ion ion-md-hammer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-hand" /> ion ion-md-hand
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-happy" /> ion ion-md-happy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-headset" /> ion ion-md-headset
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-heart" /> ion ion-md-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-heart-dislike" /> ion ion-md-heart-dislike
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-heart-empty" /> ion ion-md-heart-empty
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-heart-half" /> ion ion-md-heart-half
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-help" /> ion ion-md-help
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-help-buoy" /> ion ion-md-help-buoy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-help-circle" /> ion ion-md-help-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-help-circle-outline" /> ion ion-md-help-circle-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-home" /> ion ion-md-home
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-hourglass" /> ion ion-md-hourglass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-ice-cream" /> ion ion-md-ice-cream
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-image" /> ion ion-md-image
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-images" /> ion ion-md-images
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-infinite" /> ion ion-md-infinite
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-information" /> ion ion-md-information
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-information-circle" /> ion ion-md-information-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-information-circle-outline" /> ion ion-md-information-circle-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-jet" /> ion ion-md-jet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-journal" /> ion ion-md-journal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-key" /> ion ion-md-key
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-keypad" /> ion ion-md-keypad
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-laptop" /> ion ion-md-laptop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-leaf" /> ion ion-md-leaf
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-link" /> ion ion-md-link
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-list" /> ion ion-md-list
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-list-box" /> ion ion-md-list-box
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-locate" /> ion ion-md-locate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-lock" /> ion ion-md-lock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-log-in" /> ion ion-md-log-in
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-log-out" /> ion ion-md-log-out
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-magnet" /> ion ion-md-magnet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-mail" /> ion ion-md-mail
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-mail-open" /> ion ion-md-mail-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-mail-unread" /> ion ion-md-mail-unread
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-male" /> ion ion-md-male
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-man" /> ion ion-md-man
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-map" /> ion ion-md-map
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-medal" /> ion ion-md-medal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-medical" /> ion ion-md-medical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-medkit" /> ion ion-md-medkit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-megaphone" /> ion ion-md-megaphone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-menu" /> ion ion-md-menu
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-mic" /> ion ion-md-mic
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-mic-off" /> ion ion-md-mic-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-microphone" /> ion ion-md-microphone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-moon" /> ion ion-md-moon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-more" /> ion ion-md-more
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-move" /> ion ion-md-move
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-musical-note" /> ion ion-md-musical-note
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-musical-notes" /> ion ion-md-musical-notes
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-navigate" /> ion ion-md-navigate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-notifications" /> ion ion-md-notifications
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-notifications-off" /> ion ion-md-notifications-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-notifications-outline" /> ion ion-md-notifications-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-nuclear" /> ion ion-md-nuclear
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-nutrition" /> ion ion-md-nutrition
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-open" /> ion ion-md-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-options" /> ion ion-md-options
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-outlet" /> ion ion-md-outlet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-paper" /> ion ion-md-paper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-paper-plane" /> ion ion-md-paper-plane
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-partly-sunny" /> ion ion-md-partly-sunny
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-pause" /> ion ion-md-pause
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-paw" /> ion ion-md-paw
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-people" /> ion ion-md-people
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-person" /> ion ion-md-person
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-person-add" /> ion ion-md-person-add
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-phone-landscape" /> ion ion-md-phone-landscape
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-phone-portrait" /> ion ion-md-phone-portrait
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-photos" /> ion ion-md-photos
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-pie" /> ion ion-md-pie
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-pin" /> ion ion-md-pin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-pint" /> ion ion-md-pint
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-pizza" /> ion ion-md-pizza
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-planet" /> ion ion-md-planet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-play" /> ion ion-md-play
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-play-circle" /> ion ion-md-play-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-podium" /> ion ion-md-podium
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-power" /> ion ion-md-power
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-pricetag" /> ion ion-md-pricetag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-pricetags" /> ion ion-md-pricetags
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-print" /> ion ion-md-print
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-pulse" /> ion ion-md-pulse
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-qr-scanner" /> ion ion-md-qr-scanner
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-quote" /> ion ion-md-quote
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-radio" /> ion ion-md-radio
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-radio-button-off" /> ion ion-md-radio-button-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-radio-button-on" /> ion ion-md-radio-button-on
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-rainy" /> ion ion-md-rainy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-recording" /> ion ion-md-recording
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-redo" /> ion ion-md-redo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-refresh" /> ion ion-md-refresh
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-refresh-circle" /> ion ion-md-refresh-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-remove" /> ion ion-md-remove
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-remove-circle" /> ion ion-md-remove-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-remove-circle-outline" /> ion ion-md-remove-circle-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-reorder" /> ion ion-md-reorder
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-repeat" /> ion ion-md-repeat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-resize" /> ion ion-md-resize
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-restaurant" /> ion ion-md-restaurant
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-return-left" /> ion ion-md-return-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-return-right" /> ion ion-md-return-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-reverse-camera" /> ion ion-md-reverse-camera
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-rewind" /> ion ion-md-rewind
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-ribbon" /> ion ion-md-ribbon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-rocket" /> ion ion-md-rocket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-rose" /> ion ion-md-rose
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-sad" /> ion ion-md-sad
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-save" /> ion ion-md-save
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-school" /> ion ion-md-school
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-search" /> ion ion-md-search
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-send" /> ion ion-md-send
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-settings" /> ion ion-md-settings
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-share" /> ion ion-md-share
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-share-alt" /> ion ion-md-share-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-shirt" /> ion ion-md-shirt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-shuffle" /> ion ion-md-shuffle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-skip-backward" /> ion ion-md-skip-backward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-skip-forward" /> ion ion-md-skip-forward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-snow" /> ion ion-md-snow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-speedometer" /> ion ion-md-speedometer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-square" /> ion ion-md-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-square-outline" /> ion ion-md-square-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-star" /> ion ion-md-star
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-star-half" /> ion ion-md-star-half
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-star-outline" /> ion ion-md-star-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-stats" /> ion ion-md-stats
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-stopwatch" /> ion ion-md-stopwatch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-subway" /> ion ion-md-subway
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-sunny" /> ion ion-md-sunny
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-swap" /> ion ion-md-swap
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-switch" /> ion ion-md-switch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-sync" /> ion ion-md-sync
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-tablet-landscape" /> ion ion-md-tablet-landscape
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-tablet-portrait" /> ion ion-md-tablet-portrait
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-tennisball" /> ion ion-md-tennisball
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-text" /> ion ion-md-text
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-thermometer" /> ion ion-md-thermometer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-thumbs-down" /> ion ion-md-thumbs-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-thumbs-up" /> ion ion-md-thumbs-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-thunderstorm" /> ion ion-md-thunderstorm
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-time" /> ion ion-md-time
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-timer" /> ion ion-md-timer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-today" /> ion ion-md-today
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-train" /> ion ion-md-train
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-transgender" /> ion ion-md-transgender
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-trash" /> ion ion-md-trash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-trending-down" /> ion ion-md-trending-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-trending-up" /> ion ion-md-trending-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-trophy" /> ion ion-md-trophy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-tv" /> ion ion-md-tv
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-umbrella" /> ion ion-md-umbrella
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-undo" /> ion ion-md-undo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-unlock" /> ion ion-md-unlock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-videocam" /> ion ion-md-videocam
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-volume-high" /> ion ion-md-volume-high
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-volume-low" /> ion ion-md-volume-low
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-volume-mute" /> ion ion-md-volume-mute
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-volume-off" /> ion ion-md-volume-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-walk" /> ion ion-md-walk
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-wallet" /> ion ion-md-wallet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-warning" /> ion ion-md-warning
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-watch" /> ion ion-md-watch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-water" /> ion ion-md-water
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-wifi" /> ion ion-md-wifi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-wine" /> ion ion-md-wine
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-md-woman" /> ion ion-md-woman
                    </Col>
                  </Row>
                </CardBody>

                <div className="card-box">
                  <h4 className="header-title mb-4">iOS</h4>

                  <Row className="icon-demo-content">
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-add" /> ion ion-ios-add
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-add-circle" /> ion ion-ios-add-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-add-circle-outline" /> ion ion-ios-add-circle-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-airplane" /> ion ion-ios-airplane
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-alarm" /> ion ion-ios-alarm
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-albums" /> ion ion-ios-albums
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-alert" /> ion ion-ios-alert
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-american-football" /> ion ion-ios-american-football
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-analytics" /> ion ion-ios-analytics
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-aperture" /> ion ion-ios-aperture
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-apps" /> ion ion-ios-apps
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-appstore" /> ion ion-ios-appstore
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-archive" /> ion ion-ios-archive
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-arrow-back" /> ion ion-ios-arrow-back
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-arrow-down" /> ion ion-ios-arrow-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-arrow-dropdown" /> ion ion-ios-arrow-dropdown
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-arrow-dropdown-circle" /> ion ion-ios-arrow-dropdown-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-arrow-dropleft" /> ion ion-ios-arrow-dropleft
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-arrow-dropleft-circle" /> ion ion-ios-arrow-dropleft-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-arrow-dropright" /> ion ion-ios-arrow-dropright
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-arrow-dropright-circle" /> ion ion-ios-arrow-dropright-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-arrow-dropup" /> ion ion-ios-arrow-dropup
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-arrow-dropup-circle" /> ion ion-ios-arrow-dropup-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-arrow-forward" /> ion ion-ios-arrow-forward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-arrow-round-back" /> ion ion-ios-arrow-round-back
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-arrow-round-down" /> ion ion-ios-arrow-round-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-arrow-round-forward" /> ion ion-ios-arrow-round-forward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-arrow-round-up" /> ion ion-ios-arrow-round-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-arrow-up" /> ion ion-ios-arrow-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-at" /> ion ion-ios-at
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-attach" /> ion ion-ios-attach
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-backspace" /> ion ion-ios-backspace
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-barcode" /> ion ion-ios-barcode
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-baseball" /> ion ion-ios-baseball
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-basket" /> ion ion-ios-basket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-basketball" /> ion ion-ios-basketball
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-battery-charging" /> ion ion-ios-battery-charging
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-battery-dead" /> ion ion-ios-battery-dead
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-battery-full" /> ion ion-ios-battery-full
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-beaker" /> ion ion-ios-beaker
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-bed" /> ion ion-ios-bed
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-beer" /> ion ion-ios-beer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-bicycle" /> ion ion-ios-bicycle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-bluetooth" /> ion ion-ios-bluetooth
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-boat" /> ion ion-ios-boat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-body" /> ion ion-ios-body
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-bonfire" /> ion ion-ios-bonfire
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-book" /> ion ion-ios-book
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-bookmark" /> ion ion-ios-bookmark
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-bookmarks" /> ion ion-ios-bookmarks
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-bowtie" /> ion ion-ios-bowtie
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-briefcase" /> ion ion-ios-briefcase
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-browsers" /> ion ion-ios-browsers
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-brush" /> ion ion-ios-brush
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-bug" /> ion ion-ios-bug
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-build" /> ion ion-ios-build
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-bulb" /> ion ion-ios-bulb
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-bus" /> ion ion-ios-bus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-business" /> ion ion-ios-business
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-cafe" /> ion ion-ios-cafe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-calculator" /> ion ion-ios-calculator
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-calendar" /> ion ion-ios-calendar
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-call" /> ion ion-ios-call
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-camera" /> ion ion-ios-camera
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-car" /> ion ion-ios-car
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-card" /> ion ion-ios-card
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-cart" /> ion ion-ios-cart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-cash" /> ion ion-ios-cash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-cellular" /> ion ion-ios-cellular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-chatboxes" /> ion ion-ios-chatboxes
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-chatbubbles" /> ion ion-ios-chatbubbles
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-checkbox" /> ion ion-ios-checkbox
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-checkbox-outline" /> ion ion-ios-checkbox-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-checkmark" /> ion ion-ios-checkmark
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-checkmark-circle" /> ion ion-ios-checkmark-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-checkmark-circle-outline" /> ion ion-ios-checkmark-circle-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-clipboard" /> ion ion-ios-clipboard
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-clock" /> ion ion-ios-clock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-close" /> ion ion-ios-close
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-close-circle" /> ion ion-ios-close-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-close-circle-outline" /> ion ion-ios-close-circle-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-cloud" /> ion ion-ios-cloud
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-cloud-circle" /> ion ion-ios-cloud-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-cloud-done" /> ion ion-ios-cloud-done
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-cloud-download" /> ion ion-ios-cloud-download
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-cloud-outline" /> ion ion-ios-cloud-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-cloud-upload" /> ion ion-ios-cloud-upload
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-cloudy" /> ion ion-ios-cloudy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-cloudy-night" /> ion ion-ios-cloudy-night
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-code" /> ion ion-ios-code
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-code-download" /> ion ion-ios-code-download
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-code-working" /> ion ion-ios-code-working
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-cog" /> ion ion-ios-cog
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-color-fill" /> ion ion-ios-color-fill
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-color-filter" /> ion ion-ios-color-filter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-color-palette" /> ion ion-ios-color-palette
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-color-wand" /> ion ion-ios-color-wand
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-compass" /> ion ion-ios-compass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-construct" /> ion ion-ios-construct
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-contact" /> ion ion-ios-contact
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-contacts" /> ion ion-ios-contacts
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-contract" /> ion ion-ios-contract
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-contrast" /> ion ion-ios-contrast
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-copy" /> ion ion-ios-copy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-create" /> ion ion-ios-create
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-crop" /> ion ion-ios-crop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-cube" /> ion ion-ios-cube
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-cut" /> ion ion-ios-cut
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-desktop" /> ion ion-ios-desktop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-disc" /> ion ion-ios-disc
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-document" /> ion ion-ios-document
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-done-all" /> ion ion-ios-done-all
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-download" /> ion ion-ios-download
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-easel" /> ion ion-ios-easel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-egg" /> ion ion-ios-egg
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-exit" /> ion ion-ios-exit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-expand" /> ion ion-ios-expand
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-eye" /> ion ion-ios-eye
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-eye-off" /> ion ion-ios-eye-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-fastforward" /> ion ion-ios-fastforward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-female" /> ion ion-ios-female
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-filing" /> ion ion-ios-filing
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-film" /> ion ion-ios-film
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-finger-print" /> ion ion-ios-finger-print
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-fitness" /> ion ion-ios-fitness
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-flag" /> ion ion-ios-flag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-flame" /> ion ion-ios-flame
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-flash" /> ion ion-ios-flash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-flash-off" /> ion ion-ios-flash-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-flashlight" /> ion ion-ios-flashlight
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-flask" /> ion ion-ios-flask
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-flower" /> ion ion-ios-flower
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-folder" /> ion ion-ios-folder
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-folder-open" /> ion ion-ios-folder-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-football" /> ion ion-ios-football
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-funnel" /> ion ion-ios-funnel
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-gift" /> ion ion-ios-gift
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-git-branch" /> ion ion-ios-git-branch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-git-commit" /> ion ion-ios-git-commit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-git-compare" /> ion ion-ios-git-compare
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-git-merge" /> ion ion-ios-git-merge
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-git-network" /> ion ion-ios-git-network
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-git-pull-request" /> ion ion-ios-git-pull-request
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-glasses" /> ion ion-ios-glasses
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-globe" /> ion ion-ios-globe
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-grid" /> ion ion-ios-grid
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-hammer" /> ion ion-ios-hammer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-hand" /> ion ion-ios-hand
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-happy" /> ion ion-ios-happy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-headset" /> ion ion-ios-headset
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-heart" /> ion ion-ios-heart
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-heart-dislike" /> ion ion-ios-heart-dislike
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-heart-empty" /> ion ion-ios-heart-empty
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-heart-half" /> ion ion-ios-heart-half
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-help" /> ion ion-ios-help
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-help-buoy" /> ion ion-ios-help-buoy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-help-circle" /> ion ion-ios-help-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-help-circle-outline" /> ion ion-ios-help-circle-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-home" /> ion ion-ios-home
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-hourglass" /> ion ion-ios-hourglass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-ice-cream" /> ion ion-ios-ice-cream
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-image" /> ion ion-ios-image
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-images" /> ion ion-ios-images
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-infinite" /> ion ion-ios-infinite
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-information" /> ion ion-ios-information
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-information-circle" /> ion ion-ios-information-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-information-circle-outline" /> ion ion-ios-information-circle-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-jet" /> ion ion-ios-jet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-journal" /> ion ion-ios-journal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-key" /> ion ion-ios-key
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-keypad" /> ion ion-ios-keypad
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-laptop" /> ion ion-ios-laptop
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-leaf" /> ion ion-ios-leaf
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-link" /> ion ion-ios-link
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-list" /> ion ion-ios-list
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-list-box" /> ion ion-ios-list-box
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-locate" /> ion ion-ios-locate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-lock" /> ion ion-ios-lock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-log-in" /> ion ion-ios-log-in
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-log-out" /> ion ion-ios-log-out
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-magnet" /> ion ion-ios-magnet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-mail" /> ion ion-ios-mail
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-mail-open" /> ion ion-ios-mail-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-mail-unread" /> ion ion-ios-mail-unread
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-male" /> ion ion-ios-male
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-man" /> ion ion-ios-man
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-map" /> ion ion-ios-map
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-medal" /> ion ion-ios-medal
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-medical" /> ion ion-ios-medical
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-medkit" /> ion ion-ios-medkit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-megaphone" /> ion ion-ios-megaphone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-menu" /> ion ion-ios-menu
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-mic" /> ion ion-ios-mic
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-mic-off" /> ion ion-ios-mic-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-microphone" /> ion ion-ios-microphone
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-moon" /> ion ion-ios-moon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-more" /> ion ion-ios-more
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-move" /> ion ion-ios-move
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-musical-note" /> ion ion-ios-musical-note
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-musical-notes" /> ion ion-ios-musical-notes
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-navigate" /> ion ion-ios-navigate
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-notifications" /> ion ion-ios-notifications
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-notifications-off" /> ion ion-ios-notifications-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-notifications-outline" /> ion ion-ios-notifications-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-nuclear" /> ion ion-ios-nuclear
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-nutrition" /> ion ion-ios-nutrition
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-open" /> ion ion-ios-open
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-options" /> ion ion-ios-options
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-outlet" /> ion ion-ios-outlet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-paper" /> ion ion-ios-paper
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-paper-plane" /> ion ion-ios-paper-plane
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-partly-sunny" /> ion ion-ios-partly-sunny
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-pause" /> ion ion-ios-pause
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-paw" /> ion ion-ios-paw
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-people" /> ion ion-ios-people
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-person" /> ion ion-ios-person
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-person-add" /> ion ion-ios-person-add
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-phone-landscape" /> ion ion-ios-phone-landscape
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-phone-portrait" /> ion ion-ios-phone-portrait
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-photos" /> ion ion-ios-photos
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-pie" /> ion ion-ios-pie
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-pin" /> ion ion-ios-pin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-pint" /> ion ion-ios-pint
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-pizza" /> ion ion-ios-pizza
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-planet" /> ion ion-ios-planet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-play" /> ion ion-ios-play
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-play-circle" /> ion ion-ios-play-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-podium" /> ion ion-ios-podium
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-power" /> ion ion-ios-power
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-pricetag" /> ion ion-ios-pricetag
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-pricetags" /> ion ion-ios-pricetags
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-print" /> ion ion-ios-print
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-pulse" /> ion ion-ios-pulse
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-qr-scanner" /> ion ion-ios-qr-scanner
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-quote" /> ion ion-ios-quote
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-radio" /> ion ion-ios-radio
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-radio-button-off" /> ion ion-ios-radio-button-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-radio-button-on" /> ion ion-ios-radio-button-on
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-rainy" /> ion ion-ios-rainy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-recording" /> ion ion-ios-recording
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-redo" /> ion ion-ios-redo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-refresh" /> ion ion-ios-refresh
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-refresh-circle" /> ion ion-ios-refresh-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-remove" /> ion ion-ios-remove
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-remove-circle" /> ion ion-ios-remove-circle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-remove-circle-outline" /> ion ion-ios-remove-circle-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-reorder" /> ion ion-ios-reorder
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-repeat" /> ion ion-ios-repeat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-resize" /> ion ion-ios-resize
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-restaurant" /> ion ion-ios-restaurant
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-return-left" /> ion ion-ios-return-left
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-return-right" /> ion ion-ios-return-right
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-reverse-camera" /> ion ion-ios-reverse-camera
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-rewind" /> ion ion-ios-rewind
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-ribbon" /> ion ion-ios-ribbon
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-rocket" /> ion ion-ios-rocket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-rose" /> ion ion-ios-rose
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-sad" /> ion ion-ios-sad
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-save" /> ion ion-ios-save
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-school" /> ion ion-ios-school
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-search" /> ion ion-ios-search
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-send" /> ion ion-ios-send
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-settings" /> ion ion-ios-settings
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-share" /> ion ion-ios-share
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-share-alt" /> ion ion-ios-share-alt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-shirt" /> ion ion-ios-shirt
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-shuffle" /> ion ion-ios-shuffle
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-skip-backward" /> ion ion-ios-skip-backward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-skip-forward" /> ion ion-ios-skip-forward
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-snow" /> ion ion-ios-snow
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-speedometer" /> ion ion-ios-speedometer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-square" /> ion ion-ios-square
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-square-outline" /> ion ion-ios-square-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-star" /> ion ion-ios-star
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-star-half" /> ion ion-ios-star-half
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-star-outline" /> ion ion-ios-star-outline
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-stats" /> ion ion-ios-stats
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-stopwatch" /> ion ion-ios-stopwatch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-subway" /> ion ion-ios-subway
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-sunny" /> ion ion-ios-sunny
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-swap" /> ion ion-ios-swap
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-switch" /> ion ion-ios-switch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-sync" /> ion ion-ios-sync
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-tablet-landscape" /> ion ion-ios-tablet-landscape
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-tablet-portrait" /> ion ion-ios-tablet-portrait
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-tennisball" /> ion ion-ios-tennisball
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-text" /> ion ion-ios-text
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-thermometer" /> ion ion-ios-thermometer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-thumbs-down" /> ion ion-ios-thumbs-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-thumbs-up" /> ion ion-ios-thumbs-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-thunderstorm" /> ion ion-ios-thunderstorm
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-time" /> ion ion-ios-time
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-timer" /> ion ion-ios-timer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-today" /> ion ion-ios-today
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-train" /> ion ion-ios-train
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-transgender" /> ion ion-ios-transgender
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-trash" /> ion ion-ios-trash
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-trending-down" /> ion ion-ios-trending-down
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-trending-up" /> ion ion-ios-trending-up
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-trophy" /> ion ion-ios-trophy
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-tv" /> ion ion-ios-tv
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-umbrella" /> ion ion-ios-umbrella
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-undo" /> ion ion-ios-undo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-unlock" /> ion ion-ios-unlock
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-videocam" /> ion ion-ios-videocam
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-volume-high" /> ion ion-ios-volume-high
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-volume-low" /> ion ion-ios-volume-low
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-volume-mute" /> ion ion-ios-volume-mute
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-volume-off" /> ion ion-ios-volume-off
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-walk" /> ion ion-ios-walk
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-wallet" /> ion ion-ios-wallet
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-warning" /> ion ion-ios-warning
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-watch" /> ion ion-ios-watch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-water" /> ion ion-ios-water
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-wifi" /> ion ion-ios-wifi
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-wine" /> ion ion-ios-wine
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-ios-woman" /> ion ion-ios-woman
                    </Col>
                  </Row>
                </div>

                <CardBody>
                  <h4 className="card-title mb-4">Logo</h4>

                  <Row className="icon-demo-content">
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-android" /> ion ion-logo-android
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-angular" /> ion ion-logo-angular
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-apple" /> ion ion-logo-apple
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-bitbucket" /> ion ion-logo-bitbucket
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-bitcoin" /> ion ion-logo-bitcoin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-buffer" /> ion ion-logo-buffer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-chrome" /> ion ion-logo-chrome
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-closed-captioning" /> ion ion-logo-closed-captioning
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-codepen" /> ion ion-logo-codepen
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-css3" /> ion ion-logo-css3
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-designernews" /> ion ion-logo-designernews
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-dribbble" /> ion ion-logo-dribbble
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-dropbox" /> ion ion-logo-dropbox
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-euro" /> ion ion-logo-euro
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-facebook" /> ion ion-logo-facebook
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-flickr" /> ion ion-logo-flickr
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-foursquare" /> ion ion-logo-foursquare
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-freebsd-devil" /> ion ion-logo-freebsd-devil
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-game-controller-a" /> ion ion-logo-game-controller-a
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-game-controller-b" /> ion ion-logo-game-controller-b
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-github" /> ion ion-logo-github
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-google" /> ion ion-logo-google
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-googleplus" /> ion ion-logo-googleplus
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-hackernews" /> ion ion-logo-hackernews
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-html5" /> ion ion-logo-html5
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-instagram" /> ion ion-logo-instagram
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-ionic" /> ion ion-logo-ionic
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-ionitron" /> ion ion-logo-ionitron
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-javascript" /> ion ion-logo-javascript
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-linkedin" /> ion ion-logo-linkedin
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-markdown" /> ion ion-logo-markdown
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-model-s" /> ion ion-logo-model-s
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-no-smoking" /> ion ion-logo-no-smoking
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-nodejs" /> ion ion-logo-nodejs
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-npm" /> ion ion-logo-npm
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-octocat" /> ion ion-logo-octocat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-pinterest" /> ion ion-logo-pinterest
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-playstation" /> ion ion-logo-playstation
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-polymer" /> ion ion-logo-polymer
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-python" /> ion ion-logo-python
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-reddit" /> ion ion-logo-reddit
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-rss" /> ion ion-logo-rss
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-sass" /> ion ion-logo-sass
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-skype" /> ion ion-logo-skype
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-slack" /> ion ion-logo-slack
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-snapchat" /> ion ion-logo-snapchat
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-steam" /> ion ion-logo-steam
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-tumblr" /> ion ion-logo-tumblr
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-tux" /> ion ion-logo-tux
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-twitch" /> ion ion-logo-twitch
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-twitter" /> ion ion-logo-twitter
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-usd" /> ion ion-logo-usd
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-vimeo" /> ion ion-logo-vimeo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-vk" /> ion ion-logo-vk
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-whatsapp" /> ion ion-logo-whatsapp
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-windows" /> ion ion-logo-windows
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-wordpress" /> ion ion-logo-wordpress
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-xbox" /> ion ion-logo-xbox
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-xing" /> ion ion-logo-xing
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-yahoo" /> ion ion-logo-yahoo
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-yen" /> ion ion-logo-yen
                    </Col>
                    <Col xl={3} lg={4} sm={6}>
                      <i className="ion ion-logo-youtube" /> ion ion-logo-youtube
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default IconIon;
