import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import bg from '../../assets/images/bg.jpg';
import { get, put } from '../../Libraries/Ajax/httpService';
import Loader from '../../Components/loader';
import { loginMessage } from '../../Store/actions';

class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showconfirmPassword: false,
      showPassword: false,
      userdata: {},
      password: '',
      confirmPassword: '',
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.setState({ isLoaderActive: true });
    let { history, setMessage, match, location } = this.props;
    let query = new URLSearchParams(location.search);
    let token = query.get('token');
    let reject = query.get('reject');

    if (token) {
      this.setState({ token });

      if (reject == 1) {
        put(`user-token/${token}`, { rejected: true })
          .then((rejectResponse) => {
            setMessage(rejectResponse.data?.success, rejectResponse.data?.message ? rejectResponse.data.message : 'Something went wrong');
            history.push('/login');
          })
          .catch((error) => {
            setMessage(false, error.message ? error.message : 'Something went Wrong');
            history.push('/login');
          });
      } else if (match.url == '/recovery-password') {
        get(`forgot-password-token/${token}`)
          .then((response) => {
            if (response.data.success) {
              this.setState({ userdata: response.data?.data[0] });
            } else {
              setMessage(false, response.data && response.data?.message ? response.data.message : 'Something went Wrong');
              history.push('/login');
            }
            this.setState({ isLoaderActive: false });
          })
          .catch((error) => {
            setMessage(false, error.message ? error.message : 'Something went Wrong');
            history.push('/login');
          });
      } else if (match.url == '/set-password') {
        get(`user-token/${token}`)
          .then((response) => {
            if (response.data.success) this.setState({ userdata: response.data?.data.user });
            else {
              setMessage(false, response.data && response.data?.message ? response.data.message : 'Something went Wrong');
              history.push('/login');
            }
            this.setState({ isLoaderActive: false });
          })
          .catch((error) => {
            setMessage(false, error.message ? error.message : 'Something went Wrong');
            history.push('/login');
          });
      }
    } else if (match.url == '/lock-screen') {
      this.setState({ isLoaderActive: false });
    } else {
      setMessage(false, 'Invalid verification link');
      history.push('/login');
    }
  }

  inputOnchangeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errorMessage: '',
    });
  };

  onBlurHandler = (e) => {
    if (e.target.value?.length < 6) {
      this.setState({
        errorMessage: 'Password should have minimum 6 characters',
      });
    }
  };

  onUnlockButtonClick = () => {
    if (this.state.confirmPassword == this.state.password) {
      let { history, setMessage, match } = this.props;
      let { token, password } = this.state;
      this.setState({ isLoaderActive: true });
      if (match.url == '/recovery-password') {
        put(`forgot-password-token/${token}`, { password })
          .then((tokenResponse) => {
            setMessage(tokenResponse.data?.success, tokenResponse.data?.message ? tokenResponse.data.message : 'Something went wrong');
            history.push('/login');
          })
          .catch((error) => {
            this.setState({
              isLoaderActive: false,
              errorMessage: error.message ? error.message : 'Something went wrong',
            });
          });
      } else if (match.url == '/set-password') {
        put(`user-token/${token}`, { password, isAdmin: true })
          .then((tokenResponse) => {
            setMessage(tokenResponse.data?.success, tokenResponse.data?.message ? tokenResponse.data.message : 'Something went wrong');
            history.push('/login');
          })
          .catch((error) => {
            this.setState({
              isLoaderActive: false,
              errorMessage: error.message ? error.message : 'Something went wrong',
            });
          });
      }
    } else {
      this.setState({ errorMessage: 'Passwords Mismatch!!' });
    }
  };

  handlePassword = (id) => {
    let obj1 = this.state.showPassword;
    let obj2 = this.state.confirmPassword;
    if (id == 1) {
      this.setState({ showPassword: !obj1 });
    } else {
      this.setState({ showconfirmPassword: !obj2 });
    }
  };

  render() {
    let { isLoaderActive, errorMessage } = this.state;
    return (
      <React.Fragment>
        {isLoaderActive === true ? <Loader /> : ''}{' '}
        <div
          className="accountbg"
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${bg})`,
          }}
        />
        <div className="wrapper-page account-page-full">
          <div className="card shadow-none">
            <div className="card-block">
              <div className="account-box">
                <div className="card-box shadow-none p-4">
                  <div className="p-2">
                    <div className="text-center mt-4">
                      <Link to="/">
                        <img src="/static/media/memlo-logo-lg.5eb77334.png" alt="" height="45" />
                      </Link>
                    </div>
                    <br />
                    <br />
                    <p className="text-muted text-center">Hello {this.state.userdata.name}, Enter your password to setup the account.</p>
                    {errorMessage ? (
                      <Alert color="danger" style={{ opacity: 1 }}>
                        {errorMessage}
                      </Alert>
                    ) : null}
                    <form className="mt-4" action="#">
                      <div className="form-group password-show-hide">
                        <input
                          type={this.state.showPassword ? 'text' : 'password'}
                          className="form-control"
                          id="userpassword"
                          name="password"
                          placeholder="Enter password"
                          onChange={this.inputOnchangeHandler}
                          onBlur={this.onBlurHandler}
                        />
                        <span className="show-hide set-password">
                          <em className={this.state.showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'} onClick={() => this.handlePassword(1)} />
                        </span>
                      </div>
                      <div className="form-group password-show-hide">
                        <input
                          type={this.state.showconfirmPassword ? 'text' : 'password'}
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Re-enter password"
                          onChange={this.inputOnchangeHandler}
                          onBlur={this.onBlurHandler}
                        />
                        <span className="show-hide set-password">
                          <em className={this.state.showconfirmPassword ? 'fas fa-eye' : 'fas fa-eye-slash'} onClick={() => this.handlePassword(2)} />
                        </span>
                      </div>
                      <div className="form-group row">
                        <div className="col-12 text-right">
                          <Button type="button" onClick={this.onUnlockButtonClick} className="btn btn-primary w-md waves-effect waves-light">
                            Set Password
                          </Button>{' '}
                        </div>
                      </div>
                    </form>
                    <div className="mt-5 pt-4 text-center">
                      <p>
                        Not you ? return{' '}
                        <Link to="/login" className="font-weight-medium text-primary">
                          {' '}
                          Sign In{' '}
                        </Link>{' '}
                      </p>
                      <p>© {new Date().getFullYear()} Memlo.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setMessage: (success, message) => dispatch(loginMessage(success, message)),
});
export default connect(null, mapDispatchToProps)(SetPassword);
