import React, { Component } from 'react';
import { CheckPicker } from 'rsuite';
import { Button } from 'react-bootstrap';
import { get } from '../../../Libraries/Ajax/httpService';
import { LazyRun } from '../../../Libraries/LazyFunctions/lazyFunction';

class MultiSelectWithCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoading: !!props.filterapiForChild,
      selectedValues: [],
    };
  }

  footerStyles = {
    padding: '5px 2px',
    borderTop: '1px solid rgb(229, 229, 229)',
    display: 'flex',
    flexDirection: 'row-reverse',
  };

  footerButtonStyle = {
    minWidth: '70px',
  };

  getListApiCall = (inputValue) => {
    let { filterapiForChild, apiModel } = this.props;
    this.setState({ isLoading: true });
    get(filterapiForChild, {
      ...apiModel,
      take: 20,
      q: inputValue,
      sortField: apiModel?.sortField ? apiModel.sortField : 'name',
      sortOrder: 'ASC',
    }).then((res) => {
      if (res.data?.data.rows) {
        let itemArr = [];
        if (typeof inputValue != 'undefined') itemArr = res.data.data.rows;
        else {
          let mergedItemArr = [...this.state.selectedValues, ...res.data.data.rows];
          itemArr = mergedItemArr.filter((item, i) => i === mergedItemArr.findIndex((elem) => elem.id === item.id));
        }
        this.setState({ isLoading: false, items: itemArr });
      }
    });
  };

  handleUpdate = () => {
    let { itemList } = this.props;
    if (this.state.items.length === 0 && itemList?.length > 0) {
      this.setState({
        items: itemList,
      });
    } else if (!itemList?.length > 0) {
      this.getListApiCall();
    }
  };

  onSearchHandler = (inputValue) => {
    LazyRun(() => {
      this.getListApiCall(inputValue);
    }, 500);
  };

  applySelectedFilter = (e, isClear) => {
    let { setpredicates, idFilterListName } = this.props;
    setpredicates({
      [idFilterListName]: isClear ? [] : this.picker.state.value,
      PageNumber: 1,
    });
    this.picker.close();
  };

  render() {
    let { items, isLoading } = this.state;
    let { labelKey, valueKey, itemList } = this.props;
    return (
      <CheckPicker
        // open={true}
        data={items}
        labelKey={labelKey || 'label'}
        valueKey={valueKey || 'value'}
        sticky
        onOpen={this.handleUpdate}
        onSelect={(val, currentItem) => {
          // eslint-disable-next-line react/no-access-state-in-setstate
          let selectedValuesArr = [...this.state.selectedValues];
          let index = selectedValuesArr.indexOf(currentItem);
          if (index > -1) selectedValuesArr.splice(index, 1);
          else selectedValuesArr = [...selectedValuesArr, currentItem];
          this.setState({ selectedValues: selectedValuesArr });
        }}
        onSearch={(inputValue) => (this.props.itemList?.length > 0 ? this.handleUpdate() : this.onSearchHandler(inputValue))}
        maxHeight={260}
        menuStyle={{
          width: '250px',
          pointerEvents: 'auto',
          marginTop: '20px',
        }}
        style={{ width: '32px', float: 'right' }}
        appearance="subtle"
        placeholder=" "
        placement="bottomEnd"
        ref={(ref) => {
          this.picker = ref;
        }}
        cleanable
        onClean={(e) => this.applySelectedFilter(e, true)}
        renderMenu={(menu) => {
          if (isLoading || (itemList?.length > 0 && items.length === 0)) {
            return (
              <div style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            );
          }
          if (menu.props.data && menu.props.data.length > 0) {
            menu = {
              ...menu,
              props: { ...menu.props, data: menu.props.data },
            };
          }
          return menu;
        }}
        renderMenuItem={(label) => (
          <div className="dots" title={label}>
            {label}
          </div>
        )}
        renderExtraFooter={() => (
          <div style={this.footerStyles}>
            <Button style={this.footerButtonStyle} appearance="primary" size="sm" onClick={(e) => this.applySelectedFilter(e, false)}>
              {' '}
              Apply
            </Button>
          </div>
        )}
      />
    );
  }
}

export default MultiSelectWithCheck;
