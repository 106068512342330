import React from 'react';
import { Form } from 'react-bootstrap';

import Validation from '../../../Libraries/Validations/validation';
import ValidationMessage from '../Validation/validationMessage';
import FormComponent from '../Form/formComponent';

class CheckBox extends FormComponent {
  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.onNativeChange = this.onNativeChange.bind(this);

    this.state = {
      message: '',
      messageType: 'i',
    };
  }

  validation = new Validation();

  isTouched = false;

  isChecked = false;

  handleOnBlur(e) {
    let properties = { ...this.props, value: this.isChecked };
    if (properties.onBlurValidation) {
      properties.onBlurValidation(e, properties);
    }
  }

  handleOnChange(e) {
    this.isChecked = !this.isChecked;
    let properties = {
      ...this.props,
      checked: this.isChecked,
      value: this.isChecked,
    };
    if (properties.onChangeValidation) {
      properties.onChangeValidation(e, properties);
    }
  }

  onNativeChange(e) {
    let { onNativeChange } = this.props;

    if (onNativeChange) {
      onNativeChange(e, this.props);
    }
  }

  componentDidMount() {
    if (this.isTouched === false) {
      let properties = {
        ...this.props,
        value: this.isChecked,
      };
      if (properties.onChangeValidation) {
        properties.onChangeValidation(null, properties);
      }
    }
  }

  render() {
    let { errors: [errFromProp] = [] } = this.props;
    let message = '';
    if (errFromProp) {
      ({ message } = errFromProp);
    }
    let messageType = 'i';
    if (this.props.isTouched) {
      messageType = 'e';
    }
    if (!this.props.isFocused || this.props.isSilent) {
      messageType = '';
    }
    let { onFocus, disableFormGroup, ref, id, label, type, placeholder, name, value, checked, rules, autoComplete, list } = this.props;
    let inputproperties = {
      checked,
      label,
      type,
      placeholder,
      name,
      value,
      autoComplete,
      list,
    };
    if (ref) {
      inputproperties.ref = ref;
    }
    if (id) {
      inputproperties.id = id;
    }

    if (!onFocus) {
      onFocus = () => {};
    }

    if (checked) {
      inputproperties.checked = checked;
    }
    return (
      <div className={disableFormGroup ? '' : 'form-group'}>
        <Form.Label>
          <div className="__checkbox">
            <input
              type="checkbox"
              name="checkboxApproval"
              id={inputproperties && inputproperties.id ? `checkboxApproval-${inputproperties.id}` : 'checkboxApproval'}
              checked={checked}
              onChange={() => this.handleOnChange()}
            />
            <label htmlFor={inputproperties && inputproperties.id ? `checkboxApproval-${inputproperties.id}` : 'checkboxApproval'}>{label}</label>
          </div>

          {rules && rules.required && !this.props.disableRequiredsymbol ? <sup>*</sup> : ''}
        </Form.Label>

        {messageType ? <ValidationMessage {...{ message }} /> : <span className="app-c-validation-message" />}
      </div>
    );
  }
}

export default CheckBox;
