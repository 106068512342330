import React from 'react';
import { Row, Col, Container, Form } from 'react-bootstrap';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { get } from '../../Libraries/Ajax/httpService';
import Loader from '../../Components/loader';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { ContentItems } from '../../SharedComponents/ListComponents/manageComponent';
import ListOrderLogContainer from './listOrderLog';

import FormModal from './formModal';

dayjs.extend(advancedFormat);
class ViewOrders extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleRow: null,
      checkout: null,
      rowData: {},
      statusId: {
        renderapi: 'delivery-status?page=0&take=10',
        placeholder: 'Enter status',
        className: 'mb-0',
        controlId: 'exampleForm.ControlSelect1',
        name: 'statusId',
        optionvalue: 'id',
        optiontext: 'title',
      },
      deliveryOptions: [],
      showModal: false,
      currentOrder: {},
      reloadList: false,
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      get(`order/order-details/${this.props.match.params.id}`).then((response) => {
        this.setState({ singleRow: response.data?.data });
      });

      // this.setJsonUrl('order');
      get('delivery-status?page=0&take=10').then((res) => {
        if (res.data?.data.rows) this.setState({ deliveryOptions: res.data.data.rows });
      });
    }
  }

  onStatusChange = (e, rowData) => {
    let row = { ...rowData, statusId: parseInt(e.target.value) };
    this.setState((prevState) => ({
      prevStatusId: rowData.statusId,
      showModal: !prevState.showModal,
      currentOrder: row,
      singleRow: row,
    }));
  };

  onToggle = (isPrevious) => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      singleRow: {
        ...prevState.singleRow,
        statusId: isPrevious ? this.state.prevStatusId : prevState.singleRow,
      },
    }));
  };

  render() {
    let { apiResponse, singleRow, isLoaderActive, showModal, reloadList, deliveryOptions, selectedStatusId, currentOrder } = this.state;
    return (
      <>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          {singleRow && (
            <div className="app-l-heading-block">
              <Row className="align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">Order Details</h4>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/list-orders">Order</Link>
                      </li>
                      <li className="breadcrumb-item active">Order Details</li>
                    </ol>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {singleRow && (
            <Container fluid className="px-0">
              <Card>
                <CardBody className="view-page">
                  {/* TopSection1 */}
                  <Row>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Product"> {singleRow.product?.name}</ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Price">
                        {' '}
                        <i>€</i> {singleRow.price}
                      </ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Quantity"> {singleRow.quantity}</ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Obituary"> {singleRow.obituary?.name}</ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Checkout Name"> {singleRow.payment?.checkout.name}</ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Checkout Address">
                        {' '}
                        {singleRow.payment?.checkout.address == ' ' ? '_______' : singleRow.payment?.checkout.address}
                      </ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Message Tag">
                        {' '}
                        {singleRow.payment?.checkout.messageTag == '' ? '_______' : singleRow.payment?.checkout.messageTag}
                      </ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Order Date and Time">
                        {' '}
                        {dayjs(singleRow.createdDate).format('DD/MM/YYYY')} {dayjs(singleRow.createdDate).format('HH:mm')}
                      </ContentItems>
                    </Col>
                    <Col>
                      <ContentItems title="Payment Gateway">
                        {' '}
                        {singleRow.payment?.checkout.paymentMode === 'paypal' ? 'PayPal' : '_______'}
                      </ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Payment Status"> {singleRow.payment?.prerefrenceNumber ? 'Paid' : 'Not Paid'}</ContentItems>
                      <ContentItems title="Payment Reference Id"> {singleRow.payment?.prerefrenceNumber}</ContentItems>
                    </Col>

                    <Col>
                      <ContentItems title="Ticket Number"> {singleRow.requestTicket}</ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Order Status">
                        <Form.Control value={singleRow.statusId} as="select" onChange={(e) => this.onStatusChange(e, singleRow)}>
                          {deliveryOptions &&
                            deliveryOptions.length > 0 &&
                            deliveryOptions.map((option) => (
                              <option disabled={option.disabled} key={option.id} value={option.id}>
                                {option.title}
                              </option>
                            ))}
                        </Form.Control>
                      </ContentItems>
                    </Col>
                  </Row>
                  <Row />
                </CardBody>{' '}
              </Card>
              <FormModal
                isViewPage
                setParentState={this.setParentState}
                showModal={showModal}
                setpredicates={this.setpredicates}
                selectedStatusId={selectedStatusId}
                deliveryOptions={deliveryOptions}
                order={currentOrder}
                onToggle={this.onToggle}
              />
              <Card>
                <Row>
                  <ListOrderLogContainer
                    data={apiResponse}
                    reloadList={reloadList}
                    orderId={this.props.match.params.id}
                    {...this.setproperties(this.props)}
                  />
                </Row>
              </Card>
            </Container>
          )}
        </div>
      </>
    );
  }
}

export default ViewOrders;
