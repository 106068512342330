import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon, WhatsappIcon, WhatsappShareButton } from '@kashuab/react-share';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { get, post, put } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import { PermissionSettings } from './modalsettingpermission';
import { ListContacts } from './listContacts';
import { ActivityOnObituary } from './activityOnObituary';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { DeleteRow } from '../../SharedComponents/ListComponents/manageComponent';
import Loader from '../../Components/loader';
import ListPostTypeContainer from '../Post/postTypeTabs';
import ObituaryProfileDetails from './obituaryProfileDetails';
import CustomModal from '../../SharedComponents/customModal';
import CustomerFeedback from './customerFeedback';
import ManageObituaryProfiles from './manageObituaryProfile';
import ProfileStatusComponent from './profileStatusComponent';
import LinkButton from '../../SharedComponents/linkButton';
import ManageObituarySettings from './manageObituarySettings';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';
import { ActionButton } from '../../SharedComponents/FormHelpers/Controls/actionButton';
import createSlugRoute from '../../Libraries/slugHelperFunction';

class ViewObituaryProfiles extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      // apiResponse: null,
      singleRow: null,
      settingsModal: false,
      manageModal: false,
      hasProfileUpdated: false,
      showPermissionsModal: false,
      showContactDetailsModal: false,
      showActivityModal: false,
      showCustomerFeedbackModal: false,
      obituaryId: 0,
      isLoaderActive: true,
      page: '',
      setNotification: false,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  apiUrl = null;

  toast = new ToastNotification();

  toggleModalState = (modalToggleKey) => {
    this.setState({ [modalToggleKey]: !this.state[modalToggleKey] });
  };

  formRef = {};

  onConfirmButtonClick = async (e) => {
    if (this.state.manageModal) {
      this.formRef.onClose = this.closeModal;
      await this.formRef.onFormSubmit(e);
    }
  };

  showManageModal = (e) => {
    if (this.state.manageModal && e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState((prevState) => ({
      manageModal: !prevState.manageModal,
    }));
  };

  closeModal = () => {
    this.setState({
      manageModal: false,
      settingsModal: false,
    });
  };

  getUrlCheck = async () => {
    let { user } = this.props.userData;
    let { singleRow } = this.state;
    let model = {
      obituaryId: this.props.match.params.id,
      userId: user.id,
      roleId: user.roleId,
      isShare: true,
    };

    if (this.state.singleRow.statusTitle.toLowerCase().trim() === 'published') {
      let slug = createSlugRoute(singleRow.name);
      return `${process.env.REACT_APP_FRONTEND_URL}pages/view-memorial/${slug}/${this.props.match.params.id}`;
    }
    let response = await post('profile-preview-token', model);
    if (response.data.data && response.data.success) {
      return response.data.data.previewUrl;
    }
    this.toast.show('update_error', 'ERROR', "Couldn't send  priview. Please try after some time.");
    return null;
  };

  onStatusChange(statusId, statusTitle) {
    let { singleRow } = this.state;
    this.setState({ singleRow: { ...singleRow, statusId, statusTitle }, hasProfileUpdated: true });
  }

  handleOnChange(typeId, typeTitle) {
    let obj = this.state.isTypeChecked;
    this.setState({ isTypeChecked: { ...obj, [typeTitle]: !obj[typeTitle] } });
    let firstTimeModel = {
      obituaryId: this.props.match.params.id,
      typeId,
      isVisible: false,
    };
    let paramsModel = {
      obituaryId: this.props.match.params.id,
      typeId,
    };
    get('obituary-settings?take=10&page=0', paramsModel)
      .then((response) => {
        response.data = response.data.data;
        if (response.data && response.data.rows && response.data.rows.length > 0) {
          let model = response.data.rows[0];
          model.isVisible = model.isVisible.data[0] == 0;
          put(`obituary-settings/${response.data.rows[0].id}`, model).then((res) => {
            if (res.data.data && res.data.success) {
              this.toast.show('update_success', 'SUCCESS', 'Visibility changed successfully.');
            } else {
              this.toast.show('update_error', 'ERROR', 'Could not change visibility settings.');
            }
          });
        } else {
          post('obituary-settings', firstTimeModel).then((res) => {
            if (res.data.data && res.data.success) {
              this.toast.show('create_success', 'SUCCESS', 'Visibility changed successfully.');
            } else {
              this.toast.show('create_error', 'ERROR', 'Could not change visibility settings.');
            }
          });
        }
      })
      .catch(() => {});
  }

  componentDidMount() {
    let {
      match: { params },
      history,
    } = this.props;
    let page = 'Manage Obituary Profiles';
    if (params.value === 'renewal') page = 'Manage Renewal Profiles';

    this.setState({ access: setComponentPrivilege(page) });

    if (params.id) {
      get(`obituary-profile/${params.id}`)
        .then((response) => {
          response.data = response.data.data;
          // 1 year = 1*365*24*60*60*1000 milliseconds
          let years = Math.floor((new Date(response.data.dateOfDeath) - new Date(response.data.dateOfBirth)) / (1 * 365 * 24 * 60 * 60 * 1000));
          if (response.data.publishedTime) {
            let today = dayjs();
            response.data.publishedTime = `${today.format('YYYY-MM-DDT') + response.data.publishedTime}.000Z`;
          }
          this.setState({
            singleRow: response.data,
            age: years,
            obituaryParamsId: params.id,
            isLoaderActive: false,
            page: params.value,
          });
        })
        .catch((err) => {
          this.toast.show('fetch_error', 'ERROR', err?.message ? err.message : 'Something went wrong');
          this.setState({ isLoaderActive: false });
          if (err.status === '403' || err.status === 400) {
            history.push('/list-obituary-profiles/general');
          }
        });
    }
  }

  componentDidUpdate() {
    if (this.state.hasProfileUpdated) {
      get(`obituary-profile/${this.props.match.params.id}`).then((response) => {
        response.data = response.data.data;
        // 1 year = 1*365*24*60*60*1000 milliseconds
        let years = Math.floor((new Date(response.data.dateOfDeath) - new Date(response.data.dateOfBirth)) / (1 * 365 * 24 * 60 * 60 * 1000));
        if (response.data.publishedTime) {
          let today = dayjs();
          response.data.publishedTime = `${today.format('YYYY-MM-DDT') + response.data.publishedTime}.000Z`;
        }
        if (response.data.mediaUrl && response.data.mediaUrl.length > 0) {
          if (response.data.mediaUrl.includes('?')) {
            response.data.mediaUrl = `${response.data.mediaUrl}&date=${new Date()}`;
          } else {
            response.data.mediaUrl = `${response.data.mediaUrl}?date=${new Date()}`;
          }
        }
        this.setState({
          singleRow: response.data,
          age: years,
          hasProfileUpdated: false,
          isLoaderActive: false,
        });
      });
    }
  }

  feedbackStyle = {
    background: 'red',
    left: '7px',
    bottom: '9px',
    width: '10px',
    height: '10px',
    borderRadius: '50px',
    display: 'block',
    position: 'absolute',
    zIndex: ' 1',
    border: '2px solid #fff',
  };

  onSetNotification = (notify) => {
    this.setState({ setNotification: notify });
  };

  updateBackgroundImage = (bgId, bgUrl, bgHexCode) => {
    this.setState((prevState) => ({
      singleRow: { ...prevState.singleRow, backgroundImageId: bgId, backgroundImageUrl: bgUrl, solidColor: bgHexCode },
    }));
  };

  render() {
    let { isLoaderActive, singleRow, access, manageModal, settingsModal, page, setNotification } = this.state;
    this.formRef.onFormSubmit = {};
    let { history } = this.props;
    return (
      <>
        {isLoaderActive === true ? <Loader /> : ''}
        <Container fluid>
          {singleRow && (
            <div className="app-l-heading-block">
              <Row className="align-items-center">
                <Col sm={12} md={6} lg={6} xl={6}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">Obituary Profile Details</h4>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        {page === 'general' ? (
                          <Link to="/list-obituary-profiles/general">Obituary Profiles</Link>
                        ) : (
                          <Link to="/list-obituary-profiles/renewal">Renewal Profiles</Link>
                        )}
                      </li>
                      <li className="breadcrumb-item active">Obituary Profile Details</li>
                    </ol>
                  </div>
                </Col>
                <Col className="d-none d-md-block">
                  <div className="float-right">
                    {access && access.delete && (
                      <DeleteRow
                        renderapi={`obituary-profile/${this.props.match.params.id}`}
                        model={singleRow}
                        redirecturl={`/list-obituary-profiles/${page}`}
                        deleteText="Delete"
                        history={history}
                        isCardViewTrue="True"
                      />
                    )}{' '}
                    {access && access.edit && (
                      <Button className="btn-secondary" onClick={() => this.setState({ manageModal: true })}>
                        Edit
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {singleRow && (
            <Container fluid className="px-0 pt-2">
              <Row>
                <Col xs={12} sm={12} md={4} lg={3} xl={3}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xs={12} md={12}>
                          <div className="float-right" style={{ marginBottom: `${1}rem` }}>
                            {/* <div> */}
                            <LinkButton
                              toString="customerFeedback"
                              title="Customer Feedback"
                              className="relative"
                              toDoFunction={() => this.toggleModalState('showCustomerFeedbackModal')}
                            >
                              {setNotification ? <span style={this.feedbackStyle} /> : null}
                              <i className="fas fa-comment" />
                            </LinkButton>
                            {/* </div> */}
                            &nbsp;&nbsp;&nbsp;
                            <LinkButton toString="profileActivity" title="Activities" toDoFunction={() => this.toggleModalState('showActivityModal')}>
                              <i className="fas fa-clipboard-list" />
                            </LinkButton>
                            &nbsp;&nbsp;&nbsp;
                            <LinkButton
                              toString="profileAccessPermission"
                              title="Permissions"
                              toDoFunction={() => this.toggleModalState('showPermissionsModal')}
                            >
                              <i className="fas fa-user-shield" />
                            </LinkButton>
                            &nbsp;&nbsp;&nbsp;
                            <LinkButton toString="Contacts" title="Contacts" toDoFunction={() => this.toggleModalState('showContactDetailsModal')}>
                              <i className="mdi mdi-contact-mail" />
                            </LinkButton>
                            &nbsp;&nbsp;&nbsp;
                            <LinkButton
                              toString="profileSettings"
                              title="Profile Settings"
                              toDoFunction={() => this.setState({ settingsModal: true })}
                            >
                              <i className="fas fa-cog" />
                            </LinkButton>
                            &nbsp;&nbsp;&nbsp;
                            {access && access.edit && (
                              <LinkButton toString="editProfile" title="Edit Profile" toDoFunction={() => this.setState({ manageModal: true })}>
                                <i className="fas fa-edit" />
                              </LinkButton>
                            )}
                            &nbsp;&nbsp;&nbsp;
                            {access && access.delete && (
                              <ActionButton
                                value="delete"
                                rowId={singleRow.id}
                                url="#deleteProfile"
                                api="obituary-profile/"
                                redirecturl={`/list-obituary-profiles/${page}`}
                                history={history}
                              />
                            )}
                          </div>
                          <CustomerFeedback
                            showCheck={this.state.showCustomerFeedbackModal}
                            obituaryRowId={singleRow.id}
                            toggleState={this.toggleModalState}
                            stateKey="showCustomerFeedbackModal"
                            setNotification={this.onSetNotification}
                          />
                          <ActivityOnObituary
                            showCheck={this.state.showActivityModal}
                            obituaryRowId={singleRow.id}
                            toggleState={this.toggleModalState}
                            stateKey="showActivityModal"
                          />
                          <PermissionSettings
                            showCheck={this.state.showPermissionsModal}
                            obituaryRowId={singleRow.id}
                            toggleState={this.toggleModalState}
                            stateKey="showPermissionsModal"
                            access={access}
                          />
                          <ListContacts
                            showCheck={this.state.showContactDetailsModal}
                            obituaryRowId={singleRow.id}
                            toggleState={this.toggleModalState}
                            stateKey="showContactDetailsModal"
                            access={access}
                          />
                        </Col>

                        <Col xs={12} md={12}>
                          <div className="text-centre" style={{ marginBottom: `${1}rem` }}>
                            <span title="Share obituary via facebook">
                              <FacebookShareButton className="col-4" url={this.getUrlCheck}>
                                <FacebookIcon size={32} round />
                              </FacebookShareButton>
                            </span>
                            <span title="Share obituary via whatsapp">
                              <WhatsappShareButton className="col-4" url={this.getUrlCheck}>
                                <WhatsappIcon size={32} round />
                              </WhatsappShareButton>
                            </span>
                            <span title="Share obituary via mail">
                              <EmailShareButton className="col-4" url={this.getUrlCheck}>
                                <EmailIcon size={32} round />
                              </EmailShareButton>
                            </span>
                          </div>
                        </Col>

                        <ObituaryProfileDetails singleRow={singleRow} />
                      </Row>
                    </CardBody>{' '}
                  </Card>
                </Col>
                <Col xs={12} sm={12} md={8} lg={9} xl={9}>
                  <ProfileStatusComponent
                    id={singleRow.statusId}
                    title={singleRow.statusTitle}
                    statusChange={() => this.onStatusChange()}
                    publishedDate={singleRow.publishedDate}
                    publishedTime={singleRow.publishedTime}
                    expiryDate={singleRow.expiryDate}
                    lastRenewedDate={singleRow.lastRenewedDate}
                    lastReminderSent={singleRow.lastReminderSent}
                    lastReminderSeen={singleRow.lastReminderSeen}
                    obituaryId={this.props.match.params.id}
                    partnerId={singleRow.partnerId}
                    createdByRoleId={singleRow.createdByRoleId}
                    commissionRate={singleRow.partnerCommission}
                    access={access}
                    isBlockedByAdmin={singleRow.isBlockedByAdmin}
                  />
                  <ListPostTypeContainer
                    obituaryId={parseInt(this.props.match.params.id)}
                    age={this.state.age}
                    genderId={singleRow.genderId}
                    bgId={singleRow.backgroundImageId}
                    bgUrl={singleRow.backgroundImageUrl}
                    bgHexCode={singleRow.solidColor}
                    statusId={singleRow.statusId}
                    setBackgroundImage={this.updateBackgroundImage}
                    access={access}
                  />
                </Col>
              </Row>
            </Container>
          )}
          {(manageModal || settingsModal) && (
            <CustomModal
              modal={manageModal || settingsModal}
              toggleModal={this.closeModal}
              title={manageModal ? 'Edit Obituary Profile' : 'Configure Engagements'}
              buttonList={[{ name: 'Save', color: 'primary', onClick: this.onConfirmButtonClick }, { name: 'Cancel' }]}
              hideFooter={!!settingsModal}
              className={manageModal ? 'modal-lg' : null}
              maxHeight={manageModal ? 400 : null}
            >
              {manageModal && (
                <ManageObituaryProfiles
                  formRef={this.formRef}
                  profileData={singleRow}
                  setParentState={this.setParentState}
                  page={page}
                  access={access}
                  {...this.props}
                />
              )}
              {settingsModal && <ManageObituarySettings disabled={!(access && access.edit)} obituaryId={parseInt(this.props.match.params.id)} />}
            </CustomModal>
          )}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.Login.userData,
});

export default connect(mapStateToProps, null)(ViewObituaryProfiles);
