import 'react-image-lightbox/style.css';
import React from 'react';
import Lightbox from 'react-image-lightbox';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import imgUser from '../../assets/images/userpic.png';
import Loader from '../../Components/loader';
import { ActionButton } from '../../SharedComponents/FormHelpers/Controls/actionButton';
import CustomModal from '../../SharedComponents/customModal';
import { ViewDate, ListPagination, DataColHead } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { HeadingBlock, DataTableHolder, DataTable, THead, TRow } from '../../SharedComponents/Table/tableElements';
import ManageObituaryProfiles from './manageObituaryProfile';
import { Search, Sort } from '../../SharedComponents/ListComponents/dataOperations';
import LinkButton from '../../SharedComponents/linkButton';
import DateRangeFilter from '../../SharedComponents/dateRangeFilter';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';
import { combineDateTime } from '../../Libraries/DateTimeHelpers/dateTimeHelperFunctions';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';

class DirectoryObituaryProfile extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      showLightBox: false,
      imageUrl: '',
      manageModal: false,
      isLoaderActive: false,
      showDeleteConfirmation: false,
      page: '',
      resetDateFilter: 0,
      statusTextColor: ['#02a499', '#f8b425', '#343a40', '#ec4561'],
    };
  }

  formRef = {};

  onConfirmButtonClick = (e) => {
    if (this.state.manageModal) {
      this.formRef.onClose = this.showManageModal;
      this.formRef.onFormSubmit(e);
    }
  };

  showManageModal = (e) => {
    if (this.state.manageModal && e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState((prevState) => ({
      manageModal: !prevState.manageModal,
    }));
  };

  toast = new ToastNotification();

  componentDidMount() {
    let {
      match: { params },
    } = this.props;
    this.updatePage(params);
  }

  updatePage(params) {
    this.setState((prevState) => ({ page: params.value, apiResponse: {}, resetDateFilter: prevState.resetDateFilter + 1 }));
    let model = {
      take: 10,
      page: 0,
      sortField: 'createdDate',
      sortOrder: 'DESC',
    };
    let page = 'Manage Obituary Profiles';
    if (params.value === 'renewal') {
      model.sortField = 'expiryDate';
      model.sortOrder = 'ASC';
      model.statusId = 1;
      model.filterRenewalObituaries = true;
      page = 'Manage Renewal Profiles';
    }
    this.setState({ access: setComponentPrivilege(page) });
    this.renderApi('obituary-profile', model);
  }

  componentDidUpdate() {
    let {
      match: { params },
    } = this.props;
    let { page } = this.state;
    if (page !== params.value) {
      this.updatePage(params);
    }
  }

  filterByDate = (dates) => {
    this.setpredicates({ fromDate: dates.fromDate, toDate: dates.toDate });
  };

  formatImageUrl = (url) => {
    if (url && url.length > 0) {
      if (url.includes('?')) {
        return `${url}&date=${new Date()}`;
      }
      return `${url}?date=${new Date()}`;
    }
    return imgUser;
  };

  render() {
    let { apiResponse, isLoaderActive, manageModal, page, resetDateFilter, access, statusTextColor } = this.state;
    let { user } = this.props.userData;
    this.formRef.onFormSubmit = {};

    return (
      <React.Fragment>
        <Container fluid>
          <Row className="align-items-center">
            <Col sm={6}>
              {isLoaderActive === true ? <Loader /> : ''}
              {page === 'general' ? (
                <HeadingBlock title="Obituary Profiles" />
              ) : (
                <HeadingBlock title="Obituary Profiles Up For Renewal" customBreadcrumbsTitle="Renewal Profiles" />
              )}
            </Col>
            {page === 'general' && access && access.create && (
              <Col sm={6} className="py-3">
                <div className="float-sm-right d-block">
                  <LinkButton
                    toString="createObituary"
                    className="btn btn-primary waves-effect waves-light"
                    role="button"
                    toDoFunction={(e) => this.showManageModal(e)}
                  >
                    Add Obituary Profile
                  </LinkButton>
                </div>
              </Col>
            )}
            {page === 'renewal' && (
              <Col sm={6} className="pr-0 card-view">
                <div className="float-right d-block">
                  <Search
                    {...this.setproperties({
                      searchField: 'expiresInDays',
                      isCardViewTrue: true,
                      placeholder: 'Enter days till expiry',
                    })}
                  />
                </div>
              </Col>
            )}
          </Row>
          <div className="app-l-obituary-profile-holder">
            <DataTableHolder
              className="app-l-obituary-profile-block"
              pagination={apiResponse && apiResponse.rows && apiResponse.rows.length > 0 ? <ListPagination {...this.setpagination()} /> : null}
            >
              <Row className="card-view w-100">
                <Col xs={8} sm={6} className="pr-0 form-group">
                  <Search
                    {...this.setproperties({
                      searchField: 'q',
                      isCardViewTrue: true,
                      placeholder: 'Search for name, ticket Id etc',
                    })}
                  />
                </Col>
                <Col xs={4} sm={6} className="d-flex form-group">
                  <Sort
                    {...this.setproperties({
                      isCardViewTrue: true,
                      sortFieldList: [
                        { title: 'Name', sortField: 'name' },
                        { title: 'Ticket Id', sortField: 'ticketId' },
                        { title: 'Created Date', sortField: 'createdDate' },
                      ],
                    })}
                  />
                </Col>
              </Row>
              <div className="app-l-filter-holder">
                <DateRangeFilter pageSwitchCount={resetDateFilter} setFilterDates={this.filterByDate} />
              </div>
              <div className="app-l-table-holder">
                <DataTable>
                  <THead className="card-table-header">
                    <TRow>
                      <DataColHead
                        {...this.setheadproperties({
                          title: 'Gender',
                          sublist: 'gender',
                          coltype: 'filter',
                          filterapi: 'gender',
                          sublistFilterField: 'gender',
                          filterClassName: 'app-l-condensed-column',
                        })}
                      />

                      <DataColHead
                        {...this.setheadproperties({
                          title: 'Privacy',
                          sublist: 'privacy',
                          coltype: 'filter',
                          filterapi: 'obituary-privacy',
                          sublistFilterField: 'privacy',
                          filterClassName: 'app-l-condensed-column',
                        })}
                      />

                      <DataColHead
                        {...this.setheadproperties({
                          title: 'Owners',
                          sublist: 'owners',
                          coltype: 'filter',
                          filterapi: '',
                          sublistFilterField: 'obituaryManagers',
                          filterClassName: 'app-l-condensed-column',
                          extraOptions: [
                            { id: 1, title: 'No profile owners' },
                            { id: 2, title: 'No profile admins' },
                          ],
                        })}
                      />

                      {page === 'general' && (
                        <DataColHead
                          {...this.setheadproperties({
                            title: 'Status',
                            sublist: 'status',
                            coltype: 'filter',
                            filterapi: 'obituary-status',
                            sublistFilterField: 'status',
                            filterClassName: 'app-l-condensed-column',
                            removeOptions: { shouldRemove: page === 'renewal', ids: [2, 3] },
                            extraOptions: [
                              { id: 5, title: 'Blocked', index: 0 },
                              { id: 4, title: 'Expired', index: 2 },
                              { id: 6, title: 'To be published', index: 4 },
                            ],
                          })}
                        />
                      )}
                      {page === 'renewal' && (
                        <DataColHead
                          {...this.setheadproperties({
                            title: 'Status',
                            sublist: 'status',
                            coltype: 'filter',
                            filterapi: 'obituary-status',
                            sublistFilterField: 'status',
                            filterClassName: 'app-l-condensed-column',
                            removeOptions: { shouldRemove: page === 'renewal', ids: [2, 3] },
                            extraOptions: [
                              { id: 5, title: 'Blocked', index: 0 },
                              { id: 4, title: 'Expired', index: 2 },
                            ],
                          })}
                        />
                      )}

                      <DataColHead
                        {...this.setheadproperties({
                          title: 'Tags',
                          labelKey: 'title',
                          valueKey: 'id',
                          isMultiSelectCheck: true,
                          apiModel: { sortField: 'title', sortOrder: 'ASC' },
                          filterapiForChild: 'tag',
                          idFilterListName: 'tagsIdList',
                          coltype: 'multiselect',
                          className: 'app-l-condensed-column multiselect-filter-checkbox',
                        })}
                      />
                      <DataColHead
                        {...this.setheadproperties({
                          title: 'Place',
                          valueKey: 'id',
                          isMultiSelectCheck: true,
                          filterapiForChild: 'place',
                          apiModel: {},
                          idFilterListName: 'placesIdList',
                          coltype: 'multiselect',
                          className: 'app-l-condensed-column multiselect-filter-checkbox',
                        })}
                      />
                    </TRow>
                  </THead>
                </DataTable>
              </div>
              <Row className="w-100 m-auto app-l-obituary-card">
                {this.state.showLightBox ? (
                  <Lightbox
                    mainSrc={this.state.imageUrl}
                    clickOutsideToClose
                    enableZoom
                    onCloseRequest={() => this.setState({ showLightBox: false })}
                  />
                ) : null}
                {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                  ? apiResponse.rows.map((row, i) => {
                      let addressTitle = '';
                      let shortLocation = '';
                      let { statusTitle } = row;
                      let statusStyle = statusTextColor[row.statusId - 1];
                      if (row.statusId === 1 && row.publishedDate) {
                        // let publishedDateTime = combineDateTime(row.publishedDate, `T${row.publishedTime}.`);
                        let publishedDateTime = combineDateTime(row.publishedDate, `${dayjs().format('YYYY-MM-DDT') + row.publishedTime}.000Z`);
                        if (row.isBlockedByAdmin) {
                          statusTitle = 'Blocked';
                          statusStyle = statusTextColor[row.statusId + 2];
                        } else if (new Date(publishedDateTime.replace(/Z/i, '')).getTime() > new Date().getTime()) {
                          statusTitle = 'To Be Published';
                        } else if (new Date().getTime() > new Date(row.expiryDate).getTime()) {
                          statusTitle = 'Expired';
                          statusStyle = statusTextColor[row.statusId + 2];
                        }
                      }
                      if (row.place) {
                        addressTitle = row.place.address;
                        if (row.place.state) {
                          shortLocation = `${row.place.state}, ${row.place.country}`;
                        } else {
                          shortLocation = row.place.country;
                        }
                      }
                      return (
                        <Col xl={6} md={6} className={i % 2 == 0 ? 'pl-0' : 'pr-0'}>
                          <Card className="directory-card">
                            <CardBody>
                              <div className="media">
                                <img
                                  onClick={() => {
                                    this.setState({
                                      showLightBox: true,
                                      imageUrl: this.formatImageUrl(row.mediaUrl),
                                    });
                                  }}
                                  src={this.formatImageUrl(row.mediaUrl)}
                                  alt=""
                                  className="img-fluid img-thumbnail rounded-circle avatar-lg"
                                />
                                <div className="media-body ml-3">
                                  <b className="text-primary truncate-text font-size-18 mt-0 mb-1" title={row.name}>
                                    {row.name}
                                  </b>
                                  <p className="font-size-16 mb-2 truncate-text" title={addressTitle}>
                                    {shortLocation}
                                  </p>
                                  <p className="font-size-13 mb-2">
                                    <ViewDate isTableView dateValue={row.dateOfBirth} />
                                    {' - '}
                                    <ViewDate isTableView dateValue={row.dateOfDeath} />
                                  </p>
                                </div>
                                <ul className="list-unstyled social-links float-right">
                                  {user.roleId == 1 && row.isBlockedByAdmin && (
                                    <LinkButton
                                      toString="viewProfile"
                                      title="Blocked by Memlo"
                                      toDoFunction={
                                        () =>
                                          // eslint-disable-next-line implicit-arrow-linebreak
                                          this.toast.show(
                                            'access-error',
                                            'ERROR',
                                            'This obituary is blocked, please contact Memlo support team for further details',
                                          )
                                        // eslint-disable-next-line react/jsx-curly-newline
                                      }
                                    >
                                      <i className="fas fa-eye-slash" />
                                    </LinkButton>
                                  )}
                                  {user.roleId == 1 && !row.isBlockedByAdmin && user.id != row.ownerId && user.id != row.createdById && (
                                    <LinkButton
                                      toString="viewProfile"
                                      title="View memorial in Memlo website"
                                      toDoFunction={() => window.open(`${process.env.REACT_APP_FRONTEND_URL}pages/view-memorial/${row.id}`)}
                                    >
                                      <i className="fas fa-eye" />
                                    </LinkButton>
                                  )}
                                  {(user.roleId != 1 ||
                                    ((user.id == row.createdById || user.id == row.ownerId) && !(user.roleId == 1 && row.isBlockedByAdmin))) && (
                                    <ActionButton value="view" rowId={row.id} url={`/view-obituary-profiles/${page}/`} />
                                  )}
                                </ul>
                              </div>
                              <hr />
                              <Row>
                                <Col xl={6} md={6}>
                                  <p className="mb-0 font-size-16">
                                    <i className="mdi mdi-human-child" /> {row.genderTitle ? row.genderTitle : '______'}
                                  </p>
                                </Col>

                                <Col xl={6} md={6}>
                                  <p className="mb-0 font-size-16">
                                    <i className="mdi mdi-update" /> <ViewDate isTableView dateValue={row.publishedDate} />
                                  </p>
                                </Col>
                                <Col xl={6} md={6}>
                                  <p className="mb-0 font-size-16">
                                    <i className="mdi mdi-shield-star" /> {row.privacyTitle}
                                  </p>
                                </Col>
                                <Col xl={6} md={6}>
                                  <p className="mb-0 font-size-16" style={{ color: statusStyle }}>
                                    {row.isBlockedByAdmin ? (
                                      <i className="mdi mdi-block-helper" />
                                    ) : (
                                      <i className="mdi mdi-file-document-box-check-outline" />
                                    )}{' '}
                                    {statusTitle}
                                  </p>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })
                  : null}
                {apiResponse.rows && apiResponse.rows.length === 0 && <div className="col text-center no-content-block">No results found </div>}
              </Row>
            </DataTableHolder>
          </div>
        </Container>
        {manageModal && (
          <CustomModal
            modal={manageModal}
            toggleModal={this.showManageModal}
            title="Create New Obituary Profile"
            buttonList={[
              { name: 'Save', color: 'primary', onClick: this.onConfirmButtonClick },
              { name: 'Cancel', color: 'secondary' },
            ]}
            className="modal-lg"
            maxHeight={400}
          >
            <ManageObituaryProfiles formRef={this.formRef} {...this.props} />
          </CustomModal>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.Login.userData,
});

export default connect(mapStateToProps, null)(DirectoryObituaryProfile);
