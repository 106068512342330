import React, { Component } from 'react';
import { arrayMoveImmutable } from 'array-move';
import Loader from '../../Components/loader';
import { get, put } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import SortableList from './sortElements';

class SortMediaComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      isLoaderActive: false,
    };
  }

  toast = new ToastNotification();

  componentDidMount() {
    let { renderapi } = this.props;
    if (renderapi && renderapi.length > 0) {
      get(renderapi).then((response) => {
        response.data = response.data.data;
        this.setState({ apiResponse: response.data });
      });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState((prevState) => ({
      apiResponse: { ...prevState.apiResponse, rows: arrayMoveImmutable(prevState.apiResponse.rows, oldIndex, newIndex) },
    }));
  };

  onSaveButtonClick = () => {
    this.setState({ isLoaderActive: true });
    let { apiResponse } = this.state;
    let { updateapi, sortRef } = this.props;
    apiResponse.rows.forEach((row, i) => {
      apiResponse.rows[i].mediaOrder = i + 1;
    });
    put(updateapi, apiResponse.rows)
      .then((response) => {
        if (response && response.data.data && response.data.success) {
          this.toast.show('update-success', 'SUCCESS', 'New arrangement saved successfully');
          sortRef.rerender(apiResponse);
        }
        sortRef.onClose();
        this.setState({ isLoaderActive: false });
      })
      .catch(() => {
        this.setState({ isLoaderActive: false });
        sortRef.onClose();
        this.toast.show('update-error', 'ERROR', 'Could not reorder photos and videos');
      });
  };

  render() {
    let { apiResponse, isLoaderActive } = this.state;
    let { sortRef } = this.props;
    sortRef.onSaveButtonClick = this.onSaveButtonClick;

    return (
      <React.Fragment>
        {isLoaderActive ? <Loader /> : null}
        <SortableList
          items={apiResponse.rows}
          keyName="media-card"
          axis="xy"
          lockToContainerEdges
          helperClass="sortable-list-helper"
          onSortEnd={this.onSortEnd}
        />
      </React.Fragment>
    );
  }
}

export default SortMediaComponent;
