/* eslint-disable no-fallthrough */
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import PlacesAutocomplete from 'react-places-autocomplete';
/* global google */
import { connect } from 'react-redux';
import Validation from '../Libraries/Validations/validation';
import ValidationMessage from './FormHelpers/Validation/validationMessage';

export class LocationSearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  validation = new Validation();

  componentDidMount() {
    let map = new google.maps.Map(document.createElement('div'));
    this.googlePlaces = new google.maps.places.PlacesService(map);
  }

  handleChange = (address) => {
    let properties = {
      ...this.props.placeDetails,
      value: { address },
    };
    if (properties.onChangeValidation) {
      properties.onChangeValidation(address, properties);
    }
  };

  handleSelect = (address, placeId, suggestion) => {
    let { placeDetails } = this.props;
    let fullAddress = {};
    if (placeId) {
      placeDetails.setParentState({ locationLoader: true });
      this.googlePlaces.getDetails({ placeId }, (place, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          fullAddress.latitude = place.geometry.location.lat().toString();
          fullAddress.longitude = place.geometry.location.lng().toString();
          if (place.photos && place.photos.length > 0) {
            fullAddress.image = place.photos[0].getUrl({ maxWidth: 500, maxHeight: 500 });
          }
          if (place.address_components.length > 0) {
            place.address_components.forEach((element) => {
              if (element.types[0] == 'country') {
                fullAddress.countryCode = element.short_name;
              }
            });
          }
          this.setAdministrativeLevelsFromAddress(address, suggestion, fullAddress);
        }
      });
    }
  };

  setAdministrativeLevelsFromAddress = (address, suggestion, fullAddress) => {
    let { placeDetails, hideAfterUse, type } = this.props;
    let addressArray = [];
    let size = 0;
    if (address.includes(',')) {
      addressArray = address.split(',');
    } else if (address.includes('-')) {
      addressArray = address.split('-');
    } else {
      addressArray = [address];
    }
    size = addressArray.length;
    fullAddress.address = address;
    // Assuming only one type is set at a time
    if (type[0] == 'establishment') {
      fullAddress.name = suggestion.formattedSuggestion?.mainText;
      fullAddress.location = suggestion.formattedSuggestion?.secondaryText;
    } else {
      switch (size > 0) {
        case size > 4:
          fullAddress.name = addressArray[0].trim();
        case size > 3:
          if (!Object.prototype.hasOwnProperty.call(fullAddress, 'name')) {
            fullAddress.name = addressArray[size - 4].trim();
          }
        case size > 2:
          fullAddress.city = addressArray[size - 3].trim();
        case size > 1:
          fullAddress.state = addressArray[size - 2].trim();
        case size > 0:
          fullAddress.country = addressArray[size - 1].trim();
        default:
          if (!Object.prototype.hasOwnProperty.call(fullAddress, 'name')) {
            fullAddress.name = addressArray[0].trim();
          }
          break;
      }
    }
    let properties = {
      ...placeDetails,
      value: fullAddress,
    };
    if (properties.onChangeValidation) {
      properties.onChangeValidation(fullAddress, properties);
    }
    placeDetails.setParentState({ locationLoader: false });
    if (hideAfterUse) {
      placeDetails.setParentState({ showLocationComponent: false });
    }
  };

  render() {
    let { placeDetails, type, disableLabel, formLabel, formGroup, className } = this.props;
    let message = '';

    if (this.props.placeDetails.errors && this.props.placeDetails.errors.length > 0) {
      message = this.props.placeDetails.errors[0] && this.props.placeDetails.errors[0].message;
    }
    let messagetype = 'i';
    if (this.props.placeDetails.isTouched) {
      messagetype = 'e';
    }
    if (!this.props.placeDetails.isFocused || this.props.placeDetails.isSilent) {
      messagetype = '';
    }
    let onError = (status, clearSuggestions) => {
      clearSuggestions();
    };

    let searchOptions = {};
    if (type) {
      searchOptions = { types: type };
    }
    return (
      <div>
        <div className={formGroup || 'form-control app-c-form'}>
          {!disableLabel ? (
            <Form.Label className={formLabel || 'app-c-input__label'}>
              {placeDetails.label}
              {placeDetails.rules && placeDetails.rules.locationRequired && !placeDetails.disableRequiredsymbol ? <sup>*</sup> : ''}
            </Form.Label>
          ) : (
            ''
          )}

          <PlacesAutocomplete
            value={placeDetails.value?.address ? placeDetails.value?.address : null}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
            onError={onError}
            searchOptions={searchOptions}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: placeDetails.placeholder,
                    className: `form-control ${className || ''}`,
                    name: placeDetails.name,
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => (
                    <div className="input-sugggestion" {...getSuggestionItemProps(suggestion, {})} key={suggestion.placeId}>
                      <span>{suggestion.description}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        {messagetype ? <ValidationMessage {...{ message }} {...{ type: messagetype }} /> : <span className="app-c-validation-message" />}
      </div>
    );
  }
}
const mapStateToProps = () => ({});

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationSearchInput);
