import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { put } from '../../Libraries/Ajax/httpService';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import CustomDatePicker from '../../SharedComponents/FormHelpers/Controls/customDatePicker';
import { combineDateTime, convertToLocalISOString, formatDate } from '../../Libraries/DateTimeHelpers/dateTimeHelperFunctions';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';

class ReschedulePublishComponent extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      publishedDate: {
        label: 'Date of publish',
        name: 'publishedDate',
        type: 'date',
        placeholder: 'Enter published date',
        rules: { required: true },
      },
      publishedTime: {
        label: 'Time of Publish',
        name: 'publishedTime',
        type: 'string',
        placeholder: 'Enter published time',
        relatedPropertyName: 'publishedDate',
        rules: {
          required: true,
          minNow: true,
        },
      },
    };
  }

  toast = new ToastNotification();

  componentDidMount() {
    let { publishedDate, publishedTime } = this.props;
    this.setState((prevState) => ({
      publishedDate: {
        ...prevState.publishedDate,
        value: publishedDate.replace(/Z/i, ''),
      },
      publishedTime: {
        ...prevState.publishedTime,
        value: publishedTime,
      },
    }));
  }

  onSaveButtonClick = (model) => {
    let { formRef, obituaryId, setParentState, statusChange } = this.props;
    let statusTitle = 'Published';
    model.statusId = 1;
    let toastMessage = 'The Obituary Profile has been successfully published';
    let newPublishedDateTime = combineDateTime(model.publishedDate, model.publishedTime);
    if (new Date(newPublishedDateTime.replace(/Z/i, '')).getTime() > new Date().getTime()) {
      statusTitle = 'To Be Published';
      toastMessage = 'The Obituary Profile will be published in the future';
    }
    model.publishedDate = formatDate(model.publishedDate);
    if (!newPublishedDateTime.toUpperCase().includes('.000Z')) {
      newPublishedDateTime = newPublishedDateTime.concat('.000Z');
    }
    put(`obituary-profile/${obituaryId}`, model)
      .then((response) => {
        if (response.data.data && response.data.success) {
          setParentState({
            stsId: 1,
            stsTitle: statusTitle,
            publishDate: newPublishedDateTime,
            publishTime: response.data.data.publishedTime,
            expiryDate: convertToLocalISOString(response.data.data.expiryDate),
          });
          statusChange(1, 'Published');
          formRef.onClose();
          this.toast.show('update_success', 'SUCCESS', toastMessage);
        } else if (!response.data.success && response.data.mess) {
          formRef.onClose();
          this.toast.show('update_error', 'ERROR', response.data.message);
        } else {
          formRef.onClose();
          this.toast.show('update_error', 'ERROR', "Couldn't update status. Please try after some time.");
        }
      })
      .catch((err) => {
        this.toast.show('update_error', 'ERROR', err.message ? err.message : "Couldn't update. Please try after some time.");
      });
  };

  render() {
    let { formRef } = this.props;

    return (
      <React.Fragment>
        <Card className="mb-0">
          <CardBody>
            <p>To reschedule the publication of this obituary profile, pick a date from the calender and then click Reschedule.</p>
            <SubmitForm onSubmit={this.onSaveButtonClick} {...this.setFormProperties({ disableReactiveForm: true })} formRef={formRef}>
              <Row>
                <Col sm={12} md={6}>
                  <CustomDatePicker
                    formGroup
                    appearance="subtle"
                    futureDate
                    format="DD/MM/YYYY"
                    isDate
                    oneTap
                    placement="auto"
                    {...this.setProperties(this.state.publishedDate)}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <CustomDatePicker
                    formGroup
                    appearance="subtle"
                    format="HH:mm"
                    isDate={false}
                    oneTap={false}
                    placement="auto"
                    {...this.setProperties(this.state.publishedTime)}
                  />
                </Col>
              </Row>
            </SubmitForm>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default ReschedulePublishComponent;
