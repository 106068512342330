import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class Salesdonut extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        labels: [1, 2, 3, 4, 5],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: '70%',
            },
          },
        },
        colors: ['#02a499', '#626ed4', '#f8b425', '#343a40', '#ea4335'],
      },
      series: props.series,
      // labels: [1, 2, 3, 4, 5],
    };
  }

  render() {
    return (
      <React.Fragment>
        <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height="220" />
      </React.Fragment>
    );
  }
}

export default Salesdonut;
