import React from 'react';
import { Container, Row, Col, Card, CardBody, Button, CardTitle, FormGroup } from 'reactstrap';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import CustomDatePicker from '../../SharedComponents/FormHelpers/Controls/customDatePicker';
import CustomComponentDropdown from '../../SharedComponents/FormHelpers/Controls/customComponentDropdown';
import FormInput from '../../SharedComponents/FormHelpers/Controls/formInput';
import { post } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import { convertToLocalISOString, formatDate } from '../../Libraries/DateTimeHelpers/dateTimeHelperFunctions';

class AddCustomerResponse extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      calledDate: {
        label: 'Called on',
        name: 'calledDate',
        type: 'Date',
        placeholder: 'DD/MM/YY',
        className: 'form-control app-c-form',
        rules: {
          required: true,
          maxToday: true,
        },
        value: convertToLocalISOString(Date.now()).replace(/Z/i, ''),
      },
      contactPersonId: {
        label: 'Contacted Person',
        name: 'contactPersonId',
        className: 'mb-0',
        optionvalue: 'id',
        renderapi: `user/obituary-managers/${props.obituaryId}`,
        apiModel: {},
        isSelectOnly: true,
        noOptionsMessage: 'No users found',
        imageKey: 'userImageUrl',
        subText: 'phone',
        mainText: 'name',
        rules: {
          required: true,
        },
      },
      remarks: {
        label: 'Notes',
        name: 'remarks',
        type: 'text',
        placeholder: 'Enter response',
        rules: {
          required: true,
          maxLength: 500,
        },
      },
    };
  }

  toast = new ToastNotification();

  onSaveButtonClick = (model) => {
    let { obituaryId, reloadParent } = this.props;
    model.calledDate = formatDate(model.calledDate);
    model.obituaryId = obituaryId;
    post('renewal-response', model)
      .then((response) => {
        if (response && response.data && response.data.data && response.data.success) {
          this.toast.show('create-success', 'SUCCESS', response.data.message);
          reloadParent(true);
        }
      })
      .catch((err) => this.toast.show('create-error', 'ERROR', err.data.message));
    this.onCancelButtonClick();
  };

  onCancelButtonClick = () => {
    this.props.setParentState({ hideAddButton: false });
  };

  render() {
    return (
      <React.Fragment>
        <Container fluid className="px-0">
          <Row>
            <Col>
              <Card>
                <CardBody className="pt-0">
                  <CardTitle tag="h4" className="mb-3">
                    Add Call Details
                  </CardTitle>
                  <SubmitForm onSubmit={this.onSaveButtonClick} {...this.setFormProperties({ disableReactiveForm: true })}>
                    <Row>
                      <Col sm="12" md="6">
                        <CustomDatePicker
                          formGroup
                          appearance="subtle"
                          futureDate={false}
                          format="DD/MM/YYYY"
                          isDate
                          oneTap
                          placement="auto"
                          {...this.setProperties(this.state.calledDate)}
                        />
                      </Col>
                      <Col sm="12" md="6">
                        <CustomComponentDropdown {...this.setProperties(this.state.contactPersonId)} />
                      </Col>
                      <Col sm="12">
                        <FormInput as="textarea" rows="3" className="form-control" {...this.setProperties(this.state.remarks)} />
                      </Col>
                    </Row>
                    <FormGroup className="mb-0">
                      <div className="float-right d-block">
                        <br />
                        <Button type="submit" color="primary" className="mr-1">
                          Save
                        </Button>{' '}
                        <Button className="btn-secondary" type="reset" onClick={() => this.onCancelButtonClick()}>
                          <i className="icon-close"> </i> <span> Cancel </span>
                        </Button>
                      </div>
                    </FormGroup>
                  </SubmitForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default AddCustomerResponse;
