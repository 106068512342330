/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import Loader from '../../Components/loader';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';
import { ActionButton } from '../../SharedComponents/FormHelpers/Controls/actionButton';
import { DataColHead, ListPagination, ViewDate } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { DataTable, DataTableHolder, HeadingBlock, TBody, TCol, THead, TRow, TColBadge } from '../../SharedComponents/Table/tableElements';

class ListPermissions extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleSelect: false,
      multiSelect: false,
      rowData: {},
      isLoaderActive: false,
      showDeleteConfirmation: false,
    };
  }

  componentDidMount() {
    this.setJsonUrl('profile-admin');
    this.setpredicates({ take: 10, page: 0 });
    this.setState({ access: setComponentPrivilege('Profile Admin') });
  }

  render() {
    let { apiResponse, access, isLoaderActive } = this.state;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              {isLoaderActive === true ? <Loader /> : ''}
              <HeadingBlock title="Obituary Profile Permissions" />
            </Col>
            {access && access.create && (
              <Col sm={6}>
                <div className="float-right d-md-block">
                  <Link className="btn btn-primary waves-effect waves-light" to="/set-permissions" role="button">
                    Add Permission
                  </Link>
                </div>
              </Col>
            )}
          </Row>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                    <div className="row">
                      <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                        <DataTable>
                          <THead>
                            <TRow>
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Obituary Name',
                                })}
                              />

                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'User Name',
                                  // width: 250,
                                })}
                              />

                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Created By',
                                })}
                              />

                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Created Date',
                                  width: 120,
                                })}
                              />

                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Email Status',
                                  width: 120,
                                })}
                              />

                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Action',
                                  filterfield: 'action',
                                  width: 120,
                                  className: 'action',
                                })}
                              />
                            </TRow>
                          </THead>
                          <TBody>
                            {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                              ? apiResponse.rows.map((row, i) => (
                                  <TRow key={`access-user-${i}`}>
                                    <TCol>{row.obituaryName}</TCol>
                                    <TCol>{row.userName}</TCol>
                                    <TCol>{row.createdByName}</TCol>
                                    <TCol>
                                      <ViewDate isTableView dateValue={row.createdDate} />
                                    </TCol>
                                    <TColBadge field="EmailStatus" value={row.emailStatusTitle} id={row.emailStatusId} />
                                    <TCol>
                                      <div className="action-col">
                                        {access && access.edit && <ActionButton value="edit" rowId={row.id} url="set-permissions/" />}
                                        {access && access.delete && (
                                          <ActionButton
                                            value="delete"
                                            rowId={row.id}
                                            url="list-permissions"
                                            setJsonUrl={this.setJsonUrl}
                                            setpredicates={this.setpredicates}
                                            api="profile-admin/"
                                          />
                                        )}
                                      </div>
                                    </TCol>
                                  </TRow>
                                ))
                              : null}
                          </TBody>
                        </DataTable>
                        {apiResponse.rows && apiResponse.rows.length === 0 && <div className="text-center no-content-block">No results found </div>}
                      </DataTableHolder>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ListPermissions;
