import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Modal, Row } from 'reactstrap';
import dayjs from 'dayjs';

import LinkButton from '../../SharedComponents/linkButton';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { DataTable, DataTableHolder, TBody, TCol, THead, TRow } from '../../SharedComponents/Table/tableElements';
import ThankYou from './thankYouModal';

export class ActivityOnObituary extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      thankYouModal: false,
      apiResponse: {},
      obituaryId: this.props.obituaryRowid,
      message: {
        label: 'Message',
        type: 'text',
        placeholder: 'Type message',
        rules: {
          required: true,
          maxLength: 500,
        },
      },
    };

    this.setpredicates = this.setpredicates.bind(this);
  }

  componentDidMount() {
    let { obituaryRowId } = this.props;
    this.setJsonUrl('engagements');
    this.setpredicates({
      take: 20,
      obituaryId: obituaryRowId,
      statusId: 3,
    });
  }

  thankYou = () => {
    this.setState((prevState) => ({
      thankYouModal: !prevState.thankYouModal,
    }));
  };

  render() {
    let { apiResponse } = this.state;
    let { showCheck, toggleState, stateKey } = this.props;
    return (
      <div className="container-fluid">
        <Modal className="modal-lg" isOpen={showCheck} scrollable toggle={() => toggleState(stateKey)}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Activities in Obituary Profiles
            </h5>
            <button
              onClick={() => {
                toggleState(stateKey);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Card className="enable-scroll">
            <CardBody>
              <Row className="align-items-center">
                <Col sm={6}>
                  <p>The activities on obituary profile</p>
                </Col>
                {apiResponse?.rows?.length > 0 && (
                  <Col sm={6}>
                    <div className="float-right d-md-block">
                      <LinkButton
                        toString="sendThankYou"
                        className="btn btn-primary waves-effect waves-light"
                        toDoFunction={this.thankYou}
                        role="button"
                      >
                        Send Thank You Messages
                      </LinkButton>
                      &nbsp;
                    </div>
                  </Col>
                )}
              </Row>
              <div className="row">
                <DataTableHolder pagination={<ListPagination {...this.setpagination()} />} className="min-160">
                  <DataTable>
                    <THead>
                      <TRow>
                        <DataColHead
                          {...this.setheadproperties({
                            title: 'DATE',
                          })}
                        />
                        <DataColHead
                          {...this.setheadproperties({
                            title: 'USER NAME',
                          })}
                        />
                        <DataColHead
                          {...this.setheadproperties({
                            title: 'ACTIVITY',
                          })}
                        />
                      </TRow>
                    </THead>
                    <TBody>
                      {apiResponse && apiResponse.rows && apiResponse.rows?.length > 0 ? (
                        apiResponse.rows.map((row) => (
                          <TRow>
                            <TCol>{dayjs(row.createdDate).format('DD.MM.YYYY')}</TCol>
                            <TCol>{row.userName}</TCol>
                            <TCol>
                              <span>
                                {row.activity == 'Flower' && <i className="mdi mdi-flower" />}
                                {row.activity == 'Tree' && <i className="fas fa-tree" />}
                                {row.activity == 'Attendance' && <i className="fa fa-heart" />}
                              </span>
                              {row.activity}
                            </TCol>
                          </TRow>
                        ))
                      ) : (
                        <TRow>
                          <TCol colSpan="3">
                            <div className="text-center no-content-block">No results found </div>
                          </TCol>
                        </TRow>
                      )}
                    </TBody>
                  </DataTable>
                </DataTableHolder>
              </div>
            </CardBody>
          </Card>
        </Modal>
        {this.state.thankYouModal && (
          <ThankYou thankYouModal={this.state.thankYouModal} toggleThank={this.thankYou} obituaryId={this.props.obituaryRowId} />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userData: state.Login.userData,
});
export default connect(mapStateToProps)(ActivityOnObituary);
