import React, { useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import { ActionButton } from '../../SharedComponents/FormHelpers/Controls/actionButton';
import { StatusSwitch } from '../../SharedComponents/FormHelpers/Controls/statusSwitch';
import { ViewDate } from '../../SharedComponents/ListComponents/components';
import LinkButton from '../../SharedComponents/linkButton';

const ListMediaCard = (props) => {
  let [showLightBox, setshowLightBox] = useState(false);
  let {
    alt,
    content,
    modifiedDate,
    mediaType,
    obituaryId,
    fromTribute,
    toggleStatus,
    rowData,
    rowId,
    setJsonUrl,
    setpredicates,
    deleteurl,
    setParentState,
    apiResponse,
    postType,
    access,
    isLocked,
    row,
  } = props;

  let updateParent = () => {
    props.onDelete();
  };
  let src = null;
  if (props.src) {
    src = props.src + (props.src.includes('?') ? `&date=${new Date()}` : `?date=${new Date()}`);
  }
  return (
    <React.Fragment>
      {/* **********Lightbox Content************* */}
      {showLightBox ? <Lightbox mainSrc={src} clickOutsideToClose enableZoom onCloseRequest={() => setshowLightBox(false)} /> : null}
      {/* **********Lightbox Content************* */}
      <Card>
        <Container fluid className="px=0 app-c-file-upload-preview" style={!props.src ? { background: props.rowData?.solidColor } : {}}>
          {props.src &&
            (['avi', 'mp4'].every((type) => type != mediaType) ? (
              <img src={src} alt={alt} onClick={() => setshowLightBox(true)} height="100%" width="100%" />
            ) : (
              <video width="100%" height="100%" preload="metadata" controls="controls">
                <track src="captions_en.vtt" kind="captions" label="english_captions" />
                <source src={`${src}#t=0.5`} type="video/mp4" />
                <source src={`${src}#t=0.5`} type="video/avi" />
              </video>
            ))}
        </Container>
        {fromTribute && (postType == 'background-image' || postType == 'solid-colors') && (
          <CardBody>
            {access && access.edit && (
              <StatusSwitch
                isListIcon
                value={toggleStatus}
                name="statusId"
                rowData={rowData}
                updateApi={`background-template/${rowId}`}
                valueObj={{ on: 1, off: 2 }}
                apiResponse={apiResponse}
                setParentState={setParentState}
              />
            )}
            {!(access && access.edit) && (
              <div className="float-left">
                <b>Status: </b>
                {rowData.statusTitle}
              </div>
            )}
            <div className="float-right d-block">
              {access && access.edit && (
                <ActionButton
                  value="edit-color"
                  rowId={rowId}
                  url={`/manage-template/${props.postType}/`}
                  setJsonUrl={setJsonUrl}
                  setpredicates={setpredicates}
                  api="background-template/"
                />
              )}
              {access && access.delete && (
                <ActionButton value="delete" rowId={rowId} setJsonUrl={setJsonUrl} setpredicates={setpredicates} api="background-template/" />
              )}
            </div>
          </CardBody>
        )}

        {fromTribute && postType != 'background-image' && postType != 'solid-colors' && (
          <CardBody>
            {access && access.edit && (
              <StatusSwitch
                isListIcon
                value={toggleStatus}
                name="statusId"
                rowData={rowData}
                updateApi={`template-message/${rowId}`}
                valueObj={{ on: 1, off: 2 }}
                apiResponse={apiResponse}
                setParentState={setParentState}
              />
            )}
            {!(access && access.edit) && (
              <div className="float-left">
                <b>Status: </b>
                {rowData.statusTitle}
              </div>
            )}
            {access && access.delete && (
              <div className="float-right d-block">
                <ActionButton
                  value="delete"
                  rowId={rowId}
                  url={deleteurl}
                  setJsonUrl={setJsonUrl}
                  setpredicates={setpredicates}
                  api="template-message/"
                />
              </div>
            )}
          </CardBody>
        )}

        {!fromTribute && (
          <CardBody className="p-2">
            {content && <p className="card-text">{content}</p>}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <small className="text-muted">
                <ViewDate isTableView dateValue={modifiedDate} />
              </small>
              {isLocked && (
                <small className="text-muted">
                  <span>
                    <b>Locked</b>
                  </span>
                </small>
              )}
            </div>
            <hr />
            <div className="float-right d-block">
              {access && access.edit && (
                <LinkButton to="editMedia" toDoFunction={() => props.onEditButtonClick()}>
                  <i className="fas fa-edit" />
                </LinkButton>
              )}
              &nbsp;
              {access && access.delete && (
                <ActionButton value="delete" rowId={rowId} row={row} url={obituaryId} api="obituary-post/" updateParent={updateParent} />
              )}{' '}
            </div>
          </CardBody>
        )}
      </Card>
    </React.Fragment>
  );
};

export default ListMediaCard;
