import { Col, FormText, FormGroup, Label } from 'reactstrap';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { ContentItems } from '../../SharedComponents/ListComponents/manageComponent';
import { ViewDate } from '../../SharedComponents/ListComponents/components';
import imgUser from '../../assets/images/userpic.png';

function ObituaryProfileDetails(props) {
  let [showLightBox, setshowLightBox] = useState(false);
  let { singleRow } = props;

  return (
    <>
      {showLightBox ? (
        <Lightbox
          mainSrc={singleRow.mediaUrl ? singleRow.mediaUrl : imgUser}
          clickOutsideToClose
          enableZoom
          onCloseRequest={() => setshowLightBox(false)}
        />
      ) : null}
      <Col xs={12} md={12} className="mb-3 text-center pt-2">
        <img
          onClick={() => setshowLightBox(true)}
          className="card-img-top img-fluid"
          src={singleRow.mediaUrl ? singleRow.mediaUrl : imgUser}
          alt="profile"
          style={{ maxHeight: '230px', maxWidth: '230px', margin: 'auto' }}
        />
      </Col>
      <Col xs={12} md={12}>
        <ContentItems title="Name">{singleRow.name}</ContentItems>
      </Col>

      <Col xs={12} md={12}>
        <ContentItems title="Gender">{singleRow.genderTitle}</ContentItems>
      </Col>

      <Col xs={12} md={12}>
        <ContentItems title="Location">{singleRow.place?.address}</ContentItems>
      </Col>

      <Col xs={12} md={12}>
        <FormGroup>
          <Label>Lived In Places</Label>
          {singleRow.livedInPlaces && singleRow.livedInPlaces.length > 0 ? (
            singleRow.livedInPlaces.map((row, i) => <FormText key={`livedInPlace-${i}`}>{row.placeName}</FormText>)
          ) : (
            <FormText>__________</FormText>
          )}
        </FormGroup>
      </Col>

      <Col xs={12} md={12}>
        <ContentItems title="Privacy">{singleRow.privacyTitle}</ContentItems>
      </Col>

      <Col xs={12} md={12}>
        <ContentItems title="Date Of Death">
          <ViewDate isTableView dateValue={singleRow.dateOfDeath} />
        </ContentItems>
      </Col>
      <Col xs={12} md={12}>
        <ContentItems title="Date Of Birth">
          <ViewDate isTableView dateValue={singleRow.dateOfBirth} />
        </ContentItems>
      </Col>
      <Col xs={12} md={12}>
        <ContentItems title="Profile Owner">{singleRow.ownerName}</ContentItems>
      </Col>
      <Col xs={12} md={12}>
        <ContentItems title="Partner">{singleRow.partnerName}</ContentItems>
      </Col>
      <Col xs={12} md={12}>
        <ContentItems title="Created By">{singleRow.createdByName}</ContentItems>
      </Col>
      <Col xs={12} md={12}>
        <ContentItems title="Created Date">
          <ViewDate isTableView dateValue={singleRow.createdDate} />
        </ContentItems>
      </Col>
      <Col xs={12} md={12}>
        <ContentItems title="Last Modified Date">
          <ViewDate isTableView dateValue={singleRow.modifiedDate} />
        </ContentItems>
      </Col>
      <Col xs={12} md={12}>
        <FormGroup>
          <Label>Tags</Label>
          {singleRow.obituaryTags && singleRow.obituaryTags.length > 0 ? (
            singleRow.obituaryTags.map((row, i) => <FormText key={`tag-${i}`}>{`#${row.tagTitle}`}</FormText>)
          ) : (
            <FormText>__________</FormText>
          )}
        </FormGroup>
      </Col>

      <Col xs={12} md={12}>
        <ContentItems title="Ticket Id">{singleRow.ticketId}</ContentItems>
      </Col>
    </>
  );
}

export default ObituaryProfileDetails;
