import React from 'react';
import { Link } from 'react-router-dom';

const LinkButton = (props) => {
  let { toString, children, title, toDoFunction, className, role } = props;
  let onClickAction = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toDoFunction(e);
  };

  return (
    <Link to={`#${toString}`} title={title || null} className={className || null} role={role || 'none'} onClick={(e) => onClickAction(e)}>
      {children}
    </Link>
  );
};

export default LinkButton;
