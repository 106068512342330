import React from 'react';
import { Link } from 'react-router-dom';

import { Row, Col } from 'reactstrap';
import { DataTable, TBody, THead, TCol, TRow, DataTableHolder } from '../../SharedComponents/Table/tableElements';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import Loader from '../../Components/loader';
// import { get } from '../../Libraries/Ajax/httpService';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';
import { get } from '../../Libraries/Ajax/httpService';

class IncompleteOrdersContainer extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      viewURL: 'view-orders/',
      viewProduct: 'view-products/',
      multiSelect: false,
      productList: [],
      obituaryList: [],
    };
  }

  componentDidMount() {
    let statusId = 1;
    this.setJsonUrl(`payment/pending-payment/${statusId}`);
    this.setpredicates({ take: 10, page: 0 });
    // let model = {
    //   take: 10,
    // };
    // this.renderApi(`payment/pending-payment/${statusId}`, model);
    get('product', { isAll: true, isDelete: true })
      .then((res) => {
        if (res.data?.data.rows) this.setState({ productList: res.data.data.rows });
      })
      .catch(() => {});
    get('obituary-profile', { isAll: true, isDelete: true })
      .then((res) => {
        if (res.data?.data.rows) this.setState({ obituaryList: res.data.data.rows });
      })
      .catch(() => {});

    this.setState({ access: setComponentPrivilege('Manage Orders') });
  }

  render() {
    let { apiResponse, isLoaderActive, productList, obituaryList } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              {isLoaderActive === true ? <Loader /> : ''}
              {/* <HeadingBlock title="Incomplete Order" /> */}
            </Col>
            <Col sm={6} />
          </Row>
          {/* tabs */}
          <div className="row">
            <div className="col-12">
              {/* <div className="card">
                <div className="card-body"> */}
              <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                  <DataTable>
                    <THead>
                      <TRow>
                        <DataColHead
                          {...this.setheadproperties({
                            title: 'User',
                            enableSearch: true,
                            searchField: 'checkoutCreatedBySearch',
                            className: 'app-l-search-column',
                          })}
                        />

                        <DataColHead
                          {...this.setheadproperties({
                            title: 'User Email',
                            className: 'table-email-content',
                            coltype: 'hideSort',
                          })}
                        />

                        <DataColHead
                          {...this.setheadproperties({
                            title: 'Product',
                            isMultiSelectCheck: true,
                            itemList: productList,
                            idFilterListName: 'productIdList',
                            coltype: 'multiselect',
                            className: 'app-l-search-column multiselect-filter-checkbox',
                          })}
                        />

                        <DataColHead
                          {...this.setheadproperties({
                            title: 'Obituary',
                            isMultiSelectCheck: true,
                            itemList: obituaryList,
                            idFilterListName: 'obituaryIdList',
                            coltype: 'multiselect',
                            className: 'app-l-search-column multiselect-filter-checkbox',
                          })}
                        />

                        <DataColHead
                          {...this.setheadproperties({
                            title: 'Price',
                            enableSearch: true,
                            enableSort: true,
                            className: 'app-l-condensed-column',
                            searchField: 'priceSearch',
                            // className: 'app-l-search-column multiselect-filter-checkbox',
                          })}
                        />

                        <DataColHead
                          {...this.setheadproperties({
                            title: 'Quantity',
                            enableSearch: true,
                            enableSort: true,
                            searchField: 'quantitySearch',
                            className: 'app-l-135-column',
                            // className: 'app-l-search-column multiselect-filter-checkbox',
                          })}
                        />

                        <DataColHead
                          {...this.setheadproperties({
                            title: 'Payment Status',
                            // isMultiSelectCheck: true,
                            // itemList: productList,
                            // idFilterListName: "paymentIdList",
                            className: 'app-l-search-column multiselect-filter-checkbox',
                          })}
                        />
                      </TRow>
                    </THead>
                    <TBody>
                      {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                        ? apiResponse.rows.map((row) => (
                            <TRow>
                              <TCol>
                                <Link to={`/view-users/${row.checkout.createdBy?.id}`} target="_blank" rel="noreferrer">
                                  {' '}
                                  {row.checkout.createdBy?.name}{' '}
                                </Link>
                              </TCol>
                              <TCol>{row.checkout.createdBy?.email}</TCol>
                              <TCol>
                                {' '}
                                <span>
                                  <Link to={`/view-products/${row.checkout.cart[0]?.product.id}`} target="_blank" rel="noreferrer">
                                    {' '}
                                    {row.checkout.cart[0]?.product.name}{' '}
                                  </Link>
                                </span>{' '}
                              </TCol>
                              <TCol>
                                <Link to={`/view-obituary-profiles/general/${row.checkout.obituary?.id}`} target="_blank" rel="noreferrer">
                                  {' '}
                                  {row.checkout.obituary?.name}{' '}
                                </Link>
                              </TCol>
                              <TCol>
                                <i>€</i> {row.checkout.cart[0]?.price}
                              </TCol>
                              <TCol>{row.checkout.cart[0]?.count}</TCol>
                              <TCol>Payment Pending</TCol>
                            </TRow>
                          ))
                        : null}
                    </TBody>
                  </DataTable>
                  {apiResponse.rows && apiResponse.rows.length === 0 && <div className="text-center no-content-block">No results found </div>}
                </DataTableHolder>
              </div>
            </div>
            {/* </div>
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
// mapStateToProps = (state) => {
//   // let {}=state.MasterData
// };

// export default connect()(ListOrderContainer);
export default IncompleteOrdersContainer;
