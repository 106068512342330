import React, { useEffect, useState } from 'react';
import { FormGroup, Container } from 'react-bootstrap';
import ToastNotification from '../../../Libraries/ToastNotifications/toastNotification';
import { post } from '../../../Libraries/Ajax/httpService';
import { LoaderSmall } from '../../../Components/loader';

const CustomFileUpload = (props) => {
  let { validExtensions, fileUploadUrl } = props;
  let [errors, setErrors] = useState([]);
  let [bgUrl, setBgUrl] = useState('');
  let [bgPath, setBgPath] = useState('');
  let [loader, setLoader] = useState(false);

  useEffect(() => {
    if (props.attachmentUrl) {
      let newUrl;
      if (props.attachmentUrl.includes('?')) {
        newUrl = `${props.attachmentUrl}&date=${new Date()}`;
      } else {
        newUrl = `${props.attachmentUrl}?date=${new Date()}`;
      }
      setBgUrl(newUrl);
      setBgPath(props.value);
    }
  }, [props.attachmentUrl]);

  useEffect(() => {
    if (props.errors && props.errors.length > 0) {
      let err = props.errors.map((error) => error.message);
      setErrors(err);
    }
  }, [props.errors]);

  useEffect(() => {
    setErrors([]);
    setBgUrl('');
    setBgPath('');
  }, [props.hasResetted]);

  let toast = new ToastNotification();

  let checkFileExtension = (files) => {
    let error = [];
    if (validExtensions.every((type) => files[0].type.split('/').pop().toUpperCase() !== type.toUpperCase())) {
      error.push('This file format not supported');
      setErrors(error);
      files = null;
      return false;
    }
    return true;
  };

  let onFileUpload = (files) => {
    let properties = { ...props };
    let formData = new FormData();
    formData.append('file', files[0]);
    setLoader(true);
    post(fileUploadUrl, formData)
      .then((response) => {
        if (response && response.data) {
          properties.value = response.data.filePath;
          properties.item = {
            mediaUrl: response.data.webUrl,
          };
          setBgUrl(response.data.webUrl);
          setBgPath(response.data.filePath);
          setLoader(false);
        }
        if (properties.onChangeValidation) {
          properties.onChangeValidation(null, properties);
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.message) toast.show('file_too_large', 'ERROR', error.message ? error.message : 'File Too Large');
      });
  };

  let checkFileSize = (files) => {
    let maxSize = 5 * 1024 * 1024;
    let error = [];
    if (files[0] && files[0].type.includes('video')) {
      maxSize *= 5;
    }
    if (files[0] && files[0].size > maxSize) {
      error.push(`${files[0].name} is too large, please pick a smaller file`);
      setErrors(error);
      files = null;
      return false;
    }
    return true;
  };

  let validateFiles = (files) => {
    setErrors([]);
    if (checkFileExtension(files) && checkFileSize(files)) {
      onFileUpload(files);
    }
  };

  let onFileChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.target.files.length > 0) {
      validateFiles(event.target.files);
      event.target.value = null;
    }
  };

  return (
    <FormGroup className="mb-0">
      <Container fluid className="px=0 app-c-file-upload-preview">
        {bgUrl &&
          (['avi', 'mp4'].every((type) => bgUrl.split('.').pop() !== type) ? (
            <img src={bgUrl} alt={bgPath} height="100%" width="100%" />
          ) : (
            <video width="100%" preload="metadata" height="100%" controls="controls">
              <track kind="captions" />
              <source src={`${bgUrl}#t=0.5`} type="video/mp4" />
              <source src={`${bgUrl}#t=0.5`} type="video/avi" />
            </video>
          ))}
        <div className="app-c-file-input fit-to-container">
          <input type="file" id={`file-${props.unqKey}`} onChange={(e) => onFileChange(e)} />
          {loader === true ? (
            <LoaderSmall />
          ) : (
            <label htmlFor={`file-${props.unqKey}`} className="app-c-file-input-btn">
              {bgUrl ? 'Change Media' : 'Upload Media'}
            </label>
          )}
        </div>
      </Container>
      {errors && errors.length > 0
        ? errors.map((error, i) => (
            <span key={`error-log-${i}`} style={{ color: 'red' }}>
              <sup>*</sup>
              {error}
            </span>
          ))
        : null}
    </FormGroup>
  );
};

export default CustomFileUpload;
