import React from 'react';
import 'react-image-lightbox/style.css';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { DataTable, DataTableHolder, TBody, TCol, THead, TRow } from '../../SharedComponents/Table/tableElements';

class ListPartnerManagerAmountContainer extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleSelect: false,
      multiSelect: false,
      rowData: {},
    };
  }

  componentDidMount() {}

  months = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  render() {
    let { apiResponse, amountArray } = this.props;
    return (
      <React.Fragment>
        <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
          <div className="row">
            <DataTableHolder pagination={<ListPagination {...this.setpagination()} />} className="min-auto">
              <DataTable>
                <THead>
                  <TRow>
                    <DataColHead
                      {...this.setheadproperties({
                        title: 'Month-Year',
                        className: 'app-l-search-column',
                      })}
                    />
                    <DataColHead
                      {...this.setheadproperties({
                        title: 'Obituaries Created',
                        className: 'app-l-search-column',
                      })}
                    />
                    <DataColHead
                      {...this.setheadproperties({
                        title: 'Total Amount',
                        className: 'app-l-search-column',
                      })}
                    />
                    <DataColHead
                      {...this.setheadproperties({
                        title: 'Outstanding Amount',
                        className: 'app-l-search-column',
                      })}
                    />
                    <DataColHead
                      {...this.setheadproperties({
                        title: 'Amount Adjusted',
                        className: 'app-l-search-column',
                      })}
                    />
                    <DataColHead
                      {...this.setheadproperties({
                        title: 'Balance Outstanding',
                        className: 'app-l-search-column',
                      })}
                    />
                  </TRow>
                </THead>
                <TBody>
                  {apiResponse && apiResponse.length > 0 && amountArray
                    ? apiResponse.map((row, i) => (
                        <TRow key={`monthDetails${i}`}>
                          <TCol>
                            {this.months[row.month]} - {row.year}
                          </TCol>
                          <TCol>{row.totalObituaries} </TCol>
                          <TCol>€ {row.totalAmount} </TCol>
                          <TCol>€ {row.outstandingAmount} </TCol>
                          <TCol>€ {amountArray[i].amountAdjusted} </TCol>
                          <TCol>€ {amountArray[i].balanceOutstanding} </TCol>
                        </TRow>
                      ))
                    : null}
                </TBody>
              </DataTable>
              {apiResponse && apiResponse.length === 0 && <div className="text-center no-content-block">No results found </div>}
            </DataTableHolder>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ListPartnerManagerAmountContainer;
