import React from 'react';
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Input } from 'reactstrap';
import ListComponent from './listComponent';

import { LazyRun } from '../../Libraries/LazyFunctions/lazyFunction';

export class Filter extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      isFilterActive: false,
      open: false,
      customiseOptions: false,
    };
  }

  componentDidMount() {
    let { filterapi, extraOptions } = this.props;
    if (filterapi) {
      // setting filter data from api
      this.setFilterData(filterapi);
    } else {
      // pass an empty string as filterapi
      // setting filter data without api (custom values)
      let arr = [];
      if (extraOptions && extraOptions.length > 0) {
        extraOptions.forEach((option) => {
          arr.splice(typeof option.index == 'number' ? option.index : arr.length, 0, option);
        });
      }
      this.modifyInitialOptions(arr);
    }
    let { model } = this.props;
    let idListName = `${this.props.sublistFilterField}IdList`;
    let selectedValues = model && model[idListName] ? model[idListName] : [];
    if (selectedValues.length > 0) {
      this.setState({
        isFilterActive: true,
      });
    }
  }

  modifyInitialOptions = (arr) => {
    this.setState((prevState) => ({
      customiseOptions: true,
      apiResponse: { ...prevState.apiResponse, rows: arr },
    }));
  };

  componentDidUpdate() {
    let { extraOptions, removeOptions } = this.props;
    let { apiResponse, customiseOptions } = this.state;
    if (!customiseOptions && apiResponse && apiResponse.rows && apiResponse.rows.length > 0) {
      let arr = [...apiResponse.rows];
      if (extraOptions && extraOptions.length > 0) {
        extraOptions.forEach((option) => {
          arr.splice(typeof option.index == 'number' ? option.index : arr.length, 0, option);
        });
      }
      if (removeOptions && removeOptions.shouldRemove && removeOptions.ids && removeOptions.ids.length > 0) {
        arr = arr.filter((option) => removeOptions.ids.every((id) => id !== option.id));
      }
      this.modifyInitialOptions(arr);
    }
  }

  onDropDownChange = (e, id) => {
    let { filterField, sublistFilterField, model, sublist } = this.props;
    if (typeof sublist != 'undefined' && id) {
      let idListName = `${sublistFilterField}IdList`;
      if (!model[idListName]) {
        model[idListName] = [];
      }
      if (e.target.checked) {
        model[idListName].push(id);
      } else if (e.target.checked == false) {
        let index = model[idListName].indexOf(id);
        model[idListName].splice(index, 1);
      }
      if (model[idListName].length == 0) {
        delete model[idListName];
        this.props.setpredicates({ [idListName]: [], page: 0 });
        this.setState({ isFilterActive: false });
      } else {
        this.props.setpredicates({
          [idListName]: model[idListName],
          page: 0,
        });
        this.setState({ isFilterActive: true });
      }
    } else if (e.target.checked && id) {
      this.props.setpredicates({ [filterField]: id, page: 0 });
    } else {
      this.props.setpredicates({ [filterField]: '', page: 0 });
    }
  };

  isChecked = (item, selectedValues) => {
    let selectedItem = selectedValues.filter((x) => x === item.id);
    return selectedItem.length > 0;
  };

  allClear = () => {
    let { sublistFilterField, model } = this.props;
    let idListName = `${sublistFilterField}IdList`;
    model[idListName] = [];
    delete model[idListName];
    this.props.setpredicates({ [idListName]: [] });
    this.setState({ isFilterActive: false });
  };

  toggle = () => this.setState((prevState) => ({ open: !prevState.open }));

  render() {
    let { apiResponse, isFilterActive, open } = this.state;
    let idListName = `${this.props.sublistFilterField}IdList`;

    let uniqueId = this.props.title.replace(/ /gi, '_');
    let { model } = this.props;
    let selectedValues = model && model[idListName] ? model[idListName] : [];
    return (
      <>
        <span
          className="app-c-filter-clear-icon"
          role="button"
          tabIndex="-1"
          onClick={(e) => this.allClear(e, selectedValues)}
          title="Clear filter"
          hidden={selectedValues.length === 0 || open}
        >
          ✕
        </span>
        <Dropdown className={`app-c-filter-dropDown ${this.props.filterClassName}`} {...this.props} isOpen={open} toggle={this.toggle}>
          <DropdownToggle variant="success" className={`appFilterButton ${isFilterActive ? 'active' : ''}`} id="dropdown-basic">
            <span className="mdi mdi-filter-variant" />
          </DropdownToggle>

          <DropdownMenu className="app-l-filter-dropdown-list">
            <Button className="app-c-filter-clear-btn" onClick={(e) => this.allClear(e, selectedValues)} disabled={selectedValues.length === 0}>
              Clear
            </Button>

            <div className="app-l-dropdown-scroll-holder">
              {apiResponse.rows ? (
                apiResponse.rows.map((item, i) => (
                  <DropdownItem key={`dataoperation${i}`} toggle={false}>
                    <div className="__checkbox">
                      <input
                        type="checkbox"
                        name={uniqueId}
                        id={`${uniqueId}_${item.id}`}
                        onChange={(e) => this.onDropDownChange(e, item.id)}
                        checked={this.isChecked(item, selectedValues)}
                      />
                      <label htmlFor={`${uniqueId}_${item.id}`}>{item.title ? item.title : item.name}</label>
                    </div>
                  </DropdownItem>
                ))
              ) : (
                <div />
              )}
            </div>
          </DropdownMenu>
        </Dropdown>
      </>
    );
  }
}
// export const Filter = connect(null, null)(FilterClass);
export class Sort extends ListComponent {
  state = {
    isFilterActive: false,
    open: false,
  };

  sortOrder = null;

  seletedField = '';

  onSortButtonClick = () => {
    if (this.sortOrder === null) {
      this.sortOrder = 'ASC';
    } else if (this.sortOrder === 'DESC') {
      this.sortOrder = 'ASC';
    } else if (this.sortOrder === 'ASC') {
      this.sortOrder = 'DESC';
    }
    this.callApiOnUpdate();
  };

  callApiOnUpdate = () => {
    let { sortField, isCardViewTrue } = this.props;
    this.props.setpredicates({
      sortOrder: this.sortOrder,
      sortField: isCardViewTrue ? this.seletedField : sortField,
    });
  };

  onSortFieldClick = ({ sortField, title }) => {
    if (!this.sortOrder) this.sortOrder = 'ASC';
    this.seletedField = sortField;
    this.setState({
      seletedFieldTitle: title,
      open: false,
    });
    this.callApiOnUpdate(sortField);
  };

  onSortOrderSelect = (order) => {
    this.sortOrder = order;
    this.callApiOnUpdate();
  };

  onClear = () => {
    this.props.setpredicates({
      sortOrder: '',
      sortField: '',
    });
    this.setState({ seletedFieldTitle: false });
  };

  toggle = () => this.setState((prevState) => ({ open: !prevState.open }));

  render() {
    let { model, sortField, coltype, sortFieldList, isCardViewTrue } = this.props;
    let { open, seletedFieldTitle } = this.state;
    if (model) {
      if (model && sortField === model.sortField) {
        this.sortOrder = model.sortOrder;
      }
    }
    let subClassName = 'sorting';
    if (coltype === 'hideSort') subClassName = '';
    else if (model && sortField === model.sortField && this.sortOrder === 'DESC') subClassName = 'descending';
    else if (model && sortField === model.sortField && this.sortOrder === 'ASC') subClassName = 'ascending';

    return (
      <>
        {isCardViewTrue ? (
          <>
            <Dropdown className="sort-dropDown  " {...this.props} isOpen={open} toggle={this.toggle} active>
              <DropdownToggle caret variant="success" className="w-100" color="primary" id="dropdown-basic">
                {seletedFieldTitle || 'Sort By'}
              </DropdownToggle>

              <DropdownMenu className="app-l-filter-dropdown-list">
                <div>
                  {sortFieldList?.length > 0 ? (
                    sortFieldList.map((item, i) => (
                      <DropdownItem key={`dataoperation${i}`} toggle={false} onClick={() => this.onSortFieldClick(item)}>
                        {item.title}
                      </DropdownItem>
                    ))
                  ) : (
                    <div />
                  )}
                </div>
              </DropdownMenu>
            </Dropdown>

            {seletedFieldTitle && (
              <div className=" m-auto">
                {[
                  { lable: 'Ascending', value: 'ASC' },
                  { lable: 'Descending', value: 'DESC' },
                ].map((row, i) => (
                  <div className="custom-control custom-radio custom-control-inline" key={`radio-${i}`}>
                    <Input
                      type="radio"
                      id={`customRadioInline-${row.lable}`}
                      checked={(this.sortOrder == null && row.value === 'ASC') || this.sortOrder === row.value}
                      className="custom-control-input"
                    />
                    <Label onClick={() => this.onSortOrderSelect(row.value)} className="custom-control-label" for="customRadioInline1">
                      {row.lable}
                    </Label>
                  </div>
                ))}
                {seletedFieldTitle && <Button onClick={this.onClear}>Clear</Button>}
              </div>
            )}
          </>
        ) : (
          <Button className={`app-c-sorting ${subClassName}`} onClick={() => this.onSortButtonClick()} />
        )}
      </>
    );
  }
}

export class Search extends ListComponent {
  constructor(props) {
    super(props);
    this.titleRef = React.createRef();
    this.state = {
      enableSearch: false,
      isOpen: false,
    };
  }

  onKeyUp = () => {
    let { searchField, setpredicates, setParentState } = this.props;
    let fun = () => {
      setParentState({ singleSelect: false, multiSelect: false });
      if (this.titleRef.current.value.length > 0) setpredicates({ [searchField]: this.titleRef.current.value, page: 0 });
      else {
        setpredicates({ [searchField]: undefined, page: 0 });
      }
    };
    LazyRun(fun, 800);
  };

  onClearButtonClick = () => {
    let { searchField } = this.props;
    this.titleRef.current.value = '';
    this.titleRef.current.focus();
    this.props.setpredicates({ [searchField]: undefined, page: 0 });
  };

  onSearchBlur = () => {
    if (this.titleRef.current.nextSibling) {
      let classname = this.titleRef.current.nextSibling.className;
      if (!this.state.isOpen && this.titleRef.current.nextSibling) {
        setTimeout(() => {
          if (this.titleRef && this.titleRef.current && this.titleRef.current.value.length > 0 && this.titleRef.current.nextSibling) {
            this.titleRef.current.nextSibling.className = `${classname} d-none`;
          }
        }, 500);
      }
    }
    if (this.state.isOpen && this.titleRef && this.titleRef.current && this.titleRef.current.value.length == 0) this.setState({ isOpen: false });
  };

  onSearchFocus = () => {
    this.setState({ isOpen: true });
    if (this.titleRef.current.nextSibling) {
      this.titleRef.current.nextSibling.className = this.titleRef.current.nextSibling.className.replace(' d-none', '');
    }
  };

  render() {
    let { isCardViewTrue, searchStyle, placeholder } = this.props;
    return (
      <>
        <div className={this.state.isOpen ? 'app-c-search app-c-search-expand' : 'app-c-search '} style={searchStyle}>
          <input
            type="search"
            className="form-control"
            ref={this.titleRef}
            placeholder={placeholder || 'Search'}
            onBlur={this.onSearchBlur}
            onFocus={this.onSearchFocus}
            onChange={(e) => this.onKeyUp(e)}
          />
          {isCardViewTrue && this.titleRef.current && this.titleRef.current.value.length > 0 && (
            <button type="submit" onClick={this.onClearButtonClick}>
              <i className="mdi mdi-close" />
            </button>
          )}
          {!isCardViewTrue && this.titleRef.current && this.titleRef.current.value.length > 0 && (
            <button type="submit" className="app-c-table-search-clear " onClick={this.onClearButtonClick}>
              <i className="mdi mdi-close" />
            </button>
          )}
        </div>
      </>
    );
  }
}
