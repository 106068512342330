import React from 'react';
import { Button, FormGroup, Modal } from 'reactstrap';
import { post } from '../../Libraries/Ajax/httpService';

import FormInput from '../../SharedComponents/FormHelpers/Controls/formInput';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import Loader from '../../Components/loader';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';

export default class ThankYou extends FormComponent {
  constructor(props) {
    super(props);
    this.setProperties = this.setProperties.bind(this);
    this.setFormProperties = this.setFormProperties.bind(this);
    this.state = {
      thankYouModal: false,
      isSubmitted: true,
      apiResponse: {},
      message: {
        name: 'message',
        label: 'Message',
        type: 'text',
        placeholder: 'Type message',
        rules: {
          required: true,
          maxLength: 200,
        },
        // value: 'Greetings From Memlo',
      },
    };
  }

  componentDidMount() {
    if (this.props.obituaryRowid) {
      this.setState({ obituaryId: this.props.obituaryRowid });
    }
  }

  toast = new ToastNotification();

  onSendButtonClick = (model) => {
    this.setState({ isLoaderActive: true });
    model = {
      ...model,
      obituaryId: this.props.obituaryId,
    };

    post('thank-you', model)
      .then((response) => {
        if (response.data?.message) {
          this.toast.show('create_success', 'SUCCESS', response.data.message);
        }
        this.props.toggleThank();
        this.setState({ isLoaderActive: false });
      })
      .catch((err) => {
        if (err.message) this.toast.show('update_error', 'ERROR', err.message);
        this.setState({ isLoaderActive: false });
      });
  };

  render() {
    let { thankYouModal, toggleThank } = this.props;
    return (
      <div className="container-fluid">
        {this.state.isLoaderActive === true ? <Loader /> : ''}
        <Modal
          isOpen={thankYouModal}
          // toggle={() => toggleThank()}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Send Thank You
            </h5>
            <button
              onClick={() => {
                toggleThank();
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Type the greetings to be send</p>
            <SubmitForm onSubmit={this.onSendButtonClick} {...this.setFormProperties({ disableReactiveForm: true })}>
              <FormInput rows={5} as="textarea" {...this.setProperties(this.state.message)} />
              <FormGroup className="mb-0">
                <div className="float-right d-md-block">
                  <br />
                  <Button type="submit" color="primary" className="mr-1">
                    Send
                  </Button>{' '}
                  <Button
                    className="btn-secondary"
                    type="reset"
                    onClick={() => {
                      toggleThank();
                    }}
                  >
                    <i className="icon-close"> </i> <span> Cancel </span>
                  </Button>
                </div>
              </FormGroup>
            </SubmitForm>
            {/* </CardBody>
            </Card> */}
          </div>
        </Modal>
      </div>
    );
  }
}
