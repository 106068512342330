import React from 'react';
import Lightbox from 'react-image-lightbox';
import { Row, Col } from 'reactstrap';
import { DataTable, TBody, THead, TCol, TRow, HeadingBlock, DataTableHolder } from '../../SharedComponents/Table/tableElements';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import Loader from '../../Components/loader';
import CustomModal from '../../SharedComponents/customModal';
import { get } from '../../Libraries/Ajax/httpService';
import { StatusSwitch } from '../../SharedComponents/FormHelpers/Controls/statusSwitch';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';

class ListMediaAccessContainer extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleSelect: false,
      multiSelect: false,
      rowData: {},
      isLoaderActive: false,
      showDeleteConfirmation: false,
      showMediaModal: false,
      list: [],
      lightBox: false,
    };
  }

  showMedia = (obituaryId, postId) => {
    this.setState({ showMediaModal: true, isLoaderActive: true });

    let model = {
      obituaryId,
      typeId: 3,
      lockMedia: 1,
    };

    if (postId) {
      model = { ...model, id: postId };
    }

    get('obituary-post-public', model).then((res) => {
      this.mediaList = res.data.data.rows;
      this.setState({ list: this.mediaList, isLoaderActive: false });
    });
  };

  showLightBox = (imageUrl) => {
    this.setState({ mediaUrl: imageUrl, lightBox: true });
  };

  closeLightBox = () => {
    this.setState({ lightBox: false });
  };

  closeModal = () => {
    this.setState({ showMediaModal: false, list: [] });
  };

  componentDidMount() {
    this.setJsonUrl('media-view-request');
    this.setpredicates({ take: 10, page: 0 });
    this.setState({ access: setComponentPrivilege('Media Access') });
  }

  render() {
    let { apiResponse, access, isLoaderActive, showMediaModal, list, lightBox, mediaUrl } = this.state;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              {isLoaderActive === true ? <Loader /> : ''}
              <HeadingBlock title="Media Access" />
            </Col>
          </Row>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                    <div className="row">
                      <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                        <DataTable>
                          <THead>
                            <TRow>
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Image',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Obituary Profile',
                                })}
                              />

                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Requested By',
                                })}
                              />

                              {/* <DataColHead
                                {...this.setheadproperties({
                                  title: 'Access ',
                                })}
                              /> */}

                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Permission',
                                  width: 70,
                                })}
                              />
                            </TRow>
                          </THead>
                          <TBody>
                            {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                              ? apiResponse.rows.map((row) => (
                                  <TRow>
                                    <TCol>
                                      <button
                                        className="btn"
                                        type="button"
                                        onClick={() => this.showMedia(row.obituaryProfileId, row.obituaryPostId)}
                                        style={{ textDecoration: 'underline' }}
                                      >
                                        {row.obituaryPostId ? 'Photos/Videos' : 'All Media'}
                                      </button>
                                    </TCol>
                                    <TCol>{row.obituaryProfileName}</TCol>
                                    <TCol>{row.requestedByName}</TCol>

                                    {/* <TCol>{row.accessControl} </TCol> */}

                                    <TCol>
                                      {access && access.edit && (
                                        <StatusSwitch
                                          isListIcon
                                          value={row.permission}
                                          name="permission"
                                          rowData={row}
                                          valueObj={{ on: true, off: false }}
                                          updateApi={`media-view-request/${row.id}`}
                                          apiResponse={apiResponse}
                                          setParentState={this.setParentState}
                                          title={!row.permission ? 'click to grant access' : 'click to deny access'}
                                        />
                                      )}
                                      {!(access && access.edit) && (row.permission ? 'Granted' : 'Denied')}
                                    </TCol>
                                  </TRow>
                                ))
                              : null}
                          </TBody>
                        </DataTable>
                        {apiResponse.rows && apiResponse.rows.length === 0 && <div className="text-center no-content-block">No results found </div>}
                      </DataTableHolder>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showMediaModal && (
            // <Row>
            <CustomModal
              modal={showMediaModal}
              toggleModal={this.closeModal}
              title="Photos and Videos"
              hideFooter
              maxHeight={300}
              className="modal-lg"
            >
              <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                {list && list.length > 0 ? (
                  list.map((row) => (
                    // <Row>
                    //   <Col>
                    <div>
                      {row.mediaUrl &&
                        (row.mediaType === 'jpg' || row.mediaType === 'png' || row.mediaType === 'jpeg' ? (
                          <img
                            src={row.mediaUrl}
                            alt="Flowers"
                            style={{
                              // minWidth: `${100}%`,
                              margin: `${10}px`,
                              maxHeight: `${100}%`,
                              width: `${160}px`,
                              height: `${160}px`,
                              objectFit: 'fill',
                              borderRadius: `${15}px`,
                              marginBottom: `${0.3}rem`,
                              backgroundColor: '#ccc',
                            }}
                            onClick={() => {
                              this.showLightBox(row.mediaUrl);
                            }}
                          />
                        ) : (
                          <video
                            style={{
                              // minWidth: `${100}%`,
                              margin: `${10}px`,
                              maxHeight: `${100}%`,
                              width: `${160}px`,
                              height: `${160}px`,
                              objectFit: 'fill',
                              borderRadius: `${15}px`,
                              marginBottom: `${0.3}rem`,
                            }}
                            preload="metadata"
                            controls="controls"
                          >
                            <track src="captions_en.vtt" kind="captions" label="english_captions" />
                            <source src={`${row.mediaUrl}#t=0.5`} type="video/mp4" />
                            <source src={`${row.mediaUrl}#t=0.5`} type="video/avi" />
                          </video>
                        ))}
                    </div>
                    //   </Col>
                    // </Row>
                  ))
                ) : (
                  <div style={{ textAlign: 'center', margin: 'auto' }}>
                    <span>The media has been unlocked/deleted</span>
                  </div>
                )}
              </div>
            </CustomModal>
            // </Row>
          )}
        </div>
        {lightBox && <Lightbox mainSrc={mediaUrl} enableZoom={false} clickOutsideToClose onCloseRequest={() => this.closeLightBox()} />}
      </React.Fragment>
    );
  }
}

export default ListMediaAccessContainer;
