import React from 'react';
import { Container } from 'react-bootstrap';
import { Row, Col, Card, CardBody, FormGroup, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import FormInput from '../../SharedComponents/FormHelpers/Controls/formInput';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import { get, post, put } from '../../Libraries/Ajax/httpService';
import Loader from '../../Components/loader';
import { DeleteRow } from '../../SharedComponents/ListComponents/manageComponent';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import { PhoneNumber } from '../../SharedComponents/FormHelpers/Controls/phoneNumber';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';

class ManageShops extends FormComponent {
  constructor(props) {
    super(props);
    this.setProperties = this.setProperties.bind(this);
    this.setFormProperties = this.setFormProperties.bind(this);
    this.state = {
      isSubmitted: true,
      singleRow: {},
      action: 'Add',
      editFlag: false,
      id: {
        label: 'Id',
        type: 'hidden',
      },
      name: {
        label: 'Name',
        type: 'text',
        placeholder: 'Enter name',
        rules: {
          required: true,
          maxLength: 100,
        },
      },
      phone: {
        label: 'Phone',
        type: 'text',
        placeholder: 'Enter phone',
        rules: {
          required: true,
          // phone: true,
          phoneValid: true,
          minLength: 8,
          maxLength: 100,
        },
      },
      address: {
        label: 'Address',
        type: 'text',
        placeholder: 'Enter address',
        rules: {
          required: true,
          maxLength: 3000,
        },
      },
      email: {
        label: 'Email',
        type: 'text',
        placeholder: 'Enter email',
        rules: {
          required: true,
          email: true,
          maxLength: 300,
        },
      },
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ editFlag: true });
      this.setState({ action: 'Edit' });
      get(`shop/${this.props.match.params.id}`).then((response) => {
        this.setState({ singleRow: response.data.data });
      });
      this.setState({ access: setComponentPrivilege('Manage Shops') });
    }
  }

  componentDidUpdate() {
    if (this.state.singleRow) {
      this.mapJsonToState(this.state.singleRow);
    }
  }

  toast = new ToastNotification();

  onCancelButtonClick = () => {
    this.props.history.push('/list-shops');
  };

  onSaveButtonClick = (model) => {
    this.setState({ isLoaderActive: true });
    if (this.props.match.params.id) {
      put(`shop/${this.props.match.params.id}`, model).then((response) => {
        this.setState({ isLoaderActive: false });
        if (response.data?.success) {
          this.toast.show('update_success', 'SUCCESS', response.data?.message ? response.data.message : 'Shop updated  successfully');
        }
        this.props.history.push('/list-shops');
      }).catch((err) => {
        if (err.message) this.toast.show('update_error', 'ERROR', `Could not Update Shop! ${err.message}`);
        this.setState({ isLoaderActive: false });
        this.props.history.push('/list-shops');
      });
    } else {
      post('shop', model).then((response) => {
        this.setState({ isLoaderActive: false });
        if (response.data?.success) {
          this.toast.show('update_success', 'SUCCESS', response.data?.message ? response.data.message : 'Shop Created  successfully');
        }
        this.props.history.push('/list-shops');
      }).catch((err) => {
        if (err.message) this.toast.show('update_error', 'ERROR', `Could not Create Shop! ${err.message}`);
        this.setState({ isLoaderActive: false });
        this.props.history.push('/list-shops');
      });
    }
  };

  render() {
    let { isLoaderActive, singleRow, access } = this.state;
    let { history } = this.props;
    return (
      <React.Fragment>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Shop Details</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/list-shops">Shops</Link>
                  </li>
                  <li className="breadcrumb-item active">{this.state.action} Shop</li>
                </ol>
              </div>
            </Col>
            {this.state.editFlag && access && access.delete && (
              <Col sm="6">
                <div className="float-right d-md-block">
                  <DeleteRow
                    renderapi={`shop/${this.props.match.params.id}`}
                    model={singleRow}
                    redirecturl="/list-shops"
                    deleteText="Delete"
                    history={history}
                    isCardViewTrue="True"
                  />{' '}
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Container fluid className="px-0">
              <div className="col-12">
                <Card>
                  <CardBody>
                    <SubmitForm onSubmit={this.onSaveButtonClick} {...this.setFormProperties({ disableReactiveForm: true })}>
                      <Row>
                        <Col xs={12} md={6}>
                          <FormInput {...this.setProperties(this.state.name)} />
                        </Col>
                        {/* <Col xs={12} md={6}>
                          <FormInput {...this.setProperties(this.state.phone)} />
                        </Col> */}
                        <Col xs={12} md={6}>
                          <PhoneNumber {...this.setProperties(this.state.phone)} />
                        </Col>
                        <Col xs={12} md={6}>
                          <FormInput {...this.setProperties(this.state.address)} />
                        </Col>
                        <Col xs={12} md={6}>
                          <FormInput {...this.setProperties(this.state.email)} />
                        </Col>
                      </Row>
                      <FormGroup className="mb-0">
                        <div className="float-right d-md-block">
                          <br />
                          <Button type="submit" color="primary" className="mr-1">
                            Save
                          </Button>{' '}
                          <Button className="btn-secondary" type="reset" onClick={this.onCancelButtonClick}>
                            <i className="icon-close"> </i> <span> Cancel </span>
                          </Button>
                        </div>
                      </FormGroup>
                    </SubmitForm>
                  </CardBody>
                </Card>
              </div>
            </Container>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
export default ManageShops;
