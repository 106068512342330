import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
// AUTH related methods
// import { getFirebaseBackend } from "../helpers/authUtils";

const AppRoute = ({ component: Component, layout: Layout, isAuthProtected, ...rest }) => {
  let user = useSelector((state) => state.Login?.userData);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !(user && user.accessToken && user.refreshToken)) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default AppRoute;
