import React from 'react';
import { Card, CardBody, Modal, Col, Row } from 'reactstrap';
import { ContactDetails } from './manageContacts';
import { get } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import { ActionButton } from '../../SharedComponents/FormHelpers/Controls/actionButton';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
import { DataTable, DataTableHolder, TBody, TCol, THead, TRow } from '../../SharedComponents/Table/tableElements';
import LinkButton from '../../SharedComponents/linkButton';

export class ListContacts extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      updateContactDetailsModal: false,
      contactList: {},
      childCallType: '',
      rowData: {},
    };
  }

  toast = new ToastNotification();

  toggleModalState = () => {
    this.setState({
      updateContactDetailsModal: !this.state.updateContactDetailsModal,
    });
  };

  listContacts = () => {
    let model = {
      obituaryId: this.props.obituaryRowId,
    };
    get('contact-information', model).then((response) => {
      this.setState({ contactList: response });
    });
  };

  componentDidMount() {
    this.listContacts();
  }

  render() {
    let { contactList } = this.state;
    let { showCheck, toggleState, stateKey, obituaryRowId, access } = this.props;
    return (
      <div className="container-fluid ">
        <Modal className="modal-lg" isOpen={showCheck} scrollable toggle={() => toggleState(stateKey)}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Contact Details
            </h5>
            <button
              onClick={() => {
                toggleState(stateKey);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {this.state.childCallType == 'add' && (
            <ContactDetails
              callType={this.state.childCallType}
              showCheck={this.state.updateContactDetailsModal}
              obituaryRowId={obituaryRowId}
              toggleState={this.toggleModalState}
              refreshContacts={this.listContacts}
            />
          )}
          <Card className="enable-scroll">
            <CardBody>
              <div>
                <Row className="align-items-center pb-3">
                  <Col sm={6}>
                    <p className="mb-0">The contacts for this profile</p>
                  </Col>
                  {access && access.create && (
                    <Col sm={6}>
                      <div className="float-right d-md-block">
                        <LinkButton
                          toString="addContact"
                          className="btn btn-primary waves-effect waves-light"
                          role="button"
                          toDoFunction={() => {
                            this.toggleModalState();
                            this.setState({ childCallType: 'add' });
                          }}
                        >
                          Add Contact
                        </LinkButton>
                      </div>
                    </Col>
                  )}
                </Row>

                <div className="row ">
                  <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                    <DataTable>
                      <THead>
                        <TRow>
                          <DataColHead
                            {...this.setheadproperties({
                              className: 'app-l-search-column',
                              title: 'Name',
                            })}
                          />
                          <DataColHead
                            {...this.setheadproperties({
                              title: 'Relationship',
                              className: 'app-l-search-column',
                            })}
                          />
                          <DataColHead
                            {...this.setheadproperties({
                              title: 'Contact Type',
                              className: 'app-l-search-column',
                            })}
                          />
                          <DataColHead
                            {...this.setheadproperties({
                              title: 'Contact',
                              className: 'app-l-long-column',
                            })}
                          />
                          {access && (access.edit || access.delete) && (
                            <DataColHead
                              {...this.setheadproperties({
                                title: 'Action',
                              })}
                            />
                          )}
                        </TRow>
                      </THead>
                      {this.state.childCallType == 'edit' ? (
                        <ContactDetails
                          rowData={this.state.rowData}
                          callType={this.state.childCallType}
                          showCheck={this.state.updateContactDetailsModal}
                          obituaryRowId={obituaryRowId}
                          toggleState={this.toggleModalState}
                          refreshContacts={this.listContacts}
                        />
                      ) : null}
                      <TBody>
                        {contactList && contactList.data && contactList.data.length > 0
                          ? contactList.data.map((row, i) => (
                              <TRow key={`contact-detail-${i}`}>
                                <TCol>{row.name}</TCol>
                                <TCol>{row.relationName}</TCol>
                                <TCol>{row.typeName} </TCol>
                                {row.contactType == 1 && <TCol>{row.phoneNumber} </TCol>}
                                {row.contactType == 2 && <TCol>{row.email} </TCol>}
                                {row.contactType == 3 && <TCol>{row.whatsappNumber} </TCol>}
                                {access && (access.edit || access.delete) && (
                                  <TCol>
                                    <div className="action-col">
                                      {access && access.edit && (
                                        <LinkButton
                                          toString="editContact"
                                          toDoFunction={() => {
                                            this.setState({ childCallType: 'edit', rowData: row });
                                            this.toggleModalState();
                                          }}
                                        >
                                          <i className="fas fa-edit mdi-18px" />
                                        </LinkButton>
                                      )}
                                      {access && access.delete && (
                                        <ActionButton value="delete" rowId={row.id} api="contact-information/" renderList={this.listContacts} />
                                      )}
                                    </div>
                                  </TCol>
                                )}
                              </TRow>
                            ))
                          : null}
                      </TBody>
                    </DataTable>
                    {contactList.data && contactList.data.length === 0 && <div className="text-center no-content-block">No contacts found </div>}
                  </DataTableHolder>
                </div>
              </div>
            </CardBody>
          </Card>
        </Modal>
      </div>
    );
  }
}
export default ListContacts;
