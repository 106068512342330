import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import { get } from '../../Libraries/Ajax/httpService';
import 'react-image-lightbox/style.css';
import Loader from '../../Components/loader';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { DeleteRow, EditRow, ContentItems } from '../../SharedComponents/ListComponents/manageComponent';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';

class ViewTemplateMessages extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      singleRow: null,
      showLightBox: false,
      imageUrl: '',
      page: '',
    };
  }

  setPrivilege = () => {
    let page = '';
    switch (this.props.match.params.value) {
      case 'message':
        page = 'Message Templates';
        break;
      case 'background-image':
        page = 'Background Images';
        break;
      case 'solid-colors':
        page = 'Solid Colors';
        break;
      case 'tribute-card':
        page = 'Tribute Cards';
        break;
    }
    this.setState({ access: setComponentPrivilege(page) });
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      get(`template-message/${this.props.match.params.id}`).then((response) => {
        this.setState({ singleRow: response.data.data });
      });
    }
    this.setPrivilege();
  }

  titleName = (str) => {
    let words = str.match(/([^-]+)/g) || [];
    words.forEach((word, i) => {
      words[i] = word[0].toUpperCase() + word.slice(1);
    });
    return words.join(' ');
  };

  onDidUpdate = () => {
    this.setState({ page: this.props.match.params.value });
  };

  onLightBoxClick = (singleRow) => {
    this.setState({
      showLightBox: true,
      imageUrl: singleRow.mediaUrl,
    });
  };

  componentDidUpdate() {
    let {
      match: { params },
    } = this.props;
    if (this.state.page !== this.props.match.params.value) {
      document.title = `View ${this.titleName(params.value)} | Memlo - Admin Panel`;
      this.onDidUpdate();
    }
  }

  render() {
    let { isLoaderActive, singleRow, page, access } = this.state;
    let { history } = this.props;
    return (
      <>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          {singleRow && (
            <div className="app-l-heading-block">
              <Row className="align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">{this.titleName(page)} Details</h4>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to={`/list-template/${page}`}>{this.titleName(page)}</Link>
                      </li>
                      <li className="breadcrumb-item active">View {this.titleName(page)}</li>
                    </ol>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {singleRow && (
            <Container fluid className="px-0">
              <Card>
                <CardBody>
                  {/* TopSection1 */}
                  {page == 'message' && (
                    <Row>
                      <Col xs={12} md={6}>
                        <ContentItems title="Gender"> {singleRow.genderTitle}</ContentItems>
                      </Col>
                      <Col xs={12} md={6}>
                        <ContentItems title="Type"> {singleRow.typeTitle}</ContentItems>
                      </Col>
                      <Col xs={12} md={6}>
                        <ContentItems title="Age To"> {singleRow.ageTo}</ContentItems>
                      </Col>
                      <Col xs={12} md={6}>
                        <ContentItems title="Age From"> {singleRow.ageFrom}</ContentItems>
                      </Col>
                      <Col xs={12} md={12}>
                        <ContentItems title="Message">
                          {' '}
                          <p>{singleRow.message ? singleRow.message : '-'}</p>
                        </ContentItems>
                      </Col>
                    </Row>
                  )}

                  {this.state.showLightBox ? (
                    <Lightbox
                      mainSrc={this.state.imageUrl}
                      enableZoom={false}
                      clickOutsideToClose
                      onCloseRequest={() => this.setState({ showLightBox: false })}
                    />
                  ) : null}
                  <Row>
                    {page != 'message' && (
                      <Col xs={12} md={12}>
                        <ContentItems title={this.titleName(page)}>
                          <div className="image-view">
                            <a href="#lightBox">
                              <img
                                onClick={() => {
                                  this.onLightBoxClick(singleRow);
                                }}
                                data-dz-thumbnail=""
                                height="250"
                                width="auto"
                                className="rounded bg-light"
                                alt={singleRow.image}
                                src={singleRow.mediaUrl}
                              />
                            </a>
                          </div>
                        </ContentItems>
                      </Col>
                    )}
                  </Row>
                </CardBody>{' '}
              </Card>
              <div>
                <Col>
                  <div className="float-right ">
                    {access && access.delete && (
                      <DeleteRow
                        renderapi={`template-message/${this.props.match.params.id}`}
                        model={singleRow}
                        redirecturl={`/list-template/${page}`}
                        deleteText="Delete"
                        history={history}
                        isCardViewTrue="True"
                      />
                    )}{' '}
                    {access && access.edit && (
                      <EditRow
                        history={history}
                        editText="Edit"
                        redirecturl={`/manage-template/${page}/${this.props.match.params.id}`}
                        rowData={singleRow}
                      />
                    )}
                  </div>
                </Col>
              </div>
            </Container>
          )}
        </div>
      </>
    );
  }
}
export default ViewTemplateMessages;
