import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logoutUser } from '../../Store/actions';
import { facebookService } from '../../Helpers/facebookInit';

class Logout extends Component {
  componentDidMount = () => {
    let { history } = this.props;
    facebookService.onLogout();
    let { gapi } = window;
    if (gapi && gapi.auth2 && gapi.auth2.getAuthInstance() && gapi.auth2.getAuthInstance().isSignedIn.get()) {
      gapi.auth2.getAuthInstance().disconnect();
      gapi.auth2
        .getAuthInstance()
        .signOut()
        .then(() => {
          localStorage.clear();
          history.push('/login');
        });
    } else {
      localStorage.clear();
      history.push('/login');
    }
    this.props.logoutUser();
  };

  render() {
    return <React.Fragment />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
});
export default withRouter(connect(null, mapDispatchToProps)(Logout));
