import React from 'react';
import 'react-image-lightbox/style.css';
import { connect } from 'react-redux';

import { Row, Col } from 'reactstrap';
import { DataTable, TBody, THead, TCol, TRow, HeadingBlock, DataTableHolder } from '../../SharedComponents/Table/tableElements';
import { DataColHead, ListPagination, ViewDate } from '../../SharedComponents/ListComponents/components';

import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import Loader from '../../Components/loader';
// import { get } from '../../Libraries/Ajax/httpService';
import SearchableDropdown from '../../SharedComponents/FormHelpers/Controls/searchableDropdown';

class ListActivityContainer extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleSelect: false,
      showLightBox: false,
      imageUrl: '',
      multiSelect: false,
      rowData: {},
      isLoaderActive: false,
      showDeleteConfirmation: false,
      productList: [],
    };
  }

  componentDidMount() {
    this.setState({ isLoaderActive: true });
    this.setJsonUrl('engagements');
    this.setpredicates({
      take: 10,
      page: this.state.pageNo,
    });
  }

  onDidUpdate = () => {
    this.setState({ selectedMemorial: this.state.memorialId, pageNo: 0, result: [] });
  };

  componentDidUpdate() {
    if (this.state.memorialId) {
      if (this.state.selectedMemorial !== this.state.memorialId) {
        this.onDidUpdate();
        this.setJsonUrl('engagements');
        this.setpredicates({
          take: 20,
          page: 0,
        });
      }
    }
  }

  customOnchangeHandler = (option) => {
    this.setpredicates({ obituaryId: option ? option.id : 0 });
    this.setState({ memorialId: option ? option.id : 0 });
  };

  render() {
    let { apiResponse, isLoaderActive } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              {isLoaderActive === true ? <Loader /> : ''}
              <HeadingBlock title="Activity in Obituaries" />
            </Col>
            <Col sm={6}>
              <SearchableDropdown
                {...{
                  placeholder: 'Search for Obituary Profile',
                  name: 'obituary',
                  optionvalue: 'id',
                  rules: {},
                  renderapi: 'obituary-profile/activities',
                  apiModel: {
                    searchId: true,
                  },
                  imageKey: 'mediaUrl',
                  mainText: 'name',
                  value: this.state.memorialId,
                  noOptionsMessage: 'No obituaries found',
                }}
                customOnchangeHandler={this.customOnchangeHandler}
              />
            </Col>
          </Row>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                    <div className="row">
                      <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                        <DataTable>
                          <THead>
                            <TRow>
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'User',
                                })}
                              />
                              {!this.state.memorialId && (
                                <DataColHead
                                  {...this.setheadproperties({
                                    title: 'Obituary',
                                  })}
                                />
                              )}

                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Date',
                                })}
                              />

                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Activity',
                                })}
                              />
                            </TRow>
                          </THead>
                          <TBody>
                            {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                              ? apiResponse.rows.map((row) => (
                                  <TRow>
                                    <TCol> {row.userName}</TCol>
                                    {!this.state.memorialId && <TCol> {row.obituaryName}</TCol>}
                                    <TCol>
                                      <ViewDate isTableView dateValue={row.createdDate} />
                                    </TCol>
                                    <TCol>
                                      <span>
                                        {row.activity == 'Flower' && <i className="mdi mdi-flower" />}
                                        {row.activity == 'Tree' && <i className="fas fa-tree" />}
                                        {row.activity == 'Attendance' && <i className="fa fa-heart" />}
                                      </span>
                                      {row.activity}
                                    </TCol>
                                  </TRow>
                                ))
                              : null}
                          </TBody>
                        </DataTable>
                        {apiResponse.rows && apiResponse.rows.length === 0 && <div className="text-center no-content-block">No results found </div>}
                      </DataTableHolder>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  userData: state.Login.userData,
});
export default connect(mapStateToProps)(ListActivityContainer);
