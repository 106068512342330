/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable prefer-template */
import React from 'react';
import {} from 'react-bootstrap';
import { Col, Row, Card, CardBody, Button } from 'reactstrap';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';

export class AccessModuleCards extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleEachChange = ({ isClearEvent, module, subMod, permission, perIndex, boolValue }) => {
    let { updateModuleList, modulesList } = this.props;
    let accessList = [...modulesList];
    let index = accessList.findIndex((item) => item.id == module.id);
    let item = accessList[index];

    item.subModules.forEach((sub) => {
      if (isClearEvent) {
        if ((subMod && subMod.id === sub.id) || !subMod) {
          sub.permissions.forEach((per) => {
            per.isSelected = false;
            item[`is${per.title}All`] = false;
          });
        }
      } else {
        if (boolValue != undefined || sub.id === subMod.id) {
          sub.permissions.forEach((per) => {
            if (permission.id === per.id && !sub[`disable${per.title}`]) per.isSelected = boolValue != undefined ? boolValue : !per.isSelected;
          });
        }
        if (boolValue != undefined) item[`is${permission.title}All`] = boolValue;
        else if (
          item.subModules.every((subMods) => {
            let a = subMods.permissions[perIndex].isSelected;
            let b = subMods[`disable${permission.title}`];
            if (a && !b) return true;
            if (b) return true;
            return false;
          })
        ) {
          item[`is${permission.title}All`] = true;
        } else item[`is${permission.title}All`] = false;
      }
    });
    updateModuleList(accessList);
  };

  render() {
    let { modulesList, allPermissions, isView } = this.props;
    return (
      <>
        {modulesList?.map((module, i) => (
          <Card className="scroll-card" key={'module-' + i}>
            <CardBody className="p-0">
              {module.subModules.length > 1 && (
                <Row className="access-top-row mx-0">
                  <Col xs={2} md={2} lg={2} />
                  <Col xs={2} md={2} lg={2}>
                    Sub Menu Title
                  </Col>
                  <Col xs={6} md={6} lg={isView ? 8 : 6}>
                    <Row>
                      {allPermissions.map((headPermission, j) => (
                        <Col key={'per-' + j}>
                          <div className="__checkbox">
                            <input
                              type="checkbox"
                              id={'nCall-' + headPermission.title + module.id}
                              value="nCall"
                              onChange={
                                () =>
                                  this.handleEachChange({
                                    module,
                                    permission: headPermission,
                                    boolValue: !module['is' + headPermission.title + 'All'],
                                  })
                                // eslint-disable-next-line react/jsx-curly-newline
                              }
                              disabled={isView || module.subModules.every((item) => item['disable' + headPermission.title]) || false}
                              checked={module['is' + headPermission.title + 'All']}
                            />
                            <label htmlFor={'nCall-' + headPermission.title + module.id}> {headPermission.title}</label>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Col className={isView && 'd-none'} xs={2} md={2} lg={2}>
                    <Button onClick={() => this.handleEachChange({ module, isClearEvent: true })}>Clear All</Button>
                  </Col>
                </Row>
              )}
              <Row className="access-content mx-0">
                <Col xs={2} md={2} lg={2} className="text-nowrap">
                  {module.title}
                </Col>
                <Col xs={2} md={2} lg={2} className="py-0">
                  <Row>
                    {module.subModules.map((sub, k) => (
                      <Col xs={12} key={'sub-' + k}>
                        {sub.title}
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col xs={6} md={6} lg={isView ? 8 : 6} className="flex-column pt-0">
                  {module.subModules.map((subMod, l) => (
                    <div className="w-100" key={'sub-per' + l}>
                      <Row>
                        {subMod.permissions.map((permission, perIndex) => (
                          <Col key={'per' + permission.title + l}>
                            <div className="__checkbox">
                              <input
                                type="checkbox"
                                id={subMod.title + permission.title + module.id}
                                onChange={() => this.handleEachChange({ module, subMod, permission, perIndex })}
                                checked={permission.isSelected}
                                disabled={isView || subMod['disable' + permission.title] || false}
                              />
                              <label htmlFor={subMod.title + permission.title + module.id}>
                                {' '}
                                {module.subModules.length <= 1 && permission.title}
                              </label>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ))}
                </Col>
                <div className="access-content-button-holder">
                  <Col className={isView && 'd-none '}>
                    {module.subModules.map((subItem, index) => (
                      <div key={subItem.title + '-' + index}>
                        <Button onClick={() => this.handleEachChange({ module, isClearEvent: true, subMod: subItem })}>Clear All</Button>
                      </div>
                    ))}
                  </Col>
                </div>
              </Row>
            </CardBody>
          </Card>
        ))}
      </>
    );
  }
}

export default AccessModuleCards;
