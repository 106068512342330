import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { get } from '../../Libraries/Ajax/httpService';
import Loader from '../../Components/loader';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { ViewDate } from '../../SharedComponents/ListComponents/components';
import { DeleteRow, EditRow, ContentItems } from '../../SharedComponents/ListComponents/manageComponent';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';

class ViewShops extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleRow: null,
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      get(`shop/${this.props.match.params.id}`)
        .then((response) => {
          this.setState({ singleRow: response.data.data });
        })
        .catch((err) => {
          if (err.message) this.toast.show('update_error', 'ERROR', `Could not fetch Shop! ${err.message}`);
          this.setState({ isLoaderActive: false });
          this.props.history.push('/list-shops');
        });
      this.setState({ access: setComponentPrivilege('Manage Shops') });
    }
  }

  render() {
    let { isLoaderActive, singleRow, access } = this.state;
    let { history } = this.props;
    return (
      <>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          {singleRow && (
            <div className="app-l-heading-block">
              <Row className="align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">Shop Details</h4>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/list-shops">Shops</Link>
                      </li>
                      <li className="breadcrumb-item active">Shop Details</li>
                    </ol>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {singleRow && (
            <Container fluid className="px-0">
              <Card>
                <CardBody className="view-page">
                  <Row>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Name"> {singleRow.name}</ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Phone"> {singleRow.phone}</ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Address"> {singleRow.address}</ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Email"> {singleRow.email}</ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Created Date">
                        <ViewDate isTableView dateValue={singleRow.createdDate} />
                      </ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Last Modified Date">
                        <ViewDate isTableView dateValue={singleRow.modifiedDate} />
                      </ContentItems>
                    </Col>
                  </Row>
                </CardBody>{' '}
              </Card>
              <div>
                <Col>
                  <div className="float-right ">
                    {access && access.delete && (
                      <DeleteRow
                        renderapi={`shop/${this.props.match.params.id}`}
                        model={singleRow}
                        redirecturl="/list-shops"
                        deleteText="Delete"
                        history={history}
                        isCardViewTrue="True"
                      />
                    )}{' '}
                    {access && access.edit && (
                      <EditRow history={history} editText="Edit" redirecturl={`/manage-shops/${this.props.match.params.id}`} rowData={singleRow} />
                    )}{' '}
                  </div>
                </Col>
              </div>
            </Container>
          )}
        </div>
      </>
    );
  }
}
export default ViewShops;
