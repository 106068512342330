import { API_ERROR, SET_DEFAULT_URL } from './actionTypes';

export const apiError = (error) => ({
  type: API_ERROR,
  payload: error,
});
export const setDefaultMenuUrl = (payload) => ({
  type: SET_DEFAULT_URL,
  payload: payload,
});
