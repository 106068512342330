import React from 'react';
import { Card, CardBody, Row, Col, Container } from 'reactstrap';
import { ViewDate } from '../../SharedComponents/ListComponents/components';
import { ContentItems } from '../../SharedComponents/ListComponents/manageComponent';
import userpic from '../../assets/images/userpic.png';

const ViewObituaryPosts = (props) => {
  let { singleRow } = props;
  return (
    <React.Fragment>
      <Container fluid className="px-0">
        <Card>
          <CardBody>
            <Row>
              <Col xs={12} md={6} lg={4} xl={4}>
                <ContentItems title="Obituary"> {singleRow.obituaryName}</ContentItems>
              </Col>

              <Col xs={12} md={6} lg={4} xl={4}>
                <ContentItems title="Created By"> {singleRow.createdByName}</ContentItems>
              </Col>
              <Col xs={12} md={6} lg={4} xl={4}>
                <ContentItems title="Created Date">
                  <ViewDate isTableView dateValue={singleRow.createdDate} />
                </ContentItems>
              </Col>
              <Col xs={12} md={6} lg={4} xl={4}>
                <ContentItems title="Last Modified Date">
                  <ViewDate isTableView dateValue={singleRow.modifiedDate} />
                </ContentItems>
              </Col>

              {singleRow.mediaUrl && (
                <Col xs={12} md={12} lg={12} xl={12}>
                  <ContentItems title={singleRow.typeId == 2 ? 'Card' : 'Media'} style={{ maxHeight: '150px', width: '50%' }}>
                    <img
                      data-dz-thumbnail=""
                      height="200"
                      className="mw-100 rounded bg-light"
                      alt="obituary media"
                      href={singleRow.mediaUrl}
                      src={singleRow.mediaUrl ? singleRow.mediaUrl : userpic}
                    />
                  </ContentItems>
                </Col>
              )}

              {singleRow.content && (
                <Col xs={12} md={12}>
                  <ContentItems title="Content">
                    <p>{singleRow.content ? singleRow.content : '-'}</p>
                  </ContentItems>
                </Col>
              )}
            </Row>
            <Row />
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default ViewObituaryPosts;
