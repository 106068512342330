import React, { useEffect, useState } from 'react';
import { get, put } from '../../Libraries/Ajax/httpService';
import { convertToLocalISOString } from '../../Libraries/DateTimeHelpers/dateTimeHelperFunctions';
import { ViewDate } from '../../SharedComponents/ListComponents/components';

const RenewProfileComponent = (props) => {
  let { obituaryId, expiryDate, sendRenewRequest, setParentState, isRepublish } = props;
  let [period, setPeriod] = useState(null);
  let [price, setPrice] = useState(null);
  let today = new Date();
  let cmpDate = today.getTime() > new Date(expiryDate).getTime() ? today.toISOString() : expiryDate;

  useEffect(() => {
    get('memorial-expiry', { roleId: 9, compareDate: cmpDate }).then((response) => {
      response.data = response.data.data;
      if (response.data && response.data.rows && response.data.rows.length > 0) {
        let item = response.data.rows[0];
        setPeriod(item.expiryPeriod);
      }
    });
    get('memorial-pricing', { roleId: 9, compareDate: cmpDate, isRenewal: true }).then((response) => {
      response.data = response.data.data;
      if (response.data && response.data.rows && response.data.rows.length > 0) {
        let item = response.data.rows[0];
        setPrice(item.pricePerProfile);
      }
    });
  }, [expiryDate]);

  useEffect(() => {
    if (sendRenewRequest) {
      put(`obituary-profile/subscription/${obituaryId}`, { expiryDate: expiryDate.replace(/Z/i, ''), expiryPeriod: period }).then((response) => {
        if (response.data && response.data.data && response.data.success) {
          setParentState({
            renewModal: false,
            modalUser: null,
            stsId: 1,
            stsTitle: 'Published',
            showRenewButton: false,
            showReminderDetails: false,
            expiryDate: convertToLocalISOString(response.data.data.expiryDate),
            renewedDate: convertToLocalISOString(response.data.data.lastRenewedDate),
            sendRenewRequest: false,
          });
        }
      });
    }
  }, [sendRenewRequest]);

  return (
    <React.Fragment>
      {isRepublish && <p style={{ color: 'red' }}>The subscription for this obituary profile has expired, please renew!</p>}
      {period && (
        <p>
          Once renewed, this profile will become active for a period of {period} months from <ViewDate isTableView dateValue={cmpDate} />
        </p>
      )}
      {price && <p>Renew Rate: &euro; {price}</p>}
    </React.Fragment>
  );
};

export default RenewProfileComponent;
