import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { changeLayout, changeSidebarTheme, changeSidebarType, toggleRightSidebar, changeTopbarTheme, changeLayoutWidth } from '../../Store/actions';

import RightSidebar from '../rightSidebar';
import TopBar from './topBar';
// Other Layout related Component
import Sidebar from './sidebar';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    };

    this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
    this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
  }

  toggleRightSidebar() {
    this.props.toggleRightSidebar();
  }

  capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  componentDidMount() {
    // Scroll Top to 0
    window.scrollTo(0, 0);
    let currentpage = this.props.location.pathname;
    let parts = currentpage.split('-');
    let pageTitle = '';
    let len = parts.length;
    parts[0] = parts[0].slice(1);
    let partsWithId = parts[len - 1].split('/');
    if (partsWithId.length > 1) {
      partsWithId.pop();
    }
    parts[len - 1] = partsWithId[0];
    for (let i = 0; i < len; i++) {
      pageTitle = `${pageTitle + this.capitalizeFirstLetter(parts[i])} `;
    }

    document.title = `${pageTitle} | Memlo - Admin Portal`;
    if (this.props.leftSideBarTheme) {
      this.props.changeSidebarTheme(this.props.leftSideBarTheme);
    }

    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }

    if (this.props.leftSideBarType) {
      this.props.changeSidebarType(this.props.leftSideBarType);
    }
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }

    if (this.props.showRightSidebar) {
      this.toggleRightSidebar();
    }
  }

  toggleMenuCallback = () => {
    if (this.props.leftSideBarType === 'default') {
      this.props.changeSidebarType('condensed', this.state.isMobile);
    } else if (this.props.leftSideBarType === 'condensed') {
      this.props.changeSidebarType('default', this.state.isMobile);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div id="layout-wrapper">
          <TopBar toggleMenuCallback={this.toggleMenuCallback} toggleRightSidebar={this.toggleRightSidebar} />
          <div className="app-l-page-layout">
            <div className="vertical-menu">
              <div data-simplebar className="h-100">
                <Sidebar theme={this.props.leftSideBarTheme} type={this.props.leftSideBarType} isMobile={this.state.isMobile} />
              </div>
            </div>
            <div className="main-content">
              <div className="page-content">{this.props.children}</div>
            </div>
          </div>
          {/* <Footer /> */}

          <RightSidebar />
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => ({
  ...state.Layout,
});
export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth,
})(withRouter(Layout));
