import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, CardBody, Col, FormGroup, Row, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { get, post, put } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import FormInput from '../../SharedComponents/FormHelpers/Controls/formInput';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import { DeleteRow } from '../../SharedComponents/ListComponents/manageComponent';
import Loader from '../../Components/loader';
import MultiSelectCheckBox from '../../SharedComponents/FormHelpers/Controls/multiSelectCheckBoxs';
import CustomDropDown from '../../SharedComponents/FormHelpers/Controls/customComponentDropdown';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';

class ManageUserAccess extends FormComponent {
  constructor(props) {
    super(props);
    this.setProperties = this.setProperties.bind(this);
    this.setFormProperties = this.setFormProperties.bind(this);
    this.state = {
      action: 'Add',
      isSubmitted: true,
      isLoaderActive: false,
      singleRow: {},
      group: [],
      id: {
        label: 'id',
        type: 'hidden',
      },

      groupList: {
        name: 'groupList',
        label: 'Roles',
        renderApi: 'access?isAll=true',
        className: 'mb-0',
        rules: { required: true },
        isCollection: true,
      },
      users: {
        name: 'users',
        isMulti: true,
        isSelectOnly: true,
        optionvalue: 'id',
        listName: 'userIdList',
        rules: { required: true },
        renderapi: 'user',
        imageKey: 'userImageUrl',
        subText: 'email',
        mainText: 'name',
        label: 'Users',
        apiModel: { isActive: true, isUserAccess: true },
        value: [],
        noOptionsMessage: 'No results found',
      },
      userName: {
        label: 'Users',
        name: 'userName',
        type: 'text',
        placeholder: 'Enter Users',
        disabled: true,
      },
      editFlag: props.match.params.id || false,
    };
  }

  componentDidMount() {
    let { match } = this.props;
    if (match.params.id) {
      this.setState({ isLoaderActive: true, action: 'Edit' });
      sessionStorage.setItem('setPredicate', true);
      get(`user-access/${match.params.id}`)
        .then((response) => {
          if (response.data.data && response.data.data.rows.length > 0) {
            response.data.data.rows[0].groupList.forEach((role) => {
              role.isChecked = true;
            });
            this.mapJsonToState(response.data.data.rows[0]);
            this.setState({ singleRow: response.data.data.rows[0] });
          }
        })
        .finally(() => this.setState({ isLoaderActive: false }));
    }
    this.setState({ access: setComponentPrivilege('Manage User Access') });
  }

  toast = new ToastNotification();

  onSaveButtonClick = (model) => {
    this.setState({ isLoaderActive: true });
    let { match, history } = this.props;
    if (match?.params.id) {
      put(`user-access/${match.params.id}`, model)
        .then((response) => {
          this.setState({ isLoaderActive: false });
          if (response.data.success) {
            this.toast.show('create_success', 'SUCCESS', 'User access updated successfully');
            history.push('/list-user-access-roles');
          }
        })
        .catch((err) => {
          this.setState({ isLoaderActive: false });
          if (err.message) this.toast.show('update_error', 'ERROR', err.message);
        });
    } else {
      post('user-access', model)
        .then((response) => {
          this.setState({ isLoaderActive: false });
          if (response.data.success) {
            this.toast.show('create_success', 'SUCCESS', 'User access created successfully');
            history.push('/list-user-access-roles');
          }
        })
        .catch((err) => {
          this.setState({ isLoaderActive: false });
          if (err.message) this.toast.show('create_error', 'ERROR', err.message);
        });
    }
  };

  onCancelButtonClick = () => {
    this.props.history.goBack();
  };

  render() {
    let { editFlag, isLoaderActive, singleRow, userName, action, groupList, selectedRoles, access } = this.state;
    let { history } = this.props;
    return (
      <React.Fragment>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">User Access Details</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/list-user-access-roles">Manage User Access</Link>
                  </li>
                  <li className="breadcrumb-item active">{action} User Access Details</li>
                </ol>
              </div>
            </Col>
            {editFlag && access && access.delete && (
              <Col sm="6">
                <div className="float-right d-md-block">
                  <DeleteRow
                    renderapi={`user-access/${this.props.match.params.id}`}
                    model={singleRow}
                    redirecturl="/list-user-access-roles"
                    deleteText="Delete"
                    history={history}
                    isCardViewTrue="True"
                  />{' '}
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Container fluid className="px-0 manage-user">
              <div className="col-12">
                <SubmitForm onSubmit={this.onSaveButtonClick} {...this.setFormProperties({ disableReactiveForm: true })}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xs={12}>
                          {this.props.match.params.id ? (
                            <FormInput {...this.setProperties(userName)} />
                          ) : (
                            <CustomDropDown {...this.setProperties(this.state.users)} />
                          )}
                        </Col>
                      </Row>
                      <Row />
                      <Row>
                        <Col xl={12}>
                          <MultiSelectCheckBox {...this.setProperties(groupList)} selectedRoles={selectedRoles} />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <FormGroup className="mb-0">
                    <div className="float-right d-md-block">
                      <br />
                      <Button type="submit" color="primary" className="mr-1">
                        Save
                      </Button>{' '}
                      <Button className="btn-secondary" type="reset" onClick={this.onCancelButtonClick}>
                        <i className="icon-close"> </i> <span> Cancel </span>
                      </Button>
                    </div>
                  </FormGroup>
                </SubmitForm>
              </div>
            </Container>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.Login.userData,
});
export default connect(mapStateToProps)(ManageUserAccess);
