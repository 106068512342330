import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { remove } from '../../../Libraries/Ajax/httpService';
import Modal from '../../modal';
import ToastNotification from '../../../Libraries/ToastNotifications/toastNotification';

export class ActionButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toast = new ToastNotification();

  confirmButtonClick = () => {
    let { api, row, redirecturl, history } = this.props;
    let { deletedId } = this.state;
    remove(api + deletedId, row)
      .then(() => {
        this.setState({ showDeleteConfirmation: false });

        if (redirecturl && history) {
          history.push(redirecturl);
        }

        if (this.props.updateParent) this.props.updateParent();

        if (this.props.setpredicates) this.props.setpredicates({});

        if (this.props.renderList) {
          this.props.renderList();
        }
        this.toast.show('create_success', 'SUCCESS', 'Record deleted successfully.');
      })
      .catch((err) => {
        if (err) {
          if (err.message) {
            this.toast.show('update_error', 'ERROR', err.message);
          } else this.toast.show('update_error', 'ERROR', 'Something went wrong, Please try again.');
        }
      });
  };

  onDelClick = (e, id) => {
    this.setState({
      deletedId: id,
      showDeleteConfirmation: true,
    });
  };

  onCancelButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      showDeleteConfirmation: false,
    });
  };

  render() {
    let { rowId, value, url, isDisabled, title } = this.props;
    let values = {};

    if (value === 'edit') {
      values = { icon: 'fas fa-edit', title: 'Edit' };
    } else if (value === 'view') {
      values = { icon: 'fas fa-eye', title: 'View' };
    } else if (value === 'edit-color') {
      values = { icon: 'fas fa-palette', title: 'Font color settings' };
    }

    return (
      <>
        <>
          {' '}
          {value === 'view' || value === 'edit' || value == 'edit-color' ? (
            <span className="mr-2" style={isDisabled ? { pointerEvents: 'none' } : {}}>
              <Link to={url + rowId} title={title || values.title} style={isDisabled ? { color: '#d7dce0' } : {}}>
                <i className={values.icon} />
              </Link>
            </span>
          ) : (
            <span onClick={(e) => this.onDelClick(e, rowId)} style={isDisabled ? { pointerEvents: 'none' } : {}}>
              <Link to={url} title={title || 'Delete'} style={isDisabled ? { color: '#d7dce0' } : {}}>
                <i className="fas fa-trash" />
              </Link>
            </span>
          )}
        </>
        {this.state.showDeleteConfirmation ? (
          <Modal
            title="Delete confirmation"
            cancelButtonName="Cancel"
            confirmButtonName="OK"
            cancelButtonClick={this.onCancelButtonClick}
            confirmButtonClick={this.confirmButtonClick}
          >
            <div style={{ textAlign: 'left' }}>Are you sure you want to delete this record?</div>
          </Modal>
        ) : null}
      </>
    );
  }
}
export default ActionButton;
