import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

import Loader from '../../../Components/loader';
import Validation from '../../../Libraries/Validations/validation';
import ValidationMessage from '../Validation/validationMessage';
import { get } from '../../../Libraries/Ajax/httpService';
import { LazyRun } from '../../../Libraries/LazyFunctions/lazyFunction';

class DropDown extends Component {
  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.onNativeChange = this.onNativeChange.bind(this);
    this.state = {
      message: '',
      isLoaderActive: false,
    };
  }

  validation = new Validation();

  isTouched = false;

  handleOnChange(e) {
    let properties = {
      ...this.props,
      value: e && e.target ? e.target.value : '',
    };
    // if (properties.isTextValue) {
    // } else {
    properties.value = Number(properties.value);
    // }
    let filteredArray = this.props.options.filter((item) => item[properties.optionvalue] === properties.value);
    if (filteredArray.length > 0) {
      let [first] = filteredArray;
      properties.item = first;
    }
    if (properties.onChangeValidation) {
      properties.onChangeValidation(e, properties);
    }
    if (properties.childControl) {
      let { stateKey } = this.props.childControl;
      let { serverName } = this.props.childControl;
      let statedetails = properties.getParentState(stateKey);
      let obj = {};
      if (!properties.value) {
        statedetails.options = [];
      }
      obj[statedetails.name] = {
        ...statedetails,
        predicate: { [serverName]: properties.value },
      };
      properties.setParentState(obj);
    }
  }

  onNativeChange(e) {
    let { onNativeChange } = this.props;
    let properties = { ...this.props, value: e.target.value };
    if (properties.onChangeValidation) {
      properties.onChangeValidation(e, properties);
    }
    if (onNativeChange) {
      onNativeChange(e, this.props);
    }
  }

  isValueUpdated = false;

  prevPredicate = '';

  modelToQuery = (_model) => {
    let queryList = [];
    Object.keys(_model).forEach((item) => {
      if (Object.prototype.hasOwnProperty.call(_model, item)) {
        queryList.push(`${item}=${_model[item]}`);
      }
    });
    if (queryList.length > 0) {
      return `?${queryList.join('&')}`;
    }
    return '';
  };

  componentDidUpdate() {
    if (this.props.predicate && JSON.stringify(this.props.predicate) != this.prevPredicate) {
      LazyRun(() => {
        this.prevPredicate = JSON.stringify(this.props.predicate);

        let tempName = '';
        this.props.predicate.forEach((element) => {
          tempName = element;
        });
        if (this.props.predicate && this.props.predicate[tempName]) {
          this.setState({
            isLoaderActive: true,
          });
          get(this.props.renderapi + this.modelToQuery(this.props.predicate)).then((response) => {
            let properties = { ...this.props };
            let obj = {};
            obj[properties.name] = {
              ...properties,
              options: response.data.data,
            };
            properties.setParentState(obj);
            this.setState({
              isLoaderActive: false,
            });
          });
        }
      }, 500);
    }

    let properties = { ...this.props };
    if (!this.isValueUpdated && this.props.disableplaceholder && !properties.value) {
      this.isValueUpdated = true;
      let obj = {};
      obj[properties.name] = {
        ...properties,
        value: this.props.options[properties.optionvalue],
      };
      properties.setParentState(obj);
      // this.setState({
      //   isLoaderActive: false,
      // });
    }
    if (properties.childControl) {
      let { stateKey } = this.props.childControl;
      let { serverName } = this.props.childControl;
      let statedetails = properties.getParentState(stateKey);

      let obj = {};
      if (
        (!statedetails.predicate && properties.value) ||
        (statedetails.predicate && statedetails.predicate[serverName] && statedetails.predicate[serverName] != properties.value)
      ) {
        obj[statedetails.name] = {
          ...statedetails,
          predicate: { [serverName]: properties.value },
        };
        properties.setParentState(obj);
        // this.setState({
        //   isLoaderActive: false,
        // });
      }
    }
  }

  componentDidMount() {
    let properties = { ...this.props };
    let res = [];
    if (!this.props.isfilteredFromParent) {
      get(this.props.renderapi).then((response) => {
        properties = { ...this.props };
        if (response.data.data) {
          res = response.data.data.rows;
          if (this.props.disableAll === true) {
            res = response.data.data.filter((data) => data.title !== 'All');
          }
        }
        let obj = {};
        obj[properties.name] = {
          ...properties,
          options: res,
        };
        properties.setParentState(obj);
      });
    }

    let { id, name, index } = this.props;

    let input = document.querySelectorAll(`[name="${name}"]`);
    if (id) {
      input = document.querySelectorAll(`[id="${id}"]`);
    }
    if (typeof index !== 'undefined') {
      input = [input[index]];
    }

    input.forEach((element) => {
      element.addEventListener('change', this.onNativeChange);
    });
  }

  render() {
    let {
      onFocus,
      className,
      disableFormGroup,
      ref,
      id,
      label,
      type,
      placeholder,
      name,
      value,
      as,
      controlId,
      optionvalue,
      optiontext,
      rules,
      placeholderValue,
      disableLabel,
      disabled,
      autoComplete,
      list,
      isHidden,
      options,
      errors: [errFromProp] = [],
    } = this.props;
    let message = '';
    // if (this.props.errors?.length > 0) {
    //   message = this.props.errors[0].message;
    // }
    if (errFromProp) {
      ({ message } = errFromProp);
    }
    let messagetype = 'i';
    if (this.props.isTouched) {
      messagetype = 'e';
    }
    if (!this.props.isFocused || this.props.isSilent) {
      messagetype = '';
    }

    let inputproperties = {
      label,
      type,
      placeholder,
      name,
      value,
      autoComplete,
      list,
    };
    if (ref) {
      inputproperties.ref = ref;
    }
    if (id) {
      inputproperties.id = id;
    }

    if (as === 'select' && placeholder === `Enter ${label}`) {
      placeholder = `Select ${label}`;
    }
    if (!onFocus) {
      onFocus = () => {};
    }
    if (!options) {
      options = [];
    }
    if (!isHidden) {
      return (
        <>
          {this.state.isLoaderActive === true ? <Loader /> : null}
          <div className={disableFormGroup ? '' : 'form-group'}>
            {!disableLabel ? (
              <Form.Label>
                {label}
                {rules && rules.required && !this.props.disableRequiredsymbol ? <sup>*</sup> : ''}
              </Form.Label>
            ) : (
              ''
            )}

            {options && (
              <Form.Control
                value={value && value}
                as="select"
                name={name}
                disabled={disabled}
                controlid={controlId}
                className={className || (`${this.state.message}` ? ' has-error' : ' has-success')}
                placeholder={placeholder}
                onChange={this.handleOnChange}
                onBlur={this.handleOnBlur}
              >
                {placeholder && <option value={placeholderValue || ''}>{placeholder}</option>}

                {options &&
                  options.length > 0 &&
                  options.map((option) => (
                    <option disabled={option.disabled} key={option[optionvalue]} value={option[optionvalue]}>
                      {option[optiontext]}
                    </option>
                  ))}
              </Form.Control>
            )}

            {messagetype ? <ValidationMessage {...{ message }} {...{ type: messagetype }} /> : <span className="app-c-validation-message" />}
          </div>
        </>
      );
    }
    return <></>;
  }
}

export default DropDown;
