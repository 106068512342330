import React from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { get } from '../../Libraries/Ajax/httpService';
import Loader from '../../Components/loader';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import ManagePartnerManager from './managePartnerManager';

dayjs.extend(advancedFormat);
class ViewPartnerManager extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      totalObituaries: null,
      totalAmount: null,
      totalOutstanding: null,
      partnerManager: null,
      totalObituariesPrice: null,
    };
  }

  calculateObituary = (response) => {
    let ttlObituary = 0;
    let ttlAmount = 0;
    let ttlOutstanding = 0;
    let ttlObAmount = 0;
    response.forEach((row) => {
      ttlObituary += row.totalObituaries;
      ttlAmount += parseFloat(row.totalAmount);
      ttlOutstanding += parseFloat(row.outstandingAmount);
      ttlObAmount += parseFloat(row.totalObituariesPrice);
    });
    this.setState({ totalObituaries: ttlObituary, totalObituariesPrice: ttlObAmount, totalAmount: ttlAmount, totalOutstanding: ttlOutstanding });
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      let model = {
        partnerManagerId: parseInt(this.props.match.params.id),
      };
      if (model) {
        get('partner-manager-obituary', model).then((response) => {
          this.setState({ apiResponse: response.data?.data.rows });
          this.calculateObituary(response.data.data.rows);
        });
      }
      this.setState({ partnerManager: parseInt(this.props.match.params.id) });
    }
  }

  render() {
    let { apiResponse, isLoaderActive, totalObituariesPrice, totalObituaries, totalOutstanding, partnerManager } = this.state;
    return (
      <>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          {apiResponse && (
            <div className="app-l-heading-block">
              <Row className="align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">Partner Manager Transactions</h4>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/list-partner-managers">Partner Manager</Link>
                      </li>
                      <li className="breadcrumb-item active">Partner Manager Transactions</li>
                    </ol>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="float-right d-md-block">
                    <Link
                      className="btn btn-primary waves-effect waves-light"
                      to={`/view-partner-managers/${partnerManager}/view-transactions`}
                      role="button"
                    >
                      View Transactions
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <Row>
            <Col sm={12} md={4}>
              <Card>
                <CardBody style={{ textAlign: 'center' }}>
                  <h5 className="text-primary font-size-18 mt-0 mb-1 truncate-text">Obituary Created</h5>
                  <p className="font-size-16 mb-2 truncate-text">{totalObituaries != 0 ? totalObituaries : 0}</p>
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card>
                <CardBody style={{ textAlign: 'center' }}>
                  <h5 className="text-primary font-size-18 mt-0 mb-1 truncate-text">Total Amount</h5>
                  <p className="font-size-16 mb-2 truncate-text">€ {totalObituariesPrice != 0 ? totalObituariesPrice : 0}</p>
                </CardBody>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card>
                <CardBody style={{ textAlign: 'center' }}>
                  <h5 className="text-primary font-size-18 mt-0 mb-1 truncate-text">Outstanding Amount</h5>
                  <p className="font-size-16 mb-2 truncate-text">€ {totalOutstanding != 0 ? totalOutstanding : 0}</p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <ManagePartnerManager {...this.props} partnerManager={partnerManager} />
          </Row>
        </div>
      </>
    );
  }
}

export default ViewPartnerManager;
