import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const TemplateCardsList = (props) => {
  let { cardList, isBackground } = props;
  let [index, setIndex] = useState(-1);
  let [imgUrl, setImgUrl] = useState('');
  let [lightBox, setLightBox] = useState(false);
  let [backgroundOptions, setBackgroundOptions] = useState({
    image: true,
    colour: false,
  });
  let [imageCount, setImageCount] = useState(-1);
  let [colourCount, setColourCount] = useState(-1);
  let colSizes = [
    { xs: 6, sm: 6, md: 4, lg: 3, xl: 3 },
    { xs: 12, sm: 12, md: 12, lg: 6, xl: 6 },
  ];
  let styleSelector = 0;
  let imageStyles = [
    { objectFit: 'fill', divHeight: `${200}px`, imgHeight: `${179}px` },
    { objectFit: 'contain', divHeight: 'auto', imgHeight: 'auto' },
  ];

  useEffect(() => {
    if (isBackground) {
      let col = 0;
      let img = 0;
      cardList.forEach((item) => {
        if (item.solidColor) {
          col += 1;
        } else {
          img += 1;
        }
      });
      setImageCount(img);
      setColourCount(col);
    }
  }, []);

  let setTemplateCard = (i, isColour) => {
    if (index !== i) {
      setIndex(i);
      if (props.selectTemplate) {
        props.selectTemplate(i, isColour);
      }
    }
  };

  let onSelectChanged = (e) => {
    if (e.target.value === 'colour') {
      setBackgroundOptions({ image: false, colour: true });
    } else {
      setBackgroundOptions({ image: true, colour: false });
    }
  };

  return (
    <React.Fragment>
      {isBackground && (
        <Row>
          <Col xs={6} sm={3}>
            <label
              htmlFor="image"
              onChange={(e) => {
                onSelectChanged(e);
              }}
            >
              <input type="radio" name="radio" id="image" value="image" checked={backgroundOptions.image} style={{ marginRight: `${0.3}rem` }} />
              Background Image
            </label>
          </Col>
          <Col xs={6} sm={3}>
            <label
              htmlFor="colour"
              onChange={(e) => {
                onSelectChanged(e);
              }}
            >
              <input type="radio" name="radio" id="colour" value="colour" checked={backgroundOptions.colour} style={{ marginRight: `${0.3}rem` }} />
              Solid Color
            </label>
          </Col>
        </Row>
      )}
      <Row>
        {cardList &&
          cardList.length > 0 &&
          cardList
            .map((row, i) => {
              if (isBackground && !row.solidColor) {
                styleSelector = 1;
              } else {
                styleSelector = 0;
              }
              return (
                <Col key={row.solidColor ? `colour-list-${i}` : `image-list-${i}`} {...colSizes[styleSelector]} className="py-2">
                  <div
                    onClick={() => setTemplateCard(i, !!row.solidColor)}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexDirection: 'column',
                      borderRadius: `${15}px`,
                      height: imageStyles[styleSelector].divHeight,
                      backgroundColor: 'lightgray',
                    }}
                  >
                    {backgroundOptions.colour ? (
                      <div
                        style={{
                          background: row.solidColor,
                          padding: `${10}px`,
                          minWidth: `${100}%`,
                          maxHeight: `${100}%`,
                          height: `${179}px`,
                          objectFit: 'fill',
                          borderRadius: `${15}px`,
                          marginBottom: `${0.3}rem`,
                        }}
                      />
                    ) : (
                      <img
                        src={row.mediaUrl}
                        alt={row.mediaUrl}
                        style={{
                          minWidth: `${100}%`,
                          maxHeight: `${100}%`,
                          height: imageStyles[styleSelector].imgHeight,
                          objectFit: imageStyles[styleSelector].objectFit,
                          borderRadius: `${15}px`,
                          marginBottom: `${0.3}rem`,
                        }}
                        onClick={() => {
                          setImgUrl(row.mediaUrl);
                          setLightBox(true);
                        }}
                      />
                    )}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <input type="radio" checked={i == index} id={row.id} name="cardList" />
                    </div>
                  </div>
                </Col>
              );
            })
            .filter((item) => {
              let jsx;
              if (backgroundOptions.colour && item.key.includes('colour')) {
                jsx = true;
              } else if (backgroundOptions.image && item.key.includes('image')) {
                jsx = true;
              } else {
                jsx = false;
              }
              return jsx;
            })}
        {backgroundOptions.colour && colourCount == 0 && <Col className="text-center">No templates available</Col>}
        {backgroundOptions.image && imageCount == 0 && <Col className="text-center">No templates available</Col>}
      </Row>
      {lightBox && <Lightbox mainSrc={imgUrl} enableZoom={false} clickOutsideToClose onCloseRequest={() => setLightBox(false)} />}
    </React.Fragment>
  );
};

export default TemplateCardsList;
