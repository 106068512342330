import React from 'react';
import { Row, Col, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Button } from 'reactstrap';

import FormComponent from './FormHelpers/Form/formComponent';
import CustomDatePicker from './FormHelpers/Controls/customDatePicker';
import { convertToLocalISOString } from '../Libraries/DateTimeHelpers/dateTimeHelperFunctions';

class DateRangeFilter extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      dropDown: false,
      setChildState: {
        shouldSet: false,
        fromDate: null,
        toDate: null,
        currentFilter: null,
      },
      filterOptions: [
        { id: 1, title: 'Today' },
        { id: 2, title: 'Yesterday' },
        { id: 3, title: 'Last Week' },
        { id: 4, title: 'Last Month' },
      ],
      fromDate: {
        name: 'fromDate',
        placeholder: 'Created From',
        type: 'date',
        relatedPropertyName: 'toDate',
        rules: {
          maxToday: true,
          lesser: true,
        },
      },
      toDate: {
        name: 'toDate',
        placeholder: 'Created To',
        type: 'date',
        relatedPropertyName: 'fromDate',
        rules: {
          maxToday: true,
        },
      },
    };
  }

  formatDate = (date) => {
    if (date.value && date.value.length > 0) {
      let d = new Date(date.value.split('T')[0]);
      if (date.name === 'fromDate') {
        d.setHours(0, 0, 0, 0);
      } else {
        d.setHours(23, 59, 59, 999);
      }
      d = convertToLocalISOString(d);
      return d.replace(/Z/i, '');
    }
    return '';
  };

  componentDidUpdate(prevProps, prevState) {
    let { setFilterDates, pageSwitchCount } = this.props;
    let { fromDate, toDate } = this.state;
    if (prevState.fromDate.value !== fromDate.value || prevState.toDate.value !== toDate.value) {
      let dates = {
        fromDate: this.formatDate(fromDate),
        toDate: this.formatDate(toDate),
      };
      setFilterDates(dates);
    }
    if (pageSwitchCount > prevProps.pageSwitchCount) {
      this.onClearFilters();
    }
  }

  componentDidMount() {
    let { fromDate, toDate } = this.state;
    if (this.props.transactionLabel) {
      this.setState({ fromDate: { ...fromDate, placeholder: 'Transactions From' } });
      this.setState({ toDate: { ...toDate, placeholder: 'Transactions To' } });
    }
  }

  onFilterOptionChange = (option) => {
    let startDate = new Date();
    let endDate = new Date();
    if (option.id === 2) {
      startDate.setDate(startDate.getDate() - 1);
      endDate = startDate;
    } else if (option.id === 3) {
      startDate.setDate(startDate.getDate() - 7);
    } else if (option.id === 4) {
      startDate.setMonth(startDate.getMonth() - 1);
    }
    startDate = convertToLocalISOString(startDate);
    endDate = convertToLocalISOString(endDate);
    this.setState((prevState) => ({
      fromDate: { ...prevState.fromDate, value: startDate },
      toDate: { ...prevState.toDate, value: endDate },
      setChildState: { shouldSet: true, fromDate: startDate, toDate: endDate, currentFilter: option.title },
    }));
  };

  onClearFilters = () => {
    this.setState((prevState) => ({
      fromDate: { ...prevState.fromDate, value: null },
      toDate: { ...prevState.toDate, value: null },
      setChildState: { shouldSet: true, fromDate: null, toDate: null, currentFilter: null },
    }));
  };

  render() {
    let { dropDown, filterOptions, fromDate, toDate, setChildState } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col sm={12} md={4}>
            <CustomDatePicker
              formGroup
              disableLabel
              appearance="subtle"
              futureDate={false}
              format="DD/MM/YYYY"
              isDate
              oneTap
              placement="auto"
              imposeParentState={setChildState}
              setParentState={this.setParentState}
              maxDate={toDate.value}
              {...this.setProperties(fromDate)}
            />
          </Col>
          <Col sm={12} md={4}>
            <CustomDatePicker
              formGroup
              disableLabel
              appearance="subtle"
              futureDate={false}
              format="DD/MM/YYYY"
              isDate
              oneTap
              placement="auto"
              imposeParentState={setChildState}
              setParentState={this.setParentState}
              minDate={fromDate.value}
              {...this.setProperties(toDate)}
            />
          </Col>
          <Col xs={6} md={2}>
            <Dropdown isOpen={dropDown} toggle={() => this.setState({ dropDown: !dropDown })} className="form-group">
              <DropdownToggle className="btn btn-secondary" caret>
                Filter by {setChildState.currentFilter ? <small>- {setChildState.currentFilter}</small> : null}
              </DropdownToggle>
              <DropdownMenu>
                {filterOptions.map((option, i) => (
                  <DropdownItem key={`dropdown-item-${i}`} onClick={() => this.onFilterOptionChange(option)}>
                    {option.title}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col xs={6} md={2}>
            <div className="form-group float-right">
              <Button onClick={() => this.onClearFilters()}>Clear Date Filter</Button>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default DateRangeFilter;
