import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Row, Col, Container, Card, CardBody, Button } from 'reactstrap';
import { DataTable, TBody, THead, TCol, TRow, DataTableHolder } from '../../SharedComponents/Table/tableElements';
import { DataColHead, ListPagination, ViewDate } from '../../SharedComponents/ListComponents/components';
import { DeleteRow } from '../../SharedComponents/ListComponents/manageComponent';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { ActionButton } from '../../SharedComponents/FormHelpers/Controls/actionButton';
import Loader from '../../Components/loader';
import ManageObituaryPosts from './manageObituaryPost';
import ViewObituaryPosts from './viewObituaryPost';
import ListMediaCard from '../Post/listMediaCard';
import ManageMediaCard from '../Post/manageMediaCard';
import ManageAnnouncementCard from '../Post/manageAnnouncementCard';
import ListEvents from '../Post/listEvents';
import LinkButton from '../../SharedComponents/linkButton';
import ManageBackgroundImage from './manageBackgroundImage';
import { convertToLocalISOString } from '../../Libraries/DateTimeHelpers/dateTimeHelperFunctions';
import CustomModal from '../../SharedComponents/customModal';
import SortMediaComponent from '../Post/sortMediaComponent';

class ListObituaryPostContainer extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleSelect: false,
      multiSelect: false,
      rowData: {},
      isLoaderActive: true,
      modal: {
        user: '',
        status: false,
      },
      requiredFields: [],
      showLightBox: false,
      imageUrl: '',
      reorderModal: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.onChangeFromChild = this.onChangeFromChild.bind(this);
  }

  sortRef = {};

  manageRef = {};

  componentDidMount() {
    let obId = parseInt(this.props.obituaryId);
    let typId = parseInt(this.props.typeId);
    this.setJsonUrl('obituary-post');
    this.setpredicates({
      take: 10,
      page: 0,
      obituaryId: obId,
      typeId: typId,
    });
    if (this.state.apiResponse.rows && this.state.apiResponse.rows.length > 0) {
      this.setState({ isLoaderActive: false });
    }
    this.setChildValidations(this.props.type);
  }

  componentDidUpdate() {}

  onChangeFromChild = (noChange) => {
    if (noChange) {
      this.setState({ rowData: {} });
    } else {
      this.setState({ rowData: {}, isLoaderActive: true });
      setTimeout(() => {
        this.setpredicates();
      }, 1000);
    }
  };

  showManageCard = (row) => {
    this.setState({ rowData: row });
  };

  onConfirmButtonClick = (e) => {
    this.manageRef.onClose = this.closeModal;
    this.manageRef.onFormSubmit(e);
  };

  closeModal(saved) {
    this.setState({
      rowData: {},
      modal: { user: '', status: false },
      isLoaderActive: saved === true,
    });
    if (saved === true) {
      setTimeout(() => {
        this.setpredicates();
      }, 1000);
    }
  }

  onLightBoxClick = (mediaUrl) => {
    this.setState({
      showLightBox: true,
      imageUrl: mediaUrl,
    });
  };

  closeReorderModal = () => {
    this.setState({ reorderModal: false });
  };

  onReorderConfirm = () => {
    this.sortRef.onClose = this.closeReorderModal;
    this.sortRef.rerender = (response) => {
      response.rows = response.rows.filter((row, i) => i < 10);
      this.setState({ apiResponse: response });
    };
    this.sortRef.onSaveButtonClick();
  };

  openModal = (modalUser, rowData) => {
    let { type } = this.props;
    let obj = {};
    obj.modal = { user: modalUser, status: true };
    if (modalUser === 'view') {
      obj.modal.buttonList = [
        { name: 'Delete', component: this.deleteButton(rowData) },
        { name: 'Edit', color: 'secondary', onClick: () => this.openModal('edit') },
      ];
      obj.modal.title = `${type} details`;
    } else {
      obj.modal.buttonList = [{ name: 'Save', color: 'primary', onClick: this.onConfirmButtonClick }, { name: 'Cancel' }];
      obj.modal.title = modalUser === 'add' ? `Create ${type}` : `Edit ${type}`;
    }
    if (rowData && rowData.id) {
      obj.rowData = rowData;
    }
    this.setState({ ...obj });
  };

  setChildValidations(type) {
    let reqFields = [];
    let locationReqFields = [];
    switch (type) {
      case 'Announcement':
      case 'Tribute Message':
        reqFields = ['content'];
        break;
      case 'Photos And Videos':
      case 'Tribute Card':
      case 'Tribute Media':
        reqFields = ['media'];
        break;
      case 'Event':
        reqFields = ['startDate', 'startTime', 'endTime', 'headingId'];
        locationReqFields = ['venue'];
        break;
      default:
        break;
    }
    this.setState({ requiredFields: reqFields });
    if (type == 'Event') {
      this.setState({ locationRequiredFields: locationReqFields });
    }
  }

  deleteButton = (rowData) => (
    <DeleteRow renderapi={`obituary-post/${rowData.id}`} rowData={rowData} deleteText="Delete" isCardViewTrue closeParentModal={this.closeModal} />
  );

  render() {
    let { isLoaderActive, requiredFields, apiResponse, rowData, reorderModal, modal } = this.state;
    let { obituaryId, typeId, type, age, bgId, bgUrl, bgHexCode, statusId, setBackgroundImage, access, genderId } = this.props;
    this.sortRef.onSaveButtonClick = {};
    this.manageRef.onFormSubmit = {};
    return (
      <React.Fragment>
        <Container fluid>
          <Row className="align-items-center">
            <Col sm={12} className={type !== 'Tribute Card' && type !== 'Tribute Message' ? 'd-none' : ''}>
              <div className="float-right d-md-block">
                {access && access.create && (
                  <LinkButton
                    toString={`create${type.replace(/\s/g, '')}`}
                    className="btn btn-primary waves-effect waves-light"
                    role="button"
                    toDoFunction={() => this.openModal('add')}
                  >
                    Add {type}
                  </LinkButton>
                )}
              </div>
            </Col>
          </Row>
          <Row className="fit-to-container">
            {isLoaderActive === true ? <Loader /> : ''}
            {type == 'Announcement' && (
              <Col>
                {access && (access.edit || access.create) ? (
                  <ManageAnnouncementCard
                    content={apiResponse?.rows?.length > 0 ? apiResponse.rows[0].content : ''}
                    age={age}
                    genderId={genderId}
                    obituaryId={obituaryId}
                    typeId={typeId}
                    postId={apiResponse?.rows?.length > 0 ? apiResponse.rows[0].id : null}
                    statusId={statusId}
                    onSave={this.onChangeFromChild}
                  />
                ) : (
                  <div>{apiResponse?.rows?.length > 0 ? apiResponse.rows[0].content : ''}</div>
                )}
              </Col>
            )}
            {type == 'Background Image' && (
              <ManageBackgroundImage
                obituaryId={obituaryId}
                backgroundImageId={bgId}
                backgroundImageUrl={bgUrl}
                backgroundColourCode={bgHexCode}
                setObituaryProfileState={setBackgroundImage}
                access={access}
              />
            )}
            {(type == 'Photos And Videos' || type == 'Tribute Media') && (
              <>
                {type == 'Photos And Videos' && apiResponse && apiResponse.rows && apiResponse.rows.length > 1 && (
                  <Col sm={12} className="text-md-right mb-2">
                    <Button color="primary" onClick={() => this.setState({ reorderModal: true })}>
                      Reorder
                    </Button>
                  </Col>
                )}
                {access && (access.create || access.edit) && (
                  <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                    <ManageMediaCard
                      obituaryId={obituaryId}
                      typeId={typeId}
                      onSave={this.onChangeFromChild}
                      unqKey={-1}
                      age={age}
                      genderId={genderId}
                    />
                  </Col>
                )}
                {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                  ? apiResponse.rows.map((row, i) => (
                      <Col key={`media-card-${i}`} xs={12} sm={12} md={6} lg={4} xl={4}>
                        {rowData && rowData.id == row.id ? (
                          <ManageMediaCard obMedia={rowData} onSave={this.onChangeFromChild} unqKey={row.id} age={age} genderId={genderId} />
                        ) : (
                          <ListMediaCard
                            mediaType={row.mediaType}
                            rowId={row.id}
                            row={row}
                            obituaryId={obituaryId}
                            src={row.mediaUrl}
                            alt={row.media}
                            content={row.content}
                            isLocked={row.lockMedia}
                            modifiedDate={convertToLocalISOString(row.modifiedDate)}
                            onDelete={this.onChangeFromChild}
                            onEditButtonClick={() => this.showManageCard(row)}
                            access={access}
                          />
                        )}
                      </Col>
                    ))
                  : null}
                <Col xs={12}>
                  <ListPagination {...this.setpagination()} />
                </Col>
              </>
            )}
            {type == 'Event' && (
              <ListEvents
                apiResponse={apiResponse}
                setpredicates={this.setpredicates}
                setpagination={this.setpagination}
                obituaryId={obituaryId}
                typeId={typeId}
                access={access}
              />
            )}
            {(type == 'Tribute Message' || type == 'Tribute Card') && (
              <Col>
                <Card>
                  <CardBody className="p-0 pt-3">
                    <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                      <Row>
                        <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                          <DataTable>
                            <THead>
                              <TRow>
                                <DataColHead
                                  {...this.setheadproperties({
                                    title: 'Content',
                                  })}
                                />

                                {type == 'Tribute Card' && (
                                  <DataColHead
                                    {...this.setheadproperties({
                                      title: 'Image',
                                    })}
                                  />
                                )}

                                <DataColHead
                                  {...this.setheadproperties({
                                    title: 'Created By',
                                  })}
                                  width="100"
                                />

                                <DataColHead
                                  {...this.setheadproperties({
                                    title: 'Last Modified Date',
                                  })}
                                  width="110"
                                />

                                <DataColHead
                                  {...this.setheadproperties({
                                    title: 'Action',
                                    filterfield: 'action',
                                    width: 100,
                                    className: 'action',
                                  })}
                                />
                              </TRow>
                            </THead>
                            <TBody>
                              {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                                ? apiResponse.rows.map((row, i) => {
                                    let mediaUrl = '';
                                    if (type == 'Tribute Card') {
                                      mediaUrl = row.mediaUrl + (row.mediaUrl.includes('?') ? `&date=${new Date()}` : `?date=${new Date()}`);
                                    }
                                    return (
                                      <TRow key={`obituary-post-${i}`}>
                                        <TCol>
                                          <div style={{ maxHeight: `${45}px`, overflow: 'hidden' }}>{row.content ? row.content : '-'}</div>
                                        </TCol>

                                        {type == 'Tribute Card' && (
                                          <TCol>
                                            <LinkButton toString="viewTributeCard" toDoFunction={() => this.onLightBoxClick(mediaUrl)}>
                                              <img
                                                data-dz-thumbnail=""
                                                height="50"
                                                className="avatar-sm rounded bg-light"
                                                alt={row.media}
                                                src={mediaUrl}
                                              />
                                            </LinkButton>
                                          </TCol>
                                        )}

                                        <TCol>{row.createdByName}</TCol>

                                        <TCol>
                                          <ViewDate isTableView dateValue={convertToLocalISOString(row.modifiedDate)} />
                                        </TCol>

                                        <TCol>
                                          <div className="action-col">
                                            <span>
                                              {access && access.edit && (
                                                <LinkButton
                                                  toString={`edit${type.replace(/\s/g, '')}`}
                                                  toDoFunction={() => this.openModal('edit', row)}
                                                >
                                                  <i className="fas fa-edit" />
                                                </LinkButton>
                                              )}
                                            </span>
                                            <span>
                                              <LinkButton
                                                title="view"
                                                toString={`view${type.replace(/\s/g, '')}`}
                                                toDoFunction={() => this.openModal('view', row)}
                                              >
                                                <i className="fas fa-eye" />
                                              </LinkButton>
                                            </span>
                                            {access && access.delete && (
                                              <ActionButton
                                                value="delete"
                                                rowId={row.id}
                                                row={row}
                                                url={obituaryId}
                                                setJsonUrl={this.setJsonUrl}
                                                setpredicates={this.setpredicates}
                                                api="obituary-post/"
                                              />
                                            )}
                                          </div>
                                        </TCol>
                                      </TRow>
                                    );
                                  })
                                : null}
                            </TBody>
                          </DataTable>
                          {apiResponse?.rows && apiResponse.rows.length === 0 && (
                            <div className="text-center no-content-block">No results found </div>
                          )}
                        </DataTableHolder>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        {this.state.showLightBox && (
          <Lightbox mainSrc={this.state.imageUrl} clickOutsideToClose enableZoom onCloseRequest={() => this.setState({ showLightBox: false })} />
        )}
        {reorderModal && (
          <CustomModal
            modal={reorderModal}
            toggleModal={this.closeReorderModal}
            title="Rearrange Photos And Videos"
            className="modal-lg"
            buttonList={[
              { name: 'Save', color: 'primary', onClick: this.onReorderConfirm },
              { name: 'Cancel', color: 'secondary' },
            ]}
            maxHeight={400}
          >
            <SortMediaComponent
              renderapi={`obituary-post?obituaryId=${obituaryId}&typeId=${typeId}&isAll=true`}
              updateapi={`obituary-profile/reorder-media/${obituaryId}`}
              sortRef={this.sortRef}
            />
          </CustomModal>
        )}
        {modal.user && modal.status && (
          <CustomModal
            modal={modal.status}
            toggleModal={this.closeModal}
            title={modal.title}
            className="modal-lg"
            maxHeight={400}
            buttonList={modal.buttonList}
          >
            {modal.user === 'view' && rowData && <ViewObituaryPosts access={access} singleRow={rowData} />}
            {modal.user === 'add' && (
              <ManageObituaryPosts
                obituaryId={obituaryId}
                typeId={typeId}
                type={type}
                age={age}
                genderId={genderId}
                requiredFields={requiredFields}
                manageRef={this.manageRef}
              />
            )}
            {modal.user === 'edit' && rowData && (
              <ManageObituaryPosts
                postDetails={this.state.rowData}
                age={age}
                genderId={genderId}
                requiredFields={requiredFields}
                manageRef={this.manageRef}
              />
            )}
          </CustomModal>
        )}
      </React.Fragment>
    );
  }
}

export default ListObituaryPostContainer;
