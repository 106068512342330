import React from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
// import { get } from '../../Libraries/Ajax/httpService';
import Loader from '../../Components/loader';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { DataTable, DataTableHolder, TBody, TCol, THead, TRow } from '../../SharedComponents/Table/tableElements';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
// import ManagePartnerManager from './managePartnerManager';

dayjs.extend(advancedFormat);
class ViewPartnerManagerTransactionDetails extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      partnerManager: null,
      showModal: null,
      paymentId: null,
    };
  }

  months = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  componentDidMount() {
    let paymentId = this.props.payment;
    if (paymentId) {
      let model = {
        partnerManagerPaymentId: paymentId,
      };
      if (model) {
        this.renderApi('partner-manager-transaction', model);
      }
      // this.setState({ partnerManager: partnerManagerId });
    }
  }

  render() {
    let { apiResponse, isLoaderActive } = this.state;
    return (
      <>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                    <div className="row">
                      <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                        <DataTable>
                          <THead>
                            <TRow>
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Month-Year',
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Obituaries Created',
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Total Amount',
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Outstanding Amount',
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Amount Adjusted',
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Balance Outstanding Amount',
                                  className: 'app-l-search-column',
                                })}
                              />
                            </TRow>
                          </THead>
                          <TBody>
                            {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                              ? apiResponse.rows.map((row, i) => (
                                  <TRow key={`monthDetails${i}`}>
                                    <TCol>
                                      {this.months[row.createMonth]} - {row.createYear}
                                    </TCol>
                                    <TCol>{row.totalObituaries} </TCol>
                                    <TCol>€ {row.totalAmount} </TCol>
                                    <TCol>€ {row.previousOutstanding} </TCol>
                                    <TCol>€ {row.amountAdjusted} </TCol>
                                    <TCol>
                                      € {row.previousOutstanding - row.amountAdjusted > 0 ? row.previousOutstanding - row.amountAdjusted : 0}{' '}
                                    </TCol>
                                  </TRow>
                                ))
                              : null}
                          </TBody>
                        </DataTable>
                        {apiResponse && apiResponse.rows?.length === 0 && <div className="text-center no-content-block">No results found </div>}
                      </DataTableHolder>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ViewPartnerManagerTransactionDetails;
