import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MetisMenu from 'metismenujs';
import SimpleBar from 'simplebar-react';
import validator from 'validator';

import adminMenu from '../../SharedComponents/adminMenu';

const MainMenu = ({ name }) => <li className="menu-title">{name}</li>;
const SubMenu = ({ name, url, icon: Icon }) => (
  <li>
    <Link to={url}>
      <Icon />
      <span>{name}</span>
    </Link>
  </li>
);

const SidebarContent = ({ validMenu }) => (
  <div id="sidebar-menu">
    <ul className="metismenu list-unstyled" id="side-menu">
      {validMenu?.map((item) => (
        <>
          <MainMenu {...item} />
          {item.subList.map((subItem) => (
            <SubMenu {...subItem} />
          ))}
        </>
      ))}
    </ul>
  </div>
);

class Sidebar extends Component {
  constructor(props) {
    super(props);
    let userAccess;
    let validMenu = [];
    let menu = adminMenu.map((item) => ({ ...item }));
    if (!props.isAllAccess && props.moduleAccessArray) {
      userAccess = { ...props.moduleAccessArray };
    }

    if (props.isAllAccess) {
      validMenu = menu;
    } else if (userAccess) {
      menu.map((y) => {
        if (y.subList) {
          y.subList = y.subList.filter((x) => {
            if (x && x.subModule) {
              return x.subModule && userAccess[x.subModule] && userAccess[x.subModule].includes(x.permission);
            }
            if (x && x.name === 'Activity in Obituaries') return Object.keys(userAccess).includes('Manage Obituary Profiles');
            return true;
          });
        }
      });
      validMenu = menu.filter((x) => {
        if (x && x.subModule) {
          return x.subModule && userAccess[x.subModule] && userAccess[x.subModule].includes(x.permission);
        }
        if (x.subList) {
          if (x.subList.length == 0) {
            return false;
          }
        }
        return true;
      });
    }
    this.state = {
      validMenu: validMenu || [],
    };
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    let currentPathName = this.props.location.pathname;
    let previousPathName = prevProps.location.pathname;

    if (
      this.props.type !== prevProps.type ||
      (currentPathName.includes('/list-template/') && previousPathName.includes('/list-template/') && currentPathName !== previousPathName) ||
      (currentPathName.includes('/list-obituary-profiles/') &&
        previousPathName.includes('/list-obituary-profiles/') &&
        currentPathName !== previousPathName)
    ) {
      this.initMenu();
    } else {
      let path = window.location.pathname;

      let scrollLocation = `a[href='${path}']`;

      let scrollDashboardto = document.querySelector(scrollLocation);

      if (scrollDashboardto == null) {
        scrollLocation = `a[href='${this.requiredPath(path)}']`;
        scrollDashboardto = document.querySelector(scrollLocation);
      }

      if (scrollDashboardto !== null) scrollDashboardto.scrollIntoView({ block: 'center' });
    }
  }

  requiredPath(pathName) {
    let arrayPath = pathName.split('/');

    arrayPath.shift();

    if (arrayPath.findIndex((element) => validator.isNumeric(element)) >= 0) {
      let idIndex = arrayPath.findIndex((element) => validator.isNumeric(element));
      arrayPath.splice(idIndex);
    }

    arrayPath[0] = arrayPath[0].replace(/^(manage|view|set)/, 'list');

    arrayPath = '/' + arrayPath.join('/');
    return arrayPath;
  }

  initMenu() {
    if (this.props.type !== 'condensed' || this.props.isMobile) {
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      let ul = document.getElementById('side-menu');
      let items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        items[i].classList.remove('mm-active');
        if (this.props.location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
        }
      }
      if (matchingMenuItem) {
        this.activateParentDropdown(matchingMenuItem);
      }
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add('mm-active');
    let parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active'); // li
      let parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        let parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          let parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        {this.props.type !== 'condensed' ? (
          <SimpleBar style={{ maxHeight: '100%' }}>
            <SidebarContent validMenu={this.state.validMenu} />
          </SimpleBar>
        ) : (
          <SidebarContent validMenu={this.state.validMenu} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.Login.userData,
  moduleAccessArray: state.Login.moduleAccessArray,
  isAllAccess: state.Login.isAllAccess,
});
export default withRouter(connect(mapStateToProps, null)(Sidebar));
