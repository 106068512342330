import React from 'react';
import { Row, Col, Button } from 'reactstrap';

import Loader from '../../Components/loader';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import FormInput from '../../SharedComponents/FormHelpers/Controls/formInput';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import CustomDatePicker from '../../SharedComponents/FormHelpers/Controls/customDatePicker';

class ManageAttendSlots extends FormComponent {
  constructor(props) {
    super(props);
    this.setProperties = this.setProperties.bind(this);
    this.setFormProperties = this.setFormProperties.bind(this);
    this.state = {
      singleRow: {},
      apiResponse: {},
      isSubmitted: true,
      action: 'Add',
      editFlag: false,

      startTime: {
        label: 'Start Time',
        type: 'string',
        placeholder: 'Start Time',
        isDefaultSet: false,
        rules: {
          required: true,
        },
      },
      endTime: {
        label: 'End Time',
        type: 'string',
        placeholder: 'End Time',
        relatedPropertyName: 'startTime',
        isDefaultSet: false,
        rules: {
          greater: true,
          required: true,
        },
      },
      capacity: {
        label: 'Capacity',
        type: 'number',
        placeholder: 'Maximum capacity',
        rules: {
          required: true,
          noMoreThan: 9999999,
          noLessThan: '1',
          number: true,
        },
      },
    };
  }

  toast = new ToastNotification();

  componentDidMount() {
    let { slot } = this.props;
    if (slot.startTime && slot.startTime.length > 0) {
      this.setState({ editFlag: true, action: 'Edit' });
      this.setState((prevState) => ({
        startTime: { ...prevState.startTime, value: slot.startTime },
        endTime: { ...prevState.endTime, value: slot.endTime },
        capacity: { ...prevState.capacity, value: slot.capacity },
      }));
    } else {
      this.setDefaultTime();
    }
    this.setState((prevState) => ({
      startTime: {
        ...prevState.startTime,
        rules: { ...prevState.startTime.rules, greater: slot.minStartTime },
      },
      endTime: {
        ...prevState.endTime,
        rules: { ...prevState.endTime.rules, lesser: slot.minEndTime },
      },
    }));
  }

  componentDidUpdate() {
    let { startTime, endTime, capacity } = this.state;
    let { slot } = this.props;
    if (startTime.value && !endTime.value && !endTime.isDefaultSet) {
      this.setDefaultTime();
    }
    if (Object.prototype.hasOwnProperty.call(slot, 'slotFormRef') && slot.slotFormRef.hasUnsavedSlot && slot.slotFormRef.didCallSubmit) {
      let properties = [startTime, endTime, capacity];
      for (let i = 0; i < properties.length; i += 1) {
        if (properties[i].errors.length > 0) {
          slot.slotFormRef.onClose();
          break;
        }
      }
    }
  }

  setDefaultTime = () => {
    let { slot } = this.props;
    let t1 = slot.minStartTime;
    let t2 = slot.minEndTime;
    let offset = (new Date(t2) - new Date(t1)) / 2;
    t2 = new Date(new Date(t1).getTime() + offset).toISOString();
    this.setState((prevState) => ({ startTime: { ...prevState.startTime, value: t1 }, endTime: { ...prevState.endTime, value: t2 } }));
  };

  onSaveButtonClick = (model) => {
    let { slot } = this.props;
    let newSlot = { ...slot };
    this.setState({ isLoaderActive: true });
    newSlot.startTime = model.startTime;
    newSlot.endTime = model.endTime;
    newSlot.capacity = model.capacity;
    newSlot.status = 'list';
    this.props.updateSlot(newSlot);
    this.setState({ isLoaderActive: false });
  };

  onDeleteButtonClick = () => {
    this.props.deleteSlot();
  };

  render() {
    let { isLoaderActive } = this.state;
    let { slot, unqKey } = this.props;

    return (
      <React.Fragment>
        {isLoaderActive === true ? <Loader /> : ''}
        <Row>
          <Col>
            <SubmitForm
              onSubmit={this.onSaveButtonClick}
              {...this.setFormProperties({ disableReactiveForm: true })}
              formRef={slot && slot.slotFormRef ? slot.slotFormRef : null}
            >
              <Row>
                <Col xs={12} md={3}>
                  <CustomDatePicker
                    formGroup
                    appearance="subtle"
                    format="HH:mm"
                    oneTap={false}
                    isDate={false}
                    placement="auto"
                    disableLabel
                    minStartTime={slot.minStartTime}
                    maxEndTime={slot.minEndTime}
                    unqKey={`startTime${unqKey}`}
                    {...this.setProperties(this.state.startTime)}
                  />
                </Col>
                <Col xs={12} md={3}>
                  <CustomDatePicker
                    formGroup
                    appearance="subtle"
                    format="HH:mm"
                    oneTap={false}
                    isDate={false}
                    placement="auto"
                    disableLabel
                    minStartTime={slot.minStartTime}
                    maxEndTime={slot.minEndTime}
                    unqKey={`endTime${unqKey}`}
                    {...this.setProperties(this.state.endTime)}
                  />
                </Col>
                <Col xs={12} md={3}>
                  <FormInput {...this.setProperties(this.state.capacity)} disableLabel unqKey={`capacity${unqKey}`} />
                </Col>
                <Col xs={12} md={3}>
                  <Button className="btn btn-success btn-sm ml-auto mr-1" type="submit">
                    <i className="dripicons-checkmark" />
                  </Button>
                  <Button className="btn btn-danger btn-sm" type="reset" onClick={() => this.onDeleteButtonClick()}>
                    <i className="dripicons-trash" />
                  </Button>
                </Col>
              </Row>
            </SubmitForm>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ManageAttendSlots;
