import React, { Component } from 'react';
import Switch from 'react-switch';
import ToastNotification from '../../../Libraries/ToastNotifications/toastNotification';
import { put } from '../../../Libraries/Ajax/httpService';

export class StatusSwitch extends Component {
  toast = new ToastNotification();

  statusOnChange = () => {
    let { setParentState, name, isListIcon, rowData, updateApi, apiResponse, valueObj } = this.props;
    let obj = {};
    let value = valueObj.on;
    if (value === rowData[name] || value === this.props.value) value = valueObj.off;

    if (isListIcon) {
      if (updateApi && updateApi !== '') {
        rowData[name] = value;
        put(updateApi, rowData)
          .then(() => {
            let newApiResponse = { ...apiResponse };
            let updatedIndex = newApiResponse.rows.findIndex((item) => item.id == rowData.id);
            newApiResponse.rows[updatedIndex][name] = value;
            setParentState({ apiResponse: newApiResponse });
            this.toast.show('status_update_success', 'SUCCESS', 'Status Updated Successfully');
          })
          .catch(() => {
            this.toast.show('status_update_error', 'ERROR', 'Status Updated Failed');
          });
      }
    } else {
      obj[name] = {
        ...this.props,
        value,
      };
      setParentState(obj);
    }
  };

  OnSymbol = ({ isListIcon }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        color: '#fff',
        paddingRight: 2,
        width: 'fit-content',
        marginLeft: '22px',
      }}
    >
      {!isListIcon && 'Active'}
    </div>
  );

  Offsymbol = ({ isListIcon }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        color: '#fff',
        paddingRight: 2,
        width: '0px',
      }}
    >
      {!isListIcon && 'InActive'}
    </div>
  );

  render() {
    let { isListIcon, className, value, valueObj, isDisabled, title } = this.props;

    return (
      <span title={title || `click to ${value == valueObj.off ? 'activate' : 'inactivate'}`}>
        <Switch
          uncheckedIcon={<this.Offsymbol isListIcon={isListIcon} />}
          checkedIcon={<this.OnSymbol isListIcon={isListIcon} />}
          onColor="#626ed4"
          onChange={this.statusOnChange}
          checked={value === valueObj.on}
          width={isListIcon ? 40 : 100}
          height={isListIcon ? 20 : 28}
          className={className}
          disabled={isDisabled}
        />
      </span>
    );
  }
}

export default StatusSwitch;
