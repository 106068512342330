/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

// import { get } from '../../Libraries/Ajax/httpService';
import Loader from '../../Components/loader';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { DataTable, DataTableHolder, TBody, TCol, THead, TRow } from '../../SharedComponents/Table/tableElements';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
import CustomModal from '../../SharedComponents/customModal';
import ViewPartnerManagerTransactionDetails from './viewPartnerManagerTransactionDetails';
import DateRangeFilter from '../../SharedComponents/dateRangeFilter';
// import ManagePartnerManager from './managePartnerManager';

dayjs.extend(advancedFormat);
class ViewPartnerManagerTransactions extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      partnerManager: null,
      showModal: false,
      paymentId: null,
      setPermission: false,
      transactionLabel: true,
    };
  }

  onTransactionDateClick = (paymentId) => {
    this.setState({ showModal: true, paymentId });
  };

  setShowModal = () => {
    this.setState({ showModal: false });
  };

  filterByDate = (dates) => {
    this.setpredicates({ fromDate: dates.fromDate, toDate: dates.toDate });
  };

  componentDidMount() {
    let { isAllAccess, moduleArray, location } = this.props;
    if (isAllAccess || (moduleArray && Object.keys(moduleArray).includes('Partner Managers'))) {
      this.setState({ setPermission: true });
    }
    let path = location.pathname;
    let paths = path.split('/');
    let partnerManagerId = parseInt(paths[2]);
    if (partnerManagerId) {
      let model = {
        partnerManagerId,
      };
      if (model) {
        this.renderApi('partner-manager-payment', model);
      }
      this.setState({ partnerManager: partnerManagerId, showModal: false });
    }
  }

  render() {
    let { apiResponse, setPermission, isLoaderActive, partnerManager, transactionLabel, showModal, paymentId } = this.state;
    return (
      <>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          {apiResponse && (
            <div className="app-l-heading-block">
              <Row className="align-items-center">
                <Col sm={8}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">{setPermission ? 'View Transactions' : 'My Transactions'}</h4>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      {setPermission && (
                        <>
                          <li className="breadcrumb-item">
                            <Link to="/list-partner-managers">Partner Manager</Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={`/view-partner-managers/${partnerManager}`}>Partner Manager Transactions</Link>
                          </li>
                        </>
                      )}{' '}
                      <li className="breadcrumb-item active">View Transactions</li>
                    </ol>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <div className="app-l-filter-holder">
            <DateRangeFilter transactionLabel={transactionLabel} setFilterDates={this.filterByDate} />
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                    <div className="row">
                      <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                        <DataTable>
                          <THead>
                            <TRow>
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Transaction Date',
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Transaction Amount',
                                  className: 'app-l-search-column',
                                  width: 100,
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Remarks',
                                  className: 'app-l-search-column',
                                  searchField: 'emailSearch',
                                })}
                              />
                            </TRow>
                          </THead>
                          <TBody>
                            {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                              ? apiResponse.rows.map((row) => (
                                  <TRow>
                                    <TCol>
                                      <Link onClick={() => this.onTransactionDateClick(row.id)}>
                                        {dayjs(row.partnerPaymentDate).format('DD/MM/YYYY')}
                                      </Link>
                                    </TCol>
                                    <TCol>{row.amount}</TCol>
                                    <TCol>{row.remarks}</TCol>
                                  </TRow>
                                ))
                              : null}
                          </TBody>
                        </DataTable>
                        {apiResponse && apiResponse.rows?.length === 0 && <div className="text-center no-content-block">No results found </div>}
                      </DataTableHolder>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showModal ? (
            <CustomModal modal={showModal} toggleModal={this.setShowModal} title="Transaction Details" hideFooter className="modal-lg">
              <ViewPartnerManagerTransactionDetails payment={paymentId} />
            </CustomModal>
          ) : null}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  moduleArray: state.Login.moduleAccessArray,
  isAllAccess: state.Login.isAllAccess,
});

export default connect(mapStateToProps)(ViewPartnerManagerTransactions);
