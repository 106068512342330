import React, { Component } from 'react';
import { DatePicker } from 'rsuite';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import Validation from '../../../Libraries/Validations/validation';
import 'rsuite/dist/styles/rsuite-default.css';

import ValidationMessage from '../Validation/validationMessage';

class CustomDatePicker extends Component {
  constructor(props) {
    super(props);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.onNativeChange = this.onNativeChange.bind(this);
    this.handleCallBack = this.handleCallBack.bind(this);

    this.onShowCalendar = this.onShowCalendar.bind(this);
    this.state = {};
  }

  validation = new Validation();

  isTouched = false;

  handleOnBlur(e) {
    let properties = { ...this.props, value: e.target.value };
    if (properties.onBlurValidation) {
      properties.onBlurValidation(e, properties);
    }
  }

  static convertDate(str) {
    let date = new Date(str);
    let mnth = `0${date.getMonth() + 1}`.slice(-2);
    let day = `0${date.getDate()}`.slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  static convertTime(str) {
    let date = new Date(str);
    let mnth = `0${date.getMonth() + 1}`.slice(-2);
    let day = `0${date.getDate()}`.slice(-2);
    let time = `${date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`}:${
      date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`
    }:${date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`}`;
    return [date.getFullYear(), mnth, day, time].join('-');
  }

  handleCallBack(start) {
    let startDate;
    if (this.props.isDate) {
      // startDate = start ? CustomDatePicker.convertDate(start) : '';
      startDate = start ? CustomDatePicker.convertTime(start) : '';
      if (start != null) {
        startDate = `${startDate.replace(/(\b-\b)(?!.*\1)/, 'T')}`;
      }
    } else {
      startDate = start ? CustomDatePicker.convertTime(start) : '';
      if (start != null) {
        startDate = `${startDate.replace(/(\b-\b)(?!.*\1)/, 'T')}.000Z`;
      }
    }
    let properties = {
      ...this.props,
      value: startDate,
    };
    if (properties.imposeParentState && properties.imposeParentState.currentFilter) {
      this.props.setParentState({ setChildState: { ...this.props.imposeParentState, currentFilter: null } });
    }
    if (properties.onChangeValidation) {
      properties.onChangeValidation(null, properties);
    }
    this.setState({ value: properties.value });
    // this.setState({ time: properties.value });
  }

  onNativeChange(e) {
    let { onNativeChange, trim } = this.props;

    let properties = { ...this.props, value: e.target.value };
    if (trim) {
      properties.value = properties.value.trim();
    }
    if (!properties.as || properties.as !== 'select') {
      if (properties.onChangeValidation) {
        properties.onChangeValidation(e, properties);
      }
    }
    if (onNativeChange) {
      onNativeChange(e, this.props);
    }
  }

  isValueUpdated = false;

  updateState = (input) => {
    this.setState({ value: input });
  };

  componentDidUpdate() {
    let { imposeParentState, name } = this.props;
    if (!this.isValueUpdated && this.props.options && this.props.options.length > 0 && this.props.disableplaceholder && !this.props.value) {
      let properties = { ...this.props };
      this.isValueUpdated = true;
      let obj = {};
      obj[properties.name] = {
        ...properties,
        value: properties.options[0][properties.optionvalue],
      };
      properties.setParentState(obj);
    }
    if (imposeParentState && imposeParentState.shouldSet) {
      this.updateState(imposeParentState[name]);
      this.props.setParentState({ setChildState: { ...imposeParentState, shouldSet: false } });
    }
  }

  componentDidMount() {
    let { id, name, index } = this.props;

    let input = document.querySelectorAll(`[name="${name}"]`);
    if (id) {
      input = document.querySelectorAll(`[id="${id}"]`);
    }
    if (typeof index !== 'undefined') {
      input = [input[index]];
    }

    input.forEach((element) => {
      element.addEventListener('change', this.onNativeChange);
    });
  }

  onShowCalendar(e) {
    if (!e.target.value) {
      let dateVal = new Date();
      let properties = {
        ...this.props,
        value: `${new Date().getFullYear()}-${dateVal.getMonth() > 8 ? dateVal.getMonth() + 1 : `0${(dateVal.getMonth() + 1).toString()}`}-${
          dateVal.getDate() > 9 ? dateVal.getDate() : `0${dateVal.getDate().toString()}`
        }`,
      };
      if (properties.onChangeValidation) {
        properties.onChangeValidation(null, properties);
      }
      this.setState({ value: properties.value });
    }
  }

  formatDate(dateValue) {
    let date = dateValue.slice(0, 10);
    let time = `${dateValue.slice(0, 10)}T${dateValue.slice(11, 19)}.000Z`;
    let dateVal = new Date(date);
    let timeVal = new Date(time);
    dateVal.setHours(dateVal.getHours() - 5);
    dateVal.setMinutes(dateVal.getMinutes() - 30);
    timeVal.setHours(timeVal.getHours() - 5);
    timeVal.setMinutes(timeVal.getMinutes() - 30);
    let formattedDate;
    if (this.props.isDate) {
      formattedDate = `${dateVal.getFullYear()}-${dateVal.getMonth() > 8 ? dateVal.getMonth() + 1 : `0${(dateVal.getMonth() + 1).toString()}`}-${
        dateVal.getDate() > 9 ? dateVal.getDate() : `0${dateVal.getDate().toString()}`
      }`;
    } else {
      formattedDate = `${timeVal.getFullYear()}-${timeVal.getMonth() > 8 ? timeVal.getMonth() + 1 : `0${(timeVal.getMonth() + 1).toString()}`}-${
        timeVal.getDate() > 9 ? timeVal.getDate() : `0${timeVal.getDate().toString()}`
      } ${timeVal.getHours() > 9 ? timeVal.getHours() : `0${timeVal.getHours().toString()}`}:${
        timeVal.getMinutes() > 9 ? timeVal.getMinutes() : `0${timeVal.getMinutes()}`
      }:${timeVal.getSeconds() > 9 ? timeVal.getSeconds() : `0${timeVal.getSeconds()}`}`;
    }
    return formattedDate;
  }

  onClosePicker = () => {};

  validDate = (date) => {
    let testDate = new Date(date).setHours(0, 0, 0, 0);
    let today = new Date().setHours(0, 0, 0, 0);
    let { maxDate, minDate, futureDate } = this.props;
    let maxLimit;
    let minLimit;
    if (maxDate) maxLimit = new Date(maxDate).setHours(0, 0, 0, 0);
    if (minDate) minLimit = new Date(minDate).setHours(0, 0, 0, 0);
    if (futureDate === false) {
      if (maxLimit && maxLimit < today) {
        if (testDate > maxLimit) {
          return true;
        }
      } else if (minLimit && minLimit < today) {
        if (testDate < minLimit || testDate > today) {
          return true;
        }
      } else if (testDate > today) {
        return true;
      }
    } else if (futureDate === true) {
      if (minLimit && minLimit > today) {
        if (testDate < minLimit) {
          return true;
        }
      } else if (maxLimit && maxLimit > today) {
        if (testDate > maxLimit || testDate < today) {
          return true;
        }
      } else if (testDate < today) {
        return true;
      }
    } else {
      return false;
    }
    return false;
  };

  validHour = (hour) => {
    let { minStartTime, maxEndTime } = this.props;
    if (minStartTime && maxEndTime) {
      if (hour < minStartTime.slice(11, 13) || hour > maxEndTime.slice(11, 13)) {
        return true;
      }
    } else if (minStartTime) {
      if (hour < minStartTime.slice(11, 13)) {
        return true;
      }
    } else if (maxEndTime) {
      if (hour > maxEndTime.slice(11, 13)) {
        return true;
      }
    }
    return false;
  };

  validMinute = (minute, date) => {
    let { minStartTime, maxEndTime } = this.props;
    if (minStartTime && maxEndTime) {
      let hour = null;
      if (date.getHours) {
        hour = date.getHours();
      } else {
        hour = new Date(date).getHours();
      }
      if (minStartTime.slice(11, 13) == maxEndTime.slice(11, 13)) {
        if (minute < minStartTime.slice(14, 16) || minute > maxEndTime.slice(14, 16)) {
          return true;
        }
      } else if (hour == minStartTime.slice(11, 13)) {
        if (minute < minStartTime.slice(14, 16)) {
          return true;
        }
      } else if (hour == maxEndTime.slice(11, 13)) {
        if (minute > maxEndTime.slice(14, 16)) {
          return true;
        }
      } else {
        return false;
      }
    }
    return false;
  };

  render() {
    let dateString = null;
    // let minDate = '';
    let message = '';

    let {
      name,
      style,
      value,
      isTouched,
      isFocused,
      formGroup,
      appearance,
      placement,
      format,
      oneTap,
      disableRequiredsymbol,
      isSilent,
      errors: [errFromProp] = [],
    } = this.props;
    let styles = style;
    // if (minDate) {
    //   minDate = this.formatDate(minDate, true);
    // }
    if (errFromProp) {
      ({ message } = errFromProp);
    }
    if (value) {
      dateString = this.formatDate(value);
    }
    if (this.state.value) {
      dateString = this.formatDate(this.state.value);
    }
    let messagetype = 'i';
    if (isTouched) {
      messagetype = 'e';
    }
    if (!isFocused || isSilent) {
      messagetype = '';
    }
    let { rules, label, disableLabel, placeholder, isDisabled } = this.props;
    return (
      <div className={formGroup ? 'form-group' : ''}>
        {!disableLabel ? (
          <Form.Label>
            {label}
            {rules && rules.required && !disableRequiredsymbol ? <sup>*</sup> : ''}
          </Form.Label>
        ) : (
          ''
        )}

        <DatePicker
          name={name}
          oneTap={oneTap}
          size="xs"
          placeholder={placeholder || 'select'}
          disabledDate={(date) => this.validDate(date)}
          hideHours={(hour, date) => this.validHour(hour, date)}
          disabledHours={(hour, date) => this.validHour(hour, date)}
          hideMinutes={(minute, date) => this.validMinute(minute, date)}
          disabledMinutes={(minute, date) => this.validMinute(minute, date)}
          style={styles}
          appearance={appearance}
          onChange={(e) => this.handleCallBack(e)}
          ranges={[]}
          placement={placement || 'bottomStart'}
          value={`${dateString || ''}`}
          format={format}
          onClean={(e) => this.onClosePicker(e)}
          className="has-success form-control"
          disabled={!!isDisabled}
        />
        {messagetype ? <ValidationMessage {...{ message }} /> : <span className="app-c-validation-message" />}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomDatePicker);
