let globalIndex = 0;

export const LazyRun = (fn, timeout) => {
  timeout = timeout || 500;
  globalIndex += 1;
  let localIndex = globalIndex;
  setTimeout(() => {
    if (localIndex === globalIndex && fn) {
      fn();
    }
  }, timeout);
};
let globalUploadIndex = 0;
export const LazyParallelRun = (fn, timeout) => {
  timeout = timeout || 7000;

  if (globalUploadIndex < 1 && fn) {
    try {
      globalUploadIndex += 1;
      fn(() => {
        globalUploadIndex += 1;
      });
    } catch (e) {
      globalUploadIndex += 1;
    }
  } else {
    setTimeout(() => {
      LazyParallelRun(fn, timeout);
    }, timeout);
  }
};
