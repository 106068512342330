import React from 'react';
import { Row, Col, Button, Card, CardBody } from 'reactstrap';

const ListAttendSlots = (props) => {
  let { slot, setSlotEditable, deleteSlot } = props;

  let onEditButtonClick = () => {
    setSlotEditable();
  };

  let onDeleteButtonClick = () => {
    deleteSlot();
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
              <div>
                <b>Start Time:- </b>
                <span>{slot.startTime.slice(11, 16)}</span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
              <div>
                <b>End Time:- </b>
                <span>{slot.endTime.slice(11, 16)}</span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
              <div>
                <b>Capacity:- </b>
                <span>{slot.capacity}</span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={3} xl={3}>
              <Button color="primary" type="button" className="btn btn-success btn-sm ml-auto mr-1" onClick={() => onEditButtonClick()}>
                <i className="dripicons-document-edit" />
              </Button>
              <Button color="danger" type="button" className="btn btn-danger btn-sm" onClick={() => onDeleteButtonClick()}>
                <i className="dripicons-trash" />
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ListAttendSlots;
