import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';

import Validation from '../../../Libraries/Validations/validation';
import ValidationMessage from '../Validation/validationMessage';
import FormComponent from '../Form/formComponent';

class FormInput extends FormComponent {
  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.onNativeChange = this.onNativeChange.bind(this);
    this.state = {
      message: '',
      messagetype: 'i',
      setTextAreaStyle: false,
    };
  }

  validation = new Validation();

  isTouched = false;

  handleOnBlur(e) {
    let properties = { ...this.props, value: e.target.value };
    if (properties.onBlurValidation) {
      properties.onBlurValidation(e, properties);
    }
  }

  handleOnChange(e, props) {
    let properties = {
      ...this.props,
      value: e && e.target ? e.target.value : '',
    };
    if (properties.multiselect) {
      properties.value = props.values;
    } else if (properties.as && properties.as === 'select') {
      // if (properties.isTextValue) {
      // } else {
      properties.value = Number(properties.value);
      // }
      let filteredArray = properties.options.filter((item) => item[properties.optionvalue] === properties.value);
      if (filteredArray.length > 0) {
        let [first] = filteredArray;
        properties.item = first;
      }
    }
    if (properties.as && properties.as === 'textarea' && properties.id) {
      let element = document.querySelector(`[id="${properties.id}"]`);
      if (/autoGrow/i.test(element.id)) {
        let height = element.scrollHeight;
        let scrollStartHgt = properties.scrollStartHeight ? properties.scrollStartHeight : 120;
        element.style.height = 'auto';
        if (height > scrollStartHgt) {
          element.style.overflow = 'auto';
          element.style.height = `${scrollStartHgt}px`;
        } else {
          element.style.overflow = 'hidden';
          element.style.height = `${element.scrollHeight}px`;
        }
      }
    }
    if (properties.onChangeValidation) {
      properties.onChangeValidation(e, properties);
    }
  }

  onNativeChange(e) {
    let { onNativeChange, trim } = this.props;

    let properties = { ...this.props, value: e.target.value };
    if (trim) {
      properties.value = properties.value.trim();
    }
    if (!properties.as || properties.as !== 'select') {
      if (properties.onChangeValidation) {
        properties.onChangeValidation(e, properties);
      }
    }
    if (onNativeChange) {
      onNativeChange(e, this.props);
    }
  }

  isValueUpdated = false;

  updateTextAreaStyle = () => {
    this.setState((prevState) => ({ setTextAreaStyle: !prevState.setTextAreaStyle }));
  };

  componentDidUpdate() {
    let { setTextAreaStyle } = this.state;
    let { value, id, scrollStartHeight } = this.props;
    if (!this.isValueUpdated && this.props.options && this.props.options.length > 0 && this.props.disableplaceholder && !this.props.value) {
      let properties = { ...this.props };
      this.isValueUpdated = true;
      let obj = {};
      obj[properties.name] = {
        ...properties,
        value: properties.options[0][properties.optionvalue],
      };
      properties.setParentState(obj);
    }
    if (setTextAreaStyle && value && value.length > 0) {
      let scrollStartHgt = scrollStartHeight || 120;
      let element = document.querySelector(`[id="${id}"]`);
      if (/autoGrow/i.test(element.id)) {
        if (element.scrollHeight > scrollStartHgt) {
          element.style.overflow = 'auto';
          element.style.height = 'auto';
          element.style.height = `${scrollStartHgt}px`;
        } else {
          element.style.overflow = 'hidden';
          element.style.height = 'auto';
          element.style.height = `${element.scrollHeight}px`;
        }
      }
      this.updateTextAreaStyle();
    }
  }

  componentDidMount() {
    let { id, name, index } = this.props;

    let input = document.querySelectorAll(`[name="${name}"]`);
    if (id) {
      input = document.querySelectorAll(`[id="${id}"]`);
      if (/autoGrow/i.test(id)) this.updateTextAreaStyle();
    }
    if (typeof index !== 'undefined') {
      input = [input[index]];
    }
    input.forEach((element) => {
      element.addEventListener('change', this.onNativeChange);
    });
  }

  render() {
    let message = '';

    if (this.props.errors && this.props.errors.length > 0) {
      message = this.props.errors[0] && this.props.errors[0].message;
    }
    let messagetype = 'i';
    if (this.props.isTouched) {
      messagetype = 'e';
    }
    if (!this.props.isFocused || this.props.isSilent) {
      messagetype = '';
    }
    let {
      onFocus,
      className,
      disableFormGroup,
      ref,
      id,
      label,
      type,
      placeholder,
      name,
      value,
      as,
      controlId,
      optionvalue,
      optiontext,
      options,
      rules,
      placeholderValue,
      disableLabel,
      disabled,
      autoComplete,
      list,
      materialInput,
      mainDivClass,
      rows,
      title,
    } = this.props;
    let inputproperties = {
      label,
      type,
      placeholder,
      name,
      value,
      autoComplete,
      list,
    };
    if (ref) {
      inputproperties.ref = ref;
    }
    if (id) {
      inputproperties.id = id;
    }
    if (as === 'textarea') {
      inputproperties.as = as;
      inputproperties.rows = rows;
    }
    if (as === 'select' && placeholder === `Enter ${label}`) {
      placeholder = `Select ${label}`;
    }
    if (!onFocus) {
      onFocus = () => {};
    }
    if (autoComplete === 'off') {
      inputproperties.autoComplete = 'off';
    }
    if (list === 'autocompleteOff') {
      inputproperties.list = 'autocompleteOff';
    }
    if (type === 'hidden') {
      disableLabel = true;
    }
    if (materialInput) {
      disableLabel = true;
    }
    if (type === 'date' && value) {
      let [first] = value.split('T');
      inputproperties.value = first;
    }
    if (type === 'number') inputproperties.type = 'text';
    return (
      <div className={disableFormGroup ? '' : 'form-group'}>
        {!disableLabel ? (
          <Form.Label>
            {label}
            {rules && rules.required && !this.props.disableRequiredsymbol ? <sup>*</sup> : ''}
          </Form.Label>
        ) : (
          ''
        )}

        {as === 'select' ? (
          <Form.Control
            value={value}
            as="select"
            name={name}
            disabled={disabled}
            controlid={controlId}
            className={className || (`${this.state.message}` ? ' has-error' : ' has-success')}
            placeholder={placeholder}
            onChange={this.handleOnChange}
            onBlur={this.handleOnBlur}
          >
            {placeholder ? <option value={placeholderValue || ''}>{placeholder}</option> : ''}

            {options.map((option) => (
              <option disabled={option.disabled} key={option[optionvalue]} value={option[optionvalue]}>
                {option[optiontext]}
              </option>
            ))}
          </Form.Control>
        ) : (
          <div className={mainDivClass || ''} title={title}>
            {' '}
            <Form.Control
              {...inputproperties}
              className={className || (`${this.state.message}` ? ' has-error' : ' has-success')}
              placeholder={placeholder}
              onChange={this.handleOnChange}
              onFocus={onFocus}
              onBlur={this.handleOnBlur}
              disabled={disabled}
            />
            {rules?.currency && <i>€</i>}
            {rules?.percentage && <i>%</i>}
          </div>
        )}
        {materialInput && <Form.Label>{label}</Form.Label>}

        {messagetype ? <ValidationMessage {...{ message }} {...{ type: messagetype }} /> : <span className="app-c-validation-message" />}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(FormInput);
