import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Card, CardBody, FormGroup, Modal } from 'reactstrap';
import { get, post, put } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import { ActionButton } from '../../SharedComponents/FormHelpers/Controls/actionButton';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { DataTable, DataTableHolder, TBody, TCol, THead, TRow } from '../../SharedComponents/Table/tableElements';
import CustomComponentDropdown from '../../SharedComponents/FormHelpers/Controls/customComponentDropdown';
import CreateUserCustomModal from '../User/createUserCustomModal';
import LinkButton from '../../SharedComponents/linkButton';

export class PermissionSettings extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      settingsModal: false,
      addPermissionsModal: false,
      editPermissionsModal: false,
      accessUsers: {},
      userList: {},
      userId: 0,
      obituaryId: this.props.obituaryRowid,
      profileAdminID: 0,
      newUserModalOpen: false,
      createdItemState: 'userId',
    };
    this.addPermissions = this.addPermissions.bind(this);
    this.onEditPermissions = this.onEditPermissions.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.onEditButtonClick = this.onEditButtonClick.bind(this);
    this.setpredicates = this.setpredicates.bind(this);
  }

  toast = new ToastNotification();

  onUserDropDownChange = (item) => {
    this.setState({ userId: item?.value ? item.value : 0 });
  };

  onEditPermissions() {
    this.setState((prevState) => ({
      editPermissionsModal: !prevState.editPermissionsModal,
    }));
  }

  onEditButtonClick() {
    let model = {
      userId: parseInt(this.state.userId),
      obituaryId: parseInt(this.state.obituaryId),
    };
    if (model.userId && model.obituaryId) {
      put(`profile-admin/${this.state.profileAdminID}`, model)
        .then((res) => {
          this.getPermissionList();
          if (res) {
            this.toast.show('create_success', 'SUCCESS', 'Permission Updated');
          }
          this.setState((prevState) => ({
            editPermissionsModal: !prevState.editPermissionsModal,
          }));
        })
        .catch((err) => {
          if (err.message) this.toast.show('create_error', 'ERROR', err.message);
        });
    } else this.toast.show('create_error', 'ERROR', 'Select User');
  }

  addPermissions() {
    this.setState((prevState) => ({
      addPermissionsModal: !prevState.addPermissionsModal,
    }));
  }

  onAddButtonClick = () => {
    let model = {
      userId: parseInt(this.state.userId),
      obituaryId: parseInt(this.props.obituaryRowId),
    };

    post('profile-admin', model)
      .then((response) => {
        this.getPermissionList();
        this.setState({ addPermissionsModal: false });
        this.toast.show('create_success', 'SUCCESS', response.data.message);
      })
      .catch((err) => {
        if (err.message) this.toast.show('create_error', 'ERROR', err.message);
      });
  };

  getPermissionList = () => {
    let model = {
      obituaryId: parseInt(this.props.obituaryRowId),
      isAvoidRejected: true,
    };
    get('profile-admin', model).then((response) => {
      this.setState({ accessUsers: response.data.data });
    });
  };

  componentDidMount() {
    this.getPermissionList();
  }

  toggleUserModal = () => {
    this.setState((prevState) => ({
      newUserModalOpen: !prevState.newUserModalOpen,
    }));
  };

  render() {
    let { accessUsers, userId, newUserModalOpen, createdItemState } = this.state;
    let {
      showCheck,
      toggleState,
      stateKey,
      // obituaryRowId
      access,
    } = this.props;
    return (
      <div className="container-fluid">
        <Modal className="modal-lg" isOpen={showCheck} scrollable toggle={() => toggleState(stateKey)}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Obituary Profile Permissions
            </h5>
            <button
              onClick={() => {
                toggleState(stateKey);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Card className="enable-scroll">
            <CardBody>
              <Row className="align-items-center pb-4">
                <Col sm={6}>
                  <p className="mb-0">The users with access to this profile</p>
                </Col>
                {access && access.create && (
                  <Col sm={6}>
                    <div className="float-right d-md-block">
                      <LinkButton
                        toString="addPermission"
                        className="btn btn-primary waves-effect waves-light"
                        role="button"
                        toDoFunction={this.addPermissions}
                      >
                        Add Permission
                      </LinkButton>
                      &nbsp;
                    </div>
                  </Col>
                )}
              </Row>
              <div className="row">
                <DataTableHolder pagination={<ListPagination {...this.setpagination()} />} className="min-160">
                  <DataTable>
                    <THead>
                      <TRow>
                        <DataColHead
                          {...this.setheadproperties({
                            title: 'Name',
                          })}
                        />
                        <DataColHead
                          {...this.setheadproperties({
                            title: 'Email',
                            width: 140,
                          })}
                        />
                        <DataColHead
                          {...this.setheadproperties({
                            title: 'Role',
                          })}
                        />
                        {access && access.delete && (
                          <DataColHead
                            {...this.setheadproperties({
                              title: 'Action',
                              width: 60,
                              className: 'action',
                            })}
                          />
                        )}
                      </TRow>
                    </THead>
                    <TBody>
                      {accessUsers && accessUsers.rows && accessUsers.rows.length > 0 ? (
                        accessUsers.rows.map((row, i) => (
                          <TRow key={`access-user-${i}`}>
                            <TCol>{row.userName}</TCol>
                            <TCol>{row.userEmail}</TCol>
                            <TCol>{row.userRoleTitle} </TCol>
                            {access && access.delete && (
                              <TCol>
                                <div className="action-col">
                                  {' '}
                                  <ActionButton value="delete" rowId={row.id} api="profile-admin/" renderList={this.getPermissionList} />
                                </div>{' '}
                              </TCol>
                            )}
                          </TRow>
                        ))
                      ) : (
                        <TRow>
                          <TCol colSpan="4">
                            <div className="text-center no-content-block">No permissions found </div>
                          </TCol>
                        </TRow>
                      )}
                    </TBody>
                  </DataTable>
                </DataTableHolder>
              </div>
            </CardBody>
          </Card>
        </Modal>
        <Modal isOpen={this.state.addPermissionsModal} toggle={this.addPermissions}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Add Permission
            </h5>
            <button
              onClick={() => this.setState({ addPermissionsModal: false })}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Select an user to give permission to this profile</p>
            <Row>
              <Col xs={12} md={12}>
                <CustomComponentDropdown
                  {...{
                    disableLabel: true,
                    name: 'userId',
                    optionvalue: 'id',
                    rules: { required: true },
                    renderapi: 'user',
                    apiModel: { isAdminType: true, checkStatus: true },
                    imageKey: 'userImageUrl',
                    subText: 'email',
                    mainText: 'name',
                    createNewText: 'Create a new user...',
                  }}
                  customOnchangeHandler={this.onUserDropDownChange}
                  toggleModal={this.toggleUserModal}
                  setParentState={this.setParentState}
                />
              </Col>
            </Row>
            <FormGroup className="mb-0">
              <div className="float-right d-md-block">
                <br />
                <Button type="submit" color="primary" className="mr-1" onClick={this.onAddButtonClick}>
                  Add
                </Button>{' '}
                <Button className="btn-secondary" type="reset" onClick={() => this.setState({ addPermissionsModal: false })}>
                  <i className="icon-close"> </i> <span> Cancel </span>
                </Button>
              </div>
            </FormGroup>
          </div>
        </Modal>
        <Modal isOpen={this.state.editPermissionsModal} toggle={this.editPermissions}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Edit Permission
            </h5>
            <button
              onClick={() => this.setState({ editPermissionsModal: false })}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Edit obituary name and user name </p>
            <Row>
              <Col xs={12} md={12}>
                <CustomComponentDropdown
                  {...{
                    disableLabel: true,
                    name: 'userId',
                    optionvalue: 'id',
                    rules: { required: true },
                    renderapi: 'user',
                    apiModel: { isAdminType: true, checkStatus: true },
                    imageKey: 'userImageUrl',
                    subText: 'email',
                    mainText: 'name',
                    createNewText: 'Create a new user...',
                    value: userId,
                  }}
                  customOnchangeHandler={this.onUserDropDownChange}
                  toggleModal={this.toggleUserModal}
                  setParentState={this.setParentState}
                />
              </Col>
            </Row>
            <FormGroup className="mb-0">
              <div className="float-right d-md-block">
                <br />
                <Button type="submit" color="primary" className="mr-1" onClick={this.onEditButtonClick}>
                  Edit
                </Button>{' '}
                <Button className="btn-secondary" type="reset" onClick={() => this.setState({ editPermissionsModal: false })}>
                  <i className="icon-close"> </i> <span> Cancel </span>
                </Button>
              </div>
            </FormGroup>
          </div>
        </Modal>
        {newUserModalOpen && (
          <CreateUserCustomModal
            modal={newUserModalOpen}
            toggleModal={this.toggleUserModal}
            title="Create New User"
            createdItemState={createdItemState}
            setParentState={this.setParentState}
            isSingleNotObjectState
          />
        )}
      </div>
    );
  }
}
export default PermissionSettings;
