import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

import { removeObjectAndFunctionAttributes } from '../../Libraries/ArrayHelpers/arrayHelperFunctions';
import { BackButtonComponent } from '../ListComponents/components';

export const DataTable = (props) => (
  <>
    <table {...removeObjectAndFunctionAttributes(props)} className={`table table-bordered table-striped dataTable table-nowrap ${props.className}`}>
      {props.children}
    </table>
  </>
);

export const TBody = (props) => <tbody {...removeObjectAndFunctionAttributes(props)}>{props.children}</tbody>;

export const TCol = (props) => (
  <td {...removeObjectAndFunctionAttributes(props)} onClick={props.onColClick} style={props.style}>
    {props.children}
  </td>
);

export const TColBadge = (props) => {
  let { field, value, onColClick } = props;
  let variant = '';
  if (field === 'EmailStatus') {
    if (value === 'Confirmed') {
      variant = 'success';
    } else if (value === 'Rejected') {
      variant = 'danger';
    } else if (value === 'Pending') {
      variant = 'secondary';
    } else variant = 'info';
  }
  return (
    <td {...removeObjectAndFunctionAttributes(props)} className="table-badge" onClick={onColClick}>
      <Badge color={variant}>{value}</Badge>
    </td>
  );
};

export class TColActionDate extends Component {
  formatDate = (dateValue) => {
    if (dateValue) {
      return `${dateValue.split('T')[0].split('-')[2]}/${dateValue.split('T')[0].split('-')[1]}/${dateValue.split('T')[0].split('-')[0]}`;
    }
    return '';
  };

  render() {
    let { value, onColClick } = this.props;
    let colValue = '';
    let isPublished = false;
    if (value.contentStatusId === 3) {
      // Published Content
      colValue = this.formatDate(value.publishedDate);
      isPublished = true;
    } else if (value.jobDetailsStatusId === 2) {
      // Published job details
      colValue = this.formatDate(value.publishedDate);
      isPublished = true;
    }
    if (!isPublished) {
      if (value.contentStatusId === 2) {
        // Created
        colValue = this.formatDate(value.createdDate);
      } else if (value.jobDetailsStatusId === 1) {
        // Created job details
        colValue = this.formatDate(value.createdDate);
        isPublished = true;
      }
    }
    return (
      <td {...removeObjectAndFunctionAttributes(this.props)} onClick={onColClick}>
        {colValue}
      </td>
    );
  }
}

export const ThCol = (props) => (
  <th scope="col" {...removeObjectAndFunctionAttributes(props)}>
    {props.children}
  </th>
);

export const TRow = (props) => {
  let { onRowClick, children } = props;
  // let {viewDisabled} = this.props;
  return (
    <tr
      style={
        {
          // cursor: viewDisabled === true ? 'default' : 'pointer',
        }
      }
      {...removeObjectAndFunctionAttributes(props)}
      onClick={onRowClick}
    >
      {children}
    </tr>
  );
};

export const THead = (props) => <thead {...removeObjectAndFunctionAttributes(props)}>{props.children}</thead>;

export const Breadcrumbs = ({ pageHeading }) => (
  <ol className="breadcrumb mb-0">
    <li className="breadcrumb-item">
      <Link to="/#">Home</Link>
    </li>
    <li className="breadcrumb-item active">{pageHeading}</li>
  </ol>
);

export const HeadingBlock = ({ children, title, backButtonNeeded, history, customBreadcrumbsTitle }) => (
  <div className="page-title-box">
    <div className="app-l-heading-block">
      <div className="app-l-left-block">
        <h4>
          {backButtonNeeded ? <BackButtonComponent history={history} /> : null}
          {title}
        </h4>
        <Breadcrumbs pageHeading={customBreadcrumbsTitle || title} />
      </div>
      {children}
    </div>
  </div>
);

export const DataTableHolder = ({ pagination, children, className }) => (
  <div data-test="datatable-table" className="col-sm-12">
    <div data-test="table" className={`table-responsive ${className || ''}`}>
      {children}
    </div>
    {pagination}
  </div>
);
