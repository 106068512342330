import React, { Component } from 'react';
import { Row, Col, Button, Card, CardBody } from 'reactstrap';
import dayjs from 'dayjs';
import ManageAttendSlots from './manageAttendSlot';
import ListAttendSlots from './listAttendSlot';
import { get } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';

class SlotManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slotsArray: [],
      disableNewSlots: false,
    };
    this.deletedSlots = [];
  }

  toast = new ToastNotification();

  componentDidMount() {
    let { eventId, sendSlotDetails, eventStartTime } = this.props;
    let model = {
      isAll: true,
      obituaryPostId: eventId,
    };
    get('attend-slot', model).then((response) => {
      if (response.data.data && response.data.success) {
        if (response.data.data.rows && response.data.data.rows.length > 0) {
          let arr = [...response.data.data.rows];
          let today = dayjs();
          arr.forEach((row, i) => {
            arr[i].startTime = `${today.format('YYYY-MM-DDT') + row.startTime}.000Z`;
            arr[i].endTime = `${today.format('YYYY-MM-DDT') + row.endTime}.000Z`;
            delete arr[i].createdDate;
            delete arr[i].modifiedDate;
            arr[i].isDelete = false;
            arr[i].minStartTime = arr[i].startTime;
            arr[i].minEndTime = arr[i].endTime;
            arr[i].status = 'list';
          });
          sendSlotDetails(arr);
          this.setState({ slotsArray: arr });
        } else if (eventStartTime && eventStartTime.length > 0) {
          this.onAddSlot(eventStartTime);
        }
      } else {
        this.toast.show('fetch_error', 'ERROR', "Couldn't fetch slots.");
      }
    });
  }

  componentDidUpdate() {
    let { slotsArray } = this.state;
    let { eventStartTime, eventEndTime } = this.props;
    let arr = [...slotsArray];
    let size = slotsArray.length;

    if (slotsArray.length > 0) {
      if (slotsArray[0].minStartTime !== eventStartTime) {
        arr[0].minStartTime = eventStartTime;
        this.onComponentUpdate(arr);
      }
      if (slotsArray[size - 1].minEndTime !== eventEndTime) {
        arr[size - 1].minEndTime = eventEndTime;
        this.onComponentUpdate(arr);
      }
    }
  }

  onComponentUpdate = (arr) => {
    this.setState({ slotsArray: arr });
  };

  onUpdateSlot = (i, slot) => {
    let { slotsArray } = this.state;
    let { sendSlotDetails, slotFormRef } = this.props;
    let arr = [...slotsArray];
    if (Object.prototype.hasOwnProperty.call(slotsArray[i], 'slotFormRef')) {
      delete slot.slotFormRef;
      slotFormRef.hasUnsavedSlot = false;
    }
    arr.splice(i, 1, slot);
    if (slotsArray[i].capacity === null) {
      this.setState({ slotsArray: arr, disableNewSlots: false });
    } else {
      this.setState({ slotsArray: arr });
    }
    sendSlotDetails([...arr, ...this.deletedSlots]);
    if (slotFormRef.didCallSubmit) {
      slotFormRef.onClose();
    }
  };

  onAddSlot = (startTime = null) => {
    let { slotsArray } = this.state;
    let { eventId, bottomRef, slotFormRef } = this.props;
    slotFormRef.hasUnsavedSlot = true;
    bottomRef.current.scrollIntoView();
    let newSlot = {
      obituaryPostId: eventId,
      startTime,
      endTime: null,
      capacity: null,
      minStartTime: null,
      minEndTime: null,
      status: 'edit',
      slotFormRef,
    };
    if (slotsArray && slotsArray.length > 0) {
      this.setState({
        slotsArray: [...slotsArray, newSlot],
        disableNewSlots: true,
      });
    } else {
      this.setState({ slotsArray: [newSlot], disableNewSlots: true });
    }
  };

  onDeleteSlot = (i) => {
    let { slotsArray } = this.state;
    let { sendSlotDetails, slotFormRef } = this.props;
    let arr = [...slotsArray];
    if (Object.prototype.hasOwnProperty.call(slotsArray[i], 'id') && slotsArray[i].id > 0) {
      arr[i].isDelete = true;
      this.deletedSlots.push(...arr.splice(i, 1));
    } else {
      if (Object.prototype.hasOwnProperty.call(slotsArray[i], 'slotFormRef')) {
        slotFormRef.hasUnsavedSlot = false;
      }
      arr.splice(i, 1);
    }
    if (slotsArray[i].capacity === null) {
      this.setState({ slotsArray: arr, disableNewSlots: false });
    } else {
      this.setState({ slotsArray: arr });
    }
    sendSlotDetails([...arr, ...this.deletedSlots]);
  };

  onChangeSlotStatus = (i) => {
    let { slotsArray } = this.state;
    let arr = [...slotsArray];
    arr[i].status = 'edit';
    this.setState({ slotsArray: arr });
  };

  render() {
    let { slotsArray, disableNewSlots } = this.state;
    let { eventStartTime, eventEndTime } = this.props;
    let arr = [...slotsArray];

    // setting minStartTimes
    arr.forEach((row, i) => {
      if (i === 0) {
        arr[i].minStartTime = eventStartTime;
      } else {
        arr[i].minStartTime = arr[i - 1].endTime;
      }
    });

    // setting minEndTimes
    arr.reverse().forEach((row, i) => {
      if (i === 0) {
        arr[i].minEndTime = eventEndTime;
      } else if (arr[i - 1].startTime) {
        arr[i].minEndTime = arr[i - 1].startTime;
      } else {
        arr[i].minEndTime = arr[i - 1].minEndTime;
      }
    });

    // reversing array to compensate earlier reverse
    arr.reverse();

    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="float-right d-block">
                  <Button
                    color="primary"
                    type="button"
                    className={`mb-4 ${disableNewSlots ? 'disabled-button' : ''}`}
                    onClick={() => (disableNewSlots ? {} : this.onAddSlot())}
                    title={disableNewSlots ? 'Save current slot details to add new slot' : 'Add new slot'}
                  >
                    Add Slot
                  </Button>
                </div>
              </Col>
              {arr && arr.length > 0 ? (
                arr.map((row, i) => (
                  <Col xs={12} key={`time-slot-${i}`}>
                    {row.status === 'list' ? (
                      <ListAttendSlots slot={row} setSlotEditable={() => this.onChangeSlotStatus(i)} deleteSlot={() => this.onDeleteSlot(i)} />
                    ) : (
                      <ManageAttendSlots
                        slot={row}
                        unqKey={i}
                        updateSlot={(slot) => this.onUpdateSlot(i, slot)}
                        deleteSlot={() => this.onDeleteSlot(i)}
                      />
                    )}
                  </Col>
                ))
              ) : (
                <Col xs={12}>
                  <p>No slots created</p>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default SlotManagement;
