/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, FormGroup, Row } from 'reactstrap';

import Loader from '../../Components/loader';
import { get, post, put } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import CustomComponentDropdown from '../../SharedComponents/FormHelpers/Controls/customComponentDropdown';
import SearchableDropDown from '../../SharedComponents/FormHelpers/Controls/searchableDropdown';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import CreateUserCustomModal from '../User/createUserCustomModal';

class SetPermission extends FormComponent {
  constructor(props) {
    super(props);
    this.setProperties = this.setProperties.bind(this);
    this.setFormProperties = this.setFormProperties.bind(this);
    this.state = {
      isSubmitted: true,
      singleRow: {},
      action: 'Add',
      editFlag: false,

      obituaryId: {
        label: 'Obituary Profile',
        renderapi: 'obituary-profile?isAll=true',
        placeholder: 'Search Obituary Profile',
        className: 'mb-0',
        controlId: 'exampleForm.ControlSelect1',
        name: 'obituaryId',
        optionvalue: 'id',
        optiontext: 'name',
        rules: {
          required: true,
        },
        imageKey: 'mediaUrl',
        mainText: 'name',
      },

      userId: {
        label: 'User',
        renderapi: 'user',
        placeholder: 'Search User',
        name: 'userId',
        optionvalue: 'id',
        rules: {
          required: true,
        },
        apiModel: { isActive: true, isAdminType: true, checkStatus: true },
        imageKey: 'userImageUrl',
        subText: 'email',
        mainText: 'name',
        createNewText: 'Create a new user...',
      },

      newUserModalOpen: false,
      createdItemState: '',
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ editFlag: true });
      this.setState({ action: 'Edit' });
      get(`profile-admin/${this.props.match.params.id}`).then((response) => {
        this.setState({ singleRow: response.data.data });
      });
    }
  }

  componentDidUpdate() {
    if (this.state.singleRow) {
      this.mapJsonToState(this.state.singleRow);
    }
  }

  onCancelButtonClick = () => {
    this.props.history.push('/list-permissions');
  };

  toast = new ToastNotification();

  onSaveButtonClick = (model) => {
    this.setState({ isLoaderActive: true });
    if (this.props.match.params.id) {
      put(`profile-admin/${this.props.match.params.id}`, model)
        .then((response) => {
          this.toast.show('create_success', 'SUCCESS', response.data.message);
          this.setState({ isLoaderActive: false });
          this.props.history.push('/list-permissions');
        })
        .catch((err) => {
          this.setState({ isLoaderActive: false });
          if (err.message) this.toast.show('update_error', 'ERROR', err.message);
        });
    } else {
      post('profile-admin', model)
        .then((response) => {
          this.setState({ isLoaderActive: false });
          this.toast.show('create_success', 'SUCCESS', response.data.message);
          this.props.history.push('/list-permissions');
        })
        .catch((err) => {
          this.setState({ isLoaderActive: false });
          if (err.message) this.toast.show('create_error', 'ERROR', err.message);
        });
    }
  };

  toggleUserModal = () => {
    this.setState((prevState) => ({
      newUserModalOpen: !prevState.newUserModalOpen,
    }));
  };

  customOnchangeHandler = (option) => {
    this.setState({ obituaryId: option ? option.id : 0 });
  };

  render() {
    let { isLoaderActive, createdItemState, newUserModalOpen } = this.state;
    return (
      <React.Fragment>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Set Permission</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/list-permissions">Obituary Profile Permissions</Link>
                  </li>
                  <li className="breadcrumb-item active">Set Permission</li>
                </ol>
              </div>
            </Col>
          </Row>
          <Row>
            <Container fluid className="px-0">
              <div className="col-12">
                <Card>
                  <CardBody>
                    <SubmitForm onSubmit={this.onSaveButtonClick} {...this.setFormProperties({ disableReactiveForm: true })}>
                      <Row>
                        <Col xs={12} md={6}>
                          <SearchableDropDown {...this.setProperties(this.state.obituaryId)} disableSelect={this.props.match.params.id} />
                          {/* <DropDown {...this.setProperties(this.state.obituaryId)} disabled={this.props.match.params.id} /> */}
                        </Col>
                        <Col xs={12} md={6}>
                          <CustomComponentDropdown {...this.setProperties(this.state.userId)} toggleModal={this.toggleUserModal} />
                        </Col>
                      </Row>
                      <FormGroup className="mb-0">
                        <div className="float-right d-md-block">
                          <br />
                          <Button type="submit" color="primary" className="mr-1">
                            Save
                          </Button>{' '}
                          <Button className="btn-secondary" type="reset" onClick={this.onCancelButtonClick}>
                            <i className="icon-close"> </i> <span> Cancel </span>
                          </Button>
                        </div>
                      </FormGroup>
                    </SubmitForm>
                  </CardBody>
                </Card>
              </div>
            </Container>
            {newUserModalOpen && (
              <CreateUserCustomModal
                modal={newUserModalOpen}
                toggleModal={this.toggleUserModal}
                title="Create New User"
                isRolesDisable
                confirmButtonClick={this.onConfirmButtonClick}
                createdItemState={this.state[createdItemState]}
                setParentState={this.setParentState}
              />
            )}
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default SetPermission;
