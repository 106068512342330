import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Row, Col } from 'reactstrap';
import { get, post, put } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import CustomModal from '../../SharedComponents/customModal';
import PublishProfileComponent from './publishProfileComponent';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { ViewDate } from '../../SharedComponents/ListComponents/components';
import RenewProfileComponent from './renewProfileComponent';
import { combineDateTime, convertToLocalISOString } from '../../Libraries/DateTimeHelpers/dateTimeHelperFunctions';
import CustomerResponseManagement from './customerResponseManagement';
import LinkButton from '../../SharedComponents/linkButton';
import ReschedulePublishComponent from './reschedulePublishComponent';

class ProfileStatusComponent extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      dropDown: false,
      badgeColour: ['success', 'warning', 'dark'],
      stsId: 0,
      stsTitle: '',
      publishModal: false,
      publishDate: '',
      publishTime: '',
      expiryDate: '',
      renewedDate: '',
      profileUrl: '',
      previewModal: false,
      confirmationModal: false,
      renewModal: false,
      unblockModal: false,
      republishModal: false,
      rescheduleModal: false,
      modalUser: null,
      showRenewButton: false,
      sendRenewRequest: false,
      renewalPrice: '',
      callResponseModal: false,
      isAdminOrCSTM: false,
      isBlocked: false,
    };
  }

  statusTitles = ['Published', 'Draft', 'Training'];

  toast = new ToastNotification();

  setRenewalPrice = (expiryDate) => {
    get('memorial-pricing', { roleId: 9, compareDate: expiryDate, isRenewal: true }).then((response) => {
      response.data = response.data.data;
      if (response.data && response.data.rows && response.data.rows.length > 0) {
        let item = response.data.rows[0];
        this.setState({ renewalPrice: item.pricePerProfile });
      }
    });
  };

  componentDidMount() {
    let { id, title, publishedDate, publishedTime, expiryDate, lastRenewedDate, userData, isBlockedByAdmin } = this.props;
    if (id && title) {
      this.setState({ stsId: id, stsTitle: title });
    }
    if (publishedDate && publishedTime && expiryDate) {
      let statusTitle;
      let totalPeriod;
      let remainingPeriod;
      let formattedRenewedDate = '';
      let formattedExpiryDate = convertToLocalISOString(expiryDate);
      let publishedDateTime = combineDateTime(publishedDate, publishedTime);
      if (lastRenewedDate) {
        formattedRenewedDate = convertToLocalISOString(lastRenewedDate);
        totalPeriod = new Date(formattedExpiryDate) - new Date(formattedRenewedDate);
      } else {
        totalPeriod = new Date(formattedExpiryDate) - new Date(publishedDateTime);
      }
      remainingPeriod = new Date(formattedExpiryDate) - new Date();
      // set "To be published" or "Expired" only if status_id is 1 (published)
      if (new Date(publishedDateTime.replace(/Z/i, '')).getTime() > new Date().getTime()) {
        statusTitle = 'To Be Published';
      } else if (new Date().getTime() > new Date(expiryDate).getTime()) {
        statusTitle = 'Expired';
      } else {
        statusTitle = title;
      }
      // if status_id is not 1
      if (id !== 1) {
        statusTitle = title;
      }
      this.setState({
        publishDate: publishedDateTime,
        publishTime: publishedTime,
        expiryDate: formattedExpiryDate,
        renewedDate: formattedRenewedDate,
        showRenewButton: remainingPeriod < totalPeriod / 4,
        stsTitle: statusTitle,
        isBlocked: isBlockedByAdmin,
      });
      if (remainingPeriod < totalPeriod / 4) {
        let compareDate = remainingPeriod < 0 ? new Date().toISOString() : expiryDate;
        this.setRenewalPrice(compareDate);
      }
    }
    get('obituary-status?page=0&take=10').then((response) => {
      if (response.data && response.data.data && response.data.success) {
        this.setState({ apiResponse: response.data.data });
      } else {
        this.toast.show('fetch_error', 'ERROR', 'Could not load obituary statuses!');
      }
    });
    if (userData && userData.user && userData.user.roleId && [2, 3, 6].some((roleId) => roleId == userData.user.roleId)) {
      this.setState({ isAdminOrCSTM: true });
    }
  }

  updateStatus(stsId, stsTitle) {
    this.setState({ stsId, stsTitle });
  }

  componentDidUpdate(prevProps) {
    let { id } = this.props;
    let { isBlocked, publishDate, expiryDate } = this.state;
    if (prevProps.id !== id) {
      let today = new Date();
      let customTitle = this.statusTitles[id - 1];
      if (id === 1) {
        if (isBlocked) {
          customTitle = 'Blocked';
        } else if (new Date(publishDate.replace(/Z/i, '')).getTime() > today.getTime()) {
          customTitle = 'To Be Published';
        } else if (today.getTime() > new Date(expiryDate.replace(/Z/i, '')).getTime()) {
          customTitle = 'Expired';
        }
      }
      this.updateStatus(id, customTitle);
    }
  }

  changeProfileStatus = (row) => {
    let { stsId } = this.state;
    let { statusChange } = this.props;

    if (row.id != stsId) {
      let model = {
        statusId: row.id,
      };
      if (row.title.toLowerCase() === 'published') {
        this.showConfirmationModal();
      } else {
        put(`obituary-profile/${this.props.obituaryId}`, model)
          .then((response) => {
            if (response.data.data && response.data.success) {
              // this.setState({ stsId: row.id, stsTitle: row.title });
              statusChange(row.id, row.title);
              let message = row.id === 2 ? 'The obituary profile is now in Draft mode.' : 'The obituary profile is now in Training mode.';
              this.toast.show('update_success', 'SUCCESS', message);
            } else {
              this.toast.show('update_error', 'ERROR', "Couldn't update status. Please try after some time.");
            }
          })
          .catch((err) => {
            this.toast.show('update_error', 'ERROR', err.message ? err.message : "Couldn't update status. Please try after some time.");
          });
      }
    }
  };

  formRef = {};

  onRepublish = () => {
    let { publishedDate, publishedTime, expiryDate } = this.props;
    let publishedDateTime = combineDateTime(publishedDate, publishedTime);
    if (new Date(publishedDateTime.replace(/Z/i, '')).getTime() > new Date().getTime()) {
      this.showRepublishModal(true);
    } else if (new Date().getTime() > new Date(expiryDate).getTime()) {
      this.showRenewModal();
    } else {
      this.showRepublishModal();
    }
  };

  onConfirmButtonClick = (e) => {
    let { publishModal, confirmationModal, renewModal, unblockModal, publishDate, republishModal, toBePublished, rescheduleModal } = this.state;
    let { obituaryId, statusChange } = this.props;
    if (publishModal) {
      this.formRef.onClose = this.showPublishModal;
      this.formRef.onFormSubmit(e);
    } else if (confirmationModal) {
      this.showConfirmationModal();
      /**
       * A published obituary profile will be having publish and expiry dates.
       * If the status of such a profile is made draft of training, then on publishing again, check whether its validity is over:-
       *    : if not just change status to publish
       *    : else go to renew page
       */
      if (publishDate) {
        this.onRepublish();
      } else {
        this.showPublishModal();
      }
    } else if (renewModal) {
      this.setState({ sendRenewRequest: true });
    } else if (unblockModal) {
      this.showUnblockModal();
      put(`obituary-profile/${obituaryId}`, { isBlockedByAdmin: false })
        .then((response) => {
          if (response.data && response.data.data && response.data.success) {
            this.setState({ isBlocked: false });
            this.toast.show('update_success', 'SUCCESS', 'Obituary Profile unblocked successfully');
          }
        })
        .catch(() => {
          this.toast.show('update_error', 'ERROR', 'Could not unblock obituary');
        });
    } else if (republishModal) {
      this.showRepublishModal();
      put(`obituary-profile/${obituaryId}`, { statusId: 1 })
        .then((response) => {
          if (response.data.data && response.data.success) {
            statusChange(1, 'Published');
            let message = toBePublished
              ? 'The obituary profile will be published in the future'
              : 'Status has been changed to published successfully';
            this.toast.show('update_success', 'SUCCESS', message);
          } else {
            this.toast.show('update_error', 'ERROR', "Couldn't update status. Please try after some time.");
          }
        })
        .catch((err) => {
          this.toast.show('update_error', 'ERROR', err.message ? err.message : "Couldn't update status. Please try after some time.");
        });
    } else if (rescheduleModal) {
      this.formRef.onClose = this.showRescheduleModal;
      this.formRef.onFormSubmit(e);
    }
  };

  showPublishModal = () => {
    this.setState((prevState) => ({
      publishModal: !prevState.publishModal,
      modalUser: prevState.publishModal ? null : 'publish',
    }));
  };

  showConfirmationModal = () => {
    this.setState((prevState) => ({
      modalUser: prevState.confirmationModal ? null : 'confirm',
      confirmationModal: !prevState.confirmationModal,
    }));
  };

  showPreviewModal = () => {
    this.setState((prevState) => ({
      previewModal: !prevState.previewModal,
      modalUser: prevState.previewModal ? null : 'preview',
    }));
  };

  showRenewModal = () => {
    this.setState((prevState) => ({
      renewModal: !prevState.renewModal,
      modalUser: prevState.renewModal ? null : 'renew',
    }));
  };

  showUnblockModal = () => {
    this.setState((prevState) => ({
      unblockModal: !prevState.unblockModal,
      modalUser: prevState.unblockModal ? null : 'unblock',
    }));
  };

  showRepublishModal = (toBePublished = false) => {
    this.setState((prevState) => ({
      republishModal: !prevState.republishModal,
      modalUser: prevState.republishModal ? null : 'republish',
      toBePublished,
    }));
  };

  showRescheduleModal = () => {
    this.setState((prevState) => ({
      rescheduleModal: !prevState.rescheduleModal,
      modalUser: prevState.rescheduleModal ? null : 'reschedule',
    }));
  };

  generatePreviewLink = () => {
    let { profileUrl, stsId } = this.state;
    let { obituaryId } = this.props;
    if (stsId === 1) {
      window.open(`${process.env.REACT_APP_FRONTEND_URL}pages/view-memorial/${obituaryId}`);
    } else if (!profileUrl) {
      let model = {
        obituaryId,
      };
      post('profile-preview-token', model).then(async (response) => {
        if (response.data.data && response.data.success) {
          await this.setState({ profileUrl: response.data.data.previewUrl });
          this.toast.show('create_success', 'SUCCESS', 'Profile preview link created successfully.');
          this.showPreviewModal();
        } else {
          this.toast.show('create_error', 'ERROR', 'Failed to generate preview link');
        }
      });
    } else {
      this.showPreviewModal();
    }
  };

  render() {
    let {
      apiResponse,
      dropDown,
      stsId,
      stsTitle,
      badgeColour,
      publishModal,
      publishDate,
      publishTime,
      expiryDate,
      renewedDate,
      previewModal,
      modalUser,
      profileUrl,
      confirmationModal,
      showRenewButton,
      renewModal,
      unblockModal,
      republishModal,
      rescheduleModal,
      sendRenewRequest,
      renewalPrice,
      callResponseModal,
      isAdminOrCSTM,
      isBlocked,
      toBePublished,
    } = this.state;
    let { obituaryId, partnerId, lastReminderSent, createdByRoleId, commissionRate, lastReminderSeen, access, userData } = this.props;
    this.formRef.onFormSubmit = {};

    let currentUser;
    let currentToggle;
    let currentTitle;
    if (modalUser === 'publish') {
      currentUser = publishModal;
      currentToggle = this.showPublishModal;
      currentTitle = 'Publish Obituary Profile';
    } else if (modalUser === 'preview') {
      currentUser = previewModal;
      currentToggle = this.showPreviewModal;
      currentTitle = 'Profile Preview';
    } else if (modalUser === 'confirm') {
      currentUser = confirmationModal;
      currentToggle = this.showConfirmationModal;
      currentTitle = 'Publish Confirmation';
    } else if (modalUser == 'renew') {
      currentUser = renewModal;
      currentToggle = this.showRenewModal;
      currentTitle = 'Renew Obituary Profile';
    } else if (modalUser == 'unblock') {
      currentUser = unblockModal;
      currentToggle = this.showUnblockModal;
      currentTitle = 'Unblock Obituary';
    } else if (modalUser == 'republish') {
      currentUser = republishModal;
      currentToggle = this.showRepublishModal;
      currentTitle = 'Reinstate Obituary Profile';
    } else if (modalUser == 'reschedule') {
      currentUser = rescheduleModal;
      currentTitle = 'Reschedule Obituary Publish';
      currentToggle = this.showRescheduleModal;
    }
    return (
      <React.Fragment>
        <Card>
          <CardBody className="app-l-profile-publish-details">
            <Row>
              <Col lg={12} className="justify-content-between d-flex">
                {/* eslint-disable-next-line no-nested-ternary */}
                {isBlocked ? (
                  <Button color="danger" onClick={() => this.showUnblockModal()} title="click to unblock this obituary" className="ml-0">
                    <span className="pr-2">
                      <i className="fas fa-ban" />
                    </span>
                    Blocked
                  </Button>
                ) : stsTitle !== 'Expired' ? (
                  <div className={`badge ml-0 badge-${badgeColour[stsId - 1]}`}>{stsTitle}</div>
                ) : (
                  <div className="badge ml-0 badge-danger">{stsTitle}</div>
                )}
                {stsId !== 1 && (
                  <Button color="info" onClick={() => this.generatePreviewLink()}>
                    Preview Profile
                  </Button>
                )}
                {stsId === 1 && (
                  <Button
                    color="warning"
                    className={showRenewButton && !isBlocked ? '' : 'disabled-button'}
                    onClick={() => (showRenewButton && !isBlocked ? this.showRenewModal() : {})}
                    // eslint-disable-next-line no-nested-ternary
                    title={isBlocked ? 'Unblock obituary to renew' : showRenewButton ? 'Renew obituary profile' : 'It is too early to renew'}
                  >
                    Renew Profile
                  </Button>
                )}

                {stsId === 1 && isAdminOrCSTM && (
                  <Button color="info" onClick={() => this.setState({ callResponseModal: true })}>
                    Call Details
                  </Button>
                )}

                {stsId !== 1 && access.edit && (
                  <Dropdown isOpen={dropDown} toggle={() => this.setState({ dropDown: !dropDown })}>
                    <DropdownToggle className="btn btn-secondary" caret>
                      Change Status
                    </DropdownToggle>
                    <DropdownMenu>
                      {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                        ? apiResponse.rows.map((row, i) => (
                            <DropdownItem key={`dropdown-item-${i}`} onClick={() => this.changeProfileStatus(row)}>
                              {row.title}
                            </DropdownItem>
                          ))
                        : 'Status Unavailable'}
                    </DropdownMenu>
                  </Dropdown>
                )}
                {stsId === 1 && (
                  <Button color="info" className="mr-0" onClick={() => this.generatePreviewLink()}>
                    <span>View Profile</span>
                  </Button>
                )}
              </Col>
            </Row>
            <Row style={{ fontWeight: 500 }}>
              <Col sm={12} lg={4}>
                {publishDate && (
                  <span>
                    {stsTitle === 'To Be Published' ? 'Publishes ' : 'Published '} on:
                    <br className="d-none d-lg-block" /> <ViewDate isTableView dateValue={publishDate} />
                    {stsTitle === 'To Be Published' && (
                      <>
                        {' '}
                        <LinkButton toString="reschedulePublish" title="reschedule obituary publish" toDoFunction={() => this.showRescheduleModal()}>
                          <i className="fas fa-edit" />
                        </LinkButton>
                      </>
                    )}
                  </span>
                )}
              </Col>
              <Col sm={12} lg={4} className="text-lg-center">
                {renewedDate && (
                  <span>
                    {new Date(renewedDate.replace(/Z/i, '')) > new Date() ? 'Renews ' : 'Last Renewed '} on:&nbsp;
                    <br className="d-none d-lg-block" />
                    <ViewDate isTableView dateValue={renewedDate} />
                  </span>
                )}
              </Col>
              <Col sm={12} lg={4} className="text-lg-right">
                {expiryDate && (
                  <span>
                    {stsTitle === 'Expired' ? 'Expired ' : 'Expires '} on:
                    <br className="d-none d-lg-block" />
                    <ViewDate isTableView dateValue={expiryDate} />
                  </span>
                )}
              </Col>
            </Row>
            {lastReminderSent && (
              <Row className="pt-3" style={{ fontWeight: 500 }}>
                <Col sm={12} md={6}>
                  <span>
                    Last Reminder Sent:&nbsp;
                    <ViewDate isTableView dateValue={convertToLocalISOString(lastReminderSent)} />
                  </span>
                </Col>
                <Col className="text-lg-right" sm={12} md={6}>
                  <span>
                    Last Reminder Seen:&nbsp;
                    <ViewDate isTableView dateValue={lastReminderSeen ? convertToLocalISOString(lastReminderSeen) : null} />
                  </span>
                </Col>
                {showRenewButton && (
                  <Col sm={12}>
                    <span>Pending Payment: &euro;{renewalPrice} for renewal</span>
                  </Col>
                )}
              </Row>
            )}
          </CardBody>
        </Card>
        {modalUser ? (
          <CustomModal
            modal={currentUser}
            toggleModal={currentToggle}
            title={currentTitle}
            buttonList={[
              {
                name: !['confirm', 'republish'].includes(modalUser) ? modalUser[0].toUpperCase() + modalUser.slice(1) : 'Continue',
                color: 'primary',
                onClick: this.onConfirmButtonClick,
              },
              { name: 'Cancel', color: 'secondary' },
            ]}
            contentClassName={modalUser === 'preview' ? 'profile-preview' : null}
            hideFooter={modalUser === 'preview'}
            className={['publish', 'reschedule'].includes(modalUser) ? 'modal-lg' : null}
            maxHeight={modalUser === 'publish' ? '400' : null}
          >
            {modalUser === 'publish' && (
              <PublishProfileComponent
                obituaryId={obituaryId}
                partnerId={partnerId}
                formRef={this.formRef}
                toast={this.toast}
                publishDate={publishDate}
                publishTime={publishTime}
                commissionRate={commissionRate}
                createdByRoleId={createdByRoleId}
                statusChange={this.props.statusChange}
                setParentState={this.setParentState}
                loggedInUserRoleId={userData.user.roleId}
              />
            )}
            {modalUser === 'preview' && (
              <iframe src={`${profileUrl}&hideFeedBack=true`} title="Obituary profile preview" style={{ width: `${100}%`, height: `${100}%` }} />
            )}
            {modalUser === 'confirm' && (
              <p>Once published this obituary profile will appear in the Memlo website. Are you sure you want to Publish ?</p>
            )}
            {modalUser === 'renew' && (
              <RenewProfileComponent
                obituaryId={obituaryId}
                expiryDate={expiryDate}
                sendRenewRequest={sendRenewRequest}
                setParentState={this.setParentState}
                isRepublish={stsId !== 1}
              />
            )}
            {modalUser === 'unblock' && (
              <div>
                <p>
                  This obituary is currently blocked from the Memlo website after being reported by some users. By unblocking, this obituary will be
                  made available on the Memlo website. Thus make sure that the necessary corrections are done.
                </p>
                <p>
                  If the contents of this obituary are now in compliance with Memlo&apos;s rules and regulations then restore this memorial by
                  clicking on the &quot;Unblock&quot; button.
                </p>
              </div>
            )}
            {modalUser === 'republish' && (
              <div>
                {toBePublished ? (
                  <>
                    <p>
                      This obituary profile is already set to be published on <ViewDate isTableView dateValue={publishDate} />, with the validity
                      expiring on <ViewDate isTableView dateValue={expiryDate} />
                    </p>
                    <p>Click Continue to publish</p>
                  </>
                ) : (
                  <>
                    <p>
                      This obituary profile has an active validity which will expire on <ViewDate isTableView dateValue={expiryDate} />
                    </p>
                    <p>Click Continue to re-publish this obituary</p>
                  </>
                )}
              </div>
            )}
            {modalUser === 'reschedule' && (
              <ReschedulePublishComponent
                publishedDate={publishDate}
                publishedTime={publishTime}
                obituaryId={obituaryId}
                formRef={this.formRef}
                setParentState={this.setParentState}
                statusChange={this.props.statusChange}
              />
            )}
          </CustomModal>
        ) : null}
        {callResponseModal && (
          <CustomModal
            modal={callResponseModal}
            toggleModal={() => this.setState({ callResponseModal: false })}
            className="modal-lg"
            maxHeight="400"
            title="Communication Details"
            hideFooter
          >
            <CustomerResponseManagement obituaryId={this.props.obituaryId} />
          </CustomModal>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  userData: state.Login.userData,
});
export default connect(mapStateToProps)(ProfileStatusComponent);
