import adminMenu from './adminMenu';
import store from '../Store/index';

export const setComponentPrivilege = (component) => {
  let moduleAccessPrivilege = store.getState().Login.moduleAccessArray;
  // let moduleAccessPrivilege = JSON.parse(localStorage.getItem('moduleAccessArray'));

  if (localStorage.isAllAccess) {
    return {
      create: true,
      edit: true,
      delete: true,
      read: true,
    };
  }
  if (moduleAccessPrivilege && moduleAccessPrivilege[component]) {
    return {
      read: moduleAccessPrivilege[component].indexOf('Read') > -1,
      create: moduleAccessPrivilege[component].indexOf('Create') > -1,
      edit: moduleAccessPrivilege[component].indexOf('Edit') > -1,
      delete: moduleAccessPrivilege[component].indexOf('Delete') > -1,
    };
  }
  return false;
};

export const convertToModuleAccessModel = () => {
  let accessArray = {};
  JSON.parse(localStorage.accessGroups).forEach((item) => {
    if (accessArray[item.subModuleTitle] && accessArray[item.subModuleTitle].length > 0) {
      if (accessArray[item.subModuleTitle].indexOf(item.permissionTitle) === -1) accessArray[item.subModuleTitle].push(item.permissionTitle);
    } else {
      accessArray[item.subModuleTitle] = ['Read'];
      if (item.permissionTitle !== 'Read') accessArray[item.subModuleTitle].push(item.permissionTitle);
    }
  });
  localStorage.setItem('moduleAccessArray', JSON.stringify(accessArray));
  return accessArray;
};
export const setValidMenu = () => {
  let userAccess;
  let isAllAccess;
  let validMenu = [];
  let menu = adminMenu.map((item) => ({ ...item }));
  if (localStorage.isAllAccess) {
    isAllAccess = JSON.parse(localStorage.isAllAccess);
  }
  if (!isAllAccess && localStorage.moduleAccessArray) {
    userAccess = JSON.parse(localStorage.moduleAccessArray);
  }

  if (isAllAccess) {
    validMenu = menu;
  } else if (userAccess) {
    menu.forEach((y) => {
      if (y.subList) {
        y.subList = y.subList.filter((x) => {
          if (x && x.subModule) {
            return x.subModule && userAccess[x.subModule] && userAccess[x.subModule].includes(x.permission);
          }
          return true;
        });
      }
    });
    validMenu = menu.filter((x) => {
      if (x && x.subModule) {
        return x.subModule && userAccess[x.subModule] && userAccess[x.subModule].includes(x.permission);
      }
      if (x.subList) {
        if (x.subList.length == 0) {
          return false;
        }
      }

      return true;
    });
  }

  let url = '';
  if (validMenu && validMenu.length > 0) {
    let [first] = validMenu;
    let { url: urlProp, subList } = first;
    url = urlProp;
    if (first.subList && first.subList.length > 0) {
      let [subFirst] = subList;
      let { url: subUrl } = subFirst;
      url = subUrl;
    }
  }
  return url;
};
