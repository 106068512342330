import React from 'react';
import { Redirect } from 'react-router-dom';

// Pages Component

// Authentication related pages
import Login from '../Pages/Authentication/login';
import Logout from '../Pages/Authentication/logout';
import Register from '../Pages/Authentication/register';
import ForgetPwd from '../Pages/Authentication/forgetPassword';

import Dashboard from '../Pages/Dashboard';

import Calendar from '../Pages/Apps/Calendar/index';

import EmailInbox from '../Pages/Apps/Email/inbox';
import EmailRead from '../Pages/Apps/Email/read';
import EmailCompose from '../Pages/Apps/Email/compose';

import Emailtemplatealert from '../Pages/Apps/EmailTemplate/email-template-alert';
import Emailtemplatebasic from '../Pages/Apps/EmailTemplate/email-template-basic';
import Emailtemplatebilling from '../Pages/Apps/EmailTemplate/email-template-billing';

// UI Pages
import UiSweetAlert from '../Pages/UI/UiSweetAlert';
import UiAlerts from '../Pages/UI/Alerts';
import UiButtons from '../Pages/UI/Buttons';
import UiCards from '../Pages/UI/Cards';
import UiCarousel from '../Pages/UI/Carousel';
import UiDropdown from '../Pages/UI/Dropdown';
import UiGrid from '../Pages/UI/Grid';
import UiImages from '../Pages/UI/Images';
import UiLightbox from '../Pages/UI/Lightbox';
import UiModals from '../Pages/UI/Modals';
import UiRangeSlider from '../Pages/UI/RangeSlider';
import UiSessionTimeout from '../Pages/UI/SessionTimeout';
import Progressbar from '../Pages/UI/Progressbar';
import TabsAccordion from '../Pages/UI/TabsAccordion';
import Typography from '../Pages/UI/Typography';
import Video from '../Pages/UI/Video';
import General from '../Pages/UI/General';
import Colors from '../Pages/UI/Colors';
import Rating from '../Pages/UI/Rating';

// Form
import FormElement from '../Pages/Forms/FormElement';
import FormValidation from '../Pages/Forms/FormValidation';
import FormAdvanced from '../Pages/Forms/FormAdvanced';
import FormMask from '../Pages/Forms/FormMask';
import FormRepeater from '../Pages/Forms/FormRepeater';
import FormUpload from '../Pages/Forms/FormUpload';
import FormXeditable from '../Pages/Forms/FormXeditable';
import FormWizard from '../Pages/Forms/FormWizard';
import FormEditor from '../Pages/Forms/FormEditor';

// Charts
import ChartistChart from '../Pages/Charts/ChartistChart';
import ChartjsChart from '../Pages/Charts/ChartjsChart';
import ApexChart from '../Pages/Charts/ApexChart';
import SarklineChart from '../Pages/Charts/SarklineChart';
import EchartChart from '../Pages/Charts/EChart';

// Tables
import TableBasic from '../Pages/Tables/TableBasic';
import TableDatatable from '../Pages/Tables/TableDatatable';
import TableResponsive from '../Pages/Tables/TableResponsive';
import TableEditable from '../Pages/Tables/TableEditable';

// Maps
import IconDripicons from '../Pages/Icons/IconDripicons';
import IconFontAwesome from '../Pages/Icons/IconFontAwesome';
import IconIon from '../Pages/Icons/IconIon';
import IconMaterial from '../Pages/Icons/IconMaterial';
import IconThemify from '../Pages/Icons/IconThemify';
import IconTypicons from '../Pages/Icons/IconTypicons';

// Maps
import MapsVector from '../Pages/Maps/MapsVector';
import MapsGoogle from '../Pages/Maps/MapsGoogle';

// Extra Pages
import PagesLogin2 from '../Pages/ExtraPages/PagesLogin2';
import PagesRegister2 from '../Pages/ExtraPages/PagesRegister2';
import PagesRecoverpw2 from '../Pages/ExtraPages/PagesRecoverpw2';
// Extra Pages
import PagesTimeline from '../Pages/ExtraPages/PagesTimeline';
import PagesInvoice from '../Pages/ExtraPages/PagesInvoice';
import PagesDirectory from '../Pages/ExtraPages/PagesDirectory';
import PagesBlank from '../Pages/ExtraPages/PagesBlank';
import PagesPricing from '../Pages/ExtraPages/PagesPricing';
import PagesGallery from '../Pages/ExtraPages/PagesGallery';
import PagesFaq from '../Pages/ExtraPages/PagesFaq';
import Pages404 from '../Pages/ExtraPages/Pages404';
import Pages500 from '../Pages/ExtraPages/Pages500';
import PagesMaintenance from '../Pages/ExtraPages/PagesMaintenance';
import PagesComingsoon from '../Pages/ExtraPages/PagesComingsoon';

// User
import ViewUsers from '../Pages/User/viewUser';
import ListUserContainer from '../Pages/User/listUser';
import ManageUsers from '../Pages/User/manageUser';

// Profile Permission
import ListPermissions from '../Pages/ProfilePermission/listPermission';
import SetPermission from '../Pages/ProfilePermission/setPermission';

// Shop
import ManageShops from '../Pages/Shop/manageShop';
import ListShops from '../Pages/Shop/listShop';
import ViewShops from '../Pages/Shop/viewShop';

// Product
import ListProducts from '../Pages/Product/listProduct';
import ManageProducts from '../Pages/Product/manageProduct';
import ViewProducts from '../Pages/Product/viewProduct';

// Obituary
import ViewObituaryProfiles from '../Pages/Obituary/viewObituaryProfile';
import DirectoryObituaryProfile from '../Pages/Obituary/directoryOfObituaryProfiles';
import ListReportedObituaries from '../Pages/Obituary/listReportedObituaries';

// Orders
import ListOrderContainer from '../Pages/Order/listOrder';
import ViewOrders from '../Pages/Order/viewOrder';

// Library Management
import ManageTemplateMessages from '../Pages/Template/manageTemplateMessage';
import ListTemplateMessages from '../Pages/Template/listTemplateMessage';
import ViewTemplateMessages from '../Pages/Template/viewTemplateMessage';
import SetPassword from '../Pages/ExtraPages/SetPassword';
import ListMediaAccessContainer from '../Pages/Media/listMediaAccess';
import ListReportTributeContainer from '../Pages/ReportTribute/listReportTribute';
import ListVenueContainer from '../Pages/Venue/listVenue';
import ListPlaceContainer from '../Pages/Places/listPlaces';
import ViewPlaces from '../Pages/Places/viewPlaces';

// Activity on Memeorial
import ListActivityContainer from '../Pages/Activity/listActivity';
import ViewAccessProfile from '../Pages/AccessProfiles/viewAccessProfiles';
import ListAccessProfile from '../Pages/AccessProfiles/listAccessProfiles';
import ManageAccessProfile from '../Pages/AccessProfiles/manageAccessProfiles';
import ViewUserAccess from '../Pages/UserSecurityRoles/viewUserAccess';
import ListUserAccess from '../Pages/UserSecurityRoles/listUserAccess';
import ManageUserAccess from '../Pages/UserSecurityRoles/manageUserAccess';
import ListPartnerManagerContainer from '../Pages/PartnerManager/listPartnerManager';
import ViewPartnerManager from '../Pages/PartnerManager/viewPartnerManager';
import ViewPartnerManagerTransactions from '../Pages/PartnerManager/viewPartnerManagerTransactions';
import GaData from '../Pages/Analytics/ga';

const authProtectedRoutes = [
  // User
  { path: '/view-users/:id', component: ViewUsers, subModule: 'Manage Users', permission: 'Read' },
  {
    path: '/list-users',
    component: ListUserContainer,
    subModule: 'Manage Users',
    permission: 'Read',
  },
  {
    path: '/manage-users/:id?',
    component: ManageUsers,
    subModule: 'Manage Users',
    permission: 'Edit',
  },
  {
    path: '/manage-users',
    component: ManageUsers,
    subModule: 'Manage Users',
    permission: 'Create',
  },
  {
    path: '/list-permissions',
    component: ListPermissions,
    subModule: 'Profile Admin',
    permission: 'Read',
  },

  { path: '/set-permissions/:id?', component: SetPermission, subModule: 'Profile Admin', permission: 'Read' },
  { path: '/set-permissions', component: SetPermission, subModule: 'Profile Admin', permission: 'Create' },

  { path: '/list-partner-managers', component: ListPartnerManagerContainer, subModule: 'Partner Managers', permission: 'Read' },

  {
    path: '/view-partner-managers/:id/view-transactions',
    component: ViewPartnerManagerTransactions,
  },
  { path: '/view-partner-managers/:id?', component: ViewPartnerManager, subModule: 'Partner Managers', permission: 'Read' },

  // shop
  { path: '/manage-shops/:id?', component: ManageShops, subModule: 'Manage Shops', permission: 'Edit' },
  { path: '/manage-shops', component: ManageShops, subModule: 'Manage Shops', permission: 'Create' },
  { path: '/view-shops/:id?', component: ViewShops, subModule: 'Manage Shops', permission: 'Read' },
  { path: '/list-shops', component: ListShops, subModule: 'Manage Shops', permission: 'Read' },

  // product
  { path: '/list-products/', component: ListProducts, subModule: 'Manage Products', permission: 'Read' },
  { path: '/manage-products/:id?', component: ManageProducts, subModule: 'Manage Products', permission: 'Edit' },
  { path: '/manage-products', component: ManageProducts, subModule: 'Manage Products', permission: 'Create' },
  { path: '/view-products/:id?', component: ViewProducts, subModule: 'Manage Products', permission: 'Read' },

  // Analytics Data
  { path: '/analytics-data', component: GaData, subModule: 'Website Analytics Data', permission: 'Read' },

  // Profile Menu
  { path: '/my-profile/', component: ManageUsers },

  // obituary
  {
    path: '/list-obituary-profiles/:value?',
    component: DirectoryObituaryProfile,
    subModule: ['Manage Obituary Profiles', 'Manage Renewal Profiles'],
    permission: 'Read',
  },

  {
    path: '/view-obituary-profiles/:value?/:id?',
    component: ViewObituaryProfiles,
    subModule: ['Manage Obituary Profiles', 'Manage Renewal Profiles'],
    permission: 'Read',
  },

  { path: '/list-obituary-reports/', component: ListReportedObituaries, subModule: 'Reported Obituaries', permission: 'Read' },

  // orders
  {
    path: '/list-orders',
    component: ListOrderContainer,
    subModule: 'Manage Orders',
    permission: 'Read',
  },
  { path: '/view-orders/:id?', component: ViewOrders, subModule: 'Manage Orders', permission: 'Read' },

  // Library Management
  {
    path: '/manage-template/:value?/:id?',
    component: ManageTemplateMessages,
    subModule: ['Message Templates', 'Tribute Cards', 'Background Images', 'Solid Colors'],
    permission: 'Edit',
  },
  {
    path: '/manage-template/:value',
    component: ManageTemplateMessages,
    subModule: ['Message Templates', 'Tribute Cards', 'Background Images', 'Solid Colors'],
    permission: 'Create',
  },
  {
    path: '/list-template/:value?',
    component: ListTemplateMessages,
    subModule: ['Message Templates', 'Tribute Cards', 'Background Images', 'Solid Colors'],
    permission: 'Read',
  },
  {
    path: '/view-template/:value?/:id?',
    component: ViewTemplateMessages,
    subModule: ['Message Templates', 'Tribute Cards', 'Background Images', 'Solid Colors'],
    permission: 'Read',
  },

  { path: '/list-venue', component: ListVenueContainer, subModule: 'Venue', permission: 'Read' },
  { path: '/list-place', component: ListPlaceContainer, subModule: 'Place', permission: 'Read' },
  { path: '/view-place/:id?', component: ViewPlaces, subModule: 'Place', permission: 'Read' },

  // Tribute mangement
  { path: '/list-report-tribute', component: ListReportTributeContainer, subModule: 'Reported Tributes', permission: 'Read' },
  { path: '/list-media-allow-access', component: ListMediaAccessContainer, subModule: 'Media Access', permission: 'Read' },

  // Access
  {
    path: '/view-access-profiles/:id',
    component: ViewAccessProfile,
    subModule: 'Access Templates',
    permission: 'Read',
  },
  { path: '/list-access-profiles', component: ListAccessProfile, subModule: 'Access Templates', permission: 'Read' },
  { path: '/manage-access-profiles/:id?', component: ManageAccessProfile, subModule: 'Access Templates', permission: 'Edit' },
  { path: '/manage-access-profiles', component: ManageAccessProfile, subModule: 'Access Templates', permission: 'Create' },

  // User access roles
  { path: '/view-user-access-roles/:id', component: ViewUserAccess, subModule: 'Manage User Access', permission: 'Read' },
  { path: '/list-user-access-roles', component: ListUserAccess, subModule: 'Manage User Access', permission: 'Read' },
  { path: '/manage-user-access-roles/:id?', component: ManageUserAccess, subModule: 'Manage User Access', permission: 'Edit' },
  { path: '/manage-user-access-roles', component: ManageUserAccess, subModule: 'Manage User Access', permission: 'Create' },

  // Activity on Memorial
  {
    path: '/activity-memorial',
    component: ListActivityContainer,
    subModule: ['Manage Obituary Profiles'],
    permission: 'Read',
  },

  { path: '/dashboard', component: Dashboard, subModule: 'Dashboard', permission: 'Read' },

  // Calendar
  { path: '/calendar', component: Calendar },

  // Email & Email Templates
  { path: '/email-inbox', component: EmailInbox },
  { path: '/email-read', component: EmailRead },
  { path: '/email-compose', component: EmailCompose },

  // Email Template
  { path: '/email-template-alert', component: Emailtemplatealert },
  { path: '/email-template-basic', component: Emailtemplatebasic },
  { path: '/email-template-billing', component: Emailtemplatebilling },

  // Ui Pages
  { path: '/ui-sweet-alert', component: UiSweetAlert },
  { path: '/ui-alerts', component: UiAlerts },
  { path: '/ui-buttons', component: UiButtons },
  { path: '/ui-cards', component: UiCards },
  { path: '/ui-carousel', component: UiCarousel },
  { path: '/ui-dropdowns', component: UiDropdown },
  { path: '/ui-grid', component: UiGrid },
  { path: '/ui-images', component: UiImages },
  { path: '/ui-lightbox', component: UiLightbox },
  { path: '/ui-modals', component: UiModals },
  { path: '/ui-rangeslider', component: UiRangeSlider },
  { path: '/ui-session-timeout', component: UiSessionTimeout },
  { path: '/ui-progressbars', component: Progressbar },
  { path: '/ui-tabs-accordions', component: TabsAccordion },
  { path: '/ui-typography', component: Typography },
  { path: '/ui-video', component: Video },
  { path: '/ui-general', component: General },
  { path: '/ui-colors', component: Colors },
  { path: '/ui-rating', component: Rating },

  // Form
  { path: '/form-elements', component: FormElement },
  { path: '/form-validation', component: FormValidation },
  { path: '/form-advanced', component: FormAdvanced },
  { path: '/form-mask', component: FormMask },
  { path: '/form-repeater', component: FormRepeater },
  { path: '/form-uploads', component: FormUpload },
  { path: '/form-xeditable', component: FormXeditable },
  { path: '/form-wizard', component: FormWizard },
  { path: '/form-editors', component: FormEditor },

  // Charts
  { path: '/charts-chartist', component: ChartistChart },
  { path: '/charts-chartjs', component: ChartjsChart },
  { path: '/apex-chart', component: ApexChart },
  { path: '/charts-sparkline', component: SarklineChart },
  { path: '/e-charts', component: EchartChart },

  // Tables
  { path: '/tables-basic', component: TableBasic },
  { path: '/tables-datatable', component: TableDatatable },
  { path: '/tables-responsive', component: TableResponsive },
  { path: '/tables-editable', component: TableEditable },

  // Icons
  { path: '/icons-dripicons', component: IconDripicons },
  { path: '/icons-fontawesome', component: IconFontAwesome },
  { path: '/icons-ion', component: IconIon },
  { path: '/icons-material', component: IconMaterial },
  { path: '/icons-themify', component: IconThemify },
  { path: '/icons-typicons', component: IconTypicons },

  // Maps
  { path: '/maps-google', component: MapsGoogle },
  { path: '/maps-vector', component: MapsVector },

  // Extra Pages
  { path: '/pages-timeline', component: PagesTimeline },
  { path: '/pages-invoice', component: PagesInvoice },
  { path: '/pages-directory', component: PagesDirectory },
  { path: '/pages-blank', component: PagesBlank },
  { path: '/pages-pricing', component: PagesPricing },
  { path: '/pages-gallery', component: PagesGallery },
  { path: '/pages-faq', component: PagesFaq },

  { path: '/', redirect: true, exact: true, component: (url) => <Redirect to={url || '/dashboard'} /> },
];

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-Password', component: ForgetPwd },
  { path: '/pages-Register', component: Register },

  // Extra Pages
  { path: '/pages-login-2', component: PagesLogin2 },
  { path: '/pages-register-2', component: PagesRegister2 },
  { path: '/pages-recoverpw-2', component: PagesRecoverpw2 },
  { path: '/lock-screen', component: SetPassword },
  { path: '/set-password', component: SetPassword },
  { path: '/recovery-password', component: SetPassword },

  { path: '/pages-404', component: Pages404 },
  { path: '/pages-500', component: Pages500 },
  { path: '/pages-Maintenance', component: PagesMaintenance },
  { path: '/pages-comingsoon', component: PagesComingsoon },
  // { path: '/', redirect: true, exact: true, component: () => <Redirect to="/login" /> },
];

export { authProtectedRoutes, publicRoutes };
