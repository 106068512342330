import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

class BarChart extends Component {
  render() {
    let data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Sales Analytics',
          backgroundColor: '#02a499',
          borderColor: '#02a499',
          borderWidth: 1,
          hoverBackgroundColor: '#02a499',
          hoverBorderColor: '#02a499',
          data: [65, 59, 81, 45, 56, 80, 50, 20],
        },
      ],
    };

    let option = {
      tootlbar: {
        show: false,
      },
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            let dataset = data.datasets[tooltipItem.datasetIndex];
            let meta = dataset._meta[Object.keys(dataset._meta)[0]];
            let { total } = meta;
            let currentValue = dataset.data[tooltipItem.index];
            let percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
            return `${currentValue} (${percentage}%)`;
          },
          title(tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          },
        },
      },
    };

    return (
      <React.Fragment>
        <Bar width={600} height={245} data={data} options={option} />
      </React.Fragment>
    );
  }
}

export default BarChart;
