import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Alert, Button } from 'reactstrap';
// import InstagramLogin from 'react-instagram-login';

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

import Loader from '../../Components/loader';
// actions

// import images
import logoSm from '../../assets/images/memlo-symbol-lg.png';
import { get, post } from '../../Libraries/Ajax/httpService';
import { facebookService } from '../../Helpers/facebookInit';
import google from '../../assets/images/google.png';
import {
  loginMessage,
  setUser,
  setLoadingActive,
  setLoadingInactive,
  setAccessArray,
  setIsAllAccess,
  logoutUser,
} from '../../Store/Auth/Login/actions';
import { convertToModuleAccessModel, setValidMenu } from '../../SharedComponents/accessPrivilegeHelper';
import { setDefaultMenuUrl } from '../../Store/MasterData/actions';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  componentDidMount() {
    let title = this.props.location.pathname;
    let currentAge = title.charAt(1).toUpperCase() + title.slice(2);

    document.title = `${currentAge} | Memlo - Admin Panel`;
    let successCallback = this.successCallback.bind(this);
    let auth2;
    let { gapi } = window;

    function attachSignIn(element) {
      auth2.attachClickHandler(
        element,
        {},
        (googleUser) => {
          successCallback(googleUser);
        },
        () => {},
      );
    }
    gapi.load('auth2', () => {
      auth2 = gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        cookiepolicy: 'single_host_origin',
      });
      attachSignIn(document.getElementById('googleButton'));
    });
  }

  successCallback(googleUser) {
    let details = googleUser.getAuthResponse();
    let profile = googleUser.getBasicProfile();
    this.signInAPICall('account/google', {
      idToken: details.id_token,
      email: profile.getEmail(),
      isAdmin: true,
    });
  }

  handlePassword = () => {
    let obj = this.state.showPassword;
    this.setState({ showPassword: !obj });
  };

  handleValidSubmit = (event, model) => {
    this.signInAPICall('account/login', { ...model, isAdmin: true });
  };

  signInAPICall = (url, model) => {
    let { history, setUserData, setMessage, loaderActive, loaderInactive, setDefaultUrl, logout, setAccess, setAllAccess } = this.props;
    localStorage.removeItem('userData');
    localStorage.removeItem('accessGroups');
    localStorage.removeItem('moduleAccessArray');
    localStorage.removeItem('isAllAccess');
    logout();
    let nextUrl = localStorage.getItem('nextUrl');
    loaderActive();
    post(url, model)
      .then((res) => {
        if (res.data) {
          setUserData(res.data);
          localStorage.setItem('userData', JSON.stringify(res.data));
          get('user-access/user/access').then((result) => {
            let noAccess = false;
            if (result.data && result.data.data && result.data.data.rows?.accessPermissionsList.length > 0) {
              let accessRes = result.data.data.rows;
              localStorage.setItem('accessGroups', JSON.stringify(accessRes.accessPermissionsList));
              if (accessRes.accessPermissionsList[0].isAllAccess) {
                setAllAccess(true);
                localStorage.setItem('isAllAccess', true);
              }
              let moduleAccessArray = convertToModuleAccessModel(accessRes.accessPermissionsList);
              setAccess(moduleAccessArray);
              let activeMenuUrl = setValidMenu();
              if (activeMenuUrl === null || activeMenuUrl === '') noAccess = true;
              if (history.location.state && history.location.state.redirectUrl) {
                if (nextUrl) {
                  history.push(nextUrl);
                  localStorage.removeItem('nextUrl');
                } else history.push(history.location.state.redirectUrl);
              } else {
                localStorage.setItem('defaultMenuUrl', activeMenuUrl);
                setDefaultUrl(activeMenuUrl);
                if (nextUrl) {
                  history.push(nextUrl);
                  localStorage.removeItem('nextUrl');
                } else {
                  history.push(activeMenuUrl);
                }
              }
            } else {
              noAccess = true;
            }
            if (noAccess) {
              setMessage(false, "You don't have access to this application");
              let keysToRemove = ['userData', 'accessGroups', 'isAllAccess', 'defaultMenuUrl'];
              keysToRemove.forEach((k) => localStorage.removeItem(k));
            }
          });
          window.gapi.auth2.getAuthInstance().disconnect();
        }
      })
      .catch((error) => {
        if (url === 'account/google') window.gapi.auth2.getAuthInstance().disconnect();
        setMessage(false, error.message ? error.message : 'Something went wrong, please try again');
      })
      .finally(() => loaderInactive());

    // localStorage.clear();
  };

  render() {
    let { loginMessageState, messageSuccess, loading } = this.props;
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-5">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="position-relative">
                  {loading ? <Loader /> : null}
                  <Card className="overflow-hidden">
                    <div className="bg-primary">
                      <div className="text-primary text-center p-4">
                        <h5 className="text-white font-size-20">Welcome Back !</h5>
                        <p className="text-white-50">Sign in to continue to Memlo.</p>
                        <Link to="/login" className="logo logo-admin">
                          <img src={logoSm} height="29" alt="logo" />
                        </Link>
                      </div>
                    </div>

                    <CardBody className="p-4">
                      <div className="p-3">
                        <AvForm className="form-horizontal mt-4" onValidSubmit={this.handleValidSubmit}>
                          {loginMessageState ? (
                            <Alert color={messageSuccess ? 'success' : 'danger'} style={{ opacity: 1 }}>
                              {loginMessageState}
                            </Alert>
                          ) : null}
                          <div className="form-group">
                            <AvField name="email" label="Email" className="form-control" placeholder="Enter email" type="email" required />
                          </div>
                          <div className="form-group">
                            <div className="password-show-hide">
                              <AvField
                                name="password"
                                label="Password"
                                type={this.state.showPassword ? 'text' : 'password'}
                                required
                                placeholder="Enter Password"
                              />
                              <span className="show-hide">
                                <em className={this.state.showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'} onClick={this.handlePassword} />
                              </span>
                            </div>
                          </div>

                          <Row className="form-group">
                            <Col sm={6}>&nbsp;</Col>
                            <Col sm={6} className="text-right">
                              <button className="btn btn-primary w-md waves-effect waves-light" type="submit">
                                Log In
                              </button>
                            </Col>
                          </Row>
                          <Row className="form-group mt-2 mb-0">
                            <div className="col-12 mt-4">
                              <Link to="/forgot-Password">
                                <i className="mdi mdi-lock" /> Forgot your password?
                              </Link>
                            </div>
                          </Row>
                        </AvForm>
                        <div className="d-flex mt-3 app-c-mw-social">
                          <Button id="googleButton" className="btn-social google mr-1" aria-label="Sign in using Google">
                            <span className="pre-img">
                              <img src={google} alt="google logo" />
                            </span>
                            <span className="title-info"> Proceed with Google</span>
                          </Button>
                          <Button style={{ whiteSpace: 'nowrap' }} className="btn btn-facebook" onClick={facebookService.onLogin}>
                            <i className="fab fa-facebook-f mr-1" />
                            Proceed With Facebook
                          </Button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="mt-5 text-center">
                  <p className="mb-0">© {new Date().getFullYear()} Memlo.</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  let { loading, loginMessageState, messageSuccess } = state.Login;
  return { loading, loginMessageState, messageSuccess };
};
const mapDispatchToProps = (dispatch) => ({
  setUserData: (obj) => dispatch(setUser(obj)),
  setMessage: (success, message) => dispatch(loginMessage(success, message)),
  loaderInactive: () => dispatch(setLoadingInactive()),
  loaderActive: () => dispatch(setLoadingActive()),
  setDefaultUrl: (url) => dispatch(setDefaultMenuUrl(url)),
  setAccess: (accessList) => dispatch(setAccessArray(accessList)),
  setAllAccess: (payload) => dispatch(setIsAllAccess(payload)),
  logout: () => dispatch(logoutUser()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
