import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Alert } from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { loginMessage } from '../../Store/actions';

// action

import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import logoSm from '../../assets/images/memlo-symbol-lg.png';
import { post } from '../../Libraries/Ajax/httpService';

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {}

  toast = new ToastNotification();

  handleValidSubmit(event, model) {
    let { setMessage, history } = this.props;
    post('forgot-password-token', model)
      .then((response) => {
        setMessage(true, response.data.message);
        history.push('/login');
      })
      .catch((error) => {
        this.toast.show('forgot_password', 'ERROR', error.message);
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-5">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="position-relative">
                  <Card className="overflow-hidden">
                    <div className="bg-primary">
                      <div className="text-primary text-center p-4">
                        <h5 className="text-white font-size-20">Forgot Password</h5>
                        <p className="text-white-50">Enter your email below and we would send a link to reset your password.</p>
                        <Link to="/login" className="logo logo-admin">
                          <img src={logoSm} height="29" alt="logo" />
                        </Link>
                      </div>
                    </div>

                    <CardBody className="p-4">
                      <div className="p-3">
                        {this.props.forgetError && this.props.forgetError ? (
                          <Alert color="danger" style={{ marginTop: '13px' }}>
                            {this.props.forgetError}
                          </Alert>
                        ) : null}
                        {this.props.forgetSuccess ? (
                          <Alert color="success" style={{ marginTop: '13px' }}>
                            {this.props.forgetSuccess}
                          </Alert>
                        ) : null}

                        <AvForm className="form-horizontal mt-4" onValidSubmit={this.handleValidSubmit}>
                          <div className="form-group">
                            <AvField name="email" label="Email" className="form-control" placeholder="Enter email" type="email" required />
                          </div>
                          <Row className="form-group">
                            <Col className="text-right">
                              <button className="btn btn-primary w-md waves-effect waves-light" type="submit">
                                Reset
                              </button>
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-5 text-center">
                    <p>
                      Go back to{' '}
                      <Link to="login" className="font-weight-medium text-primary">
                        Login
                      </Link>{' '}
                    </p>
                    {/* <p className="mb-0">
                      © {new Date().getFullYear()} Veltrix. Crafted with{" "}
                      <i className="mdi mdi-heart text-danger"></i> by
                      Themesbrand
                    </p> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setMessage: (success, message) => dispatch(loginMessage(success, message)),
});
export default connect(null, mapDispatchToProps)(ForgetPasswordPage);
