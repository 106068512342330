import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const SetFooterButtons = (footerProps) => {
  let { buttonList, toggleModal } = footerProps;
  if (buttonList && buttonList.length > 0) {
    return buttonList.map((button) => (
      <>
        {button.name.toLowerCase() === 'delete' ? (
          <>{button.component}</>
        ) : (
          <Button
            color={button.color || 'secondary'}
            onClick={(e) => (button.onClick ? button.onClick(e) : toggleModal(e))}
            hidden={button.hide === true}
          >
            {button.name || 'Button'}
          </Button>
        )}
      </>
    ));
  }
  return null;
};

const CustomModal = (props) => {
  let { title, children, hideFooter, className, contentClassName, modal, toggleModal, buttonList } = props;
  let bodyStyle = {};
  if (props.maxHeight > 0) {
    bodyStyle = { maxHeight: `${props.maxHeight}px`, overflowY: 'scroll' };
  }

  return (
    <Modal isOpen={modal} toggle={toggleModal} className={className != null ? className : 'modal-sm'} contentClassName={contentClassName}>
      <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
      <ModalBody style={bodyStyle}>{children}</ModalBody>
      {hideFooter !== true && (
        <ModalFooter>
          <SetFooterButtons buttonList={buttonList} toggleModal={toggleModal} />
        </ModalFooter>
      )}
    </Modal>
  );
};

export default CustomModal;
