import React from 'react';
import { Col, Row } from 'reactstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const SortableItem = SortableElement(({ value }) => (
  <Col xs={4} sm={4} md={3} lg={2} xl={2} className="py-2">
    {value()}
  </Col>
));

const SortableList = SortableContainer(({ items, keyName }) => (
  <Row>
    {items && items.length > 0
      ? items.map((item, i) => (
          <SortableItem
            key={`${keyName}-${i}`}
            index={i}
            value={() => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  borderRadius: `${15}px`,
                  height: `${100}px`,
                  background: 'radial-gradient(circle, rgba(0,0,0,0), rgba(0,0,0,1))',
                }}
              >
                {['avi', 'mp4'].every((type) => type != item.mediaType) ? (
                  <img
                    src={item.mediaUrl}
                    alt={item.media}
                    style={{
                      minWidth: `${100}%`,
                      maxHeight: `${100}%`,
                      height: `${100}px`,
                      padding: `${5}px`,
                      objectFit: 'contain',
                      borderRadius: `${15}px`,
                    }}
                  />
                ) : (
                  <video width="100%" height="100%" preload="metadata" key={`video-${item.id}`}>
                    <track src="captions_en.vtt" kind="captions" label="english_captions" />
                    <source src={`${item.mediaUrl}#t=0.5`} type="video/mp4" />
                    <source src={`${item.mediaUrl}#t=0.5`} type="video/avi" />
                  </video>
                )}
              </div>
            )}
          />
        ))
      : null}
  </Row>
));

export default SortableList;
