import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import {
  AnalyticsDashboard,
  ActiveUsersChart,
  PageViewsPerPathChart,
  PagesPerSessionChart,
  SessionsByDateChart,
  SessionsBySourceChart,
  SessionsGeoChart,
} from 'react-analytics-charts';

import ErrorBoundary from './errorBoundary';

class GaData extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Website Analytics Data</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <ErrorBoundary>
                <AnalyticsDashboard
                  authOptions={{ clientid: '771793382344-qnicj01lll5mc8ojtu0i7cgoh3k1pu48.apps.googleusercontent.com' }}
                  renderCharts={(gapi, viewId) => (
                    <>
                      <div className="ga-chart">
                        <div className="ga-inner">
                          <ActiveUsersChart gapi={gapi} viewId={viewId} days={28} activeUserDays={28} />
                        </div>
                        <div className="ga-inner">
                          <SessionsByDateChart gapi={gapi} viewId={viewId} days={28} showUsers />
                        </div>
                      </div>
                      <div className="ga-chart">
                        <div className="ga-inner">
                          <SessionsBySourceChart gapi={gapi} viewId={viewId} days={28} />
                        </div>
                        <div className="ga-inner">
                          <PagesPerSessionChart gapi={gapi} viewId={viewId} days={28} />
                        </div>
                      </div>
                      <div className="ga-chart">
                        <div className="ga-inner">
                          <SessionsGeoChart gapi={gapi} viewId={viewId} days={28} showPageViews showUsers />
                        </div>
                        <div className="ga-inner">
                          <PageViewsPerPathChart gapi={gapi} viewId={viewId} days={28} />
                        </div>
                      </div>
                    </>
                  )}
                />
              </ErrorBoundary>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
export default GaData;
