import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import 'react-image-lightbox/style.css';
import { ActionButton } from '../../SharedComponents/FormHelpers/Controls/actionButton';
import { DataColHead, ListPagination, ViewDate } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import Loader from '../../Components/loader';
import { DataTable, DataTableHolder, HeadingBlock, TBody, TCol, THead, TRow } from '../../SharedComponents/Table/tableElements';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';

class ListAccessProfile extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleSelect: false,
      multiSelect: false,
      rowData: {},
      showLightBox: false,
      imageUrl: '',
      isLoaderActive: false,
      showDeleteConfirmation: false,
    };
  }

  componentDidMount() {
    this.setJsonUrl('access');
    this.setpredicates({ take: 10, page: 0 });
    this.setState({ access: setComponentPrivilege('Access Templates') });
  }

  onLightBoxClick = (row) => {
    this.setState({
      showLightBox: true,
      imageUrl: row.mediaUrl,
    });
  };

  render() {
    let { apiResponse, access, isLoaderActive } = this.state;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              {isLoaderActive === true ? <Loader /> : ''}
              <HeadingBlock title="Access Template" />
            </Col>
            {access && access.create && (
              <Col sm={6}>
                <div className="float-right d-md-block">
                  <Link className="btn btn-primary waves-effect waves-light" to="/manage-access-profiles" role="button">
                    Add Access Template
                  </Link>
                </div>
              </Col>
            )}
          </Row>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                    <div className="row">
                      <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                        <DataTable>
                          <THead>
                            <TRow>
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Template Name                                  ',
                                  enableSearch: true,
                                  enableSort: true,
                                  searchField: 'title',
                                  sortField: 'title',
                                  className: 'app-l-long-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Access To',
                                  className: 'app-l-long-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Description',
                                  enableSearch: true,
                                  enableSort: true,
                                  className: 'app-l-long-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Created By',
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Created On',
                                  enableSort: true,
                                  sortField: 'createdDate',
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Last Modified By',
                                  // enableSearch: true,
                                  className: 'app-l-search-column',
                                  searchField: 'emailSearch',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Action',
                                  filterfield: 'action',
                                  width: 120,
                                  className: 'action',
                                })}
                              />
                            </TRow>
                          </THead>
                          <TBody>
                            {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                              ? apiResponse.rows.map((row, i) => (
                                  <TRow key={`access-${i}`}>
                                    <TCol>{row.title}</TCol>
                                    <TCol>{row.accessTo}</TCol>
                                    <TCol>{row.description}</TCol>
                                    <TCol>{row.createdByName}</TCol>
                                    <TCol>
                                      <ViewDate isTableView dateValue={row.createdDate} />
                                    </TCol>
                                    <TCol>{row.modifiedByName}</TCol>
                                    <TCol>
                                      <div className="action-col">
                                        {access && access.edit && <ActionButton value="edit" rowId={row.id} url="manage-access-profiles/" />}
                                        <ActionButton value="view" rowId={row.id} url="view-access-profiles/" />
                                        {access && access.delete && (
                                          <span
                                            title={
                                              row.id === 1 || row.id === 2
                                                ? `This template cannot be deleted since it is part of the default access for ${row.title?.toLowerCase()}`
                                                : ''
                                            }
                                          >
                                            <ActionButton
                                              value="delete"
                                              isDisabled={row.id === 1 || row.id === 2}
                                              rowId={row.id}
                                              url="list-access-profiles"
                                              setJsonUrl={this.setJsonUrl}
                                              setpredicates={this.setpredicates}
                                              api="access/"
                                            />
                                          </span>
                                        )}
                                      </div>
                                    </TCol>
                                  </TRow>
                                ))
                              : null}
                          </TBody>
                        </DataTable>
                        {apiResponse.rows && apiResponse.rows.length === 0 && <div className="text-center no-content-block">No results found </div>}
                      </DataTableHolder>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ListAccessProfile;
