import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import Loader from '../../Components/loader';
import { post, put } from '../../Libraries/Ajax/httpService';
import { formatDate } from '../../Libraries/DateTimeHelpers/dateTimeHelperFunctions';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import CreatableDropDown from '../../SharedComponents/FormHelpers/Controls/creatableDropDown';
import CustomComponentDropdown from '../../SharedComponents/FormHelpers/Controls/customComponentDropdown';
import CustomDatePicker from '../../SharedComponents/FormHelpers/Controls/customDatePicker';
import DragAndDrop from '../../SharedComponents/FormHelpers/Controls/dragAndDrop';
import DropDown from '../../SharedComponents/FormHelpers/Controls/dropDown';
import FormInput from '../../SharedComponents/FormHelpers/Controls/formInput';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import { LocationSearchInput } from '../../SharedComponents/googlePlacesInput';
import CreateUserCustomModal from '../User/createUserCustomModal';
import LivedInPlacesComponent from './livedInPlacesComponent';

class ManageObituaryProfiles extends FormComponent {
  constructor(props) {
    super(props);
    this.setProperties = this.setProperties.bind(this);
    this.setFormProperties = this.setFormProperties.bind(this);
    this.state = {
      isSubmitted: true,
      singleRow: {},
      action: 'Add',
      editFlag: false,
      locationLoader: false,
      newUserModalOpen: false,
      createdItemState: '',
      id: {
        label: 'Id',
        type: 'hidden',
      },

      name: {
        label: 'Name',
        type: 'text',
        placeholder: 'Enter name',
        rules: {
          required: true,
          maxLength: 100,
        },
      },

      genderId: {
        label: 'Gender',
        renderapi: 'gender?page=0&take=10',
        placeholder: 'Choose gender',
        className: 'mb-0',
        controlId: 'exampleForm.ControlSelect1',
        name: 'genderId',
        optionvalue: 'id',
        optiontext: 'title',
        rules: {
          required: true,
        },
      },

      place: {
        label: 'Location',
        name: 'place',
        type: 'text',
        placeholder: 'Enter location [City, State, Country]',
        valueValidationProperty: 'address',
        rules: {
          locationRequired: true,
          noMoreThan: 300,
        },
      },

      image: {
        label: 'Image',
        type: 'text',
        placeholder: 'Enter image',
        rules: {},
        name: 'image',
        fileUploadUrl: 'obituary-profile/upload',
      },

      obituaryTags: {
        label: 'Tags',
        name: 'obituaryTags',
        renderapi: 'tag?sortField=title&sortOrder=ASC',
        searchField: 'q',
        createapi: 'tag',
        placeholder: 'Enter tags',
        className: 'mb-0',
        controlId: 'exampleForm.ControlSelect1',
        fieldLabel: 'title',
        fieldValue: 'id',
        dbField: 'tagId',
        maxLength: 100,
        rules: {
          noMoreThan: 10,
        },
      },

      livedInPlaces: {
        label: 'Lived in Places',
        name: 'livedInPlaces',
        renderapi: 'place?statusId=1&sortField=name&sortOrder=ASC',
        createapi: 'place',
        searchField: 'q',
        placeholder: 'Enter lived in places',
        className: 'mb-0',
        controlId: 'exampleForm.ControlSelect1',
        fieldLabel: 'name',
        fieldValue: 'id',
        dbField: 'placeId',
        customCreateLabel: 'Add new place:',
        maxLength: 300,
        rules: {
          noMoreThan: 10,
        },
      },

      dateOfDeath: {
        label: 'Date Of Death',
        type: 'date',
        placeholder: 'Enter date of death',
        rules: {
          required: true,
          maxToday: true,
        },
      },

      dateOfBirth: {
        relatedPropertyName: 'dateOfDeath',
        label: 'Date Of Birth',
        type: 'date',
        placeholder: 'Enter date of birth',
        rules: {
          required: true,
          maxToday: true,
          lesser: true,
        },
      },

      privacyId: {
        label: 'Privacy',
        renderapi: 'obituary-privacy?page=0&take=10',
        disableplaceholder: true,
        className: 'mb-0',
        controlId: 'exampleForm.ControlSelect1',
        name: 'privacyId',
        optionvalue: 'id',
        optiontext: 'title',
        rules: {},
        value: 2,
      },

      ownerId: {
        label: 'Owner',
        name: 'ownerId',
        optionvalue: 'id',
        rules: {},
        renderapi: 'user',
        apiModel: { isActive: true },
        imageKey: 'userImageUrl',
        subText: 'email',
        mainText: 'name',
        createNewText: 'Create a new user...',
      },
      partnerId: {
        label: 'Partner',
        className: 'mb-0',
        name: 'partnerId',
        optionvalue: 'id',
        rules: {},
        renderapi: 'user',
        apiModel: { isActive: true, isPartner: true },
        imageKey: 'userImageUrl',
        subText: 'email',
        mainText: 'name',
        createNewText: 'Create a new user...',
      },

      statusId: {
        label: 'Status',
        renderapi: 'obituary-status?page=0&take=10',
        className: 'mb-0',
        controlId: 'exampleForm.ControlSelect1',
        name: 'statusId',
        optionvalue: 'id',
        optiontext: 'title',
        rules: {},
        value: 2,
        filterStatus: false,
      },
      ticketId: {
        label: 'Ticket Id',
        type: 'text',
        placeholder: 'Enter ticket id',
        rules: {
          maxLength: 200,
        },
      },
    };
  }

  componentDidMount() {
    let { userData, profileData } = this.props;
    let userId = userData.user && userData.user.id ? userData.user.id : 0;
    let userRoleId = userData.user && userData.user.roleId ? userData.user.roleId : 0;
    if (userId) {
      this.setState((prevState) => ({
        ownerId: { ...prevState.ownerId, value: userId },
        partnerId: { ...prevState.partnerId, value: userRoleId === 1 ? userId : null, disableSelect: userRoleId === 1 },
      }));
    }
    if (profileData) {
      this.setState({
        editFlag: true,
        action: 'Edit',
        singleRow: profileData,
      });
    }
  }

  componentDidUpdate() {
    if (this.state.singleRow) {
      this.mapJsonToState(this.state.singleRow);
    }
    if (!this.state.statusId.filterStatus) {
      let { statusId, singleRow } = this.state;
      if (statusId && statusId.options && statusId.options.length > 0 && !singleRow.publishedDate) {
        let optns = statusId.options.filter((opt) => opt.title.toLowerCase() !== 'published');
        this.onUpdate(statusId, optns);
      }
    }
  }

  validExtensions = ['jpeg', 'jpg', 'png'];

  toast = new ToastNotification();

  onUpdate = (statusId, optns) => {
    this.setState({
      statusId: { ...statusId, options: optns, filterStatus: true },
    });
  };

  onSaveButtonClick = (model) => {
    let { formRef, profileData } = this.props;
    formRef.onClose();
    this.setState({ isLoaderActive: true });
    // setting null values
    // setting an integer partnerId to by pass decorators in the obituaryProfileModel
    if (model.partnerId == '' || model.partnerId == null) {
      model.partnerId = -10;
    }
    if (model.ownerId == '' || model.ownerId == null) {
      model.ownerId = -10;
    }
    if (model.obituaryTags.length > 0) {
      model.obituaryTags.forEach((tag, i) => {
        delete model.obituaryTags[i].label;
      });
    }
    if (model.livedInPlaces.length > 0) {
      model.livedInPlaces.forEach((place, i) => {
        delete model.livedInPlaces[i].label;
      });
    }
    model.dateOfBirth = formatDate(model.dateOfBirth);
    model.dateOfDeath = formatDate(model.dateOfDeath);
    if (profileData && profileData.id) {
      delete model.backgroundImageId;
      put(`obituary-profile/${profileData.id}`, model)
        .then((response) => {
          if (response.data.data && response.data.success) {
            this.setState({ isLoaderActive: false });
            this.props.setParentState({
              hasProfileUpdated: true,
              isLoaderActive: true,
            });
            this.toast.show('update_success', 'SUCCESS', response.data.message);
          } else {
            this.toast.show('update_error', 'ERROR', "Couldn't update obituary profile, please try again.");
          }
        })
        .catch(() => {});
    } else {
      post('obituary-profile', model)
        .then((response) => {
          if (response.data.data && response.data.success) {
            this.setState({ isLoaderActive: false });
            this.props.history.push(`/view-obituary-profiles/general/${response.data.data.id}`);
            this.toast.show('create_success', 'SUCCESS', response.data.message);
          } else {
            this.toast.show('create_error', 'ERROR', "Couldn't create obituary profile, please try again.");
          }
        })
        .catch(() => {});
    }
  };

  toggleUserModal = () => {
    this.setState((prevState) => ({
      newUserModalOpen: !prevState.newUserModalOpen,
    }));
  };

  render() {
    let { editFlag, isLoaderActive, singleRow, locationLoader, newUserModalOpen, createdItemState } = this.state;
    let { formRef } = this.props;

    return (
      <React.Fragment>
        {isLoaderActive === true ? <Loader /> : ''}
        {locationLoader ? <Loader /> : null}
        <Container fluid>
          <Row>
            <Container fluid className="px-0">
              <Col>
                <Card>
                  <CardBody>
                    <SubmitForm onSubmit={this.onSaveButtonClick} {...this.setFormProperties({ disableReactiveForm: true })} formRef={formRef}>
                      <Row>
                        <Col xs={12} md={6}>
                          <FormInput {...this.setProperties(this.state.name)} />
                        </Col>

                        <Col xs={12} md={6}>
                          <DropDown {...this.setProperties(this.state.genderId)} />
                        </Col>

                        <Col xs={12} md={6}>
                          <LocationSearchInput
                            placeDetails={this.setProperties(this.state.place)}
                            type={['(regions)']}
                            formGroup="app-c-input-box service-form"
                          />
                        </Col>

                        <Col xs={12} md={6}>
                          <LivedInPlacesComponent livedInPlaces={this.setProperties(this.state.livedInPlaces)} />
                        </Col>

                        <Col xs={12} md={6}>
                          <CustomDatePicker
                            formGroup
                            appearance="subtle"
                            futureDate={false}
                            format="DD/MM/YYYY"
                            isDate
                            oneTap
                            placement="auto"
                            minDate={this.state.dateOfBirth.value}
                            {...this.setProperties(this.state.dateOfDeath)}
                          />
                        </Col>

                        <Col xs={12} md={6}>
                          <CustomDatePicker
                            formGroup
                            appearance="subtle"
                            futureDate={false}
                            format="DD/MM/YYYY"
                            isDate
                            oneTap
                            placement="auto"
                            maxDate={this.state.dateOfDeath.value}
                            {...this.setProperties(this.state.dateOfBirth)}
                          />
                        </Col>

                        <Col xs={12} md={6}>
                          <CreatableDropDown {...this.setProperties(this.state.obituaryTags)} />
                        </Col>

                        <Col xs={12} md={6}>
                          <DropDown {...this.setProperties(this.state.privacyId)} />
                        </Col>

                        <Col xs={12} md={6}>
                          <CustomComponentDropdown {...this.setProperties(this.state.ownerId)} toggleModal={this.toggleUserModal} />
                        </Col>
                        <Col xs={12} md={6}>
                          <CustomComponentDropdown {...this.setProperties(this.state.partnerId)} toggleModal={this.toggleUserModal} />
                        </Col>

                        <Col xs={12} md={6}>
                          <DropDown {...this.setProperties(this.state.statusId)} />
                        </Col>

                        <Col xs={12} md={6}>
                          <FormInput {...this.setProperties(this.state.ticketId)} />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <DragAndDrop
                            validExtensions={this.validExtensions}
                            mediaUrl={singleRow.mediaUrl}
                            editFlag={editFlag}
                            customLabel="Profile Image"
                            allowCrop
                            allowDelete
                            aspectRatio={`${1 / 1}`}
                            {...this.setProperties(this.state.image)}
                          />
                        </Col>
                      </Row>
                    </SubmitForm>
                  </CardBody>
                </Card>
              </Col>
            </Container>
          </Row>
        </Container>
        {newUserModalOpen && (
          <CreateUserCustomModal
            modal={newUserModalOpen}
            toggleModal={this.toggleUserModal}
            title="Create New User"
            createdItemState={this.state[createdItemState]}
            setParentState={this.setParentState}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  userData: state.Login.userData,
});
export default connect(mapStateToProps)(ManageObituaryProfiles);
