import React from 'react';
import { Col, Row } from 'reactstrap';
import 'react-image-lightbox/style.css';
import { ActionButton } from '../../SharedComponents/FormHelpers/Controls/actionButton';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import Loader from '../../Components/loader';
import { DataTable, DataTableHolder, HeadingBlock, TBody, TCol, THead, TRow } from '../../SharedComponents/Table/tableElements';

class ListPartnerManagerContainer extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleSelect: false,
      multiSelect: false,
      rowData: {},
      imageUrl: '',
      isLoaderActive: false,
    };
  }

  componentDidMount() {
    let model = {
      isPartner: true,
      isActive: true,
    };
    this.renderApi('user', model);
    // get('user', model).then((response) => {
    //   this.setState({ apiResponse: response.data.data });
    // });
  }

  render() {
    let { apiResponse, isLoaderActive } = this.state;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              {isLoaderActive === true ? <Loader /> : ''}
              <HeadingBlock title="Partner Manager" />
            </Col>
            <Col sm={6} />
          </Row>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                    <div className="row">
                      <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                        <DataTable>
                          <THead>
                            <TRow>
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Name',
                                  className: 'app-l-search-column',
                                  enableSearch: true,
                                  enableSort: true,
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Family Name',
                                  className: 'app-l-search-column',
                                  width: 100,
                                  enableSearch: true,
                                  enableSort: true,
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Email',
                                  className: 'app-l-search-column',
                                  searchField: 'emailSearch',
                                  enableSearch: true,
                                  enableSort: true,
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Action',
                                  filterfield: 'action',
                                  className: 'action',
                                })}
                              />
                            </TRow>
                          </THead>
                          <TBody>
                            {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                              ? apiResponse.rows.map((row) => (
                                  <TRow>
                                    <TCol>{row.name}</TCol>
                                    <TCol>{row.familyName}</TCol>
                                    <TCol>{row.email}</TCol>
                                    <TCol>
                                      <div className="action-col">
                                        <ActionButton value="view" rowId={row.id} url="view-partner-managers/" />
                                      </div>
                                    </TCol>
                                  </TRow>
                                ))
                              : null}
                          </TBody>
                        </DataTable>
                        {apiResponse && apiResponse.rows?.length === 0 && <div className="text-center no-content-block">No results found </div>}
                      </DataTableHolder>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ListPartnerManagerContainer;
