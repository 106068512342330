import validator from 'validator';
import { isValidPhoneNumber } from 'react-phone-number-input/max';

const validPasswordRegex = RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])');
const validPhoneRegex = RegExp('[0-9]{8,13}');
export default class Validation {
  validate = (rule, properties) => {
    let { value } = properties;
    let fieldLabel = properties.label;
    switch (rule.key) {
      case 'required': {
        if (value && typeof value == 'object' && !Array.isArray(value)) {
          let validValues = value.filter((val) => val.isDelete === false);
          if (validValues.length === 0) {
            return {
              message: `${fieldLabel} is required`,
            };
          }
        } else if (Array.isArray(value)) {
          if (value != undefined && typeof value != 'object' && value.trim().length === 0) {
            return {
              message: `${fieldLabel} is required`,
            };
          }
        } else if (typeof value != 'number' && (!value || (value && value.trim().length < 1))) {
          return {
            message: `${fieldLabel} is required`,
          };
        }
        break;
      }
      case 'alphabet': {
        if (value && !validator.isAlpha(value.split(' ').join(''))) {
          return {
            message: `${fieldLabel} can contain only alphabets`,
          };
        }
        break;
      }
      case 'maxLength': {
        if (value && value.length > rule.value) {
          return {
            message: `${fieldLabel} must only contain maximum ${rule.value} characters`,
          };
        }
        break;
      }

      case 'alphanumeric': {
        if (value && !validator.isAlphanumeric(value)) {
          return {
            message: `${fieldLabel} can contain only alphabets and numbers`,
          };
        }
        break;
      }

      case 'minLength': {
        if (value && value.length < rule.value) {
          return {
            message: `${fieldLabel} must contain atleast ${rule.value} characters`,
          };
        }
        break;
      }
      case 'password': {
        if (value && !validPasswordRegex.test(value)) {
          return {
            message: `${fieldLabel} must contain one lowercase,uppercase and special character`,
          };
        }
        break;
      }

      case 'phone': {
        if (
          value &&
          (!validPhoneRegex.test(value) || value.toString().replace(/ /gi, '').length < 8 || value.toString().replace(/ /gi, '').length > 13)
        ) {
          return {
            message: `${fieldLabel} is not valid `,
          };
        }
        break;
      }
      case 'phoneValid': {
        if (value && !isValidPhoneNumber(value)) {
          return {
            message: `${fieldLabel} is not valid `,
          };
        }
        break;
      }
      case 'requireIf': {
        if (rule.value && properties.relatedProperty && properties.relatedProperty.value && !value) {
          return {
            message: `${properties.label} is required`,
          };
        }
        break;
      }

      case 'match': {
        if (value && rule.value && properties.match.value && value !== rule.value) {
          return {
            message: "Password and Confirm Password doesn't match",
          };
        }
        break;
      }

      case 'lesser': {
        if (
          value &&
          rule.value &&
          typeof rule.value === 'boolean' &&
          properties.relatedProperty &&
          properties.relatedProperty.value &&
          value > properties.relatedProperty.value
        ) {
          return {
            message: `${properties.label} cannot be greater than ${properties.relatedProperty.label}`,
          };
        }
        if (value && rule.value && typeof rule.value === 'string' && value.slice(11, 16) > rule.value.slice(11, 16)) {
          return {
            message: `${properties.label} cannot exceed ${rule.value.slice(11, 16)}`,
          };
        }
        break;
      }

      case 'greater': {
        if (
          value &&
          rule.value &&
          typeof rule.value === 'boolean' &&
          properties.relatedProperty &&
          properties.relatedProperty.value &&
          value < properties.relatedProperty.value
        ) {
          return {
            message: `${properties.label} cannot be lesser than ${properties.relatedProperty.label}`,
          };
        }
        if (value && rule.value && typeof rule.value === 'string' && value.slice(11, 16) < rule.value.slice(11, 16)) {
          return {
            message: `${properties.label} cannot be before ${rule.value.slice(11, 16)}`,
          };
        }
        break;
      }

      case 'greaterNumber': {
        if (
          value &&
          rule.value &&
          properties.relatedProperty &&
          properties.relatedProperty.value &&
          parseInt(value) < parseInt(properties.relatedProperty.value)
        ) {
          return {
            message: `${properties.label} cannot be lesser than ${properties.relatedProperty.label}`,
          };
        }
        break;
      }

      case 'email': {
        if (value && !validator.isEmail(value)) {
          return {
            message: `${fieldLabel} is not valid`,
          };
        }

        break;
      }
      case 'url': {
        if (value && !validator.isURL(value)) {
          return {
            message: `${fieldLabel} is not valid`,
          };
        }

        break;
      }
      case 'currency': {
        if (value) value = value.toString();
        if (value && !validator.isCurrency(value)) {
          return {
            message: `${fieldLabel} is not valid`,
          };
        }

        break;
      }
      case 'latlong': {
        if (value && !validator.isLatLong(value)) {
          return {
            message: `${fieldLabel} is not valid`,
          };
        }

        break;
      }
      case 'number': {
        if (value) value = value.toString();
        if (value && !validator.isNumeric(value)) {
          return {
            message: `${fieldLabel} is not valid`,
          };
        }
        break;
      }
      case 'maxToday': {
        let enteredDate = new Date(value);
        let currentDate = new Date();
        if (value && enteredDate.setHours(0, 0, 0, 0) > currentDate.setHours(0, 0, 0, 0)) {
          return {
            message: `${fieldLabel} cannot be in the future`,
          };
        }
        break;
      }
      case 'minToday': {
        let enteredDate = new Date(value);
        let currentDate = new Date();
        if (value && enteredDate.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0)) {
          return {
            message: `${fieldLabel} cannot be in the past`,
          };
        }
        break;
      }
      case 'minNow': {
        if (value && properties.relatedProperty && properties.relatedProperty.value) {
          let today = new Date();
          let inputDay = new Date(properties.relatedProperty.value);
          if (today.getFullYear() === inputDay.getFullYear() && today.getMonth() === inputDay.getMonth() && today.getDate() === inputDay.getDate()) {
            let minTime = today.getHours() * 60 + today.getMinutes();
            let inputTime = parseInt(value.slice(11, 13)) * 60 + parseInt(value.slice(14, 16));
            if (inputTime < minTime) {
              return {
                message: `${fieldLabel} cannot be in the past`,
              };
            }
          }
        }
        break;
      }
      case 'locationRequired': {
        if (typeof value === 'string' && value.trim().length === 0) {
          return {
            message: `${fieldLabel} is required`,
          };
        }
        if (typeof value === 'object' && Object.keys(value).length === 0 && value.constructor === Object) {
          return {
            message: `${fieldLabel} is required`,
          };
        }
        if (typeof value == 'object' && Object.prototype.hasOwnProperty.call(value, 'address') && value.address.trim().length === 0) {
          return {
            message: `${fieldLabel} is required`,
          };
        }
        break;
      }
      case 'noMoreThan': {
        if (typeof value == 'object') {
          if (Array.isArray(value) && value.length > 0) {
            if (Object.prototype.hasOwnProperty.call(value[0], 'isDelete')) {
              let valLength = value.filter((item) => !item.isDelete).length;
              if (valLength > rule.value) {
                return {
                  message: `Maximum ${rule.value} ${fieldLabel.toLowerCase()} are allowed`,
                };
              }
            } else if (value.length > rule.value) {
              return {
                message: `Maximum ${rule.value} ${fieldLabel.toLowerCase()} are allowed`,
              };
            }
          } else if (Object.prototype.hasOwnProperty.call(properties, 'valueValidationProperty') && properties.valueValidationProperty) {
            if (
              Object.prototype.hasOwnProperty.call(value, properties.valueValidationProperty) &&
              value[properties.valueValidationProperty].length > rule.value
            ) {
              return {
                message: `Maximum ${rule.value} characters are allowed`,
              };
            }
          }
        } else if (typeof value == 'string') {
          if (parseInt(value) > rule.value) {
            return {
              message: `Maximum ${fieldLabel.toLowerCase()} is ${rule.value}`,
            };
          }
        }
        break;
      }
      case 'noLessThan': {
        let compareValue = rule.value;
        if (typeof rule.value == 'string') {
          compareValue = parseInt(rule.value);
        }
        if (typeof value == 'string') {
          if (parseInt(value) < compareValue) {
            return {
              message: `Minimum ${fieldLabel.toLowerCase()} is ${rule.value}`,
            };
          }
        }
        break;
      }
      default:
        break;
    }
    return null;
  };
}
