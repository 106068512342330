import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';
import { get, post } from '../../Libraries/Ajax/httpService';
import Loader from '../../Components/loader';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { ViewDate } from '../../SharedComponents/ListComponents/components';
import { DeleteRow, EditRow, ContentItems, BadgeElement } from '../../SharedComponents/ListComponents/manageComponent';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';

class ViewUsers extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleRow: null,
    };
  }

  toast = new ToastNotification();

  componentDidMount() {
    if (this.props.match.params.id) {
      get(`user/${this.props.match.params.id}`).then((response) => {
        this.setState({ singleRow: response.data.data });
      });
    }
    this.setState({ access: setComponentPrivilege('Manage Users') });
  }

  onResetButtonClick = () => {
    let {
      singleRow: { email },
    } = this.state;
    let model = {
      email,
      customerMode: true,
    };
    post('forgot-password-token', model)
      .then((res) => {
        if (res.data) {
          this.toast.show('forgot_success', 'SUCCESS', res.data.message);
        }
      })
      .catch((error) => {
        this.toast.show('forgot_error', 'ERROR', error.message);
      });
  };

  render() {
    let { isLoaderActive, access, singleRow } = this.state;

    let { history } = this.props;

    return (
      <>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          {singleRow && (
            <div className="app-l-heading-block">
              <Row className="align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">User Details</h4>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/list-users">User</Link>
                      </li>
                      <li className="breadcrumb-item active">User Details</li>
                    </ol>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {singleRow && (
            <Container fluid className="px-0">
              <Card>
                <CardBody className="view-page">
                  {/* TopSection1 */}
                  <Row>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Name"> {singleRow.name}</ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Family Name"> {singleRow.familyName}</ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Email"> {singleRow.email}</ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Address"> {singleRow.address}</ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Role"> {singleRow.roleTitle}</ContentItems>
                    </Col>

                    {singleRow.roleId == 1 ? (
                      <Col xs={12} md={6} lg={4} xl={4}>
                        <ContentItems title="Commission Rate">
                          {' '}
                          {singleRow.commissionRate}
                          <i> %</i>
                        </ContentItems>
                      </Col>
                    ) : (
                      ''
                    )}
                    {singleRow.roleId == 2 ? (
                      <Col xs={12} md={6} lg={4} xl={4}>
                        <ContentItems title="Department"> {singleRow.departmentTitle}</ContentItems>
                      </Col>
                    ) : (
                      ''
                    )}

                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Email Status">
                        {' '}
                        <BadgeElement field="EmailStatus" value={singleRow.emailStatusTitle} id={singleRow.emailStatusId} />
                        {/* {singleRow.emailStatusTitle} */}
                      </ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Created Date">
                        <ViewDate isTableView dateValue={singleRow.createdDate} />
                      </ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      <ContentItems title="Last Modified Date">
                        <ViewDate isTableView dateValue={singleRow.modifiedDate} />
                      </ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4}>
                      {singleRow.roleId == 9 && (
                        <Button color="primary" onClick={() => this.onResetButtonClick()}>
                          Send Password Reset Link
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              {!singleRow.isNotEditable && (
                <div>
                  <Col>
                    <div className="float-right ">
                      {access && access.delete && (
                        <DeleteRow
                          renderapi={`user/${this.props.match.params.id}`}
                          model={singleRow}
                          redirecturl="/list-users"
                          deleteText="Delete"
                          history={history}
                          isCardViewTrue="True"
                        />
                      )}{' '}
                      {access && access.edit && (
                        <EditRow history={history} editText="Edit" redirecturl={`/manage-users/${this.props.match.params.id}`} rowData={singleRow} />
                      )}{' '}
                    </div>
                  </Col>
                </div>
              )}
            </Container>
          )}
        </div>
      </>
    );
  }
}

export default ViewUsers;
