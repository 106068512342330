import React from 'react';

const Dashboard = () => <i className="ti-home" />;
const Product = () => <i className="fas fa-cart-plus" />;
const Shop = () => <i className="fas fa-store" />;
const Order = () => <i className="fab fa-first-order" />;
const Users = () => <i className="fion ion-md-person" />;
const ProfileAdmin = () => <i className="mdi mdi-smart-card " />;
const PartnerManager = () => <i className="mdi mdi-smart-card " />;
const Obituary = () => <i className="fas fa-portrait" />;
const Renewal = () => <i className="fas fa-user-clock" />;
const Reported = () => <i className="fas fa-exclamation-circle" />;
const MessageTemplate = () => <i className="fas fa-book-open" />;
const TributeCards = () => <i className="fas fa-newspaper" />;
const Background = () => <i className="fas fa-camera-retro" />;
const SolidColors = () => <i className="mdi mdi-format-color-fill" />;
const Venue = () => <i className="fas fa-map-marked" />;
const Place = () => <i className="fas fa-globe-europe" />;
const ReportedTributes = () => <i className="fas fa-book-open" />;
const MediaAccess = () => <i className="fas fa-newspaper" />;
const AccessProfiles = () => <i className="mdi mdi-security" />;
const UserAccessRoles = () => <i className="mdi mdi-shield-account" />;
const Analytics = () => <i className="fas fa-chart-bar" />;
const Activity = () => <i className="mdi mdi-wallet font-size-17 align-middle mr-1" />;

export default [
  {
    name: 'Main',
    show: false,
    subList: [
      {
        name: 'Dashboard',
        url: '/dashboard',
        icon: Dashboard,
        subList: null,
        subModule: 'Dashboard',
        permission: 'Read',
      },
    ],
  },
  {
    name: 'Website Analytics',
    show: false,
    subList: [
      {
        name: 'Website Analytics Data',
        url: '/analytics-data',
        icon: Analytics,
        subList: null,
        subModule: 'Website Analytics Data',
        permission: 'Read',
      },
    ],
  },

  {
    name: 'Inventory Management',
    // subModule: "Thank You Note Template",
    // permission: "Read",
    subList: [
      {
        name: 'Manage Shops',
        url: '/list-shops',
        icon: Shop,
        subList: null,
        subModule: 'Manage Shops',
        permission: 'Read',
      },
      {
        name: 'Manage Products',
        url: '/list-products',
        icon: Product,
        subList: null,
        subModule: 'Manage Products',
        permission: 'Read',
      },
      {
        name: 'Manage Orders',
        url: '/list-orders',
        icon: Order,
        subList: null,
        subModule: 'Manage Orders',
        permission: 'Read',
      },
    ],
  },
  {
    name: 'User',
    url: '#',
    // icon: AuditIcon,
    subList: [
      {
        name: 'Manage Users',
        url: '/list-users',
        icon: Users,
        subList: null,
        subModule: 'Manage Users',
        permission: 'Read',
      },
      {
        name: 'Profile Admin',
        url: '/list-permissions',
        icon: ProfileAdmin,
        subList: null,
        subModule: 'Profile Admin',
        permission: 'Read',
      },
    ],
  },
  {
    name: 'Transaction Management',
    url: '#',
    // icon: AuditIcon,
    subList: [
      {
        name: 'Partner Managers',
        url: '/list-partner-managers',
        icon: PartnerManager,
        subList: null,
        subModule: 'Partner Managers',
        permission: 'Read',
      },
      // {
      //   name: 'Transactions',
      //   url: '/view-partner-managers/:id/view-transactions',
      //   icon: PartnerManager,
      //   subList: null,
      //   subModule: 'Partner Managers',
      //   permission: 'Read',
      // },
    ],
  },
  {
    name: 'Obituary',
    url: '#',
    // icon: ReportsIcon,
    subList: [
      {
        name: 'Manage Obituary Profiles',
        url: '/list-obituary-profiles/general',
        icon: Obituary,
        subList: null,
        subModule: 'Manage Obituary Profiles',
        permission: 'Read',
      },
      {
        name: 'Manage Renewal Profiles',
        url: '/list-obituary-profiles/renewal',
        icon: Renewal,
        subList: null,
        subModule: 'Manage Renewal Profiles',
        permission: 'Read',
      },
      {
        name: 'Activity in Obituaries',
        url: '/activity-memorial',
        icon: Activity,
        subList: null,
      },
      {
        name: 'Reported Obituaries',
        url: '/list-obituary-reports',
        icon: Reported,
        subList: null,
        subModule: 'Reported Obituaries',
        permission: 'Read',
      },
    ],
  },
  {
    name: 'Library Management',
    url: '#',
    // icon: AuditIcon,
    subList: [
      {
        name: 'Message Templates',
        url: '/list-template/message',
        icon: MessageTemplate,
        subList: null,
        subModule: 'Message Templates',
        permission: 'Read',
      },
      {
        name: 'Tribute Cards',
        url: '/list-template/tribute-card',
        icon: TributeCards,
        subList: null,
        subModule: 'Tribute Cards',
        permission: 'Read',
      },
      {
        name: 'Venue',
        url: '/list-venue',
        icon: Venue,
        subList: null,
        subModule: 'Venue',
        permission: 'Read',
      },
      {
        name: 'Place',
        url: '/list-place',
        icon: Place,
        subList: null,
        subModule: 'Place',
        permission: 'Read',
      },
    ],
  },
  {
    name: 'Background Settings',
    url: '#',
    subList: [
      {
        name: 'Background Images',
        url: '/list-template/background-image',
        icon: Background,
        subList: null,
        subModule: 'Background Images',
        permission: 'Read',
      },
      {
        name: 'Solid Colors',
        url: '/list-template/solid-colors',
        icon: SolidColors,
        subList: null,
        subModule: 'Solid Colors',
        permission: 'Read',
      },
    ],
  },

  {
    name: 'Tribute Management',
    url: '#',
    // icon: AuditIcon,
    subList: [
      {
        name: 'Reported Tributes',
        url: '/list-report-tribute',
        icon: ReportedTributes,
        subList: null,
        subModule: 'Reported Tributes',
        permission: 'Read',
      },
      {
        name: 'Media Access',
        url: '/list-media-allow-access',
        icon: MediaAccess,
        subList: null,
        subModule: 'Media Access',
        permission: 'Read',
      },
    ],
  },
  {
    name: ' Access Management',
    url: '#',
    // icon: AuditIcon,
    subList: [
      {
        name: 'Access Templates',
        url: '/list-access-profiles',
        icon: AccessProfiles,
        subList: null,
        subModule: 'Access Templates',
        permission: 'Read',
      },
      {
        name: 'Manage User Access',
        url: '/list-user-access-roles',
        icon: UserAccessRoles,
        subList: null,
        subModule: 'Manage User Access',
        permission: 'Read',
      },
    ],
  },
];
