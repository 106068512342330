import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { ManageContact } from './manageBusinessContacts';
import { get } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import { ActionButton } from '../../SharedComponents/FormHelpers/Controls/actionButton';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
import { DataTable, DataTableHolder, TBody, TCol, THead, TRow } from '../../SharedComponents/Table/tableElements';
import { DefaultImage } from './defaultImage';

export class BusinessContacts extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      updateContactDetailsModal: false,
      contactList: {},
      childCallType: '',
      rowData: {},
    };
  }

  toast = new ToastNotification();

  toggleModalState = () => {
    this.setState({
      updateContactDetailsModal: !this.state.updateContactDetailsModal,
    });
  };

  listContacts = () => {
    let model = {
      businessProfileId: this.props.businessProfileId,
    };
    get('business-profile-contacts', model)
      .then((response) => {
        this.setState({ contactList: response });
      })
      .catch(() => {});
  };

  onLightBoxClick = (row) => {
    this.setState({
      showLightBox: true,
      imageUrl: row.mediaUrl,
    });
  };

  componentDidMount() {
    this.listContacts();
  }

  render() {
    let { contactList } = this.state;
    let { businessProfileId } = this.props;
    return (
      <div>
        {this.state.childCallType == 'add' && (
          <ManageContact
            callType={this.state.childCallType}
            showCheck={this.state.updateContactDetailsModal}
            toggleState={this.toggleModalState}
            refreshContacts={this.listContacts}
            businessProfileId={businessProfileId}
          />
        )}
        <h4 className="font-size-18" title="The point of contacts for this profile">
          Contact Persons
        </h4>
        <Card className="enable-scroll">
          <CardBody>
            <Row className="align-items-center">
              {/* <Col sm={6}>
                <p>The point of contacts for this profile</p>
              </Col> */}
              <Col>
                <div className="float-right   d-md-block">
                  <Button
                    className="btn btn-primary waves-effect waves-light"
                    disabled={businessProfileId == 0 && true}
                    onClick={() => {
                      this.toggleModalState();
                      this.setState({ childCallType: 'add' });
                    }}
                  >
                    Add Contact
                  </Button>
                  &nbsp;
                </div>
              </Col>
            </Row>

            <div className="row ">
              <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                <DataTable>
                  <THead>
                    <TRow>
                      <DataColHead
                        {...this.setheadproperties({
                          title: 'Image',
                        })}
                      />
                      <DataColHead
                        {...this.setheadproperties({
                          title: 'Name',
                        })}
                      />
                      <DataColHead
                        {...this.setheadproperties({
                          title: 'Designation',
                        })}
                      />
                      <DataColHead
                        {...this.setheadproperties({
                          title: 'Contact Type',
                        })}
                      />
                      <DataColHead
                        {...this.setheadproperties({
                          title: 'Contact',
                        })}
                      />
                      <DataColHead
                        {...this.setheadproperties({
                          title: 'Action',
                        })}
                      />
                    </TRow>
                  </THead>
                  {this.state.childCallType == 'edit' && (
                    <ManageContact
                      rowData={this.state.rowData}
                      callType={this.state.childCallType}
                      showCheck={this.state.updateContactDetailsModal}
                      toggleState={this.toggleModalState}
                      refreshContacts={this.listContacts}
                      businessProfileId={businessProfileId}
                    />
                  )}
                  <TBody>
                    {contactList && contactList.data && contactList.data.length > 0
                      ? contactList.data.map((row) => (
                          <TRow>
                            <TCol>
                              {row.imageUrl ? (
                                <img
                                  onClick={() => this.onLightBoxClick()}
                                  data-dz-thumbnail=""
                                  height="50"
                                  className="avatar-sm rounded bg-light"
                                  alt="Business Contact "
                                  src={row.imageUrl}
                                />
                              ) : (
                                <div className="default-image-container">
                                  <DefaultImage rowData={row} firstName="name" />
                                </div>
                              )}
                            </TCol>
                            <TCol>{row.name}</TCol>
                            <TCol>{row.designation}</TCol>
                            <TCol>{row.typeName} </TCol>
                            {row.contactType == 1 && <TCol>{row.phoneNumber} </TCol>}
                            {row.contactType == 2 && <TCol>{row.email} </TCol>}
                            {row.contactType == 3 && <TCol>{row.whatsappNumber} </TCol>}
                            <TCol>
                              <div className="action-col">
                                &nbsp;&nbsp;&nbsp; &nbsp;
                                <Link
                                  href="as"
                                  className="fas fa-edit mdi-18px"
                                  onClick={() => {
                                    this.setState({
                                      childCallType: 'edit',
                                      rowData: row,
                                    });
                                    this.toggleModalState();
                                  }}
                                />
                                &nbsp;&nbsp;&nbsp; &nbsp;
                                <ActionButton value="delete" rowId={row.id} api="business-profile-contacts/" renderList={this.listContacts} />
                              </div>
                            </TCol>
                          </TRow>
                        ))
                      : null}
                  </TBody>
                </DataTable>
                {contactList.data && contactList.data.length === 0 && <div className="text-center no-content-block">No contacts available </div>}
              </DataTableHolder>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}
export default BusinessContacts;
