import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import ListComponent from '../../ListComponents/listComponent';
import { get } from '../../../Libraries/Ajax/httpService';
import ValidationMessage from '../Validation/validationMessage';

class MultiSelectCheckBox extends ListComponent {
  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.state = {
      errors: [],
      apiResponse: [],
      message: '',
      messageType: 'i',
    };
  }

  handleOnChange(e, option) {
    let properties = {
      ...this.props,
    };
    let roles = [...this.state.apiResponse];
    roles.forEach((role) => {
      if (role.id === option.id) {
        role.isChecked = !role.isChecked;
      }
    });
    let obj = {};
    obj.selectedRoles = roles;
    let selected = roles.filter((role) => role.isChecked);

    properties.value = selected;
    if (properties.onChangeValidation) {
      properties.onChangeValidation(e, properties);
    }
  }

  componentDidMount() {
    get(this.props.renderApi).then((response) => {
      this.setState({ apiResponse: response.data.data.rows });
    });
  }

  isStateUpdated = false;

  updateApiResponse = (obj) => this.setState({ apiResponse: obj });

  componentDidUpdate() {
    if (this.props.value && this.state.apiResponse && this.state.apiResponse.length > 0 && this.isStateUpdated === false) {
      this.isStateUpdated = true;
      let obj = [...this.state.apiResponse];
      obj.forEach((option) => {
        this.props.value.forEach((role) => {
          if (option.id === role.id) {
            option.isChecked = true;
          }
        });
      });
      this.updateApiResponse(obj);
    }
  }

  render() {
    let { label, rules, errors: [errFromProp] = [], isSilent, disableRequiredsymbol, isTouched, isFocused } = this.props;
    let { apiResponse } = this.state;
    let message = '';

    if (errFromProp) {
      ({ message } = errFromProp);
    }
    let messageType = 'i';
    if (isTouched) {
      messageType = 'e';
    }
    if (!isFocused || isSilent) {
      messageType = '';
    }
    return (
      <div className="form-group">
        <Form.Label>
          {label || 'Roles'}
          {rules && rules.required && !disableRequiredsymbol ? <sup>*</sup> : ''}
        </Form.Label>
        <Row>
          <Col className="access-group-list">
            {apiResponse
              ? apiResponse.map((option) => (
                  <div className="__checkbox mr-5 float-left mb-4" key={option.id}>
                    <div className="__checkbox">
                      <input
                        type="checkbox"
                        id={option.id}
                        value={option.id}
                        checked={option.isChecked}
                        onChange={(e) => this.handleOnChange(e, option)}
                      />
                      <label style={{ cursor: 'pointer' }} htmlFor={option.id}>
                        {' '}
                        {option.title}
                      </label>
                    </div>
                  </div>
                ))
              : null}
          </Col>
        </Row>
        {messageType ? <ValidationMessage message={message} /> : <span className="app-c-validation-message" />}
      </div>
    );
  }
}

export default MultiSelectCheckBox;
