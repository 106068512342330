import React, { Component } from 'react';
import { Polar } from 'react-chartjs-2';

class PolarChart extends Component {
  render() {
    let data = {
      datasets: [
        {
          data: [11, 16, 7, 18],
          backgroundColor: ['#38a4f8', '#02a499', '#ec4561', '#3c4ccf'],
          label: 'My dataset', // for legend
          hoverBorderColor: '#fff',
        },
      ],
      labels: ['Series 1', 'Series 2', 'Series 3', 'Series 4'],
    };

    let option = {
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            let dataset = data.datasets[tooltipItem.datasetIndex];
            let meta = dataset._meta[Object.keys(dataset._meta)[0]];
            let { total } = meta;
            let currentValue = dataset.data[tooltipItem.index];
            let percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
            return `${currentValue} (${percentage}%)`;
          },
          title(tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          },
        },
      },
    };

    return (
      <React.Fragment>
        <Polar width={600} height={245} data={data} options={option} />
      </React.Fragment>
    );
  }
}

export default PolarChart;
