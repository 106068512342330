import { Component } from 'react';
import Validation from '../../../Libraries/Validations/validation';

export default class FormComponent extends Component {
  constructor(props) {
    super(props);

    this.setProperties = this.setProperties.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
    this.onNativeChange = this.onNativeChange.bind(this);
    this.onReset = this.onReset.bind(this);
    this.setproperties = this.setproperties.bind(this);

    this.onBlurValidation = this.onBlurValidation.bind(this);

    this.isFormValid = this.isFormValid.bind(this);
    this.setParentState = this.setParentState.bind(this);
    this.getParentState = this.getParentState.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  mapMultiListJsonToState(list, stateKey, uniquKey, applyRowChanges, applyStateChanges) {
    let listuniqueKey = uniquKey.charAt(0).toLowerCase() + uniquKey.slice(1);
    Object.keys(this.state[stateKey].items).forEach((index) => {
      Object.keys(list).forEach((listIndex) => {
        if (list[listIndex][listuniqueKey] === this.state[stateKey].items[index][uniquKey].value) {
          let obj = list[listIndex];
          let currentStateValues = this.state[stateKey].items[index];
          if (applyRowChanges) {
            currentStateValues = applyRowChanges(currentStateValues, obj);
          }
          Object.keys(currentStateValues).forEach((formItem) => {
            let properties = currentStateValues[formItem];
            if (properties && properties.name) {
              let fieldName = formItem.charAt(0).toLowerCase() + formItem.slice(1);

              let jsonValue = obj[fieldName];
              if (formItem && properties && properties.name && typeof jsonValue !== 'undefined') {
                properties.errors = [];
                properties.value = jsonValue;
              }
            }
          });
          if (applyStateChanges) {
            currentStateValues = applyStateChanges(currentStateValues);
          }
        }
      });
    });
    let newObj = {};
    newObj[stateKey] = { ...this.state[stateKey] };
    this.setState(newObj);
    this.setValidation(true);
  }

  currentJson = {};

  mapJsonToState(obj) {
    if (JSON.stringify(this.currentJson) !== JSON.stringify(obj)) {
      let stateObjects = {};
      Object.keys(this.state).forEach((formItem) => {
        let properties = this.state[formItem];

        if (properties && properties.label && !properties.disableInMapJson) {
          let fieldName = formItem.charAt(0).toLowerCase() + formItem.slice(1);

          let jsonValue = obj[fieldName];

          if (properties.subList) {
            let subListRealName = properties.subList.charAt(0).toLowerCase() + properties.subList.slice(1);
            let subListFieldName = fieldName.replace(subListRealName, '');
            subListFieldName = subListFieldName.charAt(0).toLowerCase() + subListFieldName.slice(1);

            let subListName = `${subListRealName}List`;
            if (properties.subList && obj[subListName] && obj[subListName].length > 0) {
              jsonValue = obj[subListName][0][subListFieldName];
            }
          }
          if (properties.isCollection) {
            let subListFieldName = fieldName;
            properties.errors = [];
            properties.value = jsonValue;
            stateObjects[subListFieldName] = { ...properties };
          } else if (properties.attachmentName) {
            let subListFieldName = fieldName;
            properties.errors = [];
            properties.value = jsonValue;
            stateObjects[subListFieldName] = { ...properties };
          } else if (formItem && properties && properties.label && jsonValue && properties.name) {
            if (properties.value !== jsonValue) {
              properties.errors = [];
              properties.value = jsonValue;
              stateObjects[properties.name] = { ...properties };
            }
          } else if (
            formItem &&
            properties &&
            properties.label &&
            properties.allowZero &&
            properties.type == 'number' &&
            jsonValue >= 0 &&
            properties.name
          ) {
            if (properties.value !== jsonValue) {
              properties.errors = [];
              properties.value = jsonValue;
              stateObjects[properties.name] = { ...properties };
            }
          }
        }
      });
      if (Object.keys(stateObjects).length > 0) {
        this.setState(stateObjects);
      }
      this.currentJson = obj;
      this.setValidation(true);
    }
  }

  setValidation(isEditing) {
    let isValid = true;
    Object.keys(this.state).forEach((formItem) => {
      let properties = this.state[formItem];

      if (formItem && properties && properties.label) {
        properties.errors = [];

        if (properties.rules) {
          Object.keys(properties.rules).forEach((rule) => {
            if (Object.prototype.hasOwnProperty.call(properties.rules, rule) && properties.rules[rule]) {
              let validation = this.validation.validate({ key: rule, value: properties.rules[rule] }, properties);
              if (validation) {
                properties.errors.push(validation);
              }
            }
          });
        }
        let obj = {};
        if (isEditing) {
          properties.isTouched = true;
          properties.isFocused = true;
          if (properties.errors.length > 0) {
            this.validate(properties);
          }
        }
        obj[properties.name] = { ...properties };

        this.setState(obj);
      }
    });
    return isValid;
  }

  setParentState(properties, callback) {
    // if (JSON.stringify(this.currentJson) !== '{}' && properties.contentGroupList && !properties.contentGroupList.value) {
    // }

    if (callback) {
      this.setState(properties, callback);
    } else {
      this.setState(properties);
    }
  }

  getParentState(key) {
    return this.state[key];
  }

  isValidForm = true;

  onNativeChange(e, properties) {
    properties.isTouched = true;
    this.onChangeValidation(e, properties);
  }

  onChangeValidation(e, properties) {
    if (this.state.serverErrorResponse) {
      this.setState({
        serverErrorResponse: '',
      });
    }
    let item = {};
    if (properties.item) {
      item = { item: properties.item };
    }

    let disableReactiveForm = false;
    if (this.disableReactiveForm) {
      if (this.isNotSubmitted) {
        disableReactiveForm = true;
      }
    }
    if (this.state[properties.name]) {
      properties = {
        ...this.state[properties.name],
        ...item,
        value: properties.value,
        isTouched: properties.isTouched,
        isFocused: true,
        match: properties.match,
      };
      if (properties.isFocused && !disableReactiveForm) {
        this.validate(properties);
      } else {
        this.validate(properties, true);
      }
    } else if (properties.isMultiListItem) {
      let multiList = this.state[properties.stateKey];
      properties = {
        ...multiList.items[properties.index][properties.name],
        ...item,
        value: properties.value,
        isTouched: properties.isTouched,
        isFocused: true,
        match: properties.match,
      };
      this.validate(properties);
    }
  }

  onBlurValidation = () => {};

  validationScroll = true;

  propertiesList = {};

  validate(properties, silent, matchvalidate) {
    let validationReports = [];
    properties.errors = [];
    if (silent) {
      properties.isSilent = true;
    } else {
      properties.isSilent = false;
    }
    if (properties.rules && typeof properties.rules.match != 'undefined' && properties.match && properties.match.value) {
      properties.rules.match = properties.match.value;
      if (!matchvalidate) {
        this.validate(this.state[properties.match.name], true, true);
      }
    }

    Object.keys(properties.rules).forEach((rule) => {
      if (Object.prototype.hasOwnProperty.call(properties.rules, rule) && properties.rules[rule]) {
        let validation = this.validation.validate({ key: rule, value: properties.rules[rule] }, properties);
        if (validation) {
          validationReports.push(validation);
        }
      }
    });

    let validation = validationReports.length > 0 ? validationReports[0] : null;

    if (validation && validation.message) {
      properties.errors.push(validation);
    }

    let obj = {};

    if (this.state[properties.name]) {
      obj[properties.name] = { ...properties };
      this.propertiesList[properties.name] = { ...properties };

      this.setState(obj);
      if (properties.rules && properties.rules.match && properties.match && properties.match.value) {
        obj = {};
        obj[properties.match.name] = { ...properties.match, errors: [] };

        this.setState(obj);
      }
    } else if (properties.isMultiListItem) {
      obj[properties.stateKey] = this.state[properties.stateKey];
      obj[properties.stateKey].items[properties.index][properties.name] = {
        ...obj[properties.stateKey].items[properties.index][properties.name],
        ...properties,
      };
      this.setState(obj);
    }
    return validation;
  }

  validation = new Validation();

  onReset() {
    Object.keys(this.state).forEach((formItem) => {
      let properties = this.state[formItem];
      if (formItem && properties && properties.name && !properties.multiList) {
        properties.value = '';
        if (properties.item) {
          properties.item = null;
        }
        let obj = {};
        obj[properties.name] = { ...properties };

        this.setState(obj);
      }
    });
  }

  submitForm(submitAction) {
    this.isValidForm = this.isFormValid();
    this.validationScroll = true;

    let model = {};
    if (this.currentJson) {
      model = this.currentJson;
    }
    if (this.isValidForm) {
      Object.keys(this.state).forEach((formItem) => {
        let properties = this.state[formItem];
        if (properties.value === '' && properties.type === 'date') {
          properties.value = null;
        }
        if (properties.value === '' && properties.defaultValue !== undefined) properties.value = properties.defaultValue;

        if (properties.type === 'number') {
          if (!properties.value) {
            properties.value = 0;
          } else {
            properties.value = Number(properties.value);
          }
        }
        if (properties.isCollection) {
          if (properties.value) {
            model[properties.name] = properties.value;
          }
        } else if (properties.attachmentName) {
          if (properties.value) {
            model[properties.name] = properties.value;
          }
        } else if (properties.subList) {
          if (properties.value) {
            if (!model[`${properties.subList}List`]) {
              model[`${properties.subList}List`] = [];
            }
            if (model[`${properties.subList}List`].length === 0) {
              model[`${properties.subList}List`].push({});
            }
            let propName =
              properties.name.replace(properties.subList, '').charAt(0).toLowerCase() + properties.name.replace(properties.subList, '').slice(1);
            model[`${properties.subList}List`][0][propName] = properties.value;
          }
        } else if (formItem && properties && properties.name && !properties.multiList) {
          model[properties.name] = properties.value;
        } else if (formItem && properties && properties.multiList) {
          let listItems = [];
          Object.keys(properties.items).forEach((itemindex) => {
            let subModel = {};
            // eslint-disable-next-line no-redeclare
            Object.keys(properties.items[itemindex]).forEach((subFormItem) => {
              // eslint-disable-next-line no-redeclare
              if (properties.items[itemindex][subFormItem]) {
                let property = properties.items[itemindex][subFormItem];
                if (subFormItem && property && property.name) {
                  subModel[property.name] = property.value;
                }
              }
            });

            listItems.push(subModel);
          });
          model[properties.name] = listItems;
        }
      });
      this.disableReactiveForm = true;
      this.isNotSubmitted = false;

      submitAction(model);
    } else {
      let elementName;
      Object.keys(this.propertiesList).forEach((formItem) => {
        let properties = this.propertiesList[formItem];
        if (properties) {
          if (properties.errors?.length > 0 && this.validationScroll) {
            let modalPopup = document.querySelector('.modal.show');
            if (modalPopup) {
              elementName = `.modal.show [name= '${properties.name}' ]`;
            } else {
              elementName = `[name= '${properties.name}' ]`;
            }

            let element = document.querySelector(elementName);

            if (element != null) {
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }

            this.validationScroll = false;
          }
        }
      });
    }
  }

  isFormValid(isSilent) {
    if (!isSilent) {
      this.isNotSubmitted = false;
    }
    let isValid = true;
    Object.keys(this.state).forEach((formItem) => {
      let properties = this.state[formItem];
      let errors = [];
      if (formItem && properties && properties.name && properties.rules && !properties.items) {
        Object.keys(properties.rules).forEach((rule) => {
          if (Object.prototype.hasOwnProperty.call(properties.rules, rule) && properties.rules[rule]) {
            if (properties.rules && properties.rules.match && properties.match && properties.match.name) {
              properties.rules.match = this.state[properties.match.name].value;
              properties.match.value = this.state[properties.match.name].value;
            }
            let validation = this.validation.validate({ key: rule, value: properties.rules[rule] }, properties);
            if (validation) {
              isValid = false;

              errors.push(validation);
              if (!isSilent) {
                properties.isTouched = true;
                this.onChangeValidation(null, properties);
              }
            }
          }
        });
        if (errors.length === 0 && properties) {
          properties.errors = [];
        }
      } else if (formItem && properties && properties.isCollection && properties.items) {
        let isSetProperty = false;
        Object.keys(properties.items).forEach((itemindex) => {
          let subFormItem = properties.items[itemindex];
          if (properties.value && properties.value.length > 0) {
            Object.keys(properties.value).forEach((valueIndex) => {
              let valueObject = properties.value[valueIndex];
              if (!valueObject.properties) {
                isSetProperty = true;
                valueObject.properties = JSON.parse(JSON.stringify(properties.items));
              }
              let subProperty = valueObject.properties[subFormItem.name];
              if (!isSilent) {
                subProperty.errors = [];
              }
              subProperty.value = valueObject[subFormItem.name];
              if (subProperty && subProperty.name && subProperty.rules) {
                Object.keys(subProperty.rules).forEach((rule) => {
                  if (Object.prototype.hasOwnProperty.call(subProperty.rules, rule) && subProperty.rules[rule]) {
                    let validation = this.validation.validate({ key: rule, value: subProperty.rules[rule] }, subProperty);
                    if (validation) {
                      isValid = false;
                      if (!isSilent) {
                        subProperty.isTouched = true;
                        subProperty.errors.push(validation);
                      }
                    }
                  }
                });
              }
            });
          }
        });
        if (!isSilent || isSetProperty) {
          this.onChangeValidation(null, properties);
        }
      }
    });
    return isValid;
  }

  setFormProperties(properties) {
    if (!properties) {
      properties = {};
    }
    if (typeof this.isNotSubmitted == 'undefined') {
      this.isNotSubmitted = true;
    }
    this.isValidForm = this.isFormValid(true);
    this.disableReactiveForm = !!properties.disableReactiveForm;

    return {
      ...properties,
      onReset: this.onReset,
      submitForm: this.submitForm,
      setParentState: this.setParentState,
    };
  }

  setMultiListItemProperties(properties, index, stateKey) {
    properties.isMultiListItem = true;
    properties.index = index;
    properties.stateKey = stateKey;
    return this.setProperties(properties);
  }

  setProperties(properties) {
    if (!properties.disableplaceholder) {
      if (typeof properties?.placeholder == 'undefined') {
        properties.placeholder = `Enter ${properties.label}`;
      }
    } else {
      properties.placeholder = '';
    }
    if (typeof properties.name == 'undefined') {
      properties.name = properties.label.replace(/ /gi, '');
      if (this.state && !this.state[properties.name]) {
        let name = properties.name.charAt(0).toLowerCase() + properties.name.slice(1);
        if (this.state[name]) {
          properties.name = name;
        }
      }
    }

    if (typeof properties.type == 'undefined') {
      properties.type = 'text';
    }
    if (typeof properties.errors == 'undefined') {
      properties.errors = [];
    }
    if (typeof properties.rules == 'undefined') {
      properties.rules = {};
    }
    if (typeof properties.value == 'undefined') {
      properties.value = '';
    }
    if (typeof properties.relatedPropertyName != 'undefined') {
      properties.relatedProperty = this.state[properties.relatedPropertyName];
    }

    return {
      ...properties,
      isSubmitted: this.state.isSubmitted,
      isFormValid: this.isFormValid,
      setParentState: this.setParentState,
      onChangeValidation: this.onChangeValidation,
      onBlurValidation: this.onBlurValidation,
      getParentState: this.getParentState,
      setProperties: this.setProperties,
    };
  }

  setproperties(properties) {
    return {
      ...properties,
      setParentState: this.setParentState,
      setpredicates: this.setpredicates,
      getpredicates: this.getpredicates,
      getParentState: this.getParentState,
      setproperties: this.setproperties,
      model: this.model,
    };
  }
}
