import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { get, put } from '../../Libraries/Ajax/httpService';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import CustomDatePicker from '../../SharedComponents/FormHelpers/Controls/customDatePicker';
import { combineDateTime, convertToLocalISOString, formatDate } from '../../Libraries/DateTimeHelpers/dateTimeHelperFunctions';
import RadioButton from '../../SharedComponents/FormHelpers/Controls/radioButton';

class PublishProfileComponent extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      publishedOnBehalfOf: {
        label: 'Publish on behalf of',
        name: 'publishedOnBehalfOf',
        type: 'number',
        optionvalue: 'roleId',
        optiontext: 'roleTitle',
        rules: { required: true },
        options: [
          { roleId: 9, roleTitle: 'Customer' },
          { roleId: 1, roleTitle: 'Partner Manager' },
        ],
      },
      publishedDate: {
        label: 'Date of publish',
        name: 'publishedDate',
        type: 'date',
        placeholder: 'Enter published date',
        rules: { required: true },
        value: convertToLocalISOString(Date.now()),
      },
      publishedTime: {
        label: 'Time of Publish',
        name: 'publishedTime',
        type: 'string',
        placeholder: 'Enter published time',
        relatedPropertyName: 'publishedDate',
        rules: {
          required: true,
          minNow: true,
        },
        value: convertToLocalISOString(Date.now()),
      },
      validationMessageList: [],
      period: null,
      price: null,
    };
  }

  componentDidMount() {
    let { createdByRoleId } = this.props;
    this.setState((prevState) => ({
      publishedDate: {
        ...prevState.publishedDate,
        value: prevState.publishedDate.value.replace(/Z/i, ''),
      },
      publishedOnBehalfOf: {
        ...prevState.publishedOnBehalfOf,
        value: createdByRoleId === 1 || createdByRoleId === 9 ? createdByRoleId : null,
        isReadOnly: createdByRoleId === 1 || createdByRoleId === 9,
      },
    }));
  }

  async componentDidUpdate(prevProps, prevState) {
    let { publishedDate, publishedTime, publishedOnBehalfOf } = this.state;
    let { commissionRate } = this.props;
    let expiryPeriod;
    let pricePerProfile;
    if (publishedDate.value.length > 0 && publishedTime.value.length > 0) {
      if (
        prevState.publishedDate.value !== publishedDate.value ||
        prevState.publishedTime.value !== publishedTime.value ||
        prevState.publishedOnBehalfOf.value !== publishedOnBehalfOf.value
      ) {
        let model = {
          compareDate: new Date(combineDateTime(publishedDate.value, publishedTime.value)),
          roleId: publishedOnBehalfOf.value,
          commissionRate: publishedOnBehalfOf.value === 1 ? commissionRate : null,
        };
        await get('memorial-expiry', model).then((response) => {
          response.data = response.data.data;
          if (response.data && response.data.rows && response.data.rows.length > 0) {
            let item = response.data.rows[0];
            ({ expiryPeriod } = item);
          }
        });
        await get('memorial-pricing', model).then((response) => {
          response.data = response.data.data;
          if (response.data && response.data.rows && response.data.rows.length > 0) {
            let item = response.data.rows[0];
            ({ pricePerProfile } = item);
          }
        });
        this.updateState(expiryPeriod, pricePerProfile);
      }
    }
  }

  updateState = (period, price) => {
    this.setState({ period, price });
  };

  onSaveButtonClick = (model) => {
    let { formRef, obituaryId, toast, setParentState, statusChange, partnerId, loggedInUserRoleId } = this.props;
    let { period, price } = this.state;
    if (loggedInUserRoleId === 1) {
      delete model.publishedOnBehalfOf;
    }
    model.statusId = 1;
    let statusTitle = 'Published';
    let toastMessage = 'The Obituary Profile has been sucessfully published';
    let publishedDateTime = combineDateTime(model.publishedDate, model.publishedTime);
    if (new Date(publishedDateTime.replace(/Z/i, '')).getTime() > new Date().getTime()) {
      statusTitle = 'To Be Published';
      toastMessage = 'The Obituary Profile will be published in the future';
    }
    if (model.publishedOnBehalfOf === 1) {
      model.partnerId = partnerId;
    }
    model.expiryPeriod = period;
    model.pricePerProfile = price;
    model.publishedDate = formatDate(model.publishedDate);
    if (!publishedDateTime.toUpperCase().includes('.000Z')) {
      publishedDateTime = publishedDateTime.concat('.000Z');
    }
    put(`obituary-profile/subscription/${obituaryId}`, model)
      .then((response) => {
        if (response.data.data && response.data.success) {
          setParentState({
            stsId: 1,
            stsTitle: statusTitle,
            publishDate: publishedDateTime,
            publishTime: response.data.data.publishedTime,
            expiryDate: convertToLocalISOString(response.data.data.expiryDate),
          });
          statusChange(1, 'Published');
          formRef.onClose();
          toast.show('update_success', 'SUCCESS', toastMessage);
        } else if (!response.data.success && response.data.data && response.data.data.length > 0) {
          this.setState({ validationMessageList: response.data.data });
        } else {
          formRef.onClose();
          toast.show('update_error', 'ERROR', "Couldn't update status. Please try after some time.");
        }
      })
      .catch((err) => this.toast.show('update_error', 'ERROR', err.message ? err.message : "Couldn't update. Please try after some time."));
  };

  render() {
    let {
      validationMessageList,
      period,
      price,
      publishedOnBehalfOf: { value },
    } = this.state;
    let { formRef, loggedInUserRoleId } = this.props;

    return (
      <React.Fragment>
        <Card className="mb-0">
          <CardBody>
            <p>To publish right away press the Publish button.</p>
            <p>To schedule the publication of this obituary profile, pick a date from the calender and then press Publish.</p>
            <SubmitForm onSubmit={this.onSaveButtonClick} {...this.setFormProperties({ disableReactiveForm: true })} formRef={formRef}>
              <Row>
                <Col sm={12} md={6}>
                  <CustomDatePicker
                    formGroup
                    appearance="subtle"
                    futureDate
                    format="DD/MM/YYYY"
                    isDate
                    oneTap
                    placement="auto"
                    {...this.setProperties(this.state.publishedDate)}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <CustomDatePicker
                    formGroup
                    appearance="subtle"
                    format="HH:mm"
                    isDate={false}
                    oneTap={false}
                    placement="auto"
                    {...this.setProperties(this.state.publishedTime)}
                  />
                </Col>
                {loggedInUserRoleId !== 1 ? (
                  <Col sm={12} md={6}>
                    <RadioButton {...this.setProperties(this.state.publishedOnBehalfOf)} />
                  </Col>
                ) : null}
              </Row>
            </SubmitForm>
            {validationMessageList && validationMessageList.length > 0
              ? validationMessageList.map((message) => <p style={{ color: 'red', marginBottom: `${0}px` }}>{message}</p>)
              : null}
            {period && value && <p>Validty: {period} months</p>}
            {price && value && <p>Publish Rate: &euro; {price}</p>}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default PublishProfileComponent;
