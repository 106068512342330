import React from 'react';
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { DataTable, TBody, THead, TCol, TRow, HeadingBlock, DataTableHolder } from '../../SharedComponents/Table/tableElements';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
import ListMediaCard from '../Post/listMediaCard';
import ManageMediaCard from '../Post/manageMediaCard';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import Loader from '../../Components/loader';
import { StatusSwitch } from '../../SharedComponents/FormHelpers/Controls/statusSwitch';
import { ActionButton } from '../../SharedComponents/FormHelpers/Controls/actionButton';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';

class ListTemplateMessageContainer extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      showLightBox: false,
      imageUrl: '',
      singleSelect: false,
      multiSelect: false,
      rowData: {},
      isLoaderActive: false,
      showDeleteConfirmation: false,
      page: '',
      dummy: 0,
    };
  }

  onTitleName = (str) => {
    let words = str.match(/([^-]+)/g) || [];
    words.forEach((word, i) => {
      words[i] = word[0].toUpperCase() + word.slice(1);
    });
    return words.join(' ');
  };

  setPrivilege = () => {
    let page = '';
    switch (this.props.match.params.value) {
      case 'message':
        page = 'Message Templates';
        break;
      case 'background-image':
        page = 'Background Images';
        break;
      case 'solid-colors':
        page = 'Solid Colors';
        break;
      case 'tribute-card':
        page = 'Tribute Cards';
        break;
    }
    this.setState({ access: setComponentPrivilege(page) });
  };

  componentDidMount() {
    this.setJsonUrl('template-message');
    if (this.props.match.params.value !== 'message') this.setpredicates({ take: 30, page: 0 });
    else this.setpredicates({ take: 10, page: 0 });
    this.setPrivilege();
  }

  onLightBoxClick = (row) => {
    this.setState({
      showLightBox: true,
      imageUrl: row.mediaUrl,
    });
  };

  onDidUpdate = (params) => {
    let { value } = params;
    this.setState({ page: this.props.match.params.value, apiResponse: {} });
    document.title = `${this.onTitleName(params.value)} | Memlo - Admin Panel`;
    if (this.props.match.params.value !== 'message') {
      // NEWLY ADDED
      if (this.props.match.params.value == 'background-image' || this.props.match.params.value == 'solid-colors') {
        this.renderApi('background-template');
        this.setpredicates({
          take: 30,
          page: 0,
          isBackground: value === 'background-image',
        });
      } else {
        this.renderApi('template-message');
        this.setState({
          isImage: true,
          posttype: `${this.props.match.params.value}`,
        });
        this.setpredicates({
          take: 30,
          page: 0,
          isImage: true,
          postType: `${this.props.match.params.value}`,
        });
      }
      // ******
    } else {
      this.renderApi('template-message');
      this.setpredicates({
        take: 10,
        page: 0,
        isImage: false,
        postType: `${this.props.match.params.value}`,
      });
    }
  };

  componentDidUpdate() {
    let {
      match: { params },
    } = this.props;
    if (this.state.page !== this.props.match.params.value) {
      this.onDidUpdate(params);
      this.setPrivilege();
    }
  }

  render() {
    let { apiResponse, isLoaderActive, page, access, rowData } = this.state;
    let pageName = this.onTitleName(page);
    if (pageName === 'Message') pageName = 'Message Template';

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              {isLoaderActive === true ? <Loader /> : ''}
              <HeadingBlock title={pageName} />
            </Col>
            {access && access.create && (
              <Col sm={6}>
                <div className="float-right  d-md-block">
                  <Link className="btn btn-primary waves-effect waves-light" to={`/manage-template/${page}`} role="button">
                    Add {pageName}
                  </Link>
                </div>
              </Col>
            )}
          </Row>
          <div className="row">
            <div className="col-12 m-0 p-0">
              <Card className={this.props.match.params.value !== 'message' ? 'remove-card-background' : 'template-image-cards'}>
                <CardBody className="p-0">
                  <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                    {/* <div class="row"> */}
                    <DataTableHolder
                      pagination={
                        apiResponse && apiResponse.rows && apiResponse.rows.length > 0 ? (
                          <ListPagination
                            {...this.setpagination()}
                            {...(page != 'message' && {
                              customOptions: [15, 30, 45, 60, 75],
                            })}
                          />
                        ) : null
                      }
                    >
                      <div>
                        <DataTable>
                          <THead>
                            <TRow>
                              {page == 'message' && (
                                <>
                                  <DataColHead
                                    {...this.setheadproperties({
                                      title: 'Age From',
                                      enableSort: true,
                                      filterClassName: 'app-l-condensed-column',
                                    })}
                                  />
                                  <DataColHead
                                    {...this.setheadproperties({
                                      title: 'Age To',
                                      enableSort: true,
                                      filterClassName: 'app-l-condensed-column',
                                    })}
                                  />
                                  <DataColHead
                                    {...this.setheadproperties({
                                      title: 'Gender',
                                      sublist: 'Gender',
                                      coltype: 'filter',
                                      filterapi: 'gender',
                                      sublistFilterField: 'gender',
                                      filterClassName: 'app-l-condensed-column',
                                    })}
                                  />
                                  <DataColHead
                                    {...this.setheadproperties({
                                      title: 'Type',
                                      sublist: 'Status',
                                      coltype: 'filter',
                                      filterapi: 'post-type?isImage=false&isAll=true',
                                      sublistFilterField: 'type',
                                      filterClassName: 'app-l-condensed-column',
                                    })}
                                  />
                                  <DataColHead
                                    {...this.setheadproperties({
                                      title: 'Message Template ',
                                      enableSearch: true,
                                      enableSort: true,
                                      sortField: 'message',
                                      searchField: 'message',
                                      className: 'app-l-search-column-message-template',
                                    })}
                                  />
                                  <DataColHead
                                    {...this.setheadproperties({
                                      title: 'Status',
                                      width: 70,
                                      sublist: 'Status',
                                      coltype: 'filter',
                                      filterapi: 'master-data/status',
                                      sublistFilterField: 'status',
                                      filterClassName: 'app-l-condensed-column',
                                    })}
                                  />
                                  <DataColHead
                                    {...this.setheadproperties({
                                      title: 'Action',
                                      filterfield: 'action',
                                      width: 120,
                                      className: 'action',
                                    })}
                                  />
                                </>
                              )}
                            </TRow>
                          </THead>
                          {/* **********Lightbox Content************* */}
                          {this.state.showLightBox ? (
                            <Lightbox
                              mainSrc={this.state.imageUrl}
                              clickOutsideToClose
                              enableZoom
                              onCloseRequest={() => this.setState({ showLightBox: false })}
                            />
                          ) : null}
                          {/* **********Lightbox Content************* */}
                          {page == 'message' && (
                            <TBody>
                              {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                                ? apiResponse.rows.map((row, i) => (
                                    <TRow key={`${i}-template`}>
                                      {page != 'message' && (
                                        <TCol>
                                          <a href="#lightBox">
                                            <img
                                              onClick={() => this.onLightBoxClick(row)}
                                              data-dz-thumbnail=""
                                              height="150"
                                              width="auto"
                                              className="rounded bg-light"
                                              alt={row.image}
                                              src={row.mediaUrl}
                                            />
                                          </a>
                                        </TCol>
                                      )}
                                      {page == 'message' && (
                                        <>
                                          <TCol>{row.ageFrom}</TCol>
                                          <TCol>{row.ageTo}</TCol>
                                          <TCol>{row.genderTitle}</TCol>
                                          <TCol>{row.typeTitle}</TCol>
                                          <TCol>
                                            <p
                                              className="text-entry"
                                              dangerouslySetInnerHTML={{
                                                __html: row.message ? row.message : '-',
                                              }}
                                            />
                                          </TCol>
                                        </>
                                      )}
                                      <TCol>
                                        {access && access.edit ? (
                                          <StatusSwitch
                                            isListIcon
                                            value={row.statusId}
                                            name="statusId"
                                            rowData={row}
                                            updateApi={`template-message/${row.id}`}
                                            apiResponse={apiResponse}
                                            setParentState={this.setParentState}
                                            valueObj={{ on: 1, off: 2 }}
                                          />
                                        ) : (
                                          row.statusTitle
                                        )}
                                      </TCol>
                                      <TCol>
                                        <div className="action-col">
                                          {access && access.edit && <ActionButton value="edit" rowId={row.id} url={`/manage-template/${page}/`} />}{' '}
                                          <ActionButton value="view" rowId={row.id} url={`/view-template/${page}/`} />
                                          {access && access.delete && (
                                            <ActionButton
                                              value="delete"
                                              rowId={row.id}
                                              url={`/list-template/${page}`}
                                              setJsonUrl={this.setJsonUrl}
                                              setpredicates={this.setpredicates}
                                              api="template-message/"
                                            />
                                          )}
                                        </div>
                                      </TCol>
                                    </TRow>
                                  ))
                                : null}
                            </TBody>
                          )}
                        </DataTable>

                        {page != 'message' && (
                          <>
                            <DataTable className="width-250 template-status-filter">
                              <THead className="card-table-header ">
                                <TRow>
                                  <DataColHead
                                    {...this.setheadproperties({
                                      title: 'Status',
                                      sublist: 'status',
                                      coltype: 'filter',
                                      filterapi: 'master-data/status',
                                      sublistFilterField: 'status',
                                      filterClassName: 'app-l-condensed-column ',
                                    })}
                                  />
                                </TRow>
                              </THead>{' '}
                            </DataTable>
                            <Row>
                              {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                                ? apiResponse.rows.map((row, i) => (
                                    <Col key={`tribute-card-${i}`} xs={12} sm={12} md={6} lg={4} xl={4}>
                                      {rowData && rowData.id == row.id ? (
                                        <ManageMediaCard obMedia={rowData} onSave={this.onChangeFromChild} access={access} />
                                      ) : (
                                        <ListMediaCard
                                          fromTribute="true"
                                          toggleStatus={row.statusId}
                                          rowData={row}
                                          type={row.mediaType}
                                          rowId={row.id}
                                          postType={this.props.match.params.value}
                                          src={row.mediaUrl}
                                          alt={row.media}
                                          content={row.content}
                                          deleteurl={`/list-template/${page}`}
                                          setJsonUrl={this.setJsonUrl}
                                          setpredicates={this.setpredicates}
                                          onEditButtonClick={() => this.showManageCard(row)}
                                          apiResponse={apiResponse}
                                          setParentState={this.setParentState}
                                          access={access}
                                        />
                                      )}
                                    </Col>
                                  ))
                                : null}
                            </Row>
                          </>
                        )}
                      </div>
                      {apiResponse.rows && apiResponse.rows.length === 0 && <div className="text-center no-content-block">No results found </div>}
                    </DataTableHolder>
                    {/* </div> */}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ListTemplateMessageContainer;
