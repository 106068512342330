import React from 'react';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import dayjs from 'dayjs';
import { put, post } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import Loader from '../../Components/loader';
import DropDown from '../../SharedComponents/FormHelpers/Controls/dropDown';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import { LocationSearchInput } from '../../SharedComponents/googlePlacesInput';
import CustomDatePicker from '../../SharedComponents/FormHelpers/Controls/customDatePicker';
import SlotManagement from '../Obituary/slotManagement';
import CustomModal from '../../SharedComponents/customModal';
import { formatDate } from '../../Libraries/DateTimeHelpers/dateTimeHelperFunctions';

class ManageEvents extends FormComponent {
  constructor(props) {
    super(props);
    this.slotsArray = [];
    this.state = {
      isLoaderActive: true,
      isSubmitted: true,
      singleRow: {},
      action: 'Add',
      addSlotModal: false,
      saveSlotModal: false,
      showSlotManagement: false,

      id: {
        label: 'id',
        type: 'hidden',
      },
      headingId: {
        label: 'Event Type',
        name: 'headingId',
        renderapi: 'event-type?isAll=true',
        placeholder: 'Enter event type',
        className: 'mb-0',
        controlId: 'exampleForm.ControlSelect1',
        optionvalue: 'id',
        optiontext: 'type',
        rules: { required: true },
      },
      venue: {
        label: 'Venue',
        placeholder: 'Enter venue [Name, Location]',
        className: 'mb-0',
        name: 'venue',
        valueValidationProperty: 'address',
        rules: {
          locationRequired: true,
          noMoreThan: 300,
        },
      },
      startDate: {
        label: 'Event Date',
        name: 'startDate',
        type: 'date',
        placeholder: 'DD/MM/YY',
        className: 'form-control app-c-form',
        rules: { required: true, minToday: true },
      },
      startTime: {
        relatedPropertyName: 'startDate',
        type: 'string',
        label: 'Start Time',
        placeholder: 'Enter start time',
        rules: {
          required: true,
          minNow: true,
        },
      },
      endTime: {
        relatedPropertyName: 'startTime',
        label: 'End Time',
        type: 'string',
        placeholder: 'Enter end time',
        isDefaultSet: false,
        rules: {
          required: true,
          greater: true,
        },
      },
    };
    this.bottomElementRef = React.createRef();
    this.slotFormRef = {
      hasUnsavedSlot: false,
      didCallSubmit: false,
    };
  }

  componentDidMount() {
    let { obituaryId, typeId, eventDetails } = this.props;
    if (eventDetails) {
      let details = { ...eventDetails };
      let today = dayjs();
      if (eventDetails.startTime) {
        details.startTime = `${today.format('YYYY-MM-DDT') + eventDetails.startTime}.000Z`;
      }
      if (eventDetails.endTime) {
        details.endTime = `${today.format('YYYY-MM-DDT') + eventDetails.endTime}.000Z`;
      }
      this.setState({ singleRow: details });
    }
    if (obituaryId && typeId) {
      this.setState({
        singleRow: { obituaryId, typeId },
      });
    }
    this.setState({ isLoaderActive: false });
  }

  componentDidUpdate() {
    let { singleRow, startTime, endTime } = this.state;
    if (singleRow) {
      this.mapJsonToState(singleRow);
    }
    if (startTime.value && !endTime.value && !endTime.isDefaultSet) {
      this.setDefaultTime();
    }
  }

  toast = new ToastNotification();

  setDefaultTime = () => {
    let { startTime } = this.state;
    if (startTime.value && startTime.value.length > 0) {
      let t1 = new Date(startTime.value.slice(0, -1));
      let t2 = new Date(startTime.value.slice(0, -1));
      t2.setHours(t2.getHours() + 1);
      if (t2.getDate() > t1.getDate()) {
        t2 = new Date(t1.setHours(23, 59, 59, 999));
      }
      t2 = new Date(t2 - t2.getTimezoneOffset() * 60000).toISOString();
      this.setState((prevState) => ({ endTime: { ...prevState.endTime, value: t2, isDefaultSet: true } }));
    }
  };

  updateEventDetails = (model) => {
    if (!model) ({ model } = this.slotFormRef);
    this.setState({ isLoaderActive: true });
    let { eventDetails, formRef, setpredicates } = this.props;
    model.startDate = formatDate(model.startDate);
    let { id } = this.state;
    if (eventDetails || id.value > 0) {
      formRef.onClose();
      model.attendSlots = this.formatSlotsArray();
      let ID = eventDetails ? eventDetails.id : id.value;
      put(`obituary-post/${ID}`, model).then((response) => {
        if (response.data.data && response.data.success) {
          this.setState({ isLoaderActive: false });
          setpredicates();
          this.toast.show('update_success', 'SUCCESS', response.data.message);
        } else {
          this.toast.show('update_error', 'ERROR', "Couldn't update event details");
        }
      });
    } else {
      post('obituary-post', model).then((response) => {
        if (response.data.data && response.data.success) {
          this.setState((prevState) => ({
            isLoaderActive: false,
            id: { ...prevState.id, value: response.data.data.id },
            addSlotModal: true,
          }));
          setpredicates();
          this.toast.show('create_success', 'SUCCESS', response.data.message);
        } else {
          this.toast.show('create_error', 'ERROR', "Couldn't save event details.");
        }
      });
    }
  };

  onSaveButtonClick = (model) => {
    if (this.slotFormRef.hasUnsavedSlot) {
      this.slotFormRef.model = model;
      this.setState({ saveSlotModal: true });
    } else {
      this.updateEventDetails(model);
    }
  };

  onConfirmButtonClick = (e) => {
    let { addSlotModal, saveSlotModal } = this.state;
    if (addSlotModal) {
      this.setState({ addSlotModal: false, showSlotManagement: true });
    } else if (saveSlotModal) {
      this.slotFormRef.didCallSubmit = true;
      this.slotFormRef.onClose = this.closeModal;
      this.slotFormRef.onFormSubmit(e);
    }
  };

  closeModal = () => {
    let { addSlotModal, saveSlotModal } = this.state;
    if (addSlotModal) {
      let { formRef } = this.props;
      this.setState({ addSlotModal: false });
      formRef.onClose();
    } else if (saveSlotModal) {
      this.setState({ saveSlotModal: false });
      this.updateEventDetails();
    }
  };

  getSlotDetails = (arr) => {
    this.slotsArray = arr;
  };

  formatSlotsArray = () => {
    this.slotsArray.forEach((slot, i) => {
      delete this.slotsArray[i].minStartTime;
      delete this.slotsArray[i].minEndTime;
      delete this.slotsArray[i].status;
    });
    return this.slotsArray;
  };

  render() {
    let { formRef, eventDetails } = this.props;
    let { isLoaderActive, addSlotModal, saveSlotModal, id, showSlotManagement } = this.state;
    this.slotFormRef.onFormSubmit = {};

    return (
      <React.Fragment>
        {isLoaderActive === true ? <Loader /> : ''}
        <Container fluid className="px-0">
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <SubmitForm onSubmit={this.onSaveButtonClick} {...this.setFormProperties({ disableReactiveForm: true })} formRef={formRef}>
                    <Row>
                      <Col xs={12} md={6}>
                        <DropDown {...this.setProperties(this.state.headingId)} />
                      </Col>
                      <Col xs={12} md={6}>
                        <LocationSearchInput
                          placeDetails={this.setProperties(this.state.venue)}
                          type={['establishment']}
                          formGroup="app-c-input-box service-form"
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <CustomDatePicker
                          formGroup
                          appearance="subtle"
                          format="DD/MM/YYYY"
                          oneTap
                          isDate
                          futureDate
                          placement="auto"
                          {...this.setProperties(this.state.startDate)}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <CustomDatePicker
                          formGroup
                          appearance="subtle"
                          format="HH:mm"
                          oneTap={false}
                          isDate={false}
                          placement="auto"
                          maxEndTime={this.state.endTime.value}
                          {...this.setProperties(this.state.startTime)}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <CustomDatePicker
                          formGroup
                          appearance="subtle"
                          format="HH:mm"
                          oneTap={false}
                          isDate={false}
                          placement="auto"
                          minStartTime={this.state.startTime.value}
                          {...this.setProperties(this.state.endTime)}
                        />
                      </Col>
                    </Row>
                  </SubmitForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {eventDetails || (id.value > 0 && showSlotManagement) ? (
            <>
              <SlotManagement
                eventId={eventDetails ? eventDetails.id : id.value}
                eventStartTime={this.state.startTime.value}
                eventEndTime={this.state.endTime.value}
                sendSlotDetails={this.getSlotDetails}
                bottomRef={this.bottomElementRef}
                slotFormRef={this.slotFormRef}
              />
              <div className="scroll-to-bottom" ref={this.bottomElementRef} />
            </>
          ) : null}
          <CustomModal
            modal={addSlotModal || saveSlotModal}
            toggleModal={this.closeModal}
            title="Confirmation for slots"
            buttonList={[{ name: 'Yes', color: 'primary', onClick: (e) => this.onConfirmButtonClick(e) }, { name: addSlotModal ? 'Later' : 'No' }]}
          >
            {addSlotModal && <p>Do you want to add slots for this event?</p>}
            {saveSlotModal && <p>You have some unsaved slot details, do you want to save them?</p>}
          </CustomModal>
        </Container>
      </React.Fragment>
    );
  }
}

export default ManageEvents;
