import React from 'react';
import { Card, CardBody, FormGroup, Button } from 'reactstrap';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import FormInput from '../../SharedComponents/FormHelpers/Controls/formInput';
import { post, put } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import CustomFileUpload from '../../SharedComponents/FormHelpers/Controls/customFileUpload';
import CheckBox from '../../SharedComponents/FormHelpers/Controls/checkBox';
import SelectFromTemplatesModal from '../Template/selectFromTemplatesModal';
import LinkButton from '../../SharedComponents/linkButton';

class ManageMediaCard extends FormComponent {
  constructor(props) {
    super(props);
    this.setProperties = this.setProperties.bind(this);
    this.setFormProperties = this.setFormProperties.bind(this);
    this.state = {
      isSubmitted: true,
      singleRow: {},
      action: 'Add',
      editFlag: false,
      hasResetted: false,
      templateModal: false,

      id: {
        label: 'Id',
        type: 'hidden',
      },

      content: {
        label: 'Content',
        type: 'text',
        placeholder: 'Enter caption',
        disableFormGroup: true,
        rules: {
          maxLength: 5000,
        },
      },

      media: {
        label: 'Media',
        type: 'text',
        name: 'media',
        placeholder: 'Enter media',
        fileUploadUrl: 'obituary-post/upload',
        rules: {
          required: true,
        },
      },

      lockMedia: {
        label: 'Lock Media',
        type: 'checkbox',
        placeholder: 'Enter lock media',
        rules: {},
      },
    };
  }

  toast = new ToastNotification();

  componentDidMount() {
    if (this.props.obMedia) {
      this.setState({
        editFlag: true,
        action: 'Edit',
        singleRow: this.props.obMedia,
      });
    }
  }

  componentDidUpdate() {
    if (this.state.singleRow) {
      this.mapJsonToState(this.state.singleRow);
    }
  }

  validExtensions = ['jpeg', 'jpg', 'png', 'mp4'];

  onSaveButtonClick = (model) => {
    let { obituaryId, typeId, obMedia } = this.props;
    if (obituaryId && typeId) {
      model.obituaryId = obituaryId;
      model.typeId = typeId;
    }
    model.mediaType = model.media.slice(model.media.lastIndexOf('.') + 1);
    if (obMedia) {
      model.id = obMedia.id;
      put(`obituary-post/${obMedia.id}`, model).then((response) => {
        if (response.data.success) {
          this.toast.show('update_success', 'SUCCESS', 'Changes saved successfully!');
          this.props.onSave();
        } else {
          this.toast.show('update_error', 'ERROR', 'Could not save changes. Please try after sometime');
        }
      });
    } else {
      post('obituary-post', model).then((response) => {
        if (response.data.success) {
          this.toast.show(
            'create_success',
            'SUCCESS',
            // `Post created successfully! ${response?.data?.raw?.message}`
            'Post created successfully!',
          );
          this.setState((prevState) => ({
            media: { ...prevState.media, value: '', errors: [] },
            content: { ...prevState.content, value: '', errors: [] },
            lockMedia: { ...prevState.lockMedia, value: '', errors: [] },
            hasResetted: !prevState.hasResetted,
          }));
          this.props.onSave();
        } else {
          this.toast.show('create_error', 'ERROR', 'Could not add obituary media. Please try after sometime');
        }
      });
    }
  };

  onCancelButtonClick = () => {
    this.setState((prevState) => ({
      hasResetted: !prevState.hasResetted,
      media: { ...prevState.media, errors: [] },
    }));
    if (this.props.obMedia) {
      this.props.onSave(true);
    }
  };

  closeTemplateModal = () => {
    this.setState({ templateModal: false });
  };

  setTemplateAsContent = (msg) => {
    this.setState((prevState) => ({
      content: { ...prevState.content, value: msg },
      templateModal: false,
    }));
  };

  render() {
    let { singleRow, hasResetted, templateModal } = this.state;
    let { unqKey, typeId, obMedia, age, genderId } = this.props;

    return (
      <React.Fragment>
        <SubmitForm
          onSubmit={this.onSaveButtonClick}
          {...this.setFormProperties({
            disableReactiveForm: true,
          })}
        >
          <Card className="mb-0">
            <CustomFileUpload
              validExtensions={this.validExtensions}
              hasResetted={hasResetted}
              attachmentUrl={singleRow.mediaUrl}
              unqKey={unqKey}
              {...this.setProperties(this.state.media)}
            />
            <CardBody className="p-2">
              {((typeId && typeId == 3) || (obMedia && obMedia.typeId == 3)) && (
                <FormGroup className="mb-0">
                  <CheckBox {...this.setProperties(this.state.lockMedia)} checked={this.state.lockMedia.value} disableFormGroup id={unqKey} />
                </FormGroup>
              )}
              {((typeId && typeId == 9) || (obMedia && obMedia.typeId == 9)) && (
                <>
                  <FormInput rows={2} as="textarea" disableLabel {...this.setProperties(this.state.content)} />
                  <div className="mt-1" style={{ textAlign: 'right' }}>
                    <LinkButton toString="templates" toDoFunction={() => this.setState({ templateModal: true })}>
                      Choose From Library
                    </LinkButton>
                  </div>
                </>
              )}

              <FormGroup className="mb-0 mt-4">
                <div className="d-flex align-items-start">
                  <Button className="btn btn-success btn-sm ml-auto mr-1" type="submit">
                    <i className="dripicons-checkmark" />
                  </Button>
                  <Button className="btn btn-danger btn-sm" type="reset" onClick={() => this.onCancelButtonClick()}>
                    <i className="dripicons-cross" />
                  </Button>
                </div>
              </FormGroup>
            </CardBody>
          </Card>
        </SubmitForm>
        <SelectFromTemplatesModal
          modal={templateModal}
          showModal={this.closeTemplateModal}
          title="Message Templates"
          hide={false}
          cancelButtonName="Cancel"
          confirmButtonName="Use"
          cancelButtonClick={this.closeTemplateModal}
          confirmButtonClick={this.setTemplateAsContent}
          renderapi={`template-message?isAll=true&statusId=1&typeId=9&genderId=${genderId}`}
          templateType="message"
          age={age}
        />
      </React.Fragment>
    );
  }
}

export default ManageMediaCard;
