import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { ActionButton } from '../../SharedComponents/FormHelpers/Controls/actionButton';
import { StatusSwitch } from '../../SharedComponents/FormHelpers/Controls/statusSwitch';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import Loader from '../../Components/loader';
import { DataTable, DataTableHolder, HeadingBlock, TBody, TCol, TColBadge, THead, TRow } from '../../SharedComponents/Table/tableElements';
import { DefaultImage } from './defaultImage';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';
import error404Img from '../../assets/images/errorImg.png';

class ListUserContainer extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleSelect: false,
      multiSelect: false,
      rowData: {},
      showLightBox: false,
      imageUrl: '',
      isLoaderActive: false,
      showDeleteConfirmation: false,
    };
  }

  componentDidMount() {
    this.setJsonUrl('user');
    this.setpredicates({ take: 10, page: 0 });
    this.setState({ access: setComponentPrivilege('Manage Users') });
  }

  onLightBoxClick = (row) => {
    this.setState({
      showLightBox: true,
      imageUrl: row.userImageUrl,
    });
  };

  render() {
    let { apiResponse, access, isLoaderActive } = this.state;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              {isLoaderActive === true ? <Loader /> : ''}
              <HeadingBlock title="User" />
            </Col>
            {access && access.create && (
              <Col sm={6}>
                <div className="float-right  d-md-block">
                  <Link className="btn btn-primary waves-effect waves-light" to="manage-users" role="button">
                    Add User
                  </Link>
                </div>
              </Col>
            )}
          </Row>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                    <div className="row">
                      <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                        <DataTable>
                          <THead>
                            <TRow>
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Image',
                                  width: 100,
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Name',
                                  enableSearch: true,
                                  enableSort: true,
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Family Name',
                                  enableSearch: true,
                                  enableSort: true,
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Email',
                                  enableSearch: true,
                                  enableSort: true,
                                  className: 'app-l-long-column',
                                  searchField: 'emailSearch',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Role',
                                  sublist: 'Role',
                                  coltype: 'filter',
                                  filterapi: 'user-role',
                                  sublistFilterField: 'role',
                                  filterClassName: 'app-l-condensed-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Commission Rate',
                                  enableSearch: true,
                                  enableSort: true,
                                  className: 'app-l-long-column',
                                  searchField: 'commissionRateSearch',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Status',
                                  sublist: 'Status',
                                  coltype: 'filter',
                                  filterapi: 'master-data/status',
                                  sublistFilterField: 'status',
                                  filterClassName: 'app-l-100-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Email Status',
                                  sublist: 'Email Status',
                                  coltype: 'filter',
                                  filterapi: 'user-email-status',
                                  sublistFilterField: 'emailStatus',
                                  filterClassName: 'app-l-135-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Action',
                                  filterfield: 'action',
                                  width: 120,
                                  className: 'action',
                                })}
                              />
                            </TRow>
                          </THead>
                          <TBody>
                            {this.state.showLightBox ? (
                              <Lightbox
                                mainSrc={this.state.imageUrl ? this.state.imageUrl : error404Img}
                                onImageLoadError={() => {
                                  this.setState({ imageUrl: error404Img });
                                }}
                                clickOutsideToClose
                                enableZoom
                                onCloseRequest={() => this.setState({ showLightBox: false })}
                              />
                            ) : null}
                            {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                              ? apiResponse.rows.map((row, i) => (
                                  <TRow>
                                    <TCol>
                                      <div>
                                        {row.userImageUrl ? (
                                          <img
                                            onClick={() => this.onLightBoxClick(row)}
                                            data-dz-thumbnail=""
                                            height="50"
                                            className="avatar-sm rounded bg-light"
                                            alt="User "
                                            src={row.userImageUrl}
                                          />
                                        ) : (
                                          <div className="default-image-container">
                                            <DefaultImage rowData={row} firstName="name" secondName="familyName" />
                                          </div>
                                        )}
                                      </div>
                                    </TCol>
                                    <TCol>{row.name}</TCol>
                                    <TCol>{row.familyName}</TCol>
                                    <TCol>{row.email}</TCol>

                                    <TCol>{row.roleTitle}</TCol>
                                    {row.commissionRate ? (
                                      <TCol>
                                        {row.commissionRate} <i> %</i>
                                      </TCol>
                                    ) : (
                                      <TCol />
                                    )}

                                    <TCol>
                                      {access && access.edit ? (
                                        <StatusSwitch
                                          isListIcon
                                          value={row.statusId}
                                          name="statusId"
                                          rowData={row}
                                          updateApi={`user/${row.id}`}
                                          apiResponse={apiResponse}
                                          setParentState={this.setParentState}
                                          valueObj={{ on: 1, off: 2 }}
                                          isDisabled={row.isNotEditable}
                                          title={row.isNotEditable ? "You don't have permission to edit this role" : ''}
                                        />
                                      ) : (
                                        row.statusTitle
                                      )}
                                    </TCol>
                                    <TColBadge field="EmailStatus" value={row.emailStatusTitle} id={row.emailStatusId} />
                                    <TCol>
                                      <div className="action-col">
                                        {access && access.edit && (
                                          <span title={row.isNotEditable ? "You don't have permission to edit this role" : ''}>
                                            <ActionButton isDisabled={row.isNotEditable} value="edit" rowId={row.id} url="manage-users/" />
                                          </span>
                                        )}
                                        <ActionButton value="view" rowId={row.id} url="view-users/" />
                                        {access && access.delete && (
                                          <span title={row.isNotEditable ? "You don't have permission to delete this role" : ''}>
                                            <ActionButton
                                              value="delete"
                                              rowId={row.id}
                                              url="list-users"
                                              setJsonUrl={this.setJsonUrl}
                                              setpredicates={this.setpredicates}
                                              api="user/"
                                              isDisabled={row.isNotEditable}
                                            />
                                          </span>
                                        )}
                                      </div>
                                    </TCol>
                                  </TRow>
                                ))
                              : null}
                          </TBody>
                        </DataTable>
                        {apiResponse.rows && apiResponse.rows.length === 0 && <div className="text-center no-content-block">No results found </div>}
                      </DataTableHolder>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ListUserContainer;
