import { API_ERROR, SET_GENDER_LIST, SET_PRIVACY_LIST, SET_DEFAULT_URL } from './actionTypes';

export const initialState = {
  error: null,
  loading: false,
  genderList: [],
  defaultMenuUrl: '/dashboard',
};

const masterData = (state = initialState, { type, payload }) => {
  switch (type) {
    case API_ERROR:
      state = { ...state, error: payload, loading: false };
      break;
    case SET_GENDER_LIST:
      state = { ...state, genderList: payload };
      break;
    case SET_PRIVACY_LIST:
      state = { ...state, privacyList: payload };
      break;
    case SET_DEFAULT_URL:
      state = { ...state, defaultMenuUrl: payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default masterData;
