import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import { Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { DataTable, TBody, THead, TCol, TRow, HeadingBlock, DataTableHolder } from '../../SharedComponents/Table/tableElements';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import Loader from '../../Components/loader';
import { get } from '../../Libraries/Ajax/httpService';
import FormModal from './formModal';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';
import IncompleteOrdersContainer from './incompleteOrders';

class ListOrderContainer extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleSelect: false,
      multiSelect: false,
      rowData: {},
      isLoaderActive: false,
      viewURL: 'view-orders/',
      viewProduct: 'view-products/',
      productList: [],
      obituaryList: [],
      statusId: {
        renderapi: 'delivery-status?page=0&take=10',
        placeholder: 'Enter status',
        className: 'mb-0',
        controlId: 'exampleForm.ControlSelect1',
        name: 'statusId',
        optionvalue: 'id',
        optiontext: 'title',
      },
      deliveryOptions: [],
      showModal: false,
      currentOrder: {},
      activeTab: this.props.location.tabProps ? this.props.location.tabProps.activeTab : 1,
    };
    this.onTabToggle = this.onTabToggle.bind(this);
  }

  onTabToggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  orderTabs = [
    { id: 1, title: 'Paid Orders' },
    { id: 2, title: 'Payment Incomplete Orders' },
  ];

  componentDidMount() {
    this.setJsonUrl('order');
    this.setpredicates({ take: 10, page: 0 });
    this.setState({ access: setComponentPrivilege('Manage Orders') });

    get('delivery-status?page=0&take=10')
      .then((res) => {
        if (res.data?.data.rows) this.setState({ deliveryOptions: res.data.data.rows });
      })
      .catch(() => {});
    get('product', { isAll: true, isDelete: true })
      .then((res) => {
        if (res.data?.data.rows) this.setState({ productList: res.data.data.rows });
      })
      .catch(() => {});

    get('obituary-profile', { isAll: true, isDelete: true })
      .then((res) => {
        if (res.data?.data.rows) this.setState({ obituaryList: res.data.data.rows });
      })
      .catch(() => {});
  }

  onStatusChange = (e, rowData) => {
    let row = { ...rowData, statusId: parseInt(e.target.value) };
    let rows = this.state.apiResponse.rows.map((item) => {
      if (item.id === row.id) return row;
      return item;
    });
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      currentOrder: row,
      apiResponse: { ...this.state.apiResponse, rows },
    }));
  };

  onToggle = (reload) => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
    if (reload) this.setpredicates({});
  };

  render() {
    let { apiResponse, isLoaderActive, deliveryOptions, activeTab, productList, obituaryList, access } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              {isLoaderActive === true ? <Loader /> : ''}
              <HeadingBlock title="Order" />
            </Col>
            <Col sm={6} />
          </Row>
          {/* tabs */}
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Nav tabs className="nav-tabs-custom">
                    {this.orderTabs && this.orderTabs.length > 0
                      ? this.orderTabs.map((tab, i) => (
                          <NavItem key={`${i}-nav`}>
                            <NavLink
                              className={classnames({
                                active: activeTab === tab.id,
                              })}
                              onClick={() => {
                                this.onTabToggle(tab.id);
                              }}
                            >
                              {tab.title}
                            </NavLink>
                          </NavItem>
                        ))
                      : null}
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    {this.orderTabs && this.orderTabs.length > 0
                      ? this.orderTabs.map((tab, i) => (
                          <TabPane key={`tab-pane-${i}`} tabId={tab.id} className="pt-3">
                            <Row>
                              <Col sm="12 p-0">
                                {tab.title === 'Paid Orders' ? (
                                  <div className="row">
                                    <div className="col-12">
                                      {/* <div className="card">
                                        <div className="card-body"> */}
                                      <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                                        <div className="row">
                                          <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                                            <DataTable>
                                              <THead>
                                                <TRow>
                                                  <DataColHead
                                                    {...this.setheadproperties({
                                                      title: 'Product',
                                                      isMultiSelectCheck: true,
                                                      itemList: productList,
                                                      idFilterListName: 'productIdList',
                                                      coltype: 'multiselect',
                                                      className: 'app-l-search-column multiselect-filter-checkbox',
                                                    })}
                                                  />

                                                  <DataColHead
                                                    {...this.setheadproperties({
                                                      title: 'Obituary',
                                                      isMultiSelectCheck: true,
                                                      itemList: obituaryList,
                                                      idFilterListName: 'obituaryIdList',
                                                      coltype: 'multiselect',
                                                      className: 'app-l-search-column multiselect-filter-checkbox',
                                                    })}
                                                  />

                                                  <DataColHead
                                                    {...this.setheadproperties({
                                                      title: 'User',
                                                      enableSearch: true,
                                                      searchField: 'createdBySearch',
                                                      className: 'app-l-search-column',
                                                    })}
                                                  />

                                                  <DataColHead
                                                    {...this.setheadproperties({
                                                      title: 'Price',
                                                      enableSearch: true,
                                                      enableSort: true,
                                                      className: 'app-l-condensed-column',
                                                      searchField: 'priceSearch',
                                                    })}
                                                  />

                                                  {/* <DataColHead
                                                    {...this.setheadproperties({
                                                      title: 'Payment Status',
                                                      // isMultiSelectCheck: true,
                                                      // itemList: productList,
                                                      // idFilterListName: "paymentIdList",
                                                      className: 'app-l-search-column multiselect-filter-checkbox',
                                                    })}
                                                  /> */}

                                                  <DataColHead
                                                    {...this.setheadproperties({
                                                      title: 'Quantity',
                                                      enableSearch: true,
                                                      enableSort: true,
                                                      searchField: 'quantitySearch',
                                                      className: 'app-l-135-column',
                                                    })}
                                                  />

                                                  <DataColHead
                                                    {...this.setheadproperties({
                                                      title: 'Ticket Number',
                                                      enableSearch: true,
                                                      className: 'table-email-content',
                                                      searchField: 'ticketSearch',
                                                    })}
                                                  />

                                                  <DataColHead
                                                    {...this.setheadproperties({
                                                      title: 'Order Status',
                                                      sublist: 'Status',
                                                      coltype: 'filter',
                                                      filterapi: 'delivery-status',
                                                      sublistFilterField: 'status',
                                                      filterClassName: 'app-l-long-column',
                                                    })}
                                                  />

                                                  <DataColHead
                                                    {...this.setheadproperties({
                                                      title: 'Action',
                                                      filterfield: 'action',
                                                      width: 100,
                                                      className: 'action',
                                                    })}
                                                  />
                                                </TRow>
                                              </THead>
                                              <TBody>
                                                {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                                                  ? apiResponse.rows.map((row) => (
                                                      <TRow>
                                                        <TCol>
                                                          {' '}
                                                          <span>
                                                            <Link to={`/view-products/${row.productId}`} target="_blank" rel="noreferrer">
                                                              {' '}
                                                              {row.productName}{' '}
                                                            </Link>
                                                          </span>{' '}
                                                        </TCol>
                                                        <TCol>
                                                          <Link
                                                            to={`/view-obituary-profiles/general/${row.obituaryId}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                          >
                                                            {' '}
                                                            {row.obituaryName}{' '}
                                                          </Link>
                                                        </TCol>
                                                        <TCol>
                                                          <Link to={`/view-users/${row.createdById}`} target="_blank" rel="noreferrer">
                                                            {' '}
                                                            {row.createdByName}{' '}
                                                          </Link>
                                                        </TCol>
                                                        <TCol>
                                                          <i>€</i> {row.price}
                                                        </TCol>
                                                        <TCol>{row.quantity}</TCol>
                                                        <TCol>{row.requestTicket}</TCol>
                                                        <TCol>
                                                          {access && access.edit ? (
                                                            <Form.Control
                                                              value={row.statusId}
                                                              as="select"
                                                              onChange={(e) => this.onStatusChange(e, row)}
                                                            >
                                                              {deliveryOptions &&
                                                                deliveryOptions.length > 0 &&
                                                                deliveryOptions.map((option) => (
                                                                  <option disabled={option.disabled} key={option.id} value={option.id}>
                                                                    {option.title}
                                                                  </option>
                                                                ))}
                                                            </Form.Control>
                                                          ) : (
                                                            row.statusTitle
                                                          )}
                                                        </TCol>
                                                        <TCol>
                                                          <div className="action-col">
                                                            <span>
                                                              <Link to={`${this.state.viewURL}${row.id}`} title="view">
                                                                <i className="fas fa-eye" />
                                                              </Link>
                                                            </span>
                                                          </div>
                                                        </TCol>
                                                      </TRow>
                                                    ))
                                                  : null}
                                              </TBody>
                                            </DataTable>
                                            {apiResponse.rows && apiResponse.rows.length === 0 && (
                                              <div className="text-center no-content-block">No results found </div>
                                            )}
                                          </DataTableHolder>
                                        </div>
                                      </div>
                                      {/* </div>
                                      </div> */}
                                    </div>
                                  </div>
                                ) : (
                                  <IncompleteOrdersContainer />
                                )}
                              </Col>
                            </Row>
                          </TabPane>
                        ))
                      : null}
                  </TabContent>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <FormModal
            showModal={this.state.showModal}
            setpredicates={this.setpredicates}
            selectedStatusId={this.state.selectedStatusId}
            deliveryOptions={this.state.deliveryOptions}
            order={this.state.currentOrder}
            onToggle={this.onToggle}
          />
        </div>
      </React.Fragment>
    );
  }
}
// mapStateToProps = (state) => {
//   // let {}=state.MasterData
// };

// export default connect()(ListOrderContainer);
export default ListOrderContainer;
