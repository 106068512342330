import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

export default class SubmitForm extends Component {
  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit = (event) => {
    let properties = this.props;
    event.preventDefault();
    properties.setParentState({ isSubmitted: true });
    properties.submitForm(properties.onSubmit);
  };

  onReset = (event) => {
    let properties = this.props;
    if (properties.onReset) {
      event.preventDefault();
      properties.setParentState({ isResetted: true });
      properties.onReset(properties.onSubmit);
    }
  };

  render() {
    let { formRef } = this.props;
    if (formRef) {
      formRef.onFormSubmit = this.onFormSubmit;
      formRef.onReset = this.onReset;
    }
    return (
      <Form noValidate className="" onReset={this.onReset} onSubmit={this.onFormSubmit}>
        {this.props.children}
      </Form>
    );
  }
}
