import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

import { TCol, TRow, TBody, THead, DataTable, DataTableHolder } from '../../SharedComponents/Table/tableElements';
import { DataColHead, ViewDate, ListPagination } from '../../SharedComponents/ListComponents/components';
import { ActionButton } from '../../SharedComponents/FormHelpers/Controls/actionButton';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import CustomModal from '../../SharedComponents/customModal';
import ManageEvents from './manageEvents';
import ViewEvents from './viewEvents';
import LinkButton from '../../SharedComponents/linkButton';

class ListEvents extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      addModal: false,
      editModal: false,
      viewModal: false,
      rowData: {},
    };
  }

  closeModal = (rerender) => {
    this.setState({
      addModal: false,
      viewModal: false,
      editModal: false,
      rowData: {},
    });
    if (rerender) {
      this.props.setpredicates();
    }
  };

  onEditButtonClick = () => {
    this.setState({ addModal: false, viewModal: false, editModal: true });
  };

  formRef = {};

  onConfirmButtonClick = (e) => {
    this.formRef.onClose = this.closeModal;
    this.formRef.onFormSubmit(e);
  };

  render() {
    let { addModal, editModal, viewModal, rowData } = this.state;
    let { apiResponse, setpredicates, setpagination, obituaryId, typeId, access } = this.props;
    this.formRef.onFormSubmit = {};

    return (
      <React.Fragment>
        {access && access.create && (
          <Col sm={12}>
            <div className="float-right d-block">
              <LinkButton
                toString="createEvent"
                className="btn btn-primary waves-effect waves-light"
                role="button"
                toDoFunction={() => this.setState({ addModal: true })}
              >
                Add Event
              </LinkButton>
            </div>
          </Col>
        )}
        <Col xs={12} lg={12}>
          <Card>
            <CardBody className="p-0 pt-3">
              <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                <Row>
                  <DataTableHolder pagination={<ListPagination {...setpagination()} />}>
                    <DataTable>
                      <THead>
                        <TRow>
                          <DataColHead
                            {...this.setheadproperties({
                              title: 'Event Type',
                            })}
                          />

                          <DataColHead
                            {...this.setheadproperties({
                              title: 'Event Date',
                            })}
                            width="100"
                          />

                          <DataColHead
                            {...this.setheadproperties({
                              title: 'Start Time',
                            })}
                            width="100"
                          />

                          <DataColHead
                            {...this.setheadproperties({
                              title: 'End Time',
                            })}
                            width="100"
                          />

                          <DataColHead
                            {...this.setheadproperties({
                              title: 'Created By',
                            })}
                            width="100"
                          />

                          <DataColHead
                            {...this.setheadproperties({
                              title: 'Action',
                              filterfield: 'action',
                              width: 100,
                              className: 'action',
                            })}
                          />
                        </TRow>
                      </THead>
                      <TBody>
                        {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                          ? apiResponse.rows.map((row, i) => (
                              <TRow key={`event-${i}`}>
                                <TCol>{row.headingType}</TCol>

                                <TCol>
                                  <ViewDate isTableView dateValue={row.startDate} />
                                </TCol>

                                <TCol>{row.startTime.slice(0, 5)}</TCol>

                                <TCol>{row.endTime.slice(0, 5)}</TCol>

                                <TCol>{row.createdByName}</TCol>

                                <TCol>
                                  <div className="action-col">
                                    {access && access.edit && (
                                      <span>
                                        <LinkButton
                                          toString="editEvent"
                                          title="edit"
                                          toDoFunction={() => this.setState({ editModal: true, rowData: row })}
                                        >
                                          <i className="fas fa-edit" />
                                        </LinkButton>
                                      </span>
                                    )}
                                    <span>
                                      <LinkButton
                                        toString="viewEvent"
                                        title="view"
                                        toDoFunction={() => this.setState({ viewModal: true, rowData: row })}
                                      >
                                        <i className="fas fa-eye" />
                                      </LinkButton>
                                    </span>
                                    {access && access.delete && (
                                      <ActionButton
                                        value="delete"
                                        rowId={row.id}
                                        url={obituaryId}
                                        setJsonUrl={this.setJsonUrl}
                                        setpredicates={setpredicates}
                                        api="obituary-post/"
                                      />
                                    )}
                                  </div>
                                </TCol>
                              </TRow>
                            ))
                          : null}
                      </TBody>
                    </DataTable>
                    {apiResponse?.rows && apiResponse.rows.length === 0 && <div className="text-center no-content-block">No results found </div>}
                  </DataTableHolder>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
        {addModal || editModal || viewModal ? (
          <CustomModal
            modal={addModal || editModal || viewModal}
            toggleModal={this.closeModal}
            // eslint-disable-next-line no-nested-ternary
            title={addModal ? 'Create Event' : editModal ? 'Edit Event' : 'Event Details'}
            className="modal-lg"
            buttonList={[{ name: 'Save', color: 'primary', onClick: this.onConfirmButtonClick }, { name: 'Cancel' }]}
            hideFooter={!!viewModal}
            maxHeight={400}
          >
            {addModal && <ManageEvents obituaryId={obituaryId} typeId={typeId} setpredicates={setpredicates} formRef={this.formRef} />}
            {editModal && <ManageEvents eventDetails={rowData} setpredicates={setpredicates} formRef={this.formRef} />}
            {viewModal && (
              <ViewEvents post={rowData} deleteButtonClick={this.closeModal} editButtonClick={this.onEditButtonClick} setpredicates={setpredicates} />
            )}
          </CustomModal>
        ) : null}
      </React.Fragment>
    );
  }
}

export default ListEvents;
