import React, { useEffect, useState } from 'react';
import { components } from 'react-select';

import { Label } from 'reactstrap';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';

import { LazyRun } from '../../../Libraries/LazyFunctions/lazyFunction';
import error404Img from '../../../assets/images/errorImg.png';
import defaultPic from '../../../assets/images/userpic.png';
import ValidationMessage from '../Validation/validationMessage';
import { get } from '../../../Libraries/Ajax/httpService';

const customStyles = {
  menu: (style) => ({ ...style, zIndex: 10 }),
};

const Option = (props) => (
  <>
    <components.Option {...props}>{props.children}</components.Option>
  </>
);

const setCustomMenuOptions = (options, { imageKey, subText, mainText }) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  options.map((item) => {
    item.value = item.id;
    item.label = (
      <div className="custom-label">
        <img
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = error404Img;
          }}
          data-dz-thumbnail=""
          height="50"
          className="avatar-sm rounded-50 bg-light"
          alt="img-alt"
          src={item[imageKey] ? item[imageKey] : defaultPic}
        />
        <div className="label-sub-block">
          <span className="main-text">{item[mainText]}</span>
          <span className="sub-text">{item[subText]}</span>
        </div>
      </div>
    );
    return item;
  });

const CustomDropDown = (props) => {
  let {
    renderapi,
    imageKey,
    subText,
    mainText,
    apiModel,
    name,
    value,
    setParentState,
    createNewText,
    disableFormGroup,
    disableLabel,
    rules,
    label,
    errors,
    disableRequiredsymbol,
    onChangeValidation,
    optionvalue,
    placeholder,
    toggleModal,
    isSelectOnly,
    listName,
    isMulti,
    customOnchangeHandler,
    noOptionsMessage,
    disableSelect,
  } = props;

  let [searchValue, setSearchValue] = useState('');
  let [selectedValue, setSelectedValue] = useState('');
  let [allOptions, setOptions] = useState([]);

  let getApi = (model) => {
    get(renderapi, model)
      .then((res) => {
        if (res.data?.data?.rows?.length > 0) {
          let selectedOpn = setCustomMenuOptions(res.data.data.rows, {
            imageKey,
            subText,
            mainText,
          });
          setSelectedValue(selectedOpn.length > 0 ? selectedOpn[0] : '');
          setOptions([...selectedOpn, ...allOptions]);
        }
      })
      .catch(() => setOptions([]));
  };
  useEffect(() => {
    if (!isMulti && value && (selectedValue?.value != value || !selectedValue)) {
      let selectedItem = allOptions.filter((item) => item.value == value);
      if (!selectedItem?.length > 0) {
        getApi({ [optionvalue]: value });
      } else setSelectedValue(selectedItem?.length > 0 ? selectedItem[0] : '');
    } else if (isMulti && value?.length && ((selectedValue?.length > 0 && selectedValue.length != value.length) || !selectedValue)) {
      let selectedItems = allOptions.filter((item) => value.indexOf(item.value) != -1);
      if (selectedItems.length != value.length) getApi({ [listName]: value.map((x) => x.id) });
    }
  }, [value, selectedValue]);

  let setApiForOptions = (inputValue, callback) => {
    get(renderapi, {
      ...apiModel,
      take: 20,
      q: inputValue,
      sortField: 'name',
      sortOrder: 'ASC',
    })
      .then((res) => {
        if (res.data?.data?.rows) {
          let options = setCustomMenuOptions(res.data.data.rows, {
            imageKey,
            subText,
            mainText,
          });
          setOptions(options);
          callback(options);
        }
      })
      .catch(() => {});
  };

  let loadOptions = (inputValue, callback) => {
    if (inputValue) {
      LazyRun(() => {
        setApiForOptions(inputValue, callback);
      }, 500);
    } else setApiForOptions(inputValue, callback);
  };
  let handleOnChange = (selectedOpn) => {
    setSelectedValue(selectedOpn);
    if (customOnchangeHandler) customOnchangeHandler(selectedOpn);
    else {
      let val = null;
      if (isMulti && selectedOpn) val = selectedOpn.length > 0 ? selectedOpn : null;
      else val = selectedOpn?.value ? selectedOpn?.value : null;
      let properties = {
        ...props,
        value: val,
      };
      if (isMulti) {
        // let filteredArray = allOptions.filter((item) => properties.value.indexOf(item[properties.optionvalue]) != -1);
        // if (filteredArray.length > 0) {
        // let [first] = filteredArray;
        properties.item = selectedOpn;
        // }
      } else {
        let filteredArray = allOptions.filter((item) => item[properties.optionvalue] === properties.value);
        if (filteredArray.length > 0) {
          let [first] = filteredArray;
          properties.item = first;
        }
      }
      if (onChangeValidation) onChangeValidation(selectedOpn, properties);
    }
    // }
  };
  let handleOnCreate = () => {
    setParentState({ createdItemState: name });
    toggleModal();
  };
  return (
    <div className={disableFormGroup ? '' : 'form-group'} key={name} id={`${name}_id`}>
      {!disableLabel && (
        <Label>
          {label}
          {rules && rules.required && !disableRequiredsymbol && <sup>*</sup>}
        </Label>
      )}
      {!isSelectOnly ? (
        <AsyncCreatableSelect
          // cacheOptions
          // menuIsOpen={true}
          isDisabled={disableSelect}
          isSearchable
          key={`seletct-id-${name}`}
          id={`seletct-id-${name}`}
          value={selectedValue}
          styles={customStyles}
          onCreateOption={handleOnCreate}
          defaultOptions
          loadOptions={loadOptions}
          components={{ Option }}
          placeholder={placeholder || 'Search...'}
          isClearable
          allowCreateWhileLoading={false}
          formatCreateLabel={() => <span style={{ color: 'blue', fontSize: '13px' }}>{createNewText}</span>}
          name={name}
          className={`format-label ${name}`}
          classNamePrefix="format-label"
          searchValue={searchValue}
          onInputChange={(a) => setSearchValue(a)}
          onChange={(selectedOption) => handleOnChange(selectedOption)}
        />
      ) : (
        <AsyncSelect
          isMulti={isMulti}
          isSearchable
          isClearable
          key={`select-id-${name}`}
          id={`select-id-${name}`}
          value={selectedValue}
          styles={customStyles}
          defaultOptions
          loadOptions={loadOptions}
          components={{ Option }}
          placeholder={placeholder || 'Search...'}
          name={name}
          className={`format-label ${name}`}
          classNamePrefix="format-label"
          searchValue={searchValue}
          onInputChange={(a) => setSearchValue(a)}
          onChange={(selectedOption) => handleOnChange(selectedOption)}
          noOptionsMessage={() => noOptionsMessage || 'No options'}
        />
      )}
      <ValidationMessage
        {...{
          message: errors?.length > 0 && errors[0].message,
        }}
      />
    </div>
  );
};

export default CustomDropDown;
