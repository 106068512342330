import React, { Component } from 'react';
import { AnalyticsDashboard } from 'react-analytics-charts';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <AnalyticsDashboard />;
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
