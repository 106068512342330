import React from 'react';
import { Card, CardBody, Container, Row, Col, Button } from 'reactstrap';
import { get } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import { ViewDate } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { ContentItems } from '../../SharedComponents/ListComponents/manageComponent';
import AddCustomerResponse from './addCustomerResponse';

class CustomerResponseManagement extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      hideAddButton: false,
      pageNo: 0,
      totalResponses: 0,
    };
  }

  toast = new ToastNotification();

  loadMoreData = (isReload) => {
    let { pageNo } = this.state;
    let customerResponseModel = {
      obituaryId: this.props.obituaryId,
      take: 10,
      page: isReload ? 0 : pageNo + 1,
    };
    get('renewal-response', customerResponseModel)
      .then((response) => {
        if (response && response.data && response.data.success) {
          this.setState((prevState) => ({
            results: isReload ? response.data.data.rows : [...prevState.results, ...response.data?.data?.rows],
            totalResponses: response.data?.data.totalItems,
            pageNo: isReload ? 0 : prevState.pageNo + 1,
          }));
        }
      })
      .catch(() => this.toast.show('fetch-error', 'ERROR', `Could not load${pageNo == -1 ? ' customer ' : ' more '}responses`));
  };

  componentDidMount() {
    this.loadMoreData(true);
  }

  onAddCustomerResponse = () => {
    this.setState({ hideAddButton: true });
  };

  render() {
    let { hideAddButton, results, totalResponses } = this.state;

    return (
      <React.Fragment>
        <Container fluid className="px-0">
          <Card>
            <CardBody>
              <Row>
                {!hideAddButton && (
                  <Col xs={12}>
                    <div className="float-right d-block">
                      <Button color="primary" type="button" className="mb-4" onClick={() => this.onAddCustomerResponse()}>
                        Add Call Details
                      </Button>
                    </div>
                  </Col>
                )}
                {hideAddButton && (
                  <AddCustomerResponse obituaryId={this.props.obituaryId} setParentState={this.setParentState} reloadParent={this.loadMoreData} />
                )}
              </Row>
              {results &&
                results.length > 0 &&
                results.map((row, i) => (
                  <Row key={`customer-response-${i}`} style={{ backgroundColor: '#e6e6fa', borderRadius: `${5}px` }} className="pt-3 mb-3">
                    <Col xs={3} md={3}>
                      <ContentItems title="Contacted Person">{row.contactPersonName}</ContentItems>
                    </Col>
                    <Col>
                      <ContentItems title="Called By">{row.createdByName}</ContentItems>
                    </Col>
                    <Col>
                      <ContentItems title="Called On">
                        <ViewDate isTableView dateValue={row.calledDate} />
                      </ContentItems>
                    </Col>
                    <Col>
                      <ContentItems title="Notes">{row.remarks}</ContentItems>
                    </Col>
                  </Row>
                ))}
              {results.length < 1 && !hideAddButton && (
                <Row style={{ fontSize: `${1.08}rem` }}>
                  <Col xs={12} className="text-center font-weight-bold">
                    No calls made
                  </Col>
                </Row>
              )}
              {results && results.length > 0 && results.length < totalResponses ? (
                <Row>
                  <Col xs={12}>
                    <div className="float-right d-block">
                      <Button className="app-c-link-btn" type="button" onClick={() => this.loadMoreData()}>
                        Load More Responses
                      </Button>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </CardBody>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

export default CustomerResponseManagement;
