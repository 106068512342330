import React from 'react';
import { Form } from 'react-bootstrap';
import useGeoLocation from 'react-ipgeolocation';
import 'react-phone-number-input/style.css';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';

import ValidationMessage from '../Validation/validationMessage';
import ListComponent from '../../ListComponents/listComponent';

let location = '';
export class PhoneNumber extends ListComponent {
  constructor(props) {
    super(props);
    this.countryCodeChanged = null;
    this.handleOnChange = this.handleOnChange.bind(this);
    this.state = { addDefaultCountry: false, isEdit: false, isDefault: false };
  }

  phoneNumberValue = '';

  countryCallingCode = '';

  defaultChange = true;

  componentDidMount() {
    if (!this.props.value) {
      this.setState({ addDefaultCountry: true });
    } else {
      this.phoneNumberValue = this.props.value;
    }
  }

  onUpdate = () => {
    this.setState({ addDefaultCountry: false });
    this.countryCodeChanged = false;
  };

  onElseUpdate = () => {
    this.setState({ isEdit: true });
  };

  componentDidUpdate() {
    if (this.props.value && this.state.addDefaultCountry) {
      this.onUpdate();
      let parsed = parsePhoneNumber(this.props.value);
      if (parsed) {
        this.countryCallingCode = `+${parsed.countryCallingCode}`;
        this.phoneNumberValue = parsed.nationalNumber;
      } else {
        this.onElseUpdate();
        this.countryCallingCode = `+${this.props.value.substring(0, 2)}`;
        this.phoneNumberValue = this.props.value.substring(2);
        let properties = {
          ...this.props,
          value: this.countryCallingCode + this.phoneNumberValue,
        };
        if (properties.onChangeValidation) {
          properties.onChangeValidation('', properties);
        }
      }
    }
  }

  onCountryChangeHandle = (val) => {
    if (val) {
      this.countryCodeChanged = true;
      location = val;
      if (location && this.state.isDefault == true) {
        this.defaultChange = false;
      }
    } else {
      let properties = {
        ...this.props,
        value: '',
      };
      if (properties.onChangeValidation) {
        properties.onChangeValidation('', properties);
      }
    }
  };

  handleOnChange = (number, e) => {
    if (location) {
      if (number) {
        if (this.state.isEdit) {
          this.countryCodeChanged = false;
          this.setState({ isEdit: false });
        }
        if (this.countryCodeChanged) {
          if (this.state.isDefault && this.defaultChange) {
            this.countryCallingCode = number.substring(0, number.length - 1);
            this.setState({ isDefault: false });
            this.phoneNumberValue = number.replace(this.countryCallingCode, '');
          } else {
            this.countryCallingCode = number;
          }
          this.countryCodeChanged = false;
          number = this.countryCallingCode + this.phoneNumberValue;
        }
        this.phoneNumberValue = number.replace(this.countryCallingCode, '');
      } else if (this.phoneNumberValue.length == 1) {
        this.phoneNumberValue = '';
        number = this.countryCallingCode + this.phoneNumberValue;
      }
      let properties = {
        ...this.props,
        value: number && number ? number : '',
      };
      if (properties.onChangeValidation) {
        properties.onChangeValidation(e, properties);
      }
    }
  };

  render() {
    let { disableLabel, formLabel, label, rules, value } = this.props;
    let { addDefaultCountry } = this.state;
    let message = '';

    if (this.props.errors && this.props.errors.length > 0) {
      message = this.props.errors[0] && this.props.errors[0].message;
    }
    let messagetype = 'i';
    if (this.props.isTouched) {
      messagetype = 'e';
    }
    if (!this.props.isFocused || this.props.isSilent) {
      messagetype = '';
    }

    return (
      <>
        <div>
          {!disableLabel ? (
            <Form.Label className={formLabel || 'app-c-input__label'}>
              {label}
              {rules && rules.required && !this.props.disableRequiredsymbol ? <sup>*</sup> : ''}
            </Form.Label>
          ) : (
            ''
          )}
          <PhoneNumberInput
            setParentState={this.setParentState}
            addDefaultCountry={addDefaultCountry}
            value={value}
            handleOnChange={this.handleOnChange}
            onCountryChangeHandle={this.onCountryChangeHandle}
          />
        </div>
        {messagetype ? <ValidationMessage {...{ message }} {...{ type: messagetype }} /> : <span className="app-c-validation-message" />}
      </>
    );
  }
}

export const PhoneNumberInput = ({ setParentState, addDefaultCountry, handleOnChange, value, onCountryChangeHandle }) => {
  if (addDefaultCountry) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    location = useGeoLocation().country;
    if (location) {
      setParentState({ addDefaultCountry: false, isDefault: true });
    }
  }
  return (
    <PhoneInput
      defaultCountry={location}
      international
      placeholder="Enter phone number"
      value={value}
      limitMaxLength
      countryCallingCodeEditable={false}
      smartCaret={false}
      className="abc"
      style={{ width: 'auto' }}
      onCountryChange={(val) => onCountryChangeHandle(val)}
      onChange={(e, values) => handleOnChange(e, values)}
    />
  );
};
