import React from 'react';
import { Button, Card, CardBody, FormGroup, Modal, Col, Row } from 'reactstrap';
import { post, put } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import DropDown from '../../SharedComponents/FormHelpers/Controls/dropDown';
import FormInput from '../../SharedComponents/FormHelpers/Controls/formInput';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import Loader from '../../Components/loader';
import { PhoneNumber } from '../../SharedComponents/FormHelpers/Controls/phoneNumber';

export class ContactDetails extends FormComponent {
  constructor(props) {
    super(props);
    this.setProperties = this.setProperties.bind(this);
    this.setFormProperties = this.setFormProperties.bind(this);
    this.state = {
      isSubmitted: true,
      rows: [],
      name: {
        label: 'Name',
        name: 'name',
        type: 'text',
        placeholder: 'Enter name',
        rules: {
          required: true,
          maxLength: 100,
        },
      },
      relationship: {
        label: 'Relationship',
        renderapi: 'relationship?isAll=true',
        placeholder: 'Select relationship',
        className: 'mb-0',
        name: 'relationship',
        optionvalue: 'id',
        optiontext: 'title',
        rules: {
          required: true,
          maxLength: 100,
        },
      },
      contactType: {
        label: 'Contact Type',
        renderapi: 'contact-type',
        placeholder: 'Select contact preference type',
        className: 'mb-0',
        name: 'contactType',
        optionvalue: 'id',
        optiontext: 'type',
        rules: {
          required: true,
        },
      },
      phoneNumber: {
        label: 'Phone Number',
        name: 'phoneNumber',
        type: 'text',
        placeholder: 'Enter Phone Number',
        rules: {
          phone: true,
          number: true,
        },
      },
      email: {
        label: 'Email',
        name: 'email',
        type: 'text',
        placeholder: 'Enter email',
        rules: {
          email: true,
          maxLength: 300,
        },
      },
      whatsappNumber: {
        label: 'Whatsapp Number',
        name: 'whatsappNumber',
        type: 'text',
        placeholder: 'Enter whatsapp Number',
        rules: {
          phone: true,
          number: true,
        },
      },
    };
  }

  toast = new ToastNotification();

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.callType == 'edit') {
      this.mapJsonToState(this.props.rowData);

      if (this.props.rowData.id != prevProps.rowData.id) this.changeOfContactType();
    }
    if (this.state.contactType?.value != prevState.contactType?.value) this.changeOfContactType();
  }

  changeOfContactType = () => {
    let arrayOfContactTypes = [
      { id: 1, type: 'phoneNumber' },
      { id: 2, type: 'email' },
      { id: 3, type: 'whatsappNumber' },
    ];

    arrayOfContactTypes.forEach((item) => {
      if (item.id == this.state.contactType?.value) {
        if (Object.prototype.hasOwnProperty.call(this.state, item.type)) {
          this.setState({
            [item.type]: {
              ...this.state[item.type],
              rules: { ...this.state[item.type].rules, required: true },
            },
          });
        }
      } else if (Object.prototype.hasOwnProperty.call(this.state, item.type)) {
        this.setState({
          [item.type]: {
            ...this.state[item.type],
            rules: { ...this.state[item.type].rules, required: false },
            value: '',
          },
        });
      }
    });
  };

  onDropDownChange = (e) => {
    this.setState({ userId: e.target.value });
  };

  onSaveButtonClick = (model) => {
    this.setState({ isLoaderActive: true });

    if (this.props.callType == 'edit') {
      put(`contact-information/${this.props.rowData.id}`, model)
        .then((response) => {
          this.toast.show('edit_success', 'SUCCESS', response.data.message);
          this.props.toggleState();
          this.props.refreshContacts();
          this.setState({ isLoaderActive: false });
        })
        .catch((err) => {
          if (err.message) this.toast.show('update_error', 'ERROR', err.message);
          this.setState({ isLoaderActive: false });
        });
    } else {
      model.obituaryId = this.props.obituaryRowId;

      post('contact-information', model)
        .then((response) => {
          this.setState({ isLoaderActive: false });
          this.toast.show('create_success', 'SUCCESS', response.data.message);
          this.props.toggleState();
          this.props.refreshContacts();
        })
        .catch((err) => {
          this.setState({ isLoaderActive: false });
          if (err.message) this.toast.show('create_error', 'ERROR', err.message);
          else this.toast.show('create_error', 'ERROR', 'Something went wrong');
        });
      this.onReset();
    }
  };

  render() {
    let { showCheck, toggleState, callType } = this.props;
    return (
      <div className="container-fluid">
        {this.state.isLoaderActive === true ? <Loader /> : ''}
        <Modal className="modal-lg" isOpen={showCheck} scrollable toggle={() => toggleState()}>
          <div className="modal-header">
            {callType == 'edit' ? (
              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                Edit Contact
              </h5>
            ) : (
              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                Add Contact
              </h5>
            )}
            <button
              onClick={() => {
                toggleState();
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body ">
            {callType == 'edit' ? <p>Edit Details </p> : <p>Add Details </p>}

            <Card>
              <CardBody>
                <SubmitForm onSubmit={this.onSaveButtonClick} {...this.setFormProperties({ disableReactiveForm: true })}>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormInput {...this.setProperties(this.state.name)} />
                    </Col>
                    <Col xs={12} md={6}>
                      <DropDown {...this.setProperties(this.state.relationship)} />
                    </Col>
                    <Col xs={12} md={6}>
                      <DropDown {...this.setProperties(this.state.contactType)} />
                    </Col>
                    {this.state.contactType?.value == 1 ? (
                      <Col xs={12} md={6}>
                        <PhoneNumber {...this.setProperties(this.state.phoneNumber)} />
                      </Col>
                    ) : null}
                    {this.state.contactType?.value == 2 ? (
                      <Col xs={12} md={6}>
                        <FormInput {...this.setProperties(this.state.email)} />
                      </Col>
                    ) : null}
                    {this.state.contactType?.value == 3 ? (
                      <Col xs={12} md={6}>
                        <PhoneNumber {...this.setProperties(this.state.whatsappNumber)} />
                      </Col>
                    ) : null}
                  </Row>

                  <FormGroup className="mb-0">
                    <div className="float-right d-md-block">
                      <Button type="submit" color="primary" className="mr-1">
                        Save
                      </Button>{' '}
                      <Button
                        className="btn-secondary"
                        type="reset"
                        onClick={() => {
                          toggleState();
                        }}
                      >
                        <i className="icon-close"> </i> <span> Cancel </span>
                      </Button>
                    </div>
                  </FormGroup>
                </SubmitForm>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </div>
    );
  }
}
export default ContactDetails;
