import React from 'react';
import { Row, Col, Card, CardBody, Container } from 'reactstrap';
import FormInput from '../../SharedComponents/FormHelpers/Controls/formInput';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import { post, put } from '../../Libraries/Ajax/httpService';

import SelectFromTemplatesModal from '../Template/selectFromTemplatesModal';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import LinkButton from '../../SharedComponents/linkButton';

class ManageObituaryPosts extends FormComponent {
  constructor(props) {
    super(props);
    this.setProperties = this.setProperties.bind(this);
    this.setFormProperties = this.setFormProperties.bind(this);
    this.state = {
      isSubmitted: true,
      singleRow: {},
      action: 'Add',
      templateModal: false,
      cardModal: false,
      title: '',
      id: {
        label: 'Id',
        type: 'hidden',
      },

      content: {
        label: 'Content',
        type: 'text',
        id: 'autoGrow',
        scrollStartHeight: 200,
        placeholder: 'Enter content',
        rules: {
          maxLength: 5000,
        },
      },

      media: {
        label: 'Media',
        type: 'text',
        name: 'media',
        placeholder: 'Enter media',
        fileUploadUrl: 'obituary-post/upload',
        rules: {},
      },
    };
    this.showTemplateModal = this.showTemplateModal.bind(this);
    this.showCardModal = this.showCardModal.bind(this);
    this.setTemplateAsContent = this.setTemplateAsContent.bind(this);
    this.setTemplateAsCard = this.setTemplateAsCard.bind(this);
  }

  toast = new ToastNotification();

  componentDidMount() {
    let { postDetails, obituaryId, typeId, type, requiredFields, genderId } = this.props;
    if (postDetails) {
      this.setState({ action: 'Edit', singleRow: postDetails, genderId });
    } else if (obituaryId && typeId && type) {
      this.setState({
        singleRow: { obituaryId, typeId },
        title: type,
        genderId,
      });
    }

    if (requiredFields.length > 0) {
      requiredFields.forEach((field) => {
        if (Object.prototype.hasOwnProperty.call(this.state, field)) {
          this.setState({
            [field]: {
              ...this.state[field],
              rules: { ...this.state[field].rules, required: true },
            },
          });
        }
      });
    }
  }

  componentDidUpdate() {
    if (this.state.singleRow) {
      this.mapJsonToState(this.state.singleRow);
    }
  }

  onSaveButtonClick = (model) => {
    let { manageRef, postDetails } = this.props;
    if (model.media) {
      model.mediaType = model.media.slice(model.media.lastIndexOf('.') + 1);
    }
    this.setState({ isLoaderActive: true });
    if (postDetails && postDetails.id) {
      put(`obituary-post/${postDetails.id}`, model)
        .then((response) => {
          this.setState({ isLoaderActive: false });
          manageRef.onClose(true);
          if (response.data.data && response.data.success) {
            this.toast.show('update_success', 'SUCCESS', 'Changes saved successfully!');
          } else {
            this.toast.show('update_error', 'ERROR', 'Could not save changes!');
          }
        })
        .catch(() => {});
    } else {
      post('obituary-post', model)
        .then((response) => {
          this.setState({ isLoaderActive: false });
          manageRef.onClose(true);
          if (response.data.data && response.data.success) {
            this.toast.show('create_success', 'SUCCESS', 'Post created successfully!');
          } else {
            this.toast.show('create_error', 'ERROR', 'Could not create obituary post!');
          }
        })
        .catch(() => {});
    }
  };

  showTemplateModal = () => {
    this.setState((prevState) => ({
      templateModal: !prevState.templateModal,
    }));
  };

  showCardModal = () => {
    this.setState((prevState) => ({
      cardModal: !prevState.cardModal,
    }));
  };

  setTemplateAsContent(msg) {
    this.setState((prevState) => ({
      content: {
        ...prevState.content,
        value: msg,
      },
    }));
    this.showTemplateModal();
  }

  setTemplateAsCard(img) {
    this.setState((prevState) => ({
      media: {
        ...prevState.media,
        value: img,
      },
    }));
    this.showCardModal();
  }

  validExtensions = ['jpeg', 'jpg', 'png'];

  render() {
    let { singleRow, genderId } = this.state;
    let { manageRef } = this.props;
    return (
      <React.Fragment>
        <Container fluid className="px-0">
          <Card className="mb-0">
            <CardBody>
              <SubmitForm
                onSubmit={this.onSaveButtonClick}
                {...this.setFormProperties({
                  disableReactiveForm: true,
                })}
                formRef={manageRef}
              >
                <Row>
                  {singleRow.typeId != 7 && (
                    <Col lg={12} xl={12}>
                      <FormInput rows={5} as="textarea" {...this.setProperties(this.state.content)} />

                      {singleRow.typeId != 3 && (
                        <Col lg={12} className="pb-5">
                          <div className="float-right">
                            <LinkButton to="chooseFromTemplates" toDoFunction={this.showTemplateModal}>
                              Choose From Library
                            </LinkButton>
                          </div>
                        </Col>
                      )}
                    </Col>
                  )}

                  {singleRow.typeId == 2 && (
                    <>
                      <Col xs={12} md={6}>
                        <label>
                          Card
                          <span
                            style={{
                              top: '0.5em',
                              color: 'red',
                            }}
                          >
                            *
                          </span>
                        </label>
                        &nbsp;&nbsp;&nbsp;
                        <LinkButton toString="chooseFromTemplates" toDoFunction={this.showCardModal}>
                          Choose From Library
                        </LinkButton>
                      </Col>
                      <Col md={6} />
                    </>
                  )}

                  {singleRow.typeId == 2 && this.state.media?.value && (
                    <Col className="col-auto">
                      <Card>
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={this.state.media.value}
                          href={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}/${this.state.media.value}`}
                          src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}/${this.state.media.value}`}
                        />
                        &nbsp;&nbsp;
                        <span>{this.state.media.value}</span>
                      </Card>
                    </Col>
                  )}
                </Row>
              </SubmitForm>
            </CardBody>
          </Card>
        </Container>
        {singleRow.typeId && (
          <SelectFromTemplatesModal
            modal={this.state.templateModal}
            showModal={this.showTemplateModal}
            title="Message Templates"
            hide={false}
            cancelButtonName="Cancel"
            confirmButtonName="Use"
            cancelButtonClick={this.showTemplateModal}
            confirmButtonClick={this.setTemplateAsContent}
            renderapi={`template-message?isAll=true&statusId=1&typeId=${singleRow.typeId == 2 ? 1 : singleRow.typeId}&genderId=${genderId}`}
            templateType="message"
            age={this.props.age}
          />
        )}
        {singleRow.typeId == 2 && (
          <SelectFromTemplatesModal
            modal={this.state.cardModal}
            showModal={this.showCardModal}
            title="Card Templates"
            hide={false}
            cancelButtonName="Cancel"
            confirmButtonName="Use"
            cancelButtonClick={this.showCardModal}
            confirmButtonClick={this.setTemplateAsCard}
            renderapi={`template-message?isAll=true&statusId=1&typeId=${singleRow.typeId}`}
            templateType="card"
          />
        )}
      </React.Fragment>
    );
  }
}

export default ManageObituaryPosts;
