import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Pagination } from 'react-bootstrap';
import ListComponent from './listComponent';
import { remove, put, post } from '../../Libraries/Ajax/httpService';
import Modal from '../modal';
import { Filter, Sort, Search } from './dataOperations';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';

import MultiSelectWithCheck from '../FormHelpers/Controls/multiSelectWithCheck';
import { removeObjectAndFunctionAttributes } from '../../Libraries/ArrayHelpers/arrayHelperFunctions';

const img = require('../../assets/images/logo.svg');
const logo = require('../../assets/images/logo-sm.png');

export class ExportRow extends ListComponent {
  predicates = '';

  componentDidUpdate() {
    let { predicates } = this.props;
    this.predicates = { ...predicates };
    delete this.predicates.take;
    delete this.predicates.page;
    this.predicates = this.modelToQuery(this.predicates);
  }

  runReport = () => {
    document.mstr.submit();
  };

  render() {
    let { renderApi } = this.props;
    let { token, refreshToken } = JSON.parse(localStorage.userData);

    return (
      <>
        <form action={`${process.env.REACT_APP_API_URL}${renderApi}${this.predicates}`} method="post" target="_blank">
          <input type="hidden" name="RefreshToken" id="RefreshToken" value={refreshToken} />
          <input type="hidden" name="Authorization" id="Authorization" value={`Bearer ${token}`} />
          <input type="submit" value="Export" className="btn btn-secondary mr-2" />
        </form>
      </>
    );
  }
}
export class AddRow extends ListComponent {
  onAddButtonClick = () => {
    let { history, redirecturl } = this.props;
    history.push(redirecturl);
  };

  render() {
    let { singleSelect, multiSelect, addText } = this.props;
    return (
      !singleSelect &&
      !multiSelect && (
        <Button className="app-c-btn--primary app-c-add-btn " variant="primary" {...this.props} onClick={() => this.onAddButtonClick()}>
          <img alt="logo-Full" src={img} />
          <span> {addText || 'Add'} </span>
        </Button>
      )
    );
  }
}

export class EditRow extends ListComponent {
  onEditButtonClick = () => {
    let { history, redirecturl } = this.props;
    history.push(redirecturl);
  };

  render() {
    let { singleSelect, isCardViewTrue, editText, rowData, isGallery, isTemplate, inhouse, roles, isTypeTemplate } = this.props;
    let status = '';
    if (isTemplate) {
      status = rowData?.statusTitle;
    } else if (inhouse) {
      status = rowData.jobDetailsStatusTitle;
    } else {
      status = rowData?.contentStatusTitle;
    }

    return (status === 'Created' && (singleSelect || isCardViewTrue)) || isGallery || (isTypeTemplate && singleSelect) || (roles && singleSelect) ? (
      <button
        type="button"
        className={isCardViewTrue ? 'btn-action btn btn-success' : 'btn-secondary btn btn-primary'}
        onClick={(e) => this.onEditButtonClick(e)}
      >
        {isCardViewTrue ? <span className="icon-edit" /> : <i className="icon-edit"> </i>} <span>{editText}</span>
      </button>
    ) : null;
  }
}

export class PublishRow extends ListComponent {
  state = {
    redirect: null,
    showPublishConfirmation: false,
  };

  toast = new ToastNotification();

  onPublishButtonClick = () => {
    this.setState({
      showPublishConfirmation: true,
    });
  };

  confirmButtonClick = (e) => {
    let { rowData, renderapi, getpredicates, setParentState, isGallery, isTemplate, inhouse } = this.props;
    e.stopPropagation();
    e.preventDefault();
    let condition = { ...this.props, singleSelect: false, multiSelect: false };
    if (rowData.length > 1) {
      let index = 0;
      rowData.forEach((model) => {
        index += 1;
        if (isGallery) {
          model.galleryStatusId = 1;
        } else if (isTemplate) {
          model.statusId = 3;
        } else if (inhouse) {
          model.jobDetailsStatusId = 2;
        } else {
          model.contentStatusId = 3;
        }
        put(renderapi, model)
          .then((res) => {
            if (res && res.success === false) {
              this.toast.show('update_failed', 'ERROR', res.message ? res.message : 'Published Failed');
            }
            index -= 1;
            if (index === 0) {
              this.setState({
                showPublishConfirmation: false,
              });
              setParentState(condition);
              getpredicates().setpredicates({});
              this.toast.show('update_success', 'SUCCESS', 'Published Successfully');
            }
          })
          .catch((error) => {
            this.setState({ isLoaderActive: false });
            if (error.message) this.toast.show('publish_error', 'ERROR', error.message ? error.message : 'Publish Failed');
          });
      });
    } else {
      if (isGallery) {
        rowData.galleryStatusId = 1;
      } else if (isTemplate) {
        rowData.statusId = 3;
      } else if (inhouse) {
        rowData.jobDetailsStatusId = 2;
      } else {
        rowData.contentStatusId = 3;
      }
      put(renderapi, rowData)
        .then(() => {
          this.setState({
            showPublishConfirmation: false,
          });
          setParentState(condition);
          getpredicates().setpredicates({});
          this.toast.show('publish_success', 'SUCCESS', 'Published Successfully');
        })
        .catch((error) => {
          setTimeout(() => {
            this.setState({ isLoaderActive: false });
          }, 1000);
          if (error.message) this.toast.show('publish_error', 'ERROR', error.message ? error.message : 'Publish Failed');
        });
    }
  };

  onCancelButtonClick = () => {
    this.setState({
      showPublishConfirmation: false,
    });
  };

  render() {
    let { rowData, getParentState, isGallery, isTemplate, inhouse } = this.props;
    let singleSelect = getParentState('singleSelect');
    let multiSelect = getParentState('multiSelect');
    let status = '';
    if (isTemplate === true) {
      status = multiSelect && !isGallery ? rowData.map((entry) => entry.statusTitle) : rowData.statusTitle;
    } else if (inhouse) {
      if (rowData.length > 1) {
        status = rowData.map((row) => row.jobDetailsStatusTitle);
      } else {
        status = rowData.jobDetailsStatusTitle;
      }
    } else {
      status = multiSelect && !isGallery ? rowData.map((entry) => entry.contentStatusTitle) : rowData.contentStatusTitle;
    }

    if (status === undefined || status === null || isGallery) {
      status = multiSelect ? rowData.map((entry) => entry.galleryStatusTitle) : rowData.galleryStatusTitle;
    }
    let isCreated = false;
    if (status && status.length !== 0 && multiSelect) {
      status.map((item) => {
        if (item !== 'Created') {
          isCreated = true;
        }
        return 1;
      });
    } else if (singleSelect && status !== 'Created') {
      isCreated = true;
    }
    return (
      <>
        {!isCreated && (singleSelect || multiSelect) ? (
          <Button variant="primary" {...this.props} onClick={() => this.onPublishButtonClick()}>
            {' '}
            <i className="icon-publish-arrow" style={{ fontSize: '9px', paddingRight: '5px' }}>
              {' '}
            </i>
            Publish
          </Button>
        ) : null}
        {this.state.showPublishConfirmation ? (
          <Modal
            title="Confirmation"
            cancelButtonName="Cancel"
            confirmButtonName="OK"
            cancelButtonClick={this.onCancelButtonClick}
            confirmButtonClick={this.confirmButtonClick}
          >
            <div>{multiSelect === true ? 'Are you sure you want to publish?' : 'Are you sure you want to publish this record?'}</div>
          </Modal>
        ) : null}
      </>
    );
  }
}

export class DeleteRow extends ListComponent {
  state = {
    showDeleteConfirmation: false,
  };

  toast = new ToastNotification();

  onRemoveButtonClick = () => {
    this.setState({
      showDeleteConfirmation: true,
    });
  };

  confirmButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let { renderapi, entry, getpredicates, setParentState } = this.props;
    let condition = { ...this.props, singleSelect: false, multiSelect: false };
    if (entry.length > 1) {
      let index = 0;
      entry.forEach((item) => {
        index += 1;
        remove(renderapi, item)
          .then((response) => {
            if (response.data.success === false) {
              setParentState(condition);
              getpredicates().setpredicates({});
              this.toast.show('validation_error', 'ERROR', response.data.message);
            } else {
              index -= 1;
              if (index === 0) {
                this.setState({
                  showDeleteConfirmation: false,
                });
                setParentState(condition);
                getpredicates().setpredicates({});
                this.toast.show('delete_success', 'SUCCESS', 'Deleted Successfully');
              }
            }
          })
          .catch((error) => {
            setTimeout(() => {
              this.setState({ isLoaderActive: false });
            }, 1000);
            if (error.message) this.toast.show('delete_error', 'ERROR', error.message ? error.message : 'Delete Failed');
          });
      });
    } else {
      remove(renderapi, entry)
        .then((response) => {
          this.setState({
            showDeleteConfirmation: false,
          });
          if (response.data.success === false) {
            setParentState(condition);
            getpredicates().setpredicates({});
            this.toast.show('validation_error', 'ERROR', response.data.message);
          } else {
            setParentState(condition);
            getpredicates().setpredicates({});
            this.toast.show('delete_success', 'SUCCESS', 'Deleted Successfully');
          }
        })
        .catch((error) => {
          setTimeout(() => {
            this.setState({ isLoaderActive: false });
          }, 1000);
          if (error.message) this.toast.show('delete_error', 'ERROR', error.message ? error.message : 'Delete Failed');
        });
    }
  };

  onCancelButtonClick = () => {
    this.setState({
      showDeleteConfirmation: false,
    });
  };

  render() {
    let { isCardViewTrue, deleteText, getParentState } = this.props;
    let singleSelect = getParentState('singleSelect');
    let multiSelect = getParentState('multiSelect');
    return (
      <>
        {singleSelect || multiSelect || isCardViewTrue ? (
          <button
            type="button"
            className={isCardViewTrue ? 'btn-action btn btn-danger' : 'btn-secondary btn btn-primary'}
            onClick={(e) => this.onRemoveButtonClick(e)}
          >
            {isCardViewTrue ? <span className="icon-trash" /> : <span className="icon-trash mr-1" />}
            <span>{deleteText}</span>
          </button>
        ) : null}

        {this.state.showDeleteConfirmation ? (
          <Modal
            title="Confirmation"
            cancelButtonName="Cancel"
            confirmButtonName="OK"
            cancelButtonClick={this.onCancelButtonClick}
            confirmButtonClick={this.confirmButtonClick}
          >
            <div>{multiSelect === true ? 'Are you sure you want to delete?' : 'Are you sure you want to delete this record?'}</div>
          </Modal>
        ) : null}
      </>
    );
  }
}

export class RemoveAccessRow extends ListComponent {
  state = {
    showRemoveAccessConfirmation: false,
  };

  toast = new ToastNotification();

  onRemoveButtonClick = () => {
    this.setState({
      showRemoveAccessConfirmation: true,
    });
  };

  confirmButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let { renderapi, entry, getpredicates, setParentState } = this.props;
    let condition = { ...this.props, singleSelect: false, multiSelect: false };
    if (entry.length > 1) {
      let index = 0;
      entry.forEach((item) => {
        let model = {
          groupList: [],
          userDetailsId: item.userAccessGroupList[0].userDetailsId,
          userName: item.userName,
        };
        index += 1;
        post(renderapi, model)
          .then(() => {
            index -= 1;
            if (index === 0) {
              this.setState({
                showRemoveAccessConfirmation: false,
              });
              setParentState(condition);
              getpredicates().setpredicates({});
              this.toast.show('remove_success', 'SUCCESS', 'Removed Access Successfully');
            }
          })
          .catch((error) => {
            setTimeout(() => {
              this.setState({ isLoaderActive: false });
            }, 1000);
            if (error.message) this.toast.show('remove_error', 'ERROR', error.message ? error.message : 'Failed to remove the access');
          });
      });
    } else {
      let model = {
        groupList: [],
        userDetailsId: entry.userAccessGroupList[0].userDetailsId,
        userName: entry.userName,
      };
      post(renderapi, model)
        .then(() => {
          this.setState({
            showRemoveAccessConfirmation: false,
          });
          setParentState(condition);
          getpredicates().setpredicates({});
          this.toast.show('remove_success', 'SUCCESS', 'Removed Access Successfully');
        })
        .catch((error) => {
          setTimeout(() => {
            this.setState({ isLoaderActive: false });
          }, 1000);
          if (error.message) this.toast.show('remove_error', 'ERROR', error.message ? error.message : 'Failed to remove access');
        });
    }
  };

  onCancelButtonClick = () => {
    this.setState({
      showRemoveAccessConfirmation: false,
    });
  };

  render() {
    let { deleteText, getParentState } = this.props;
    let singleSelect = getParentState('singleSelect');
    let multiSelect = getParentState('multiSelect');
    return (
      <>
        {singleSelect || multiSelect ? (
          <button type="button" className="btn-secondary btn btn-primary" onClick={(e) => this.onRemoveButtonClick(e)}>
            <span className="icon-trash mr-1" />
            <span>{deleteText}</span>
          </button>
        ) : null}

        {this.state.showRemoveAccessConfirmation ? (
          <Modal
            title="Confirmation"
            cancelButtonName="Cancel"
            confirmButtonName="OK"
            cancelButtonClick={this.onCancelButtonClick}
            confirmButtonClick={this.confirmButtonClick}
          >
            <div>
              {multiSelect === true ? 'Are you sure you want to remove the access?' : 'Are you sure you want to remove the access of this user?'}
            </div>
          </Modal>
        ) : null}
      </>
    );
  }
}

export class DataColHead extends ListComponent {
  render() {
    let {
      coltype,
      sortField,
      title,
      filterapi,
      searchField,
      filterField,
      enableSearch,
      sublist,
      haveResuls,
      filterClassName,
      enableSort,
      isMultiSelectCheck,
      style,
    } = this.props;
    if (enableSort) {
      coltype = 'sort';
      if (!(haveResuls && haveResuls.rows?.length > 0)) coltype = 'hideSort';
    }
    let isANoFilterColumn = ' remove-space-td app-l-mini-condensed-column';
    if (coltype || enableSearch) {
      isANoFilterColumn = '';
    }

    return (
      <th
        style={style}
        scope="col"
        {...removeObjectAndFunctionAttributes(this.props)}
        {...{
          // ...this.props,
          ...{
            className: `${(this.props.className ? this.props.className : '') + (filterClassName || '')}${isANoFilterColumn}`,
          },
        }}
      >
        {title}
        {isMultiSelectCheck && <MultiSelectWithCheck {...this.props} />}
        {enableSort ? (
          <Sort {...this.props} sortField={sortField} coltype={coltype} />
        ) : (
          coltype === 'filter' && (
            <Filter
              {...this.props}
              filterClassName={filterClassName}
              filterapi={filterapi}
              filterField={filterField}
              title={title}
              sublist={sublist}
            />
          )
        )}
        {enableSearch ? <Search {...this.props} searchField={searchField} title={title} /> : null}
      </th>
    );
  }
}

export class ListPagination extends ListComponent {
  state = {
    section: 1,
    limit: 10,
    defaultOptions: [5, 10, 20, 30, 40, 50],
  };

  onSelectChange = (e) => {
    window.scroll(0, 0);
    this.props.setpredicates({ take: e.target.value, page: 0 });
    this.setState({ limit: e.target.value });
  };

  componentDidMount() {
    window.onresize = () => {
      this.setState({ section: 1 });
    };
    if (this.props.customOptions?.length > 0) this.setState({ defaultOptions: this.props.customOptions });
  }

  onPageChange = (page) => {
    this.props.setpredicates({ take: this.state.limit, page: page - 1 });
    window.scroll(0, 0);
  };

  onSectionChange = (section) => {
    this.setState({ section });
    this.props.setpredicates({
      PageNumber: this.itemCount * (section - 1) + 1,
    });
  };

  render() {
    let { currentPage, totalPages, totalItems, pageElements, pageSize } = this.props;

    let { section } = this.state;
    let firstItem = (currentPage - 1) * pageSize + 1;
    let lastItem = (currentPage - 1) * pageSize + pageElements;
    let windowwidth = window.innerWidth - (500 + window.innerWidth / 10);
    this.itemCount = parseInt(windowwidth / 34, 10);
    if (this.itemCount < 3) {
      this.itemCount = 3;
    }
    if (this.itemCount > 10) {
      this.itemCount = 10;
    }
    section = parseInt(currentPage / this.itemCount, 10) + 1;
    if (currentPage % this.itemCount == 0) {
      section -= 1;
    }
    if (section <= 0) {
      section = 1;
    }
    let totalsections = parseInt(totalPages / this.itemCount, 10);
    if (totalPages > this.itemCount * totalsections) {
      totalsections += 1;
    }
    let rows = [];
    for (let i = 0; i <= totalPages; i += 1) {
      if (i > (section - 1) * this.itemCount && i <= section * this.itemCount) {
        rows.push(i);
      }
    }
    return (
      <div className="list-pagination">
        <div className={totalItems !== 0 ? 'app-l-table-footer' : 'd-none'}>
          <div className="row">
            <div className="lp-dropdown col-sm-1">
              <select
                className="custom-select custom-select-sm form-control form-control-sm col-sm-12"
                id="pageNo"
                defaultValue={pageSize || 10}
                onChange={(e) => this.onSelectChange(e)}
              >
                {this.state.defaultOptions.map((item, j) => (
                  <option value={item} selected={pageSize == item} key={`paginationOption-${j}`}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div data-test="datatable-info col-sm-2">
              <div className="dataTables-info-pad-2">
                <p>
                  Showing {firstItem} to <span>{lastItem}</span> of <span>{totalItems}</span> results
                </p>
              </div>
            </div>

            <div data-test="datatable-pagination" className="pg-link col-sm-12 col-md-5">
              <div className="dataTables_paginate">
                <Pagination>
                  <ul data-test="pagination" className="pagination">
                    <li data-test="page-item" className={`page-item ${currentPage <= 1 ? 'disabled' : ''}`}>
                      <Link
                        to="#Paginate"
                        data-test="page-link"
                        aria-label="Previous"
                        className="page-link page-link"
                        onClick={() => this.onPageChange(currentPage - 1)}
                      >
                        <span>Previous</span>
                      </Link>
                    </li>
                    {rows.map((index, i) => (
                      <Pagination.Item
                        key={`paginationItem-${i}`}
                        onClick={() => this.onPageChange(index)}
                        active={index === currentPage}
                        // key={index}
                      >
                        {index}
                      </Pagination.Item>
                    ))}
                    <li data-test="page-item" className={`page-item ${currentPage >= totalPages ? 'disabled' : ''}`}>
                      <Link
                        to="#pageItem"
                        data-test="page-link"
                        aria-label="Next"
                        className="page-link page-link"
                        onClick={() => this.onPageChange(currentPage + 1)}
                      >
                        <span>Next</span>
                      </Link>
                    </li>
                  </ul>
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class ViewDate extends ListComponent {
  monthNames = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  render() {
    let { dateValue, isTableView } = this.props;

    return (
      <span>
        {dateValue ? (
          <>
            {isTableView ? null : <img alt="WomenDay" src={logo} />}
            {`${dateValue.split('T')[0].split('-')[2]}/${dateValue.split('T')[0].split('-')[1]}/${dateValue.split('T')[0].split('-')[0]}`}
          </>
        ) : (
          '______'
        )}
      </span>
    );
  }
}

export class ViewTime extends ListComponent {
  render() {
    let { dateValue } = this.props;
    return <span>{dateValue ? `${dateValue.split('T')[1].split('.')[0]}` : '-'}</span>;
  }
}
export class ViewHtml extends ListComponent {
  componentDidUpdate() {}

  render() {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: this.props.htmltext,
        }}
      />
    );
  }
}

export class BackButtonComponent extends ListComponent {
  onBackButtonClick = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Button className="app-c-back-btn" variant="secondary" onClick={this.onBackButtonClick}>
        <span className="icon-right_arrow" />
      </Button>
    );
  }
}
export class CancelComponent extends ListComponent {
  onCancelButtonClick = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Button className="btn-secondary" onClick={this.onCancelButtonClick}>
        <i className="icon-close"> </i> <span> Cancel </span>
      </Button>
    );
  }
}

// export class CheckBoxElement extends ListComponent {
//   state = {
//     rows: [],
//     selectAll: true,
//   };

//   onColClick = (e) => {
//     e.stopPropagation();
//   };

//   componentDidUpdate() {
//     let properties = {
//       ...this.props,
//     };
//     let rowData = properties.getParentState('rowData');
//     let apiResponse = properties.getParentState('apiResponse');
//     if (rowData?.length === apiResponse.rows?.length && typeof rowData.length !== 'undefined') {
//       document.getElementById('All').checked = true;
//     } else {
//       document.getElementById('All').checked = false;
//     }
//     if (rowData?.length === apiResponse.rows?.length - 1 && this.state.selectAll !== true) {
//       this.setState({
//         selectAll: true,
//       });
//     }
//   }

//   handleEachCheckField = (row) => {
//     let selected = 0;
//     let properties = {
//       ...this.props,
//     };
//     let apiResponse = properties.getParentState('apiResponse');
//     let { rows } = apiResponse;
//     rows.forEach((single) => {
//       if (single.id === row.id) single.isChecked = !single.isChecked;
//     });
//     rows.forEach((single) => {
//       if (single.isChecked === true) {
//         selected += 1;
//       }
//     });
//     let obj = {};
//     let condition = {};
//     obj.apiResponse = { ...apiResponse, rows };
//     properties.setParentState(obj);
//     if (selected > 1) {
//       var rowData = {};
//       rowData.rowData = rows.filter((row) => row.isChecked === true);
//       properties.setParentState(rowData);
//       condition = { ...properties, multiSelect: true, singleSelect: false };
//       properties.setParentState(condition);
//     } else if (selected === 0) {
//       var rowData = {};
//       properties.setParentState(rowData);
//       condition = { ...properties, multiSelect: false, singleSelect: false };
//       properties.setParentState(condition);
//     } else {
//       let selectedRow = {};
//       rows.forEach((row) => {
//         if (row.isChecked === true) selectedRow = row;
//       });
//       var rowData = {};
//       rowData.rowData = selectedRow;
//       properties.setParentState(rowData);
//       condition = { singleSelect: true, multiSelect: false };
//       properties.setParentState(condition);
//     }
//   };

//   handleAllChecked = () => {
//     let { selectAll } = this.state;
//     let properties = {
//       ...this.props,
//     };
//     let obj = {};
//     let condition = {};
//     let apiResponse = properties.getParentState('apiResponse');
//     let row = properties.getParentState('rowData');
//     let { rows } = apiResponse;
//     var rowData = {};

//     this.setState({
//       selectAll: !selectAll,
//     });
//     rowData.rowData = rows;
//     properties.setParentState(rowData);
//     if (selectAll === true) {
//       rows.forEach((row) => {
//         row.isChecked = true;
//       });
//       condition = { ...properties, multiSelect: true };
//       properties.setParentState(condition);
//     } else {
//       var rowData = {};
//       rowData.rowData = {};
//       properties.setParentState(rowData);
//       rows.forEach((row) => (row.isChecked = false));
//       condition = { ...properties, multiSelect: false, singleSelect: false };
//       properties.setParentState(condition);
//     }
//     obj.apiResponse = { ...apiResponse, rows };
//     properties.setParentState(obj);
//   };

//   render() {
//     let { totalItems, row, isSelectAll, labelValue, marginBottomRequired, className } = this.props;
//     let classStyle = totalItems !== 0 ? (marginBottomRequired ? '__checkbox' : '__checkbox') : '__checkbox invisible';
//     return (
//       <div className={className || classStyle} onClick={(e) => this.onColClick(e)}>
//         <input
//           type="checkbox"
//           id={isSelectAll ? 'All' : row.id}
//           onChange={isSelectAll ? () => this.handleAllChecked() : () => this.handleEachCheckField(row)}
//           value={isSelectAll ? 'All' : row.id}
//           checked={row && row.isChecked}
//         />
//         <label htmlFor={isSelectAll ? 'All' : row.id}>{labelValue}</label>
//       </div>
//     );
//   }
// }
