import dayjs from 'dayjs';

/**
 * @param {Date|string|number} date date which needs to be converted
 * @returns {string} the input date converted into the local date and time separated by 'T' and with '.000Z' appended to its end
 */
export const convertToLocalISOString = (date) => new Date(new Date(date) - new Date().getTimezoneOffset() * 60000).toISOString();

/**
 * @param {string} date
 * @returns {string} the date part of the datetime input string
 */
export const formatDate = (date) => date.split('T')[0];

/**
 * @param {Date|string} date
 * @param {Date|string} time
 * @returns {string} a datetime string that is created by taking the date part(YYYY-MM-DD) of first datetime argument and the time part(HH:mm:ss) of the second datetime argument
 */
// next line does not work in safari as it has compactibility issues with regex
// export const combineDateTime = (date, time) => date.replace(/(?<=[T|\s]).*(?=\.)/, time.match(/(?<=[T|\s]).*(?=\.)/)[0]);
export const combineDateTime = (date, time) => {
  date = date.toUpperCase();
  time = time.toUpperCase();
  let isUTC = false;
  if (date.indexOf('Z') === date.length - 1) {
    isUTC = true;
    date = date.slice(0, -1);
  }
  if (time.indexOf('Z') === time.length - 1) {
    time = time.slice(0, -1);
  }
  return `${dayjs(date).format('YYYY-MM-DDT') + dayjs(time).format('HH:mm:ss') + (isUTC ? '.000Z' : '')}`;
};
