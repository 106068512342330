import React, { useEffect, useState } from 'react';
import { components } from 'react-select';
import { Form } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';

import { LazyRun } from '../../../Libraries/LazyFunctions/lazyFunction';
import error404Img from '../../../assets/images/errorImg.png';
import defaultPic from '../../../assets/images/userpic.png';
import ValidationMessage from '../Validation/validationMessage';
import { get } from '../../../Libraries/Ajax/httpService';

const customStyles = {
  menu: (style) => ({ ...style, zIndex: 10 }),
};

const Option = (props) => (
  <>
    <components.Option {...props}>{props.children}</components.Option>
  </>
);

const setCustomMenuOptions = (options, { imageKey, subText, mainText }) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  options.map((item) => {
    item.value = item.id;
    item.label = (
      <div className="custom-label">
        <img
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = error404Img;
          }}
          data-dz-thumbnail=""
          height="50"
          className="avatar-sm rounded-50 bg-light"
          alt="img-alt"
          src={item[imageKey] ? item[imageKey] : defaultPic}
        />
        <div className="label-sub-block">
          <span className="main-text">{item[mainText]}</span>
          <span className="sub-text">{item[subText]}</span>
        </div>
      </div>
    );
    return item;
  });

const SearchableDropDown = (props) => {
  let {
    renderapi,
    imageKey,
    subText,
    mainText,
    apiModel,
    name,
    value,
    disableFormGroup,
    disableLabel,
    rules,
    label,
    errors,
    disableRequiredsymbol,
    onChangeValidation,
    optionvalue,
    customOnchangeHandler,
    placeholder,
    clearSelection,
    noOptionsMessage,
    disableSelect,
  } = props;

  let [searchValue, setSearchValue] = useState('');
  let [selectedValue, setSelectedValue] = useState('');
  let [allOptions, setOptions] = useState([]);

  useEffect(() => {
    if (value && (selectedValue?.value != value || !selectedValue)) {
      let selectedItem = allOptions.filter((item) => item.value == value);
      if (!selectedItem?.length > 0) {
        get(renderapi, { [optionvalue]: value, ...apiModel }).then((res) => {
          if (res.data?.data?.rows?.length > 0) {
            let selectedOpn = setCustomMenuOptions(res.data.data.rows, {
              imageKey,
              subText,
              mainText,
            });
            setSelectedValue(selectedOpn.length > 0 ? selectedOpn[0] : '');
            setOptions([...selectedOpn, ...allOptions]);
          }
        });
      } else setSelectedValue(selectedItem?.length > 0 ? selectedItem[0] : '');
    }
  }, [value, selectedValue]);

  let setApiForOptions = (inputValue, callback) => {
    get(renderapi, {
      ...apiModel,
      take: 20,
      q: inputValue,
      sortField: 'name',
      sortOrder: 'ASC',
    }).then((res) => {
      if (res.data?.data?.rows) {
        let options = setCustomMenuOptions(res.data.data.rows, {
          imageKey,
          subText,
          mainText,
        });
        setOptions(options);
        callback(options);
      }
    });
  };

  let loadOptions = (inputValue, callback) => {
    if (inputValue) {
      LazyRun(() => {
        setApiForOptions(inputValue, callback);
      }, 500);
    } else setApiForOptions(inputValue, callback);
  };

  let handleOnChange = (selectedOpn) => {
    if (!clearSelection) {
      setSelectedValue(selectedOpn);
    }
    if (customOnchangeHandler) customOnchangeHandler(selectedOpn);
    else {
      let properties = {
        ...props,
        value: selectedOpn?.value ? selectedOpn?.value : null,
      };
      let filteredArray = allOptions.filter((item) => item[properties.optionvalue] === properties.value);
      if (filteredArray.length > 0) {
        let [first] = filteredArray;
        properties.item = first;
      }
      if (onChangeValidation) onChangeValidation(selectedOpn, properties);
    }
  };

  return (
    <div className={disableFormGroup ? '' : 'form-group'} key={name} id={`${name}_id`}>
      {!disableLabel && (
        <Form.Label>
          {label}
          {rules && rules.required && !disableRequiredsymbol && <sup>*</sup>}
        </Form.Label>
      )}
      <AsyncSelect
        isSearchable
        isClearable
        isDisabled={disableSelect}
        key={`select-id-${name}`}
        id={`select-id-${name}`}
        value={selectedValue}
        styles={customStyles}
        defaultOptions
        loadOptions={loadOptions}
        components={{ Option }}
        placeholder={placeholder || 'Search...'}
        name={name}
        className={`format-label ${name}`}
        classNamePrefix="format-label"
        searchValue={searchValue}
        noOptionsMessage={() => noOptionsMessage}
        onInputChange={(a) => setSearchValue(a)}
        onChange={(selectedOption) => handleOnChange(selectedOption)}
      />
      <ValidationMessage
        {...{
          message: errors?.length > 0 && errors[0].message,
        }}
      />
    </div>
  );
};

export default SearchableDropDown;
