import React from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, FormGroup, Row } from 'reactstrap';

import Loader from '../../Components/loader';
import { get, post, put } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import Modal from '../../SharedComponents/modal';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';
import FormInput from '../../SharedComponents/FormHelpers/Controls/formInput';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import { DeleteRow } from '../../SharedComponents/ListComponents/manageComponent';
import { AccessModuleCards } from './accessProfileHelpers';

class ManageAccessProfile extends FormComponent {
  constructor(props) {
    super(props);
    this.setProperties = this.setProperties.bind(this);
    this.setFormProperties = this.setFormProperties.bind(this);
    this.state = {
      isSubmitted: true,
      isEdit: false,
      isLoaderActive: false,
      isEditConfirmed: false,
      singleRow: {},
      group: [],
      id: {
        label: 'Id',
        type: 'hidden',
      },
      title: {
        label: 'Template Name',
        name: 'title',
        type: 'text',
        placeholder: 'Enter Template Name',
        rules: {
          required: true,
          maxLength: 200,
        },
      },
      description: {
        label: 'Description',
        as: 'textarea',
        name: 'description',
        type: 'text',
        placeholder: 'Enter Description',
        rules: {
          required: true,
          maxLength: 500,
        },
        action: props.match.params.id ? 'Edit' : 'Create',
        editFlag: props.match.params.id || false,
      },
    };
  }

  componentDidMount() {
    let { match } = this.props;
    this.setState({ isLoaderActive: true });
    get(`access/get-list${match.params.id ? `?id=${match.params.id}` : ''}`)
      .then((response) => {
        if (response.data.data?.modules) {
          response.data.data.modules.forEach((mod) => {
            if (mod.subModules.length > 1) {
              response.data.data.allPermissions.forEach((permission, permIndex) => {
                if (!mod.subModules.every((item) => item[`disable${permission.title}`])) {
                  mod[`is${permission.title}All`] = mod.subModules.every(
                    (subModule) => subModule[`disable${permission.title}`] || subModule.permissions[permIndex].isSelected === true,
                  );
                }
              });
            }
          });
        }
        if (match?.params.id) {
          this.mapJsonToState(response.data.data);
          this.setState({ isEdit: true });
        }
        this.setState({ isLoaderActive: false, singleRow: response.data.data });
      })
      .catch(() => {});
    this.setState({ access: setComponentPrivilege('Access Templates') });
  }

  toast = new ToastNotification();

  onSaveButtonClick = (model) => {
    this.setState({ isLoaderActive: true });
    let { match, history } = this.props;
    if (!(model && model.title && model.description)) {
      model = { ...this.state.onSaveModel };
    }
    model.modules = this.state.singleRow.modules;
    if (match?.params.id) {
      put(`access/${match.params.id}`, model)
        .then((response) => {
          this.setState({ isLoaderActive: false });
          this.toast.show('create_success', 'SUCCESS', response.data.message);
          history.push('/list-access-profiles');
        })
        .catch((err) => {
          this.setState({ isLoaderActive: false });
          if (err.message) this.toast.show('update_error', 'ERROR', err.message);
        });
    } else {
      post('access', model)
        .then((response) => {
          this.setState({ isLoaderActive: false });
          this.toast.show('create_success', 'SUCCESS', response.data.message);
          history.push('/list-access-profiles');
        })
        .catch((err) => {
          this.setState({ isLoaderActive: false });
          if (err.message) this.toast.show('create_error', 'ERROR', err.message);
        });
    }
  };

  saveButtonClick = (model) => {
    if (this.state.isEdit) {
      this.setState({ showEditConfirmation: true, onSaveModel: model, isEditConfirmed: true });
    } else this.onSaveButtonClick(model);
  };

  onCancelEditClick = () => {
    this.setState({ showEditConfirmation: false });
  };

  onCancelButtonClick = () => {
    this.props.history.push('/list-access-profiles');
  };

  updateModuleList = (list) => this.setState((prev) => ({ singleRow: { ...prev.singleRow, modules: list } }));

  render() {
    let { editFlag, isLoaderActive, singleRow, action, title, description, access, showEditConfirmation } = this.state;

    let { history, match } = this.props;
    return (
      <React.Fragment>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Access Template Details</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/list-access-profiles">Access Templates</Link>
                  </li>
                  <li className="breadcrumb-item active">{action} Access Template</li>
                </ol>
              </div>
            </Col>
            {editFlag && access && access.delete && (
              <Col sm="6">
                <div className="float-right d-none">
                  <DeleteRow
                    renderapi={`access/${this.props.match.params.id}`}
                    model={singleRow}
                    redirecturl="/list-access-profiles"
                    deleteText="Delete"
                    history={history}
                    isCardViewTrue="True"
                  />{' '}
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Container fluid className="px-0 access-profile">
              <div className="col-12">
                <SubmitForm onSubmit={this.saveButtonClick} {...this.setFormProperties({ disableReactiveForm: true })}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormInput
                            {...this.setProperties(title)}
                            disabled={match.params && (match.params.id == 1 || match.params.id == 2)}
                            title={
                              match.params && (match.params.id == 1 || match.params.id == 2)
                                ? `Title cannot be edited since it is part of the default access for ${title.value?.toLowerCase()}`
                                : ''
                            }
                          />
                        </Col>

                        <Col xs={12} md={12}>
                          <FormInput {...this.setProperties(description)} />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  {singleRow.modules && (
                    <AccessModuleCards
                      {...this.setproperties({
                        modulesList: singleRow.modules,
                        allPermissions: singleRow.allPermissions,
                      })}
                      updateModuleList={this.updateModuleList}
                    />
                  )}
                  <FormGroup className="mb-0">
                    <div className="float-right d-md-block">
                      <br />
                      <Button type="submit" color="primary" className="mr-1">
                        Save
                      </Button>{' '}
                      <Button className="btn-secondary" type="reset" onClick={this.onCancelButtonClick}>
                        <i className="icon-close"> </i> <span> Cancel </span>
                      </Button>
                    </div>
                  </FormGroup>
                </SubmitForm>
              </div>
            </Container>
          </Row>
        </div>
        {showEditConfirmation ? (
          <Modal
            title="Edit confirmation"
            cancelButtonName="Cancel"
            confirmButtonName="OK"
            cancelButtonClick={this.onCancelEditClick}
            confirmButtonClick={this.onSaveButtonClick}
          >
            <div style={{ textAlign: 'left' }}>
              Editing this template can change the user access of those users to which this template has been assigned. Are you sure you want to
              continue?{' '}
            </div>
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.Login.userData,
});
export default connect(mapStateToProps)(ManageAccessProfile);
