/* eslint-disable */
import { Component } from 'react';
import { get } from '../../Libraries/Ajax/httpService';

export default class ListComponent extends Component {
  url = '';

  model = {};

  constructor(props) {
    super(props);
    this.setParentState = this.setParentState.bind(this);
    this.setheadproperties = this.setheadproperties.bind(this);
    this.setpagination = this.setpagination.bind(this);
    this.setpredicates = this.setpredicates.bind(this);
    this.getpredicates = this.getpredicates.bind(this);
    this.setObjectData = this.setObjectData.bind(this);
    this.setproperties = this.setproperties.bind(this);
    this.getParentState = this.getParentState.bind(this);
    this.setJsonUrl = this.setJsonUrl.bind(this);
    // if (sessionStorage.getItem('model') && JSON.parse(sessionStorage.getItem('setPredicate')) === true) {
    //   this.model = JSON.parse(sessionStorage.getItem('model'));
    // }
  }

  modelToQuery(_model) {
    let queryList = [];
    for (let m in _model) {
      if (_model.hasOwnProperty(m) && _model[m] !== undefined) {
        if (typeof _model[m] == 'object') {
          for (let i = 0; i < _model[m]?.length; i++) {
            queryList.push(`${m}[${i}]` + `=${_model[m][i]}`);
          }
        } else {
          queryList.push(`${m}=${_model[m]}`);
        }
      }
    }
    if (queryList.length > 0) {
      return `?${queryList.join('&')}`;
    }
    return '';
  }

  newResponse = {};

  setObjectData = (res, level, obj, baseObjName) => {
    for (let d in res) {
      for (let dd in res[d]) {
        if (typeof res[d][dd] === 'object' && res[d][dd] != null && res[d][dd].length > 0) {
          if (level === 0) {
            this.setObjectData([res[d][dd][0]], level + 1, res[d], dd);
          } else {
            this.setObjectData([res[d][dd][0]], level + 1, obj, dd);
          }
        } else if (level > 0) {
          let name = baseObjName.replace('List', '') + dd.charAt(0).toUpperCase() + dd.slice(1);
          obj[name] = res[d][dd];
        }
      }
    }
    return res;
  };

  loadIndex = 0;

  loadJSON(isSyncRequest) {
    this.loadIndex++;
    let localJsonIndex = this.loadIndex;
    setTimeout(() => {
      if (this.loadIndex === localJsonIndex) {
        if (!isSyncRequest) {
          this.setParentState({ isLoaderActive: true });
        }
        get(this.url + this.modelToQuery(this.model))
          .then((response) => {
            // response.data["body"] = this.setObjectData(response.data.body, 0);
            if (response.data?.data) {
              this.setParentState((prevState) => ({
                ...prevState,
                apiResponse: response.data.data,
                isLoaderActive: false,
              }));
            }
          })
          .catch((err) => {
            this.setParentState({
              apiResponse: err,
              isLoaderActive: false,
            });
          });
      }
    }, 100);
  }

  loadJSONClearFilter(model) {
    get(this.url + this.modelToQuery(model)).then((response) => {
      response.data.data = this.setObjectData(response.data.data, 0);
      this.setParentState({ apiResponse: response.data });
    });
  }

  renderApi(url, model) {
    this.url = url;
    this.model = model;
    this.loadJSON();
  }

  setJsonUrl(url) {
    this.url = url;
  }

  setParentState(properties) {
    this.setState(properties);
  }

  getParentState(key) {
    return this.state[key];
  }

  setpredicates(_model, isView, userSecurity, isSyncRequest) {
    if (userSecurity === true) {
      if (!isView) {
        sessionStorage.setItem('model', JSON.stringify(this.model));
      }
    } else {
      if (_model) {
        this.model = { ...this.model, ..._model };
      }
      if (!isView) {
        sessionStorage.setItem('model', JSON.stringify(this.model));
      }
      this.loadJSON(isSyncRequest);
    }
  }

  getpredicates() {
    return { setpredicates: this.setpredicates };
  }

  getmodel() {
    return this.model;
  }

  setFilterData(filterapi) {
    this.renderApi(filterapi);
  }

  currentPage = 1;

  totalPages = 1;

  totalItems = 0;

  pageSize = 0;

  pageElements = 0;

  setpagination() {
    if (this.state.apiResponse) {
      let response = this.state.apiResponse;
      this.currentPage = response.currentPage;
      this.totalPages = response.totalPages;
      this.totalItems = response.totalItems || 0;
      this.pageSize = response.pageSize;
      this.pageElements = response.pageElements;
    }
    return {
      setParentState: this.setParentState,
      setpredicates: this.setpredicates,
      model: this.model,
      currentPage: this.currentPage,
      totalPages: this.totalPages,
      totalItems: this.totalItems,
      pageSize: this.pageSize,
      pageElements: this.pageElements,
    };
  }

  setheadproperties(properties) {
    let propModifiedName = properties.title.charAt(0).toLowerCase() + properties.title.replace(/ /g, '').slice(1);
    if (properties.enableSearch) {
      if (typeof properties.searchField == 'undefined') {
        properties.searchField = propModifiedName;
      }
    }
    if (properties.enableSort) {
      if (typeof properties.sortField == 'undefined') {
        properties.sortField = propModifiedName;
      }
    }
    if (properties.coltype === 'filter') {
      if (typeof properties.filterField == 'undefined') {
        properties.filterField = `${propModifiedName}Id`;
      }
      if (typeof properties.filterapi == 'undefined') {
        properties.filterapi = properties.title.replace(/ /g, '-').toLowerCase();
      }
    }
    return {
      ...properties,
      setParentState: this.setParentState,
      setpredicates: this.setpredicates,
      model: this.model,
      haveResuls: this.state.apiResponse,
    };
  }

  setproperties(properties) {
    return {
      ...properties,
      setParentState: this.setParentState,
      setpredicates: this.setpredicates,
      getpredicates: this.getpredicates,
      getParentState: this.getParentState,
      setproperties: this.setproperties,
      setJsonUrl: this.setJsonUrl,
      setpagination: this.setpagination,
      setheadproperties: this.setheadproperties,
      model: this.model,
    };
  }
}
