/* eslint-disable implicit-arrow-linebreak */
import { get, post } from '../Libraries/Ajax/httpService';
import { convertToModuleAccessModel, setValidMenu } from '../SharedComponents/accessPrivilegeHelper';
import store from '../Store';
import { loginMessage, setLoadingActive, setUser, setAccessArray } from '../Store/actions';
import { setDefaultMenuUrl } from '../Store/MasterData/actions';
import history from './history';

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

const onInitFacebookSdk = () =>
  new Promise((resolve) => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: facebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v8.0',
      });

      // auto authenticate with the api if already logged in with facebook
      window.FB.getLoginStatus(({ authResponse }) => {
        if (authResponse) {
          window.FB.api('/me/permissions', 'delete', null, () => window.FB.logout());
          resolve();
        } else {
          resolve();
        }
      });
    };

    // load facebook sdk script
    ((d, s, id) => {
      let js;
      let fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });

const onApiAuthenticate = (accessToken) => post('account/facebook', { accessToken, isAdmin: true });

const onLogout = () => {
  // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
  if (window.FB && window.FB.getAccessToken()) window.FB.api('/me/permissions', 'delete', null, () => window.FB.logout());
};
// let toast = new ToastNotification();
const onLogin = () =>
  new Promise(async () => {
    let { authResponse } = await new Promise((resolve) => {
      window.FB.login(
        (response) => {
          resolve(response);
        },
        { scope: 'email' },
      );
    });
    if (!authResponse) return;

    window.FB.api('/me', { fields: ['email', 'name'] }, (res) => {
      if (res.email) {
        store.dispatch(setLoadingActive());
        onApiAuthenticate(authResponse.accessToken)
          .then((authRes) => {
            // let location = window.location.pathname;
            if (authRes.data?.accessToken && authRes.data?.refreshToken) {
              store.dispatch(setUser(authRes.data));
              localStorage.setItem('userData', JSON.stringify(authRes.data));
              get('user-access/user/access').then((result) => {
                let noAccess = false;
                if (result.data && result.data.data && result.data.data.rows?.accessPermissionsList.length > 0) {
                  let accessRes = result.data.data.rows;
                  localStorage.setItem('accessGroups', JSON.stringify(accessRes.accessPermissionsList));
                  if (accessRes.accessPermissionsList[0].isAllAccess) localStorage.setItem('isAllAccess', true);

                  let moduleAccessArray = convertToModuleAccessModel(accessRes.accessPermissionsList);
                  store.dispatch(setAccessArray(moduleAccessArray));

                  let activeMenuUrl = setValidMenu();
                  if (activeMenuUrl === null || activeMenuUrl === '') noAccess = true;
                  if (history.location.state && history.location.state.redirectUrl) {
                    localStorage.setItem('defaultMenuUrl', activeMenuUrl);
                    history.push(history.location.state.redirectUrl);
                  } else {
                    localStorage.setItem('defaultMenuUrl', activeMenuUrl);
                    store.dispatch(setDefaultMenuUrl(activeMenuUrl));
                    history.push(activeMenuUrl);
                  }
                } else {
                  noAccess = true;
                }
                if (noAccess) {
                  store.dispatch(loginMessage(false, "You don't have access to this application"));
                  let keysToRemove = ['userData', 'accessGroups', 'isAllAccess', 'defaultMenuUrl'];
                  keysToRemove.forEach((k) => localStorage.removeItem(k));
                }
              });
            } else {
              store.dispatch(loginMessage(false, authRes.data?.message ? authRes.data?.message : 'Something went wrong. Please try later.'));
            }
            onLogout();
          })
          .catch((error) => {
            onLogout();
            if (error.message) store.dispatch(loginMessage(false, error.message));
          });
      } else {
        store.dispatch(loginMessage(false, 'The account you provided does not have an email Id'));
      }
    });
  });

export const facebookService = {
  onLogin,
  onLogout,
  onInitFacebookSdk,
};
export default facebookService;
