import { combineReducers } from 'redux';

// Front
import Layout from './Layout/reducer';

// Authentication
import Login from './Auth/Login/reducer';
import Account from './Auth/Register/reducer';
import ForgetPassword from './Auth/Forgetpwd/reducer';
import MasterData from './MasterData/reducer';

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  MasterData,
});

export default rootReducer;
