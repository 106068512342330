/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { connect } from 'react-redux';

// import images
import servicesIcon1 from '../../assets/images/services-icon/newspaper-regular.svg';
import servicesIcon2 from '../../assets/images/services-icon/user-solid.svg';
import servicesIcon3 from '../../assets/images/services-icon/shopping-bag-solid.svg';
import servicesIcon4 from '../../assets/images/services-icon/check-square-solid.svg';
import servicesIcon5 from '../../assets/images/services-icon/exclamation-triangle-solid.svg';
import servicesIcon6 from '../../assets/images/services-icon/shopping-cart-solid.svg';
import servicesIcon7 from '../../assets/images/services-icon/calendar-times-solid.svg';
import servicesIcon8 from '../../assets/images/services-icon/flag-solid.svg';
import imgUser from '../../assets/images/userpic.png';

// Charts
import Salesdonut from '../AllCharts/apex/salesdonut';

import 'chartist/dist/scss/chartist.scss';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { get } from '../../Libraries/Ajax/httpService';
import { combineDateTime } from '../../Libraries/DateTimeHelpers/dateTimeHelperFunctions';
import LinkButton from '../../SharedComponents/linkButton';

dayjs.extend(relativeTime);

class Dashboard extends ListComponent {
  constructor(props) {
    super(props);
    this.setParentState = this.setParentState.bind(this);

    this.state = {
      obituaryResponse: {},
      userResponse: {},
      inventoryData: {},
      shopResponse: {},
      product: {},
      order: {},
      payment: {},
      tributeResponse: {},
      reviewResponse: {},
      userRole: null,
      renewalResponse: {},
      reportPostsDetails: {},
      reportMemorialDetails: {},
      customerFeedbacks: {},
      statusTextColor: ['success', 'warning', 'primary', 'danger'],
    };
  }

  componentDidMount() {
    let { userData } = this.props;
    let roleId = userData.user && userData.user.roleId ? userData.user.roleId : 0;
    let userId = userData.user && userData.user.id ? userData.user.id : 0;
    if (roleId > 0) this.setState({ userRole: roleId });
    get('obituary-profile', { take: 7 })
      .then((response) => {
        if (response.data && response.data.data && response.data.data.rows.length > 0) {
          this.setState({
            obituaryResponse: response.data.data,
          });
        }
      })
      .catch(() => {});
    if (roleId == 2 || roleId == 3 || roleId == 6) {
      get('user', { take: 5 })
        .then((response) => {
          if (response.data && response.data.data && response.data.data.rows.length > 0) {
            this.setState({
              userResponse: response.data.data,
            });
          }
        })
        .catch(() => {});
    }
    if (roleId == 1) {
      get('review', { take: 5, partnerId: userId, allowParentComment: false })
        .then((response) => {
          if (response.data && response.data.data && response.data.data.rows.length > 0) {
            this.setState({
              reviewResponse: response.data.data,
            });
          }
        })
        .catch(() => {});
    }
    get('report-tribute/report-details', { take: 5 })
      .then((response) => {
        if (response.data && response.data.data && response.data.data.rows.length > 0) {
          this.setState({
            reportPostsDetails: response.data.data,
          });
        }
      })
      .catch(() => {});
    get('report-memorial/report-details', { take: 5 })
      .then((response) => {
        if (response.data && response.data.data && response.data.data.rows.length > 0) {
          this.setState({
            reportMemorialDetails: response.data.data,
          });
        }
      })
      .catch(() => {});
    let renewModel = {
      take: 5,
      sortField: 'expiryDate',
      sortOrder: 'ASC',
      statusId: 1,
      filterRenewalObituaries: true,
    };
    get('obituary-profile', renewModel)
      .then((response) => {
        if (response.data && response.data.data && response.data.data.rows.length > 0) {
          this.setState({
            renewalResponse: response.data.data,
          });
        }
      })
      .catch(() => {});
    if (roleId == 2 || roleId == 3 || roleId == 6) {
      let model = {
        take: 5,
        page: 0,
      };
      get('inventory-count', model)
        .then((response) => {
          if (response && response.data) {
            this.setState({
              shopResponse: response.data.shop,
              product: response.data.product,
              order: response.data.order,
              payment: response.data.payment,
            });
          }
        })
        .catch(() => {});
    }
    if (roleId != 1) {
      get('obituary-post/tributes')
        .then((res) => {
          this.setState((prevState) => ({
            tributeResponse: { ...prevState.tributeResponse, totCount: res.data[0], responseData: res.data[1], chartArr: res.data[2] },
          }));
        })
        .catch(() => {});
    }
    let feedbackModel = { take: 5, page: 0, givenToId: userId };
    get('customer-feedback', feedbackModel)
      .then((response) => {
        if (response.data && response.data.rows) {
          this.setState({ customerFeedbacks: response.data });
        }
      })
      .catch(() => {});
  }

  render() {
    let {
      obituaryResponse,
      userResponse,
      product,
      order,
      payment,
      statusTextColor,
      shopResponse,
      renewalResponse,
      reportPostsDetails,
      reportMemorialDetails,
      tributeResponse,
      userRole,
      customerFeedbacks,
      reviewResponse,
    } = this.state;
    let { user } = this.props.userData;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Dashboard</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item active">Welcome to Memlo Dashboard</li>
                </ol>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50 text-center">Total Obituaries</h5>
                    <div className="float-left mini-stat-img mr-4">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <h4 className="font-weight-medium font-size-24 mt-4">
                      <Link className="text-white" to="/list-obituary-profiles/general">
                        {(obituaryResponse && obituaryResponse.totalItems) || 0}
                      </Link>
                    </h4>
                  </div>
                  <div className="pt-2">
                    <a href="#recently-added" className="text-white-50">
                      Show recently added
                      <i className="mdi mdi-arrow-right h5 ml-4" />
                    </a>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {(userRole == 2 || userRole == 3 || userRole == 6) && (
              <Col xl={3} md={6}>
                <Card className="mini-stat bg-primary text-white">
                  <CardBody>
                    <div className="mb-4">
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50 text-center">Total Users</h5>
                      <div className="float-left mini-stat-img mr-4">
                        <img src={servicesIcon2} alt="" />
                      </div>
                      <h4 className="font-weight-medium font-size-24 mt-4">
                        <Link className="text-white" to="/list-users">
                          {(userResponse && userResponse.totalItems) || 0}
                        </Link>
                      </h4>
                    </div>
                    <div className="pt-2">
                      <a href="#recently-added" className="text-white-50">
                        Show recently added
                        <i className="mdi mdi-arrow-right h5 ml-4" />
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
            {(userRole == 2 || userRole == 3 || userRole == 6) && (
              <Col xl={3} md={6}>
                <Card className="mini-stat bg-primary text-white">
                  <CardBody>
                    <div className="mb-4">
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50 text-center">Total Products</h5>
                      <div className="float-left mini-stat-img mr-4">
                        <img src={servicesIcon3} alt="" />
                      </div>
                      <h4 className="font-weight-medium font-size-24 mt-4">
                        <Link className="text-white" to="/list-products" target="_self">
                          {product && product.totalItems > 0 ? product.totalItems : 0}
                        </Link>
                      </h4>
                    </div>
                    <div className="pt-2">
                      <a href="#latest-transaction-successful" className="text-white-50">
                        Show recently added
                        <i className="mdi mdi-arrow-right h5 ml-4" />
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
            {(userRole == 2 || userRole == 3 || userRole == 6) && (
              <Col xl={3} md={6}>
                <Card className="mini-stat bg-primary text-white">
                  <CardBody>
                    <div className="mb-4">
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50 text-center text-center">Total Shops</h5>
                      <div className="float-left mini-stat-img mr-4">
                        <img src={servicesIcon6} alt="" />
                      </div>
                      <h4 className="font-weight-medium font-size-24 mt-4">
                        <Link className="text-white" to="/list-shops">
                          {(shopResponse && shopResponse.totalItems) || 0}
                        </Link>
                      </h4>
                    </div>
                    <div className="pt-2">
                      <Link className="text-white-50" to="/list-shops">
                        Show all shops
                        <i className="mdi mdi-arrow-right h5 ml-4" />
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
            {(userRole == 2 || userRole == 3 || userRole == 6) && (
              <Col xl={3} md={6}>
                <Card className="mini-stat bg-primary text-white">
                  <CardBody>
                    <div className="mb-4">
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50 text-center">Paid Orders</h5>
                      <div className="float-left mini-stat-img mr-4">
                        <img src={servicesIcon4} alt="" />
                      </div>
                      <h4 className="font-weight-medium font-size-24 mt-4">
                        <Link className="text-white" to="/list-orders" target="_self">
                          {order && order.totalItems > 0 ? order.totalItems : 0}
                        </Link>
                      </h4>
                    </div>
                    <div className="pt-2">
                      <a href="#failed-orders" className="text-white-50">
                        Show recently added &nbsp;&nbsp;&nbsp;&nbsp;
                        <i className="mdi mdi-arrow-right h5" />
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
            {(userRole == 2 || userRole == 3 || userRole == 6) && (
              <Col xl={3} md={6}>
                <Card className="mini-stat bg-primary text-white">
                  <CardBody>
                    <div className="mb-4">
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50 text-center">Failed Orders</h5>
                      <div className="float-left mini-stat-img mr-4">
                        <img src={servicesIcon5} alt="" />
                      </div>
                      <h4 className="font-weight-medium font-size-24 mt-4">
                        <Link className="text-white" to={{ pathname: '/list-orders', tabProps: { activeTab: 2 } }} target="_self">
                          {payment && payment.totalItems > 0 ? payment.totalItems : 0}
                        </Link>
                      </h4>
                    </div>
                    <div className="pt-2">
                      <a href="#failed-orders" className="text-white-50">
                        Show recently added &nbsp;&nbsp;&nbsp;&nbsp;
                        <i className="mdi mdi-arrow-right h5" />
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50 text-center">Reported Obituaries</h5>
                    <div className="float-left mini-stat-img mr-4">
                      <img src={servicesIcon8} alt="" />
                    </div>
                    <h4 className="font-weight-medium font-size-24 mt-4">
                      <Link className="text-white" to="/list-obituary-reports">
                        {(reportMemorialDetails && reportMemorialDetails.totalItems) || 0}
                      </Link>
                    </h4>
                  </div>
                  <div className="pt-2">
                    <a href="#report-details" className="text-white-50">
                      Show recently added
                      <i className="mdi mdi-arrow-right h5 ml-4" />
                    </a>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50 text-center">Reported Tributes</h5>
                    <div className="float-left mini-stat-img mr-4">
                      <img src={servicesIcon7} alt="" />
                    </div>
                    <h4 className="font-weight-medium font-size-24 mt-4">
                      <Link className="text-white" to="/list-report-tribute">
                        {(reportPostsDetails && reportPostsDetails.totalItems) || 0}
                      </Link>
                    </h4>
                  </div>
                  <div className="pt-2">
                    <a href="#report-details" className="text-white-50">
                      Show recently added
                      <i className="mdi mdi-arrow-right h5 ml-4" />
                    </a>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {userRole == 1 && (
              <Col xl={3} md={6}>
                <Card className="mini-stat bg-primary text-white">
                  <CardBody>
                    <div className="mb-4">
                      <h5 className="font-size-16 text-uppercase mt-0 text-white-50 text-center">Total Reviews</h5>
                      <div className="float-left mini-stat-img mr-4">
                        <img src={servicesIcon2} alt="" />
                      </div>
                      <h4 className="font-weight-medium font-size-24 mt-4">{(reviewResponse && reviewResponse.totalItems) || 0}</h4>
                    </div>
                    <div className="pt-2">
                      <a href="#recently-added-reviews" className="text-white-50">
                        Show recently added
                        <i className="mdi mdi-arrow-right h5 ml-4" />
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
            {/* <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-left mini-stat-img mr-4">
                      <img src={servicesIcon4} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">Product Sold</h5>
                    <h4 className="font-weight-medium font-size-24">
                      2436 <i className="mdi mdi-arrow-up text-success ml-2" />
                    </h4>
                    <div className="mini-stat-label bg-warning">
                      <p className="mb-0">+ 84%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-right">
                      <Link to="#Replace" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5" />
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
          <Row id="recently-added">
            {obituaryResponse && obituaryResponse.rows && obituaryResponse.rows.length > 0 && (
              <Col xl={8}>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Recently added Obituaries</h4>
                    <div className="table-responsive no-height">
                      <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Place</th>
                            <th scope="col">Created By</th>
                            <th scope="col" colSpan="2">
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {obituaryResponse.rows.map((row) => {
                            let { statusTitle } = row;
                            let statusStyle = statusTextColor[row.statusId - 1];
                            if (row.statusId === 1 && row.publishedDate) {
                              // let publishedDateTime = combineDateTime(row.publishedDate, `T${row.publishedTime}.`);
                              let publishedDateTime = combineDateTime(row.publishedDate, `${dayjs().format('YYYY-MM-DDT') + row.publishedTime}.000Z`);
                              if (new Date(publishedDateTime.replace(/Z/i, '')).getTime() > new Date().getTime()) {
                                statusTitle = 'To Be Published';
                              } else if (new Date().getTime() > new Date(row.expiryDate).getTime()) {
                                statusTitle = 'Expired';
                                statusStyle = statusTextColor[row.statusId + 2];
                              }
                            }
                            return (
                              <tr>
                                <td>
                                  <div>
                                    <img
                                      src={row.mediaUrl ? row.mediaUrl : imgUser}
                                      alt={row.mediaUrl ? row.mediaUrl : imgUser}
                                      className="avatar-xs rounded-circle mr-2"
                                    />{' '}
                                    {user.roleId == 1 && user.id != row.ownerId && user.id != row.createdById ? (
                                      <LinkButton
                                        toString="viewProfile"
                                        title="View memorial in Memlo website"
                                        toDoFunction={() => window.open(`${process.env.REACT_APP_FRONTEND_URL}pages/view-memorial/${row.id}`)}
                                      >
                                        {row.name}
                                      </LinkButton>
                                    ) : (
                                      <Link to={`/view-obituary-profiles/general/${row.id}`}>{row.name}</Link>
                                    )}
                                  </div>
                                </td>
                                <td>{row.place?.address}</td>
                                <td>{row.createdByName}</td>
                                <td>
                                  <span className={`badge badge-${statusStyle}`}>{statusTitle}</span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}

            {(userRole == 2 || userRole == 3 || userRole == 6) && userResponse && userResponse.rows && userResponse.rows.length > 0 && (
              <Col xl={4}>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Recently added Users</h4>
                    <ol className="activity-feed">
                      {userResponse.rows.map((row) => (
                        <li className="feed-item">
                          <div className="feed-item-list">
                            <span className="date">{dayjs(row.createdDate).format('DD.MM.YYYY')}</span>
                            <span className="activity-text">
                              <Link to={`/view-users/${row.id}`}>{row.name}</Link> has been added as {row.roleTitle}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ol>
                    <div className="text-center">
                      <Link to="/list-users" className="btn btn-primary">
                        View More
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
          <Row>
            {userRole == 1 && reviewResponse && reviewResponse?.rows?.length > 0 && (
              <Col xl={12} id="recently-added-reviews">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Recently Added Reviews</h4>
                    <div className="table-responsive no-height">
                      <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Obituary Name</th>
                            <th scope="col">Comment</th>
                            <th scope="col">Commented User</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reviewResponse.rows.map((row) => (
                            <tr>
                              <td>{row.obituaryName}</td>
                              <td>
                                {' '}
                                <span className="truncate-text" title={row.comment}>
                                  {row.comment}
                                </span>
                              </td>
                              <td>{row.userName}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
          {customerFeedbacks && customerFeedbacks.rows && customerFeedbacks.rows.length > 0 && (
            <Row id="recent-feedbacks">
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Recently Received Customer Feedbacks</h4>
                    <div className="table-responsive no-height">
                      <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Customer</th>
                            <th scope="col">Feedback</th>
                            <th scope="col">Obituary</th>
                            <th scope="col" colSpan="2">
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerFeedbacks.rows.map((row) => (
                            <tr>
                              <td>{row.givenByName}</td>
                              <td>
                                <span className="truncate-text" title={row.feedback}>
                                  {row.feedback}
                                </span>
                              </td>
                              <td>{row.obituaryProfileName}</td>
                              <td>
                                {row.isStatus === 1 ? (
                                  <span className="badge badge-success">Completed</span>
                                ) : (
                                  <span className="badge badge-warning">Not Completed</span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row id="latest-transaction-successful">
            {userRole != 1 && tributeResponse.responseData && tributeResponse?.responseData?.length > 0 && (
              <Col xl={4}>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Top 5 Obituaries with High Tribute Count</h4>
                    <div className="cleafix">
                      <p className="float-left">
                        <i className="mdi mdi-counter mr-1 text-primary" /> Total Tributes :
                      </p>
                      <h5 className="font-18 text-right">{tributeResponse.totCount}</h5>
                    </div>
                    <div id="ct-donut" className="ct-chart wid pt-4">
                      <Salesdonut series={tributeResponse.chartArr && tributeResponse.chartArr} />
                    </div>
                    <div className="mt-4">
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <td>
                              <b>Obituary Name</b>
                            </td>
                            <td className=" text-right">
                              <b>Tributes Count</b>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {tributeResponse.responseData.map((row, i) => {
                            let statusStyle;
                            if (row) {
                              // statusTitle = 'Expired';
                              statusStyle = ['success', 'primary', 'warning', 'dark', 'danger'];
                            }
                            return (
                              <>
                                {row.id && (
                                  <tr>
                                    <td>
                                      <Link to={`/view-obituary-profiles/general/${row.id}`}>
                                        <span className={`badge badge-${statusStyle[i]}`}>{row.name}</span>
                                      </Link>
                                    </td>
                                    <td className="text-right">{row.count}</td>
                                  </tr>
                                )}
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}

            {product && product.rows?.length > 0 && (
              <Col xl={8}>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Recently Added Products</h4>
                    <div className="table-responsive no-height">
                      <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Image</th>
                            <th scope="col">Name</th>
                            <th scope="col">Category</th>
                            <th scope="col">Price</th>
                            <th scope="col">Created by</th>
                            <th scope="col" colSpan="2">
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {product && product.rows?.length > 0
                            ? product.rows.map((row, i) => (
                                <tr key={`${i}`}>
                                  <th scope="row">
                                    <img src={row.mediaUrl ? row.mediaUrl : servicesIcon3} alt="" className="avatar-xs rounded-circle mr-2" />
                                  </th>
                                  <td>
                                    <Link to={`/view-products/${row.id}`}>{row.name}</Link>
                                  </td>
                                  <td>{row.categoryName}</td>
                                  <td>${row.price}</td>
                                  <td>{row.createdByName}</td>
                                  <td>
                                    {row.statusTitle == 'Active' && <span className="badge badge-success">{row.statusTitle}</span>}
                                    {row.statusTitle == 'Inactive' && <span className="badge badge-danger">{row.statusTitle}</span>}
                                  </td>
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
          {(userRole == 2 || userRole == 3 || userRole == 6) && (
            <Row id="failed-orders">
              {(userRole == 2 || userRole == 3 || userRole == 6) && order && order.rows?.length > 0 && (
                <Col xl={8}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Payment Completed Orders</h4>
                      <div className="table-responsive no-height">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                          <thead>
                            <tr>
                              <th scope="col">User Name</th>
                              <th scope="col">Product</th>
                              <th scope="col">Date</th>
                              <th scope="col">Amount</th>
                              <th scope="col" colSpan="2">
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {order && order.rows?.length > 0
                              ? order.rows.map((row, i) => (
                                  <tr key={`${i}`}>
                                    <th scope="row">{row.createdByName}</th>
                                    <td>
                                      <div>
                                        <img src={row.imageUrl ? row.imageUrl : servicesIcon3} alt="" className="avatar-xs rounded-circle mr-2" />{' '}
                                        {row.productName}
                                      </div>
                                    </td>
                                    <td>{dayjs(row.createdDate).format('DD.MM.YYYY')}</td>
                                    <td>${row.price}</td>
                                    <td>
                                      {row.statusTitle == 'Completed' && <span className="badge badge-success">{row.statusTitle}</span>}
                                      {row.statusTitle == 'Pending' && <span className="badge badge-warning">{row.statusTitle}</span>}
                                      {row.statusTitle == 'In-Progress' && <span className="badge badge-primary">{row.statusTitle}</span>}
                                      {row.statusTitle == 'Rejected/Returned' && <span className="badge badge-danger">{row.statusTitle}</span>}
                                    </td>
                                  </tr>
                                ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              {payment && payment.rows?.length > 0 && (
                <Col xl={4}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Payment Failed Orders</h4>
                      <ol className="activity-feed">
                        {payment && payment.rows?.length > 0
                          ? payment.rows.map(
                              (row, i) =>
                                row.checkout &&
                                row.checkout.createdBy && (
                                  <li className="feed-item" key={`${i}`}>
                                    <div className="feed-item-list">
                                      <span className="date">{dayjs(row.checkout?.createdDate).format('DD.MM.YYYY')}</span>
                                      <span className="activity-text">
                                        Payment Failed for <Link to={`/view-users/${row.checkout.createdById}`}>{row.checkout.createdBy.name}</Link>
                                      </span>
                                    </div>
                                  </li>
                                ),
                            )
                          : null}
                      </ol>
                      <div className="text-center">
                        <Link className="btn btn-primary" to={{ pathname: '/list-orders', tabProps: { activeTab: 2 } }} target="_self">
                          View More
                        </Link>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          )}
          <Row id="report-details">
            {reportPostsDetails && reportPostsDetails.rows && reportPostsDetails.rows.length > 0 && (
              <Col xl={7}>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Recently Reported Obituary Tributes</h4>
                    <div className="table-responsive no-height">
                      <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Obituary Name</th>
                            <th scope="col">Tribute Content</th>
                            <th scope="col">Created By</th>
                            <th scope="col">
                              Report <br /> Count
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {reportPostsDetails.rows.map((row) => (
                            <tr>
                              <td>
                                {user.roleId == 1 && user.id != row.ownerId && user.id != row.createdById ? (
                                  <LinkButton
                                    toString="viewProfile"
                                    title="View memorial in Memlo website"
                                    toDoFunction={() => window.open(`${process.env.REACT_APP_FRONTEND_URL}pages/view-memorial/${row.obituaryId}`)}
                                  >
                                    {row.obituaryName}
                                  </LinkButton>
                                ) : (
                                  <Link to={`/view-obituary-profiles/general/${row.obituaryId}`}>{row.obituaryName}</Link>
                                )}
                              </td>
                              <td>
                                <div>
                                  {row.mediaUrl && <img src={row.mediaUrl} alt={row.mediaUrl} className="avatar-xs rounded-circle mr-2" />}
                                  <span className="truncate-text" title={row.content}>
                                    {row.content}
                                  </span>
                                </div>
                              </td>
                              <td>{row.createdByName}</td>
                              <td>{row.reportCount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
            {reportMemorialDetails && reportMemorialDetails.rows && reportMemorialDetails.rows.length > 0 && (
              <Col xl={5}>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Recently Reported Obituaries</h4>
                    <div className="table-responsive no-height">
                      <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Obituary Name</th>
                            <th scope="col">Created By</th>
                            <th scope="col">
                              Report <br /> Count
                            </th>
                            {/* <th scope="col">Status</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {reportMemorialDetails.rows.map((row) => (
                            <tr>
                              <td>
                                {user.roleId == 1 && user.id != row.ownerId && user.id != row.createdById ? (
                                  <LinkButton
                                    toString="viewProfile"
                                    title="View memorial in Memlo website"
                                    toDoFunction={() => window.open(`${process.env.REACT_APP_FRONTEND_URL}pages/view-memorial/${row.id}`)}
                                  >
                                    {row.name}
                                  </LinkButton>
                                ) : (
                                  <Link to={`/view-obituary-profiles/general/${row.id}`}>{row.name}</Link>
                                )}
                              </td>
                              <td>{row.createdByName}</td>
                              <td>{row.reportCount}</td>
                              {/* <td>{row.reportCount}</td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
          <Row>
            {renewalResponse && renewalResponse.rows?.length > 0 && (
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Obituaries to renew</h4>
                    <p className="card-title-desc">These obituaries are expired or are nearing expiry period</p>
                    <div>
                      <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Obituary Name</th>
                            <th scope="col">Place</th>
                            <th scope="col">Created By</th>
                            <th scope="col">Date of Expiry</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renewalResponse.rows.map((row) => {
                            let statusTitle;
                            let statusStyle;
                            let time = dayjs(row.expiryDate).fromNow();
                            if (new Date().getTime() > new Date(row.expiryDate).getTime()) {
                              statusTitle = `Expired ${time}`;
                              statusStyle = 'danger';
                            } else {
                              statusTitle = `Expires ${time}`;
                              statusStyle = 'warning';
                            }
                            return (
                              <tr className={`table-${statusStyle}`}>
                                <td>
                                  {user.roleId == 1 && user.id != row.ownerId && user.id != row.createdById ? (
                                    <LinkButton
                                      toString="viewProfile"
                                      title="View memorial in Memlo website"
                                      toDoFunction={() => window.open(`${process.env.REACT_APP_FRONTEND_URL}pages/view-memorial/${row.id}`)}
                                    >
                                      {row.name}
                                    </LinkButton>
                                  ) : (
                                    <Link to={`/view-obituary-profiles/general/${row.id}`}>{row.name}</Link>
                                  )}
                                </td>
                                <td>{row.place?.address}</td>
                                <td>{row.createdByName}</td>
                                <td>{dayjs(row.expiryDate).format('DD.MM.YYYY')}</td>
                                <td>{statusTitle}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.Login.userData,
});

export default connect(mapStateToProps, null)(Dashboard);
