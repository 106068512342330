import React from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, FormGroup, Row } from 'reactstrap';

import Loader from '../../Components/loader';
import { get, post, put } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';
import DragAndDrop from '../../SharedComponents/FormHelpers/Controls/dragAndDrop';
import DropDown from '../../SharedComponents/FormHelpers/Controls/dropDown';
import FormInput from '../../SharedComponents/FormHelpers/Controls/formInput';
import { PhoneNumber } from '../../SharedComponents/FormHelpers/Controls/phoneNumber';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import { DeleteRow } from '../../SharedComponents/ListComponents/manageComponent';
import { BusinessContacts } from './businessContacts';

class ManageUsers extends FormComponent {
  constructor(props) {
    super(props);
    this.setProperties = this.setProperties.bind(this);
    this.setFormProperties = this.setFormProperties.bind(this);
    let businessProfile = {
      businessName: {
        label: 'Business Name',
        name: 'businessName',
        type: 'text',
        autoComplete: 'business-name',
        placeholder: 'Enter Business Name',
        rules: {
          required: true,
          maxLength: 200,
        },
      },
      businessLogo: {
        label: 'Business Logo',
        name: 'businessLogo',
        type: 'text',
        fileUploadUrl: 'business-profile/upload',
      },
      businessEmail: {
        label: 'Business Email',
        name: 'businessEmail',
        type: 'text',
        autoComplete: 'business-email',
        placeholder: 'Enter Business Email',
        rules: {
          required: true,
          email: true,
          maxLength: 300,
        },
      },
      businessWebsite: {
        label: 'Website',
        type: 'text',
        name: 'businessWebsite',
        placeholder: 'Enter Business Website',
        rules: {
          url: true,
          maxLength: 500,
        },
      },
      businessAddress: {
        label: 'Business Address',
        name: 'businessAddress',
        type: 'text',
        placeholder: 'Enter Business Address',
        rules: {
          required: true,
          maxLength: 300,
        },
      },
      businessDescription: {
        label: 'Description',
        type: 'text',
        name: 'businessDescription',
        placeholder: 'Enter description',
        rules: {
          maxLength: 5000,
        },
      },
    };
    this.isPartner = false;

    if (this.props.userData.user?.roleId == 1 && this.props.match?.url == '/my-profile') {
      this.isPartner = true;
    }
    this.state = {
      ...(this.isPartner == true && { ...businessProfile }),
      isSubmitted: true,
      singleRow: {},
      action: 'Add',
      editFlag: false,
      businessProfileId: 0,
      callBusinessContacts: false,
      myAccountMode: false,

      name: {
        label: 'Name',
        type: 'text',
        placeholder: 'Enter name',
        name: 'name',
        autoComplete: 'Enter-family-name',
        rules: {
          required: true,
          maxLength: 200,
        },
      },

      familyName: {
        label: 'Family Name',
        name: 'familyName',
        type: 'text',
        placeholder: 'Enter family name',
        rules: {
          maxLength: 200,
        },
      },

      email: {
        label: 'Email',
        type: 'text',
        placeholder: 'Enter email',
        name: 'email',
        rules: {
          required: true,
          email: true,
          maxLength: 300,
        },
      },
      phone: {
        label: 'Phone',
        type: 'text',
        placeholder: 'Enter Phone Number',
        autoComplete: 'off',
        name: 'phone',
        rules: {
          phoneValid: true,
        },
      },
      userImage: {
        label: 'User Image',
        name: 'userImage',
        type: 'text',
        fileUploadUrl: 'user/upload',
      },
      address: {
        label: 'Address',
        type: 'text',
        placeholder: 'Enter address',
        rules: {
          maxLength: 300,
        },
        name: 'address',
      },

      roleId: {
        label: 'Role',
        renderapi: 'user-role?page=0&take=10&isCreate=true',
        placeholder: 'Select role',
        className: 'mb-0',
        controlId: 'exampleForm.ControlSelect1',
        name: 'roleId',
        optionvalue: 'id',
        optiontext: 'title',
        rules: {},
      },

      commissionRate: {
        label: 'Commission Rate',
        type: 'number',
        placeholder: 'Enter commission rate',
        name: 'commissionRate',
        rules: {
          // currency: true,
          percentage: true,
        },
        className: 'percentage',
        mainDivClass: 'input-icon',
      },

      departmentId: {
        label: 'Department',
        renderapi: 'department',
        placeholder: 'Select department',
        className: 'mb-0',
        controlId: 'exampleForm.ControlSelect1',
        name: 'departmentId',
        optionvalue: 'id',
        optiontext: 'title',
        rules: {},
      },
      oldPassword: {
        label: 'Old Password',
        name: 'oldPassword',
        relatedPropertyName: 'confirmPassword',
        type: 'password',
        placeholder: 'Old Password',
        disableRequiredsymbol: true,
        materialInput: false,
        rules: { requireIf: true, maxLength: 100 },
        autoComplete: 'off',
      },
      password: {
        label: 'Password',
        relatedPropertyName: 'oldPassword',
        name: 'password',
        type: 'password',
        placeholder: 'New Password',
        disableRequiredsymbol: true,
        materialInput: false,
        rules: { minLength: 6, requireIf: true, match: 'confirmPassword', maxLength: 100 },
        autoComplete: 'off',
      },
      confirmPassword: {
        label: 'Confirm Password',
        relatedPropertyName: 'password',
        type: 'password',
        disableRequiredsymbol: true,
        name: 'confirmPassword',
        materialInput: false,
        rules: {
          // minLength: 6,
          requireIf: true,
          match: 'password',
          maxLength: 100,
        },
        autoComplete: 'off',
        placeholder: 'Confirm Password',
      },
      passwordToggleMode: false,
    };
  }

  passwordToggle = () => {
    this.setState((prevState) => ({ passwordToggleMode: !prevState.passwordToggleMode }));
  };

  validExtensions = ['jpeg', 'jpg', 'png'];

  componentDidMount() {
    if (this.props.match?.params.id) {
      this.setState({ editFlag: true });
      this.setState({ isLoaderActive: true });
      this.setState({ action: 'Edit' });
      get(`user/${this.props.match.params.id}`).then((response) => {
        this.setState({ isLoaderActive: false });
        this.setState({ singleRow: response.data.data });
        this.setRoleRules(true);
      });
    } else if (this.props.match?.url == '/my-profile') {
      this.setState({ myAccountMode: true });
      this.setState({ isLoaderActive: true });
      if (this.props.userData?.user) {
        get(`user/business-profile/${this.props.userData.user.id}`)
          .then((response) => {
            this.setState({
              isLoaderActive: false,
              singleRow: response.data.data,
              callBusinessContacts: true,
              businessProfileId: response.data.data?.businessId ? response.data.data.businessId : 0,
            });
          })
          .catch(() => {});
      }
    } else {
      this.setRoleRules(true);
    }
    if (this.props.isRolesDisable) {
      this.setState({ roleId: 9 });
    }
    this.setState({ access: setComponentPrivilege('Manage Users') });
  }

  setDepartmentRules = (bool, val) => {
    this.setState({
      departmentId: {
        ...this.state.departmentId,
        rules: {
          ...this.state.departmentId.rules,
          required: bool,
        },
        value: val || '',
      },
    });
  };

  setRoleRules = (bool) => {
    this.setState({
      roleId: {
        ...this.state.roleId,
        rules: {
          ...this.state.roleId.rules,
          required: bool,
        },
      },
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.singleRow) {
      this.mapJsonToState(this.state.singleRow);
    }
    if (this.state.roleId?.value == 2 && prevState.roleId?.value != 2) {
      this.setDepartmentRules(true);
    } else if (prevState.roleId?.value == 2 && this.state.roleId?.value != 2) {
      this.setDepartmentRules(true, 0);
    }
  }

  handlePassword = (id) => {
    if (id == 1) {
      this.setState((prev) => ({ oldPassword: { ...prev.oldPassword, type: prev.oldPassword.type == 'password' ? 'text' : 'password' } }));
    } else if (id == 2) {
      this.setState((prev) => ({
        password: { ...prev.password, type: prev.password.type == 'password' ? 'text' : 'password' },
      }));
    } else {
      this.setState((prev) => ({
        confirmPassword: { ...prev.confirmPassword, type: prev.confirmPassword.type == 'password' ? 'text' : 'password' },
      }));
    }
  };

  onCancelButtonClick = () => {
    this.props.history.goBack();
  };

  toast = new ToastNotification();

  onSaveButtonClick = (model) => {
    if (this.props.isRolesDisable) {
      model = {
        ...model,
        roleId: 9,
      };
    }

    this.setState({ isLoaderActive: true });
    let { userData, match, history, isModal, setParentState, createdItemState, isSingleNotObjectState } = this.props;

    if (this.state.myAccountMode) {
      let userId = userData.user && userData.user.id ? userData.user.id : 0;
      put(`user/business-profile/${userId}`, model)
        .then((response) => {
          if (response.data?.message) this.toast.show('update_sucess', 'SUCCESS', 'Profile details updated successfully');
          this.setState({ isLoaderActive: false, businessProfileId: response.data.data.data.businessId ? response.data.data.data.businessId : 0 });
        })
        .then(() => {
          history.push('/dashboard');
        })
        .catch((err) => {
          if (err.message) this.toast.show('update_error', 'ERROR', err.message);
          this.setState({ isLoaderActive: false });
        });
    } else if (match?.params.id) {
      put(`user/${match.params.id}`, model)
        .then((response) => {
          if (response.data?.message) this.toast.show('update_sucess', 'SUCCESS', response.data.message);
          this.setState({ isLoaderActive: false });
          history.push('/list-users');
        })

        .catch((err) => {
          this.setState({ isLoaderActive: false });
          if (err.message) this.toast.show('update_error', 'ERROR', err.message);
        });
    } else {
      post('user', model)
        .then((response) => {
          this.setState({ isLoaderActive: false });
          this.toast.show('create_success', 'SUCCESS', response.data.message);
          if (!isModal) {
            history.push('/list-users');
          } else {
            setParentState({
              newUserModalOpen: false,
              ...(isSingleNotObjectState
                ? {
                    [createdItemState]: response.data?.data?.id ? response.data.data.id : '',
                  }
                : {
                    [createdItemState?.name]: {
                      ...createdItemState,
                      value: response.data?.data?.id ? response.data.data.id : '',
                    },
                  }),
            });
          }
        })

        .catch((err) => {
          this.setState({ isLoaderActive: false });
          if (err.message) this.toast.show('create_error', 'ERROR', err.message);
        });
    }
  };

  render() {
    let {
      editFlag,
      isLoaderActive,
      singleRow,
      myAccountMode,
      roleId,
      commissionRate,
      departmentId,
      action,
      address,
      email,
      familyName,
      name,
      passwordToggleMode,
      access,
    } = this.state;

    let { history, isModal, formRef, isRolesDisable } = this.props;
    return (
      <React.Fragment>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          {!isModal && (
            <Row className="align-items-center">
              <Col sm={6}>
                {myAccountMode ? (
                  <div className="page-title-box">
                    <h4 className="font-size-18"> My Profile</h4>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">My Profile</li>
                    </ol>
                  </div>
                ) : (
                  <div className="page-title-box">
                    <h4 className="font-size-18">User Details</h4>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/list-users">User</Link>
                      </li>
                      <li className="breadcrumb-item active">{action} User</li>
                    </ol>
                  </div>
                )}
              </Col>
              {editFlag && access && access.delete && (
                <Col sm="6">
                  <div className="float-right d-md-block">
                    <DeleteRow
                      renderapi={`user/${this.props.match.params.id}`}
                      model={singleRow}
                      redirecturl="/list-users"
                      deleteText="Delete"
                      history={history}
                      isCardViewTrue="True"
                    />{' '}
                  </div>
                </Col>
              )}
            </Row>
          )}
          <Row>
            <Container fluid className="px-0">
              <div className="col-12">
                <SubmitForm onSubmit={this.onSaveButtonClick} {...this.setFormProperties({ disableReactiveForm: true })} formRef={formRef}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xs={12} md={6}>
                          <FormInput {...this.setProperties(name)} />
                        </Col>

                        <Col xs={12} md={6}>
                          <FormInput {...this.setProperties(familyName)} />
                        </Col>

                        <Col xs={12} md={6}>
                          <FormInput disabled={!(!editFlag && !myAccountMode)} {...this.setProperties(email)} />
                        </Col>
                        <Col xs={12} md={6}>
                          <PhoneNumber {...this.setProperties(this.state.phone)} />
                        </Col>
                        <Col xs={12} md={6}>
                          <FormInput {...this.setProperties(address)} />
                        </Col>
                        {!myAccountMode && !isRolesDisable && (
                          <Col xs={12} md={6}>
                            <DropDown disabled={editFlag} {...this.setProperties(roleId)} />
                          </Col>
                        )}
                      </Row>
                      {!myAccountMode && (
                        <Row>
                          {roleId?.value == 1 && (
                            <Col xs={12} md={6}>
                              <FormInput {...this.setProperties(commissionRate)} />
                            </Col>
                          )}
                          {roleId?.value == 2 && (
                            <Col xs={12} md={6}>
                              <DropDown {...this.setProperties(departmentId)} />
                            </Col>
                          )}
                        </Row>
                      )}
                      {/* *******PASSWORD*********START */}

                      {myAccountMode && (
                        <div>
                          <Row className="change-password-text">
                            <Col>
                              <href className="float-right d-md-block form-label" onClick={this.passwordToggle}>
                                <b>Change Password?</b>
                              </href>
                              <br />
                            </Col>
                          </Row>
                          {passwordToggleMode && (
                            <Row>
                              <Col xs={12} md={4}>
                                <div className="password-show-hide">
                                  <FormInput {...this.setProperties(this.state.oldPassword)} />
                                  <span className="show-hide-change">
                                    <em
                                      className={this.state.oldPassword.type == 'password' ? 'fas fa-eye-slash' : 'fas fa-eye'}
                                      onClick={() => this.handlePassword(1)}
                                    />
                                  </span>
                                </div>
                              </Col>
                              <Col xs={12} md={4}>
                                <div className="password-show-hide">
                                  <FormInput {...this.setProperties(this.state.password)} match={{ ...this.state.confirmPassword }} />
                                  <span className="show-hide-change">
                                    <em
                                      className={this.state.password.type == 'password' ? 'fas fa-eye-slash' : 'fas fa-eye'}
                                      onClick={() => this.handlePassword(2)}
                                    />
                                  </span>
                                </div>
                              </Col>
                              <Col xs={12} md={4}>
                                <div className="password-show-hide">
                                  <FormInput {...this.setProperties(this.state.confirmPassword)} match={{ ...this.state.password }} />
                                  <span className="show-hide-change">
                                    <em
                                      className={this.state.confirmPassword.type == 'password' ? 'fas fa-eye-slash' : 'fas fa-eye'}
                                      onClick={() => this.handlePassword(3)}
                                    />
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          )}
                        </div>
                      )}
                      {/* *******PASSWORD*********END */}
                      <Row>
                        <div className="col-12">
                          <DragAndDrop
                            validExtensions={this.validExtensions}
                            mediaUrl={singleRow?.userImageUrl}
                            editFlag
                            allowCrop
                            aspectRatio={`${1 / 1}`}
                            allowDelete
                            customLabel="Profile Picture"
                            // disablePreview={true}
                            {...this.setProperties(this.state.userImage)}
                          />
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                  {/* only for business accounts START */}

                  {this.isPartner && (
                    <div>
                      <h4 className="font-size-18">Business Profile</h4>
                      <Card>
                        <CardBody>
                          <div className="app-l-dashboard--right__wrapper--profile">
                            <Row>
                              <Col xs={12} md={6}>
                                <FormInput {...this.setProperties(this.state.businessName)} />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormInput {...this.setProperties(this.state.businessEmail)} />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormInput {...this.setProperties(this.state.businessWebsite)} />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormInput {...this.setProperties(this.state.businessAddress)} />
                              </Col>
                              <Col xs={12}>
                                <FormInput rows={6} as="textarea" {...this.setProperties(this.state.businessDescription)} />
                              </Col>
                              <div className="col-12">
                                <DragAndDrop
                                  validExtensions={this.validExtensions}
                                  mediaUrl={singleRow?.businessLogoUrl}
                                  editFlag
                                  allowCrop
                                  allowDelete
                                  aspectRatio={`${1 / 1}`}
                                  customLabel="Business Profile Picture"
                                  {...this.setProperties(this.state.businessLogo)}
                                />
                              </div>
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                      {/* <BusinessContacts /> */}
                    </div>
                  )}

                  {/* /* only for business accounts END */}
                  {!isModal && (
                    <FormGroup className="mb-0">
                      <div className="float-right d-md-block">
                        <br />
                        <Button type="submit" color="primary" className="mr-1">
                          Save
                        </Button>{' '}
                        <Button className="btn-secondary" type="reset" onClick={this.onCancelButtonClick}>
                          <i className="icon-close"> </i> <span> Cancel </span>
                        </Button>
                      </div>
                    </FormGroup>
                  )}
                </SubmitForm>
              </div>
            </Container>
          </Row>
          {this.isPartner && this.state.businessProfileId != 0 && <BusinessContacts businessProfileId={this.state.businessProfileId} />}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.Login.userData,
});
export default connect(mapStateToProps)(ManageUsers);
