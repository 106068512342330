import React, { useState } from 'react';
import { DataColHead } from '../../SharedComponents/ListComponents/components';
import { DataTable, TBody, TCol, THead } from '../../SharedComponents/Table/tableElements';

const TemplateMessagesList = (props) => {
  let { messageList } = props;
  let [index, setIndex] = useState(-1);

  let setTemplateMessage = (i) => {
    if (index !== i) {
      setIndex(i);
      if (props.selectTemplate) {
        props.selectTemplate(i);
      }
    }
  };

  return (
    <React.Fragment>
      <DataTable className="table-hover">
        <THead>
          <DataColHead className="table-design" title="Choose" haveResuls=" " />
          <DataColHead className="table-design" title="Message" haveResuls=" " />
        </THead>
        <TBody>
          {messageList && messageList.length > 0 ? (
            messageList.map((row, i) => (
              <tr key={`template-table-row-${i}`} className={index == i ? 'table-success' : ''} onClick={() => setTemplateMessage(i)}>
                <TCol>
                  <input type="radio" id={row.id} name="messageList" checked={index == i} style={{ margin: '0 auto', display: 'block' }} />
                </TCol>
                <TCol>
                  <p>{row.message ? row.message : '-'}</p>
                </TCol>
              </tr>
            ))
          ) : (
            <TCol colSpan="3">No templates available in this age group</TCol>
          )}
        </TBody>
      </DataTable>
    </React.Fragment>
  );
};

export default TemplateMessagesList;
