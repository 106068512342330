import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';
import { initialState } from './Auth/Login/reducer';
import { initialState as initialMaster } from './MasterData/reducer';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
function loadFromLocalStorage() {
  try {
    let serialisedState = localStorage.getItem('userData');
    let defaultMenuUrl = localStorage.getItem('defaultMenuUrl');
    let accessList = localStorage.getItem('moduleAccessArray');
    let isAllAccess = localStorage.getItem('isAllAccess');
    if (serialisedState === null) return undefined;
    return {
      Login: {
        ...initialState,
        userData: JSON.parse(serialisedState),
        ...(accessList && { moduleAccessArray: JSON.parse(accessList) }),
        isAllAccess: isAllAccess || false,
      },
      MasterData: { ...initialMaster, ...(defaultMenuUrl && { defaultMenuUrl }) },
    };
  } catch (e) {
    return undefined;
  }
}

const store = createStore(rootReducer, loadFromLocalStorage(), composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

export default store;
