import React from 'react';
import { CardBody, Row, Col, Container, Card } from 'reactstrap';
import { Link } from 'react-router-dom';

import { get } from '../../Libraries/Ajax/httpService';
import Loader from '../../Components/loader';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';
import { DeleteRow, EditRow } from '../../SharedComponents/ListComponents/manageComponent';

class ViewUserAccess extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleRow: null,
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ isLoaderActive: true });
      get(`user-access/${this.props.match.params.id}`).then((response) => {
        if (response.data.data && response.data.data.rows.length > 0) {
          this.setState({
            apiResponse: response.data.data.rows[0],
            isLoaderActive: false,
            singleRow: response.data.data.rows[0],
          });
        }
      });
    }
    this.setState({ access: setComponentPrivilege('Manage User Access') });
  }

  render() {
    let { isLoaderActive, access, singleRow, apiResponse } = this.state;
    let { groupList } = apiResponse;
    let { history, match } = this.props;

    return (
      <>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          {singleRow && (
            <div className="app-l-heading-block">
              <Row className="align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">User Access Details</h4>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/list-user-access-roles">Manage User Access</Link>
                      </li>
                      <li className="breadcrumb-item active">User Access Details</li>
                    </ol>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {singleRow && (
            <Container fluid className="px-0">
              <Card>
                <CardBody>
                  <div className="card-title">
                    <h6 style={{ color: '#8A97BA' }}>User</h6>
                    <small>{singleRow.userName}</small>
                  </div>
                  <div className="card-title">
                    <h6 style={{ color: '#8A97BA' }}>Assigned Roles</h6>
                  </div>
                  <div className="app-l-card-tags-holder">
                    {groupList && groupList.map((role) => <div className="app-l-tag-item">{role.title}</div>)}{' '}
                  </div>
                </CardBody>
              </Card>
              <div>
                <Col>
                  <div className="float-right ">
                    {access && access.delete && (
                      <DeleteRow
                        renderapi={`user-access/${match.params.id}`}
                        model={singleRow}
                        redirecturl="/list-user-access-roles"
                        deleteText="Delete"
                        history={history}
                        isCardViewTrue="True"
                      />
                    )}{' '}
                    {access && access.edit && (
                      <EditRow
                        history={history}
                        editText="Edit"
                        redirecturl={`/manage-user-access-roles/${this.props.match.params.id}`}
                        rowData={singleRow}
                      />
                    )}
                  </div>
                </Col>
              </div>
            </Container>
          )}
        </div>
      </>
    );
  }
}

export default ViewUserAccess;
