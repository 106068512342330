import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { changeLayout, changeTopbarTheme, toggleRightSidebar, changeLayoutWidth } from '../../Store/actions';

// Other Layout related Component
import RightSidebar from '../rightSidebar';
import TopBar from './topBar';
import Navbar from './navbar';
import Footer from './footer';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpened: false,
    };
    this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
  }

  /**
   * Open/close right sidebar
   */
  toggleRightSidebar() {
    this.props.toggleRightSidebar();
  }

  componentDidMount() {
    // Scrollto 0,0
    window.scrollTo(0, 0);

    let title = this.props.location.pathname;
    let currentage = title.charAt(1).toUpperCase() + title.slice(2);

    document.title = `${currentage} | Memlo - Admin Panel`;

    this.props.changeLayout('horizontal');
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }
    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }
    if (this.props.showRightSidebar) {
      this.toggleRightSidebar();
    }
  }

  /**
   * Opens the menu - mobile
   */
  openMenu = (e) => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened });
  };

  render() {
    return (
      <React.Fragment>
        <div id="layout-wrapper">
          <TopBar
            theme={this.props.topbarTheme}
            isMenuOpened={this.state.isMenuOpened}
            toggleRightSidebar={this.toggleRightSidebar}
            openLeftMenuCallBack={this.openMenu}
          />
          <div>
          <Navbar menuOpen={this.state.isMenuOpened} />

          <div className="main-content">
            <div className="page-content">{this.props.children}</div>
          </div>
          </div>
          <Footer />
          <RightSidebar />
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => ({
    ...state.Layout,
  });
export default connect(mapStatetoProps, {
  changeTopbarTheme,
  toggleRightSidebar,
  changeLayout,
  changeLayoutWidth,
})(withRouter(Layout));
