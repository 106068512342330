import React from 'react';
import Loader from '../../Components/loader';

import { get, post } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import CustomModal from '../../SharedComponents/customModal';
import CreatableDropDown from '../../SharedComponents/FormHelpers/Controls/creatableDropDown';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import { LocationSearchInput } from '../../SharedComponents/googlePlacesInput';

class LivedInPlacesComponent extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLocationComponent: false,
      dropDownRef: null,
      locationLoader: false,

      newPlace: {
        name: 'newPlace',
        placeholder: 'Enter place details',
        type: 'text',
        createapi: 'place',
        valueValidationProperty: 'address',
        rules: {
          noMoreThan: 300,
        },
      },
    };
  }

  toast = new ToastNotification();

  chooseFromGoogle = async (input, dropdownRef) => {
    await this.setState((prevState) => ({
      showLocationComponent: true,
      newPlace: { ...prevState.newPlace, value: { address: input } },
      dropDownRef: dropdownRef,
    }));
    this.setFocusOnModalField();
  };

  createNewPlace = () => {
    let { newPlace, dropDownRef } = this.state;
    let { livedInPlaces } = this.props;
    let obj = [];
    if (livedInPlaces.value && livedInPlaces.value.length > 0) {
      livedInPlaces.value
        .filter((place) => !place.isDelete)
        .forEach((place) => {
          obj.push({ value: place.placeId, label: place.label ? place.label : place.placeName });
        });
    }
    get(`${newPlace.createapi}?latitude=${newPlace.value.latitude}&longitude=${newPlace.value.longitude}&name=${newPlace.value.name}`)
      .then(async (response) => {
        if (response.data.data && response.data.data.rows && response.data.data.rows.length > 0) {
          obj.push({ value: response.data.data.rows[0].id, label: response.data.data.rows[0].name });
          await dropDownRef.onDropDownChange(obj);
        } else {
          post(newPlace.createapi, newPlace.value).then(async (res) => {
            if (res.data.data && res.data.success) {
              this.toast.show('create_success', 'SUCCESS', res.data.message);
              obj.push({ value: res.data.data.id, label: res.data.data.name });
              await dropDownRef.onDropDownChange(obj);
              dropDownRef.onCreateFromExternal();
            } else {
              this.toast.show('create_error', 'ERROR', "Couldn't add new place, please try again.");
            }
          });
        }
      })
      .catch(() => {});

    this.setState({ showLocationComponent: false, newPlace: { ...newPlace, value: null } });
  };

  setFocusOnModalField = () => {
    document.querySelector('.google-input').focus();
  };

  render() {
    let { newPlace, showLocationComponent, locationLoader } = this.state;
    let { livedInPlaces } = this.props;
    return (
      <React.Fragment>
        {locationLoader ? <Loader /> : null}
        <CreatableDropDown {...livedInPlaces} createFromExternal={this.chooseFromGoogle} />
        {showLocationComponent && (
          <CustomModal
            modal={showLocationComponent}
            toggleModal={() => this.setState({ showLocationComponent: false })}
            title="Add new place"
            buttonList={[
              { name: 'Add', color: 'primary', onClick: this.createNewPlace },
              { name: 'Cancel', color: 'secondary' },
            ]}
          >
            <LocationSearchInput
              placeDetails={this.setProperties(newPlace)}
              type={['(regions)']}
              formGroup="app-c-input-box form-group"
              className="google-input"
            />
          </CustomModal>
        )}
      </React.Fragment>
    );
  }
}

export default LivedInPlacesComponent;
