import React, { Component } from 'react';

import { Form, Col, Row, Card } from 'react-bootstrap';
import { post } from '../../../Libraries/Ajax/httpService';
import ValidationMessage from '../Validation/validationMessage';
import ToastNotification from '../../../Libraries/ToastNotifications/toastNotification';
import Loader from '../../../Components/loader';
import UserPic from '../../../assets/images/userpic.png';
import CustomModal from '../../customModal';
import ImageCropper from '../../imageCropper';

class DragAndDropComponent extends Component {
  state = {
    drag: false,
    errorState: [],
    showCropper: false,
    croppedImage: [{}],
    isLoaderActive: false,
  };

  toast = new ToastNotification();

  dropRef = React.createRef();

  componentDidMount() {
    let div = this.dropRef.current;
    if (!this.props.viewOnly) {
      div.addEventListener('dragenter', this.handleDragIn);
      div.addEventListener('dragleave', this.handleDragOut);
      div.addEventListener('dragover', this.handleDrag);
      div.addEventListener('drop', this.handleDrop);
    }
  }

  componentWillUnmount() {
    let div = this.dropRef.current;
    if (!this.props.viewOnly) {
      div.removeEventListener('dragenter', this.handleDragIn);
      div.removeEventListener('dragleave', this.handleDragOut);
      div.removeEventListener('dragover', this.handleDrag);
      div.removeEventListener('drop', this.handleDrop);
    }
  }

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter += 1;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter -= 1;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      if (e.dataTransfer.files.length > 1) {
        this.maxSelectFile(e.dataTransfer.files.length);
      } else {
        this.dropHandler(e.dataTransfer.files);
        e.dataTransfer.clearData();
        this.dragCounter = 0;
      }
    }
  };

  filesProgressCount = 0;

  validateFiles(files) {
    this.setState({
      errorState: [],
    });
    let { allowCrop } = this.props;
    let invalidItems = [...files].filter((file) => {
      if (!this.checkFileExtension([file]) && !this.checkFileSize([file])) {
        return false;
      }
      return true;
    });
    if (invalidItems.length == 0) {
      [...files].forEach((file) => {
        if (!this.checkFileExtension([file]) && !this.checkFileSize([file])) {
          if (allowCrop) {
            this.setState({ initialImage: file, showCropper: true });
          } else this.onFileUpload([file]);
        }
      });
    }
  }

  onFileChange = (event) => {
    if (event.target.files.length > 0) {
      this.validateFiles(event.target.files);
      event.target.value = '';
    }
  };

  dropHandler = (files) => {
    if (files.length > 0) {
      this.validateFiles(files);
    }
  };

  checkFileExtension = (files) => {
    let { validExtensions } = this.props;
    let error = [];
    if (validExtensions.every((type) => files[0].name.split('.').pop().toUpperCase() !== type.toUpperCase())) {
      error = 'The file format is not supported';
      this.setState({
        errorState: [error],
      });
      files = null;
      return true;
    }
    return false;
  };

  maxSelectFile = (count) => {
    if (count && count > 1) {
      let error = 'Only 1 image can be uploaded ';
      this.toast.show('upload_error', 'ERROR', error);
      return false;
    }
    if (this.props.value.length > 0 && this.props.value.filter((x) => !x.isDelete).length > 0) {
      let error = 'Only 1 image can be uploaded ';
      this.toast.show('upload_error', 'ERROR', error);
      return false;
    }
    return true;
  };

  checkFileSize = (files) => {
    let size = 5 * 1024 * 1024;
    if (files[0] && files[0].type.includes('video')) size = 25 * 1024 * 1024;
    let error = [];
    if (files[0] && files[0].size > size) {
      error = `${files[0].name} is too large, Please pick a smaller file`;
      this.setState({
        errorState: [error],
      });
      files = null;
      return true;
    }
    return false;
  };

  onFileUpload = (files) => {
    let properties = {
      ...this.props,
    };
    let formData = new FormData();
    formData.append('file', files[0]);
    let file = this.props.value ? this.props.value : '';
    this.setState({ isLoaderActive: true });
    post(this.props.fileUploadUrl, formData)
      .then((response) => {
        this.setState({ isLoaderActive: false });
        let fileObj = null;
        if (response && response.data) {
          let fileName = response.data?.filePath ? response.data.filePath.split('/').reverse()[0] : response.data.filePath;
          fileObj = {
            attachmentName: fileName,
            attachmentUrl: response.data.webUrl,
          };
          file = response.data.filePath;
        }
        properties.value = file;
        properties.item = fileObj;
        if (properties.onChangeValidation) {
          properties.onChangeValidation(null, properties);
        }
      })
      .catch((error) => {
        if (error.message) this.toast.show('file_too_large', 'ERROR', error.message ? error.message : 'File Too Large');
      });
  };

  onCropperCancelButtonClick = () => {
    this.setState({ showCropper: false });
  };

  onCropperConfirmButtonClick = () => {
    this.setState({ showCropper: false });
    this.onFileUpload(this.state.croppedImage);
  };

  onCropDone = (file) => {
    let val = { ...this.croppedImage };
    val[0] = file;
    this.setState({ croppedImage: val });
  };

  onDeleteClick = () => {
    let properties = {
      ...this.props,
    };
    properties.value = '';
    properties.item = {};
    if (properties.onChangeValidation) {
      properties.onChangeValidation(null, properties);
    }
  };

  render() {
    let message = '';
    let {
      viewOnly,
      validVideoExtensions,
      validImageExtensions,
      validExtensions,
      rules,
      item,
      editFlag,
      mediaUrl,
      value,
      mediaType,
      customLabel,
      name,
      aspectRatio,
      allowDelete,
      errors: [errFromProp] = [],
    } = this.props;
    let {
      errorState: [err],
    } = this.state;
    let messageType = 'i';
    if (errFromProp) {
      ({ message } = errFromProp);
    }
    if (err) {
      message = err;
    }
    if (this.props.isTouched) {
      messageType = 'e';
    }
    let file = item;
    if (editFlag && !file) {
      let obj = {
        attachmentName: value ? value.split('/').reverse()[0] : value,
        attachmentUrl: mediaUrl,
        mediaType,
      };
      file = obj;
    } else if (editFlag && value && (!file || (file && !file.attachmentUrl))) {
      let obj = {
        attachmentName: value ? value.split('/').reverse()[0] : value,
        attachmentUrl: mediaUrl,
        mediaType,
      };
      file = obj;
    }
    return (
      <>
        {this.state.isLoaderActive === true ? <Loader /> : ''}

        <div className="form-group">
          {!viewOnly && (
            <>
              <Form.Label className="attachment-lable">
                {customLabel || 'Attachment'}
                {rules && rules.required && !this.props.disableRequiredsymbol ? <sup style={{ top: '0.5em' }}>*</sup> : ''}
                {validExtensions && (
                  <span className="app-l-text-support">
                    Supports:{' '}
                    {validVideoExtensions && validImageExtensions
                      ? `${validImageExtensions.join(', ').toUpperCase()} (Max 5MB)` +
                        ` & ${validVideoExtensions.join(', ').toUpperCase()} (Max 25MB)`
                      : `${validExtensions.join(', ').toUpperCase()} (Max 5MB)`}
                  </span>
                )}
              </Form.Label>

              <div ref={this.dropRef}>
                {this.state.drag && <div />}
                <div className="app-l-fileUpload-section">
                  <div className="app-l-fileUpload-holder">
                    <label className="app-c-drag-file">Drop File or</label>
                    <div className="app-c-file-input">
                      <input type="file" id={`file${name}`} onChange={this.onFileChange} />

                      <label htmlFor={`file${name}`} className="app-c-file-input-btn">
                        Browse
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {messageType && message ? (
            <>
              <ValidationMessage {...{ message }} {...{ type: messageType }} />
              <br />
              <br />
            </>
          ) : (
            <span className="hh-validation-message" />
          )}

          {file?.attachmentName && file?.attachmentUrl && (
            <div>
              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key="dragfile">
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="text-overflow-class">
                      {/* <div > */}
                      {/* </div> */}{' '}
                      {['avi', 'mp4'].every(
                        (type) => file.attachmentUrl && file.attachmentUrl.split('.').pop() !== type && file.mediaType !== type,
                      ) ? (
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt="attachment"
                          href={file.attachmentUrl}
                          src={file.attachmentUrl ? file.attachmentUrl : <UserPic />}
                        />
                      ) : (
                        <video data-dz-thumbnail="" width="100%" preload="metadata" height="100%" controls="controls">
                          <track kind="captions" />

                          <source src={`${file.attachmentUrl}#t=0.5`} type="video/mp4" />
                          <source src={`${file.attachmentUrl}#t=0.5`} type="video/avi" />
                        </video>
                      )}
                      {/* </Col>
                    <Col className="ml-1"> */}
                      <a href={file.attachmentUrl} target="_new" className="ml-3 text-muted font-weight-bold">
                        {file.attachmentName ? file.attachmentName : file.attachmentUrl}
                      </a>
                      {allowDelete && (
                        <span style={{ float: 'right', padding: '13px' }}>
                          <button title="Remove Image" type="button" onClick={this.onDeleteClick} style={{ color: '#626ed4' }}>
                            <i className="fas fa-trash" />
                          </button>
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
          )}
        </div>
        {this.state.showCropper && this.state.initialImage && (
          // <Modal
          //   title="Confirmation"
          //   cancelButtonName="Cancel"
          //   confirmButtonName="Upload"
          //   cancelButtonClick={this.onCropperCancelButtonClick}
          //   confirmButtonClick={this.onCropperConfirmButtonClick}
          // >
          //   <ImageCropper onCropDone={this.onCropDone} selectedFile={this.state.initialImage} className="ImageCropper" aspectRatio={aspectRatio} />
          // </Modal>
          <CustomModal
            modal={this.state.showCropper}
            toggleModal={this.onCropperCancelButtonClick}
            title="Crop Image"
            className="modal-lg"
            maxHeight={400}
            buttonList={[{ name: 'Upload', color: 'primary', onClick: this.onCropperConfirmButtonClick }, { name: 'Cancel' }]}
          >
            <ImageCropper onCropDone={this.onCropDone} selectedFile={this.state.initialImage} className="ImageCropper" aspectRatio={aspectRatio} />
          </CustomModal>
        )}
      </>
    );
  }
}
export default DragAndDropComponent;
