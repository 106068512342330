import React from 'react';
import { Container } from 'react-bootstrap';
import { Row, Col, Card, CardBody, FormGroup, Button } from 'reactstrap';

import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import Loader from '../../Components/loader';
import CustomDatePicker from '../../SharedComponents/FormHelpers/Controls/customDatePicker';
import ListPartnerManagerAmountContainer from './listPartnerManagerAmount';
import { get, post } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import FormInput from '../../SharedComponents/FormHelpers/Controls/formInput';

class ManagePartnerManager extends FormComponent {
  constructor(props) {
    super(props);
    this.setProperties = this.setProperties.bind(this);
    this.setFormProperties = this.setFormProperties.bind(this);
    this.state = {
      isSubmitted: true,
      action: 'Add',
      apiResponse: [],
      partnerManagerId: null,
      amountArray: [],
      // amountAdjusted: null,
      // balanceOutstanding: null,
      date: {
        name: 'date',
        label: 'Transaction Date',
        type: 'date',
        placeholder: 'DD/MM/YY',
        rules: {
          required: true,
          maxToday: true,
        },
      },
      amount: {
        type: 'text',
        label: 'Transaction Amount',
        name: 'amount',
        placeholder: 'Amount',
        rules: {
          required: true,
          number: true,
        },
      },
      remarks: {
        type: 'text',
        label: 'Remarks',
        name: 'remarks',
        placeholder: 'Remarks',
        rules: {
          required: true,
        },
      },
    };
  }

  componentDidMount() {
    let { match } = this.props;
    this.setState({ partnerManagerId: parseInt(match.params.id) });
    let model = {
      partnerManagerId: parseInt(match.params.id),
      hasOutstanding: true,
    };
    if (model) {
      get('partner-manager-obituary', model).then((response) => {
        this.stateArray(response.data.data.rows);
        this.setState({ apiResponse: response.data.data.rows });
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { amount, apiResponse } = this.state;
    if (prevState.amount.value != amount.value) {
      this.calculateAmount(amount.value, apiResponse);
    }
  }

  stateArray = (responseData) => {
    let amountArray = [];
    let amountObj = {};
    responseData.forEach((element) => {
      amountObj = { amountAdjusted: 0, balanceOutstanding: element.outstandingAmount };
      amountArray.push(amountObj);
    });
    this.setState({ amountArray });
  };

  calculateAmount = (amt, responseArray) => {
    let ttlAmnt = parseFloat(amt);
    let ttlAmt = Number.isNaN(ttlAmnt) ? 0 : ttlAmnt;
    let balanceOutstanding;
    let amtAdjusted;
    let outstandingAmt;
    let prevOutstanding;
    let amtArry = [...this.state.amountArray];
    responseArray.forEach((element, i) => {
      prevOutstanding = parseFloat(element.outstandingAmount);
      outstandingAmt = parseFloat(element.outstandingAmount);
      if (ttlAmt > 0) {
        if (ttlAmt >= outstandingAmt) {
          balanceOutstanding = 0;
          amtAdjusted = outstandingAmt;
          ttlAmt -= outstandingAmt;
        } else {
          amtAdjusted = ttlAmt;
          balanceOutstanding = outstandingAmt - ttlAmt;
          ttlAmt = 0;
        }
        amtArry[i].amountAdjusted = amtAdjusted;
        amtArry[i].balanceOutstanding = balanceOutstanding;
        amtArry[i].prevOutstanding = prevOutstanding;
      } else {
        amtArry[i].amountAdjusted = 0;
        amtArry[i].balanceOutstanding = outstandingAmt;
        amtArry[i].prevOutstanding = prevOutstanding;
      }
    });
    this.setState({ amountArray: amtArry });
  };

  // onAmountPaid = () => {
  //   let { amount, apiResponse } = this.state;
  //   // this.setState({ amount: { ...amount, value: e.target.value } });
  //   this.calculateAmount(amount, apiResponse);
  // };

  onCancelButtonClick = () => {
    let { partnerManagerId } = this.state;
    this.props.history.push(`/view-partner-managers/${partnerManagerId}/view-transactions`);
  };

  toast = new ToastNotification();

  onSaveButtonClick = (model) => {
    let { partnerManagerId, apiResponse, amountArray } = this.state;
    for (let i = 0; i < apiResponse.length; i += 1) {
      apiResponse[i].outstandingAmount = amountArray[i].balanceOutstanding;
      apiResponse[i].amountAdjusted = amountArray[i].amountAdjusted;
      apiResponse[i].previousOutstanding = amountArray[i].prevOutstanding;
    }
    model.partnerPaymentDate = model.date;
    model.amount = parseFloat(model.amount);
    model.partnerManagerId = partnerManagerId;
    model.partnerManagerObituaries = apiResponse;
    post('partner-manager-payment', model).then(() => {
      this.toast.show('create_success', 'SUCCESS', 'Payment Details Updated');
      this.onCancelButtonClick();
    });
  };

  render() {
    let { isLoaderActive, apiResponse, amountArray } = this.state;
    return (
      <React.Fragment>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          <Row>
            <Container fluid className="px-0">
              <div className="col-12">
                <Card>
                  <CardBody>
                    <h3>New Transaction</h3>
                    <br />
                    <SubmitForm onSubmit={this.onSaveButtonClick} {...this.setFormProperties({ disableReactiveForm: true })}>
                      <Row className="mb-4">
                        <Col xs={12} md={3}>
                          <CustomDatePicker
                            formGroup
                            appearance="subtle"
                            format="DD/MM/YYYY"
                            isDate
                            oneTap
                            futureDate={false}
                            placement="auto"
                            {...this.setProperties(this.state.date)}
                          />
                        </Col>
                        <Col xs={12} md={3}>
                          <FormInput
                            type="text"
                            // onChange={this.onAmountPaid}
                            className="form-control"
                            {...this.setProperties(this.state.amount)}
                          />
                        </Col>
                        <Col xs={12} md={3}>
                          <FormInput type="text" className="form-control" {...this.setProperties(this.state.remarks)} />
                        </Col>
                      </Row>
                      <ListPartnerManagerAmountContainer {...this.props} apiResponse={apiResponse} amountArray={amountArray} />
                      <FormGroup className="mb-0">
                        <div className="float-right d-block">
                          <br />
                          <Button type="submit" color="primary" className="mr-1">
                            Save
                          </Button>{' '}
                          <Button className="btn-secondary" type="reset" onClick={this.onCancelButtonClick}>
                            <i className="icon-close"> </i> <span> Cancel </span>
                          </Button>
                        </div>
                      </FormGroup>
                    </SubmitForm>
                  </CardBody>
                </Card>
              </div>
            </Container>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
export default ManagePartnerManager;
