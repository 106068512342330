import React from 'react';
import { Modal, Col, Row, Card, CardBody } from 'reactstrap';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';

import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import { DataColHead } from '../../SharedComponents/ListComponents/components';
import { DataTable, THead, TRow, TBody, TCol } from '../../SharedComponents/Table/tableElements';
import { get, put } from '../../Libraries/Ajax/httpService';

class CustomerFeedback extends ListComponent {
  constructor(props) {
    super(props);

    this.state = {
      customerFeedbackDetails: {},
      setNotification: false,
    };
  }

  customerFeedbackDetailsList = [];

  toast = new ToastNotification();

  // countNotCompleted = 0;

  handleDropdown = (event, rowId) => {
    let model = {};
    let countNotCompleted = 0;

    // setting dropdown value
    this.customerFeedbackDetailsList.rows.forEach((item) => {
      if (item.id == rowId) {
        if (event.target.value.includes('Not Completed')) {
          item.isStatus = 0;
          model.isStatus = 0;
          this.setState({ setNotification: true });
        } else {
          item.isStatus = 1;
          model.isStatus = 1;
        }
      }

      if (item.isStatus == 0) countNotCompleted += 1;
    });

    // setting notification if all feedbacks are completed
    if (countNotCompleted == 0) {
      this.setState({ setNotification: false });
    }

    this.setState({ customerFeedbackDetails: this.customerFeedbackDetailsList });

    put(`customer-feedback/${rowId}`, model)
      .then((response) => {
        this.toast.show('update_success', 'SUCCESS', response.data?.message ? response.data.message : 'Feedback status successfully updated');
      })
      .catch((err) => {
        let { msg } = err;
        this.toast.show('update_error', 'ERROR', err ? msg : 'Something went wrong');
      });
  };

  // localStorage = window.localStorage;

  componentDidMount() {
    let model = { obituaryProfileId: this.props.obituaryRowId, take: 100 };
    let { setNotification } = this.props;

    get('customer-feedback', model).then((res) => {
      this.customerFeedbackDetailsList = res.data;
      this.setState({ customerFeedbackDetails: res.data });

      this.customerFeedbackDetailsList.rows.forEach((item) => {
        if (item.isStatus == 0) {
          this.setState({ setNotification: true });
          setNotification(true);
        }
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.setNotification != this.state.setNotification) {
      this.props.setNotification(this.state.setNotification);
    }
  }

  render() {
    let { showCheck, toggleState, stateKey } = this.props;
    let { customerFeedbackDetails } = this.state;
    return (
      <div className="container-fluid">
        <Modal className="modal-lg" isOpen={showCheck} scrollable toggle={() => toggleState(stateKey)}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Customer Feedback
            </h5>
            <button
              onClick={() => {
                toggleState(stateKey);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Card className="enable-scroll">
              <CardBody style={{ minWidth: '650px' }}>
                <Row className="align-items-center pb-3">
                  <Col sm={6}>
                    <p className="mb-0">Feedback received for this profile</p>
                  </Col>
                </Row>
                <div className="row customer-feedback-list">
                  {/* <div data-test="table" className="table-responsive"> */}
                  {/* <DataTableHolder pagination={<ListPagination {...this.setpagination()} />} className="min-160"> */}
                  <DataTable>
                    <THead>
                      <TRow>
                        <DataColHead
                          {...this.setheadproperties({
                            title: 'Customer',
                            className: 'app-l-search-column',
                          })}
                        />
                        <DataColHead
                          {...this.setheadproperties({
                            title: 'Feedback',
                            className: 'app-l-long-column',
                          })}
                        />
                        <DataColHead
                          {...this.setheadproperties({
                            title: 'Status',
                            className: 'app-l-width-condensed-column',
                            coltype: 'customer-status',
                          })}
                        />
                      </TRow>
                    </THead>

                    <TBody>
                      {customerFeedbackDetails && customerFeedbackDetails.rows?.length > 0
                        ? customerFeedbackDetails.rows.map((row, i) => (
                            <TRow key={`customer-feedback-${i}`}>
                              <TCol>{row.givenByName}</TCol>
                              <TCol>{row.feedback}</TCol>
                              <TCol>
                                <select
                                  id={`feedback-status-${i}`}
                                  className="customer-feedback-status mb-0 form-control"
                                  onChange={(event) => {
                                    this.handleDropdown(event, row.id);
                                  }}
                                  value={row.isStatus == 0 ? 'Not Completed' : 'Completed'}
                                >
                                  <option value="Completed">Completed</option>
                                  <option value="Not Completed">Not Completed</option>
                                </select>
                              </TCol>
                            </TRow>
                          ))
                        : null}
                    </TBody>
                  </DataTable>
                  {/* </DataTableHolder> */}

                  {/* </div> */}
                  {customerFeedbackDetails && customerFeedbackDetails.rows?.length === 0 && (
                    <div className="text-center no-content-block" style={{ width: '100%' }}>
                      No feedback found{' '}
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CustomerFeedback;
