import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import dayjs from 'dayjs';
import { DataTable, TBody, THead, TCol, TRow, DataTableHolder } from '../../SharedComponents/Table/tableElements';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
import Loader from '../../Components/loader';

class ListOrderLogController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaderActive: false,
    };
  }

  componentDidMount() {
    this.props.setJsonUrl('order-log');
    this.props.setpredicates({
      take: 10,
      page: 0,
      orderId: parseInt(this.props.orderId),
    });
  }

  onColClick = (e, id) => {
    this.props.history.push(`${id}`);
  };

  onReload = () => {
    let { setParentState, setpredicates } = this.props;
    setpredicates({});
    setParentState({ reloadList: false });
  };

  render() {
    let { isLoaderActive } = this.state;
    let { data, reloadList } = this.props;
    if (reloadList) {
      this.onReload();
    }

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>{isLoaderActive === true ? <Loader /> : ''}</Col>
          </Row>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="page-title-box">
                    <h5>Order Log</h5>
                  </div>
                  <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                    <div className="row">
                      <DataTableHolder pagination={<ListPagination {...this.props.setpagination()} />} className="min-160">
                        <DataTable>
                          <THead>
                            <TRow>
                              <DataColHead
                                {...this.props.setheadproperties({
                                  title: 'Order Status',
                                })}
                              />

                              <DataColHead
                                {...this.props.setheadproperties({
                                  title: 'Order Note',
                                })}
                              />

                              <DataColHead
                                {...this.props.setheadproperties({
                                  title: 'Date and Time',
                                })}
                              />
                            </TRow>
                          </THead>
                          <TBody>
                            {data && data.rows && data.rows.length > 0
                              ? data.rows.map((row) => (
                                  <TRow>
                                    <TCol>{row.statusTitle}</TCol>
                                    <TCol>{row.note}</TCol>
                                    <TCol>
                                      {dayjs(row.modifiedDate).format('DD/MM/YYYY')} {dayjs(row.modifiedDate).format('HH:mm')}
                                    </TCol>
                                  </TRow>
                                ))
                              : null}
                          </TBody>
                        </DataTable>
                        {data.rows && data.rows.length === 0 && <div className="text-center no-content-block">No results found </div>}
                      </DataTableHolder>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ListOrderLogController;
