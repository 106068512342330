export const listMergeToExisting = (value, list) => {
  list.push(value);
};

export const findObject = (list, callBack) => {
  let filter = list.filter((entry) => callBack(entry));
  return filter;
};

export const isExistInArray = (list, callBack) => {
  let isExist = list.filter((entry) => callBack(entry));
  if (isExist.length > 0) {
    return true;
  }
  return false;
};
export const removeObjectAndFunctionAttributes = (obj) => {
  let keyValueObject = {};
  Object.values(obj).forEach((element, i) => {
    if (typeof element !== 'object' && typeof element !== 'function') {
      if (element != null && typeof element != 'undefined') {
        keyValueObject[Object.keys(obj)[i]] = element.toString();
      }
    }
  });
  return keyValueObject;
};
