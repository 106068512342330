import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { get, post } from '../../../Libraries/Ajax/httpService';
import ValidationMessage from '../Validation/validationMessage';
import ToastNotification from '../../../Libraries/ToastNotifications/toastNotification';
import { LazyRun } from '../../../Libraries/LazyFunctions/lazyFunction';
import Loader from '../../../Components/loader';

class CreatableDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: [],
      menuIsOpen: false,
      newOptionCreated: false,
      formatCreateLabel: {},
      isLoaderActive: false,
    };
    this.searchValue = undefined;
    this.dropdownRef = {};
  }

  toast = new ToastNotification();

  componentDidMount() {
    let { customCreateLabel, renderapi } = this.props;
    if (customCreateLabel && customCreateLabel.length > 0) {
      this.setState({
        formatCreateLabel: {
          formatCreateLabel: (inputValue) => `${customCreateLabel} ${inputValue}`,
        },
      });
    }
    if (renderapi) {
      let model = {
        take: 15,
        page: 0,
      };
      get(renderapi, model)
        .then((response) => {
          if (response.data && response.data.success) {
            this.setState({ apiResponse: response.data.data.rows });
          } else {
            this.toast.show('fetch_error', 'ERROR', response.data.message);
          }
        })
        .catch(() => {});
    }
  }

  componentDidUpdate() {
    if (this.state.newOptionCreated) {
      get(this.props.renderapi)
        .then(async (response) => {
          await this.setState({
            newOptionCreated: false,
            apiResponse: response.data.data.rows,
            isLoaderActive: false,
          });
        })
        .catch(() => {});
    }
  }

  onDropDownChange = (e) => {
    if (e == null || typeof e == 'undefined') {
      e = {};
    }
    let { dbField } = this.props;
    let properties = {
      ...this.props,
    };
    let list = [];
    Object.keys(e).forEach((obj) => {
      let ob = { [dbField]: e[obj].value, label: e[obj].label, isDelete: false };
      if (properties.value) {
        let existingItems = properties.value.filter((x) => x[dbField] == ob[dbField]);
        if (existingItems.length > 0) {
          ob.id = existingItems[0].id;
        }
      }
      list.push(ob);
    });
    Object.keys(properties.value).forEach((val) => {
      let existingItems = list.filter((x) => x[dbField] == properties.value[val][dbField]);
      if (existingItems.length == 0) {
        list.push({ ...properties.value[val], isDelete: true });
      }
    });
    properties.value = list;
    if (properties.onChangeValidation) {
      properties.onChangeValidation(e, properties);
    }
  };

  onFocus = () => {
    this.setState({
      menuIsOpen: true,
    });
  };

  onBlur = () => {
    this.setState({
      menuIsOpen: false,
    });
  };

  onCreateFromExternal = () => {
    this.setState({ newOptionCreated: true });
  };

  createNewOption = (input) => {
    if (this.props.createFromExternal) {
      this.dropdownRef.onDropDownChange = this.onDropDownChange;
      this.dropdownRef.onCreateFromExternal = this.onCreateFromExternal;
      this.props.createFromExternal(input, this.dropdownRef);
    } else {
      let { createapi, fieldLabel, value, dbField, maxLength } = this.props;
      if (input !== null && typeof input === 'string') {
        if (createapi && input.length < maxLength) {
          this.setState({ isLoaderActive: true });
          post(createapi, { [fieldLabel]: input }).then(async (response) => {
            if (response.data.data && response.data.success) {
              this.toast.show('create_success', 'SUCCESS', response.data.message);
              setTimeout(() => {
                this.setState({ newOptionCreated: true });
              }, 500);
              let obj = [];
              if (value.length > 0) {
                value
                  .filter((element) => !element.isDelete)
                  .forEach((element) => {
                    let lbl;
                    if (Object.prototype.hasOwnProperty.call(element, 'label')) {
                      lbl = element.label;
                    } else {
                      let item = dbField.replace(/Id/, fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1));
                      lbl = element[item];
                    }
                    obj.push({ value: element[dbField], label: lbl });
                  });
              }
              obj.push({ value: response.data.data.id, label: response.data.data[fieldLabel] });
              this.onDropDownChange(obj);
            } else {
              this.toast.show('create_error', 'ERROR', response.data.message);
              this.setState({ isLoaderActive: false });
            }
          });
        } else if (input.length > maxLength) {
          this.toast.show('create_error', 'ERROR', `Cannot add ${createapi} with more than ${maxLength} characters`);
        }
      }
    }
  };

  fetchOptions = (e) => {
    let { searchField, renderapi, maxLength, createapi } = this.props;
    let fun = () => {
      if (e.length < maxLength) {
        get(renderapi, { [searchField]: e, page: 0 })
          .then((response) => {
            if (response.data.data && response.data.data.rows && response.data.data.rows.length > 0) {
              this.setState({ apiResponse: response.data.data.rows });
            }
          })
          .catch(() => {});
      } else this.toast.show('search_error', 'ERROR', `Cannot add ${createapi} with more than ${maxLength} characters`);
    };
    if (e.length > 0) LazyRun(fun);
  };

  render() {
    let properties = {
      ...this.props,
    };
    let { fieldLabel, fieldValue, dbField, isHidden, label, rules, disableLabel, errors } = this.props;
    let { formatCreateLabel, isLoaderActive } = this.state;
    let { menuIsOpen } = this.state;
    let message = '';

    if (errors.length > 0) {
      let error = errors[0];
      ({ message } = error);
    }
    let messagetype = 'i';
    if (this.props.isTouched) {
      messagetype = 'e';
    }
    if (!this.props.isFocused || this.props.isSilent) {
      messagetype = '';
    }
    let options = [];
    let selectedValues = [];
    for (let i = 0; i < this.state.apiResponse.length; i += 1) {
      options.push({
        label: this.state.apiResponse[i][fieldLabel],
        value: this.state.apiResponse[i][fieldValue],
      });
    }
    if (properties.value && properties.value.length > 0) {
      Object.keys(properties.value).forEach((prevValue) => {
        if (!properties.value[prevValue].isDelete) {
          let lbl;
          if (Object.prototype.hasOwnProperty.call(properties.value[prevValue], 'label')) {
            lbl = properties.value[prevValue].label;
          } else {
            let item = dbField.replace(/Id/, fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1));
            lbl = properties.value[prevValue][item];
          }
          selectedValues.push({
            value: properties.value[prevValue][dbField],
            label: lbl,
          });
        }
      });
    }
    if (!isHidden) {
      return (
        <>
          {isLoaderActive ? <Loader /> : null}
          <Form.Group>
            {!disableLabel ? (
              <Form.Label>
                {label}
                {rules && rules.required && !this.props.disableRequiredsymbol ? <sup>*</sup> : ''}
              </Form.Label>
            ) : (
              ''
            )}
            <CreatableSelect
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              value={selectedValues}
              placeholder={menuIsOpen === true ? 'Search' : 'Select'}
              isMulti
              options={options}
              noOptionsMessage={() => 'No Records'}
              onChange={(e) => this.onDropDownChange(e)}
              onCreateOption={(input) => this.createNewOption(input)}
              className="creatable-dropdown"
              onInputChange={(e) => this.fetchOptions(e)}
              {...formatCreateLabel}
            />

            {messagetype ? <ValidationMessage {...{ message }} {...{ type: messagetype }} /> : <span className="app-c-validation-message" />}
          </Form.Group>
        </>
      );
    }
    return <></>;
  }
}
export default CreatableDropDown;
