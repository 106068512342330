import {
  SET_USER,
  LOGOUT_USER,
  API_ERROR,
  SET_LOADING_INACTIVE,
  SET_LOADING_ACTIVE,
  SET_IS_ALL_ACCESS,
  LOGIN_MESSAGE,
  SET_ACCESS_ARRAY,
} from './actionTypes';

export const initialState = {
  error: null,
  loading: false,
  userData: null,
  loginMessageState: null,
  messageSuccess: null,
  moduleAccessArray: null,
  isAllAccess: false,
};

const loginData = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER:
      state = {
        ...state,
        userData: payload,
        loading: false,
        loginMessageState: null,
        messageSuccess: null,
      };
      break;
    case LOGOUT_USER:
      state = { ...state, userData: null, moduleAccessArray: null, isAllAccess: false };
      break;
    case API_ERROR:
      state = { ...state, error: payload, loading: false };
      break;
    case LOGIN_MESSAGE:
      state = {
        ...state,
        loginMessageState: payload.message,
        messageSuccess: payload.success,
        loading: false,
      };
      break;
    case SET_LOADING_INACTIVE:
      state = { ...state, loading: false };
      break;
    case SET_LOADING_ACTIVE:
      state = { ...state, loading: true };
      break;
    case SET_ACCESS_ARRAY:
      state = { ...state, moduleAccessArray: payload };
      break;
    case SET_IS_ALL_ACCESS:
      state = { ...state, isAllAccess: payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default loginData;
