import React from 'react';

import { Row, Col, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, CardTitle } from 'reactstrap';
import classnames from 'classnames';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import ListObituaryPostContainer from '../Obituary/listObituaryPost';
import { get } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';

class ListPostTypeContainer extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleSelect: false,
      multiSelect: false,
      rowData: {},
      isLoaderActive: false,
      editURL: 'manage-post-types/',
      viewURL: 'view-post-types/',
      activeTab: 8,
    };
    this.onToggle = this.onToggle.bind(this);
  }

  toast = new ToastNotification();

  onToggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    let postTypeModel = {
      take: 10,
      page: 0,
      isTabs: 1,
    };
    get('post-type', postTypeModel)
      .then((response) => {
        if (response.data && response.data.data && response.data.success) {
          this.setState({ apiResponse: response.data.data });
        }
      })
      .catch(() => {
        this.toast.show('fetch-error', 'ERROR', 'Could not load post types');
      });
  }

  render() {
    let { apiResponse } = this.state;
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <CardTitle style={{ fontWeight: 500 }}>Manage Obituary Posts</CardTitle>
                <Nav tabs className="nav-tabs-custom">
                  {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                    ? apiResponse.rows.map((tab, i) => (
                        <NavItem key={`${i}-nav`}>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === tab.id,
                            })}
                            onClick={() => {
                              this.onToggle(tab.id);
                            }}
                          >
                            {tab.title}
                          </NavLink>
                        </NavItem>
                      ))
                    : null}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                    ? apiResponse.rows.map((row, i) => (
                        <TabPane key={`tab-pane-${i}`} tabId={row.id} className="pt-3">
                          <Row>
                            <Col sm="12 p-0">
                              {this.state.activeTab == row.id ? <ListObituaryPostContainer typeId={row.id} type={row.title} {...this.props} /> : null}
                            </Col>
                          </Row>
                        </TabPane>
                      ))
                    : null}
                </TabContent>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default ListPostTypeContainer;
