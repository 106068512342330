import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import { ModalTitle } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { get } from '../../Libraries/Ajax/httpService';
import TemplateCardsList from './templateCardsList';
import TemplateMessagesList from './templateMessagesList';

class SelectFromTemplatesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      template: '',
      buttonDisabled: true,
    };
    this.onSelectTemplate = this.onSelectTemplate.bind(this);
  }

  componentDidMount() {
    if (this.props.renderapi != null && typeof this.props.renderapi != 'undefined') {
      get(this.props.renderapi).then((response) => {
        response.data = response.data.data;
        if (Object.prototype.hasOwnProperty.call(this.props, 'age')) {
          response.data.rows = response.data.rows.filter(
            (row) => this.props.age >= row.ageFrom && this.props.age <= row.ageTo && row.message != null,
          );
        }
        this.setState({ apiResponse: response.data });
      });
    }
  }

  onSelectTemplate(index, isColour) {
    let response = this.state.apiResponse;
    let templateData;
    let { templateType } = this.props;
    this.setState({ buttonDisabled: false });
    if (response && response.rows && response.rows.length > 0) {
      if (templateType === 'card') {
        templateData = response.rows[index]?.image;
      } else if (templateType === 'background-image') {
        templateData = {
          id: response.rows[index].id,
          mediaUrl: isColour ? null : response.rows[index].mediaUrl,
          hexCode: isColour ? response.rows[index].solidColor : null,
        };
      } else if (templateType === 'message') {
        templateData = response.rows[index]?.message;
      }
    }
    this.setState({ template: templateData });
  }

  render() {
    let { cancelButtonClick, title, cancelButtonName, confirmButtonName, confirmButtonClick, hide, modal, showModal, templateType } = this.props;
    let { apiResponse, template } = this.state;
    return (
      <Modal className="modal-lg" isOpen={modal} toggle={showModal}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <button
            type="button"
            onClick={() => {
              cancelButtonClick();
              this.setState({
                buttonDisabled: true,
                template: null,
              });
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody style={{ maxHeight: `${300}px`, overflow: 'scroll' }}>
          {apiResponse && apiResponse.rows && apiResponse.rows.length > 0 ? (
            <>
              {templateType === 'message' ? (
                <TemplateMessagesList messageList={apiResponse.rows} selectTemplate={this.onSelectTemplate} />
              ) : (
                <TemplateCardsList
                  isBackground={templateType === 'background-image'}
                  cardList={apiResponse.rows}
                  selectTemplate={this.onSelectTemplate}
                />
              )}
            </>
          ) : (
            <Row>
              <Col className="text-center">{templateType !== 'message' ? 'No templates available' : 'No templates available in this age group'}</Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={() => {
              cancelButtonClick();
              this.setState({
                buttonDisabled: true,
                template: null,
              });
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            {cancelButtonName}
          </Button>
          <Button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={
              confirmButtonClick
                ? () => {
                    confirmButtonClick(template);
                    this.setState({
                      buttonDisabled: true,
                      template: null,
                    });
                  }
                : null
            }
            hidden={!!hide}
            disabled={this.state.buttonDisabled}
          >
            {confirmButtonName}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default SelectFromTemplatesModal;
