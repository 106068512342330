import React, { Component } from 'react';
import { ChromePicker } from 'react-color';
import { Col } from 'reactstrap';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import reactCSS from 'reactcss';

export default class BackgroundTemplate extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      colors: {
        nameColor: '#e0dedb',
        dateColor: '#e0dedb',
        locationColor: '#e0dedb',
        tagColor: '#e0dedb',
        locationIconColor: '#e0dedb',
        lipIconColor: '#e0dedb',
        solidColor: this.props.solidColor ? '#e0dedb' : null,
      },

      editFlag: true,
      colorSet: false,
      mediaUrl: null,
    };
  }

  validExtensions = ['jpeg', 'jpg', 'png'];

  componentDidMount() {
    let { colors, editFlag } = this.props;
    if (editFlag && !this.state.colorSet && Object.keys(this.props.colors).length != 0) {
      this.setState({ colors, colorSet: true });
    }
    if (!editFlag) this.props.setColorsValue(this.state.colors);
  }

  componentDidUpdate() {
    let { editFlag } = this.props;
    if (editFlag && !this.state.colorSet && Object.keys(this.props.colors).length != 0) this.onUpdate();
  }

  onUpdate = () => {
    let { colors } = this.props;
    this.setState({ colors, colorSet: true });
  };

  getValues = (values, stateName) => {
    this.setState((prev) => ({ colors: { ...prev.colors, [stateName]: values.hex } }));
    this.props.setColorsValue(this.state.colors);
  };

  render() {
    let { editFlag, solidColor } = this.props;
    let { colors } = this.state;
    return (
      <React.Fragment>
        {solidColor && (
          <Col xs={12} md={6}>
            <SketchExample
              onRecieveValues={this.getValues}
              label="Solid Background Color"
              stateName="solidColor"
              editFlag={editFlag}
              colorValue={colors.solidColor}
            />
          </Col>
        )}
        <Col xs={12} md={6}>
          <SketchExample
            onRecieveValues={this.getValues}
            label="Name Color"
            stateName="nameColor"
            editFlag={editFlag}
            colorValue={colors.nameColor}
          />
        </Col>
        <Col xs={12} md={6}>
          <SketchExample
            onRecieveValues={this.getValues}
            label="Date Color"
            stateName="dateColor"
            editFlag={editFlag}
            colorValue={colors.dateColor}
          />
        </Col>
        <Col xs={12} md={6}>
          <SketchExample
            onRecieveValues={this.getValues}
            label="Location Icon Color"
            stateName="locationIconColor"
            editFlag={editFlag}
            colorValue={colors.locationIconColor}
          />
        </Col>
        <Col xs={12} md={6}>
          <SketchExample
            onRecieveValues={this.getValues}
            label="Location Color"
            stateName="locationColor"
            editFlag={editFlag}
            colorValue={colors.locationColor}
            positionColorPicker={solidColor ? { top: '35px' } : null}
          />
        </Col>
        <Col xs={12} md={6}>
          <SketchExample
            onRecieveValues={this.getValues}
            label="Lived in Places Icon Color"
            stateName="lipIconColor"
            editFlag={editFlag}
            colorValue={colors.lipIconColor}
            positionColorPicker={solidColor ? { top: '35px' } : null}
          />
        </Col>
        <Col xs={12} md={6}>
          <SketchExample
            onRecieveValues={this.getValues}
            label="Tag Color"
            stateName="tagColor"
            editFlag={editFlag}
            colorValue={colors.tagColor}
            positionColorPicker={solidColor ? { top: '-35px' } : null}
          />
        </Col>
      </React.Fragment>
    );
  }
}

class SketchExample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
    };
  }

  handleClick = () => {
    this.setState((prev) => ({
      displayColorPicker: !prev.displayColorPicker,
    }));
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  render() {
    let { label, stateName, positionColorPicker } = this.props;
    let styles;
    let defaultColorPickerStyle = {
      color: {
        width: '100%',
        height: '20px',
        borderRadius: '2px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        background: this.props.colorValue,
      },
      swatch: {
        width: '100%',
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
        right: '10px',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    };

    if (positionColorPicker == null) {
      styles = reactCSS({
        default: defaultColorPickerStyle,
      });
    } else {
      styles = reactCSS({
        default: { ...defaultColorPickerStyle, popover: { ...defaultColorPickerStyle.popover, ...positionColorPicker } },
      });
    }

    return (
      <div>
        <label>{label}</label>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <ChromePicker
              color={this.props.colorValue}
              onChange={(color) => this.props.onRecieveValues(color, stateName)}
              onChangeComplete={(color) => this.props.onRecieveValues(color, stateName)}
              disableAlpha
            />
          </div>
        ) : null}
      </div>
    );
  }
}
