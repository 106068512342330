import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { Badge } from 'reactstrap';
import ListComponent from './listComponent';
import { remove, put } from '../../Libraries/Ajax/httpService';
import Modal from '../modal';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';

export class DeleteRow extends Component {
  state = {
    showDeleteConfirmation: false,
  };

  toast = new ToastNotification();

  onRemoveButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      showDeleteConfirmation: true,
    });
  };

  confirmButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let { renderapi, rowData, redirecturl, history, closeParentModal } = this.props;
    remove(renderapi, rowData)
      .then((response) => {
        // if (response && response.data && response.data.success) {
        if (response && response.data) {
          this.toast.show('delete_success', 'SUCCESS', 'Deleted Successfully');
          if (closeParentModal) {
            closeParentModal(true);
          } else if (redirecturl) {
            history.push(redirecturl);
          } else {
            this.props.setpredicates({});
          }
        } else {
          this.toast.show(
            'delete_failed',
            'ERROR',
            response.data?.message !== null && response.data?.message !== '' ? response.data.message : 'Deleted Failed',
          );
        }
        this.setState({
          showDeleteConfirmation: false,
        });
      })
      .catch((error) => {
        this.setState({ showDeleteConfirmation: false });
        setTimeout(() => {
          // eslint-disable-next-line react/no-unused-state
          this.setState({ isLoaderActive: false });
        }, 1000);
        if (error.message) this.toast.show('delete_error', 'ERROR', error.message ? error.message : 'Delete Failed');
      });
  };

  onCancelButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      showDeleteConfirmation: false,
    });
  };

  render() {
    let { isCardViewTrue, deleteText } = this.props;
    return (
      <>
        <button
          title="Delete"
          type="button"
          className={isCardViewTrue ? 'btn-action btn btn-danger' : 'btn-secondary btn btn-primary'}
          onClick={(e) => this.onRemoveButtonClick(e)}
        >
          {isCardViewTrue ? <span className="icon-trash" /> : <span className="icon-trash mr-1" />}
          <span>{deleteText}</span>
        </button>

        {this.state.showDeleteConfirmation ? (
          <Modal
            title="Confirmation"
            cancelButtonName="Cancel"
            confirmButtonName="OK"
            cancelButtonClick={this.onCancelButtonClick}
            confirmButtonClick={this.confirmButtonClick}
          >
            <div className="modal-text">Are you sure you want to delete this record?</div>
          </Modal>
        ) : null}
      </>
    );
  }
}
export class EditRow extends ListComponent {
  onEditButtonClick = () => {
    let { history, redirecturl } = this.props;
    history.push(redirecturl);
  };

  render() {
    let { isCardViewTrue, editText } = this.props;
    return (
      <button
        type="button"
        className={isCardViewTrue ? 'btn-action btn btn-success' : 'btn-secondary btn btn-primary'}
        onClick={(e) => this.onEditButtonClick(e)}
      >
        {isCardViewTrue ? <span className="icon-edit" /> : <i className="icon-edit"> </i>} <span>{editText}</span>
      </button>
    );
  }
}
// export class ActionsBlock extends Component {
//   render() {
//     let { editLink, publish, isSmallButton, isCardViewTrue, visibility, isGallery, history, renderapi, deleteText, row, access } = this.props;
//     return (
//       <>
//         <div className="app-l-row-edit" style={{ visibility }}>
//           {editLink ? <EditRow isCardViewTrue={isCardViewTrue} redirectUrl={editLink} history={history} /> : null}
//           {publish && access && access.publish ? (
//             <PublishButton
//               {...this.props.setproperties({
//                 rowData: row,
//                 renderapi,
//                 isGallery,
//                 history,
//                 isCardViewTrue,
//               })}
//             />
//           ) : null}
//           {access && access.delete && (
//             <DeleteRow
//               {...this.props.setproperties({
//                 isSmallButton,
//                 deleteText,
//                 isCardViewTrue,
//                 history,
//                 onRemoveButtonClick: this.onRemoveButtonClick,
//                 renderapi,
//                 model: row,
//                 rowData: row,
//               })}
//             />
//           )}
//         </div>
//       </>
//     );
//   }
// }

export const WhiteCardBlock = (props) => {
  let { title, children, classNameProp } = props;
  return (
    <div className={`card ${classNameProp}`}>
      <div className="card-title">
        <h5 style={{ color: '#8A97BA' }}>{title}</h5>
      </div>
      <p>{children}</p>
    </div>
  );
};

export const ContentItems = (props) => {
  let { title, children } = props;

  return (
    <Form.Group>
      <Form.Label>{title}</Form.Label>
      <Form.Text>{children || '__________'}</Form.Text>
    </Form.Group>
  );
};

export const BadgeElement = ({ field, value, className }) => {
  let variant = '';
  if (field === 'EmailStatus') {
    if (value === 'Confirmed') {
      variant = 'success';
    } else if (value === 'Rejected') {
      variant = 'danger';
    } else if (value === 'Pending') {
      variant = 'secondary';
    } else variant = 'info';
  }
  return (
    <div className={className || 'view-badge'}>
      <Badge color={variant}>{value}</Badge>
    </div>
  );
};
// export class PublishButton extends Component {
//   state = {
//     showDeleteConfirmation: false,
//   };

//   toast = new ToastNotification();

//   onPublishButtonClick = (e) => {
//     e.stopPropagation();
//     e.preventDefault();
//     this.setState({
//       showDeleteConfirmation: true,
//     });
//   };

//   onCancelButtonClick = (e) => {
//     e.stopPropagation();
//     e.preventDefault();
//     this.setState({
//       showDeleteConfirmation: false,
//     });
//   };

//   onConfirmButtonClick = (e) => {
//     e.stopPropagation();
//     e.preventDefault();

//     let { rowData, renderapi, redirecturl, history } = this.props;
//     if (
//       (rowData && rowData.contentStatusId) ||
//       (rowData && (rowData.galleryStatusId || rowData.galleryStatusId === null)) ||
//       (rowData && rowData.statusId) ||
//       (rowData && rowData.jobDetailsStatusId) ||
//       (rowData && rowData.statusId)
//     ) {
//       if (rowData.contentStatusId) {
//         rowData.contentStatusId = 3;
//       }
//       if (rowData.jobDetailsStatusId) {
//         rowData.jobDetailsStatusId = 2;
//       }
//       if (rowData.statusId) {
//         rowData.statusId = 3;
//       } else {
//         rowData.galleryStatusId = 1;
//       }
//       put(renderapi, rowData)
//         .then((response) => {
//           if (response && response.data && response.data.success) {
//             this.toast.show('publish_success', 'SUCCESS', 'Published Successfully');
//             if (redirecturl) {
//               history.push(redirecturl);
//             } else this.props.setpredicates({});
//           } else if (response && response.data && !response.data.success) {
//             this.toast.show('publish_falied', 'ERROR', response.data.message ? response.data.message : 'Published Failed');
//           }
//         })
//         .catch((error) => {
//           setTimeout(() => {
//             // eslint-disable-next-line react/no-unused-state
//             this.setState({ isLoaderActive: false });
//           }, 1000);
//           if (error.message) this.toast.show('publish_error', 'ERROR', error.message ? error.message : 'Publish Failed');
//         });
//     }
//   };

//   render() {
//     let { rowData, isCardViewTrue } = this.props;
//     return (
//       <>
//         {isCardViewTrue ? (
//           <button
//             type="submit"
//             title="Publish"
//             onClick={this.onPublishButtonClick}
//             className={isCardViewTrue ? 'btn-action app-c-publish-btn btn-primary' : 'btn-action btn-primary app-c-publish-btn'}
//             variant="primary"
//           >
//             <i className="icon-publish"> </i>
//           </button>
//         ) : rowData && (rowData.contentStatusTitle !== 'Published' || rowData.galleryStatusTitle !== 'Published') ? (
//           <Button variant="primary" {...this.props} onClick={this.onPublishButtonClick}>
//             {' '}
//             <i className="icon-publish-arrow" style={{ fontSize: '9px', paddingRight: '5px' }}>
//               {' '}
//             </i>
//             Publish
//           </Button>
//         ) : null}
//         {this.state.showDeleteConfirmation ? (
//           <Modal
//             title="Confirmation"
//             cancelButtonName="Cancel"
//             confirmButtonName="OK"
//             cancelButtonClick={this.onCancelButtonClick}
//             confirmButtonClick={this.onConfirmButtonClick}
//           >
//             <div className="modal-text">Are you sure you want to publish this record?</div>
//           </Modal>
//         ) : null}
//       </>
//     );
//   }
// }

export class SetIsDeleteAndDelete extends Component {
  state = {
    showDeleteConfirmation: false,
  };

  onRemoveButtonClick = () => {
    this.setState({
      showDeleteConfirmation: true,
    });
  };

  confirmButtonClick = () => {
    let { renderapi, rowData, redirecturl, history } = this.props;
    this.props.setParentState({ isLoaderActive: true });
    rowData.galleryImagesList.map((item) => {
      if (item.isChecked) {
        item.isDelete = true;
      }
      return 1;
    });

    put(renderapi, rowData).then((response) => {
      if (response && response.data && response.data.success) {
        if (redirecturl) {
          history.push(redirecturl);
        } else {
          this.setState({ showDeleteConfirmation: false });
          this.props.setParentState({
            singleSelect: false,
            multiSelect: false,
          });
          setTimeout(() => {
            this.props.setParentState({ isLoaderActive: false });
          }, 1000);
          this.props.setpredicates({});
        }
      }
    });
  };

  onCancelButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      showDeleteConfirmation: false,
    });
  };

  render() {
    let { isCardViewTrue, deleteText } = this.props;
    return (
      <div className="app-l-row-edit">
        <button
          type="button"
          className={isCardViewTrue ? 'btn-action btn btn-danger' : 'btn-secondary btn btn-primary'}
          onClick={(e) => this.onRemoveButtonClick(e)}
        >
          {isCardViewTrue ? <span className="icon-trash" /> : <span className="icon-trash mr-1" />}
          <span>{deleteText}</span>
        </button>

        {this.state.showDeleteConfirmation ? (
          <Modal
            title="Confirmation"
            cancelButtonName="Cancel"
            confirmButtonName="OK"
            cancelButtonClick={this.onCancelButtonClick}
            confirmButtonClick={this.confirmButtonClick}
          >
            <div className="modal-text">Are you sure you want to delete these images?</div>
          </Modal>
        ) : null}
      </div>
    );
  }
}
