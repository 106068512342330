import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { get } from '../../Libraries/Ajax/httpService';
import Loader from '../../Components/loader';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import { ViewDate } from '../../SharedComponents/ListComponents/components';
import { DeleteRow, EditRow, ContentItems } from '../../SharedComponents/ListComponents/manageComponent';
import { AccessModuleCards } from './accessProfileHelpers';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';

class ViewAccessProfile extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleRow: null,
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      get(`access/get-list?id=${this.props.match.params.id}`).then((response) => {
        if (response.data.data?.modules) {
          response.data.data.modules.forEach((mod) => {
            if (mod.subModules.length > 1) {
              response.data.data.allPermissions.forEach((permission, permIndex) => {
                if (!mod.subModules.every((item) => item[`disable${permission.title}`])) {
                  mod[`is${permission.title}All`] = mod.subModules.every(
                    (subModule) => subModule[`disable${permission.title}`] || subModule.permissions[permIndex].isSelected === true,
                  );
                }
              });
            }
          });
        }
        this.setState({ isLoaderActive: false, singleRow: response.data.data });
      });
    }
    this.setState({ access: setComponentPrivilege('Access Templates') });
  }

  render() {
    let { isLoaderActive, singleRow, access } = this.state;

    let { history } = this.props;

    return (
      <>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          {singleRow && (
            <div className="app-l-heading-block">
              <Row className="align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">Access Template Details</h4>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/list-access-profiles">Access Template</Link>
                      </li>
                      <li className="breadcrumb-item active">Access Template Details</li>
                    </ol>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {singleRow && (
            <Container fluid className="px-0">
              <Card>
                <CardBody className="view-page">
                  {/* TopSection1 */}
                  <Row>
                    <Col xs={12} md={6} lg={4} xl={3}>
                      <ContentItems title="Template Name "> {singleRow.title}</ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={3}>
                      <ContentItems title="Created By"> {singleRow.createdByName}</ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={3}>
                      <ContentItems title="Created Date">
                        {' '}
                        <ViewDate isTableView dateValue={singleRow.createdDate} />
                      </ContentItems>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={3}>
                      <ContentItems title="Last Modified By"> {singleRow.createdByName}</ContentItems>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={3}>
                      <ContentItems title="Last Modified Date">
                        {' '}
                        <ViewDate isTableView dateValue={singleRow.createdDate} />
                      </ContentItems>
                    </Col>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <ContentItems title="Access To"> {singleRow.accessTo}</ContentItems>
                    </Col>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <ContentItems title="Description"> {singleRow.description}</ContentItems>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              {singleRow.modules && (
                <AccessModuleCards
                  {...this.setproperties({
                    modulesList: singleRow.modules,
                    allPermissions: singleRow.allPermissions,
                  })}
                  isView
                  updateModuleList={this.updateModuleList}
                />
              )}
              <div>
                <Col>
                  <div className="float-right ">
                    {access && access.delete && singleRow.id !== 1 && singleRow.id !== 2 && (
                      <DeleteRow
                        renderapi={`access/${this.props.match.params.id}`}
                        model={singleRow}
                        redirecturl="/list-access-profiles"
                        deleteText="Delete"
                        history={history}
                        isCardViewTrue="True"
                      />
                    )}{' '}
                    {access && access.edit && (
                      <EditRow
                        history={history}
                        editText="Edit"
                        redirecturl={`/manage-access-profiles/${this.props.match.params.id}`}
                        rowData={singleRow}
                      />
                    )}
                  </div>
                </Col>
              </div>
            </Container>
          )}
        </div>
      </>
    );
  }
}

export default ViewAccessProfile;
