import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  render() {
    let { userData } = this.props;

    return (
      <React.Fragment>
        {userData && (
          <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block">
            <DropdownToggle className="btn header-item waves-effect header" title="My Account" id="page-header-user-dropdown" tag="button">
              <span className="header-name">{userData?.user.username} </span>
              {!userData?.user.userImage ? (
                <span className="rounded-circles">
                  {userData?.user.username.split(' ').length > 1
                    ? (userData?.user.username.split(' ').shift().charAt(0) + userData?.user.username.split(' ').pop().charAt(0)).toUpperCase()
                    : userData?.user.username.charAt(0).toUpperCase()}
                </span>
              ) : (
                <img className="rounded-circle header-profile-user" src={userData?.user.userImage} alt="Header Avatar" />
              )}
            </DropdownToggle>
            <DropdownMenu right>
              <Link to="/my-profile" className="dropdown-item">
                <i className="mdi mdi-account-circle font-size-17 align-middle mr-1" />
                <span>My Profile</span>
              </Link>

              {userData.user?.id && userData.user.roleId === 1 && (
                <Link to={`/view-partner-managers/${userData.user.id}/view-transactions`} className="dropdown-item">
                  <i className="mdi mdi-wallet font-size-17 align-middle mr-1" />
                  <span> Transactions</span>
                </Link>
              )}
              <div className="dropdown-divider" />
              <Link to="/logout" className="dropdown-item">
                <i className="mdi mdi-logout font-size-17 align-middle mr-1" />
                <span>Logout</span>
              </Link>
            </DropdownMenu>
          </Dropdown>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  userData: state.Login.userData,
});

export default withRouter(connect(mapStateToProps, null)(ProfileMenu));
