import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { DataTable, TBody, THead, TCol, TRow, HeadingBlock, DataTableHolder } from '../../SharedComponents/Table/tableElements';
import { DataColHead, ListPagination } from '../../SharedComponents/ListComponents/components';
import ListComponent from '../../SharedComponents/ListComponents/listComponent';
import Loader from '../../Components/loader';
import { StatusSwitch } from '../../SharedComponents/FormHelpers/Controls/statusSwitch';
import { ActionButton } from '../../SharedComponents/FormHelpers/Controls/actionButton';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';

class ListShopContainer extends ListComponent {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: {},
      singleSelect: false,
      multiSelect: false,
      rowData: {},
      isLoaderActive: false,
      showDeleteConfirmation: false,
    };
  }

  componentDidMount() {
    this.setJsonUrl('shop');
    this.setpredicates({ take: 10, page: 0 });
    this.setState({ access: setComponentPrivilege('Manage Shops') });
  }

  render() {
    let { apiResponse, isLoaderActive, access } = this.state;

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              {isLoaderActive === true ? <Loader /> : ''}
              <HeadingBlock title="Shops" />
            </Col>
            {access && access.create && (
              <Col sm={6}>
                <div className="float-right  d-md-block">
                  <Link className="btn btn-primary waves-effect waves-light" to="manage-shops" role="button">
                    Add Shop
                  </Link>
                </div>
              </Col>
            )}
          </Row>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div data-test="datatable" className="dataTables_wrapper dt-bootstrap4">
                    <div className="row">
                      <DataTableHolder pagination={<ListPagination {...this.setpagination()} />}>
                        <DataTable>
                          <THead>
                            <TRow>
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Name',
                                  enableSearch: true,
                                  enableSort: true,
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Phone',
                                  enableSearch: true,
                                  enableSort: true,
                                  searchField: 'phoneSearch',
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Address',
                                  enableSearch: true,
                                  enableSort: true,
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Email',
                                  enableSearch: true,
                                  enableSort: true,
                                  searchField: 'emailSearch',
                                  className: 'app-l-search-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Status',
                                  width: 70,
                                  sublist: 'Status',
                                  coltype: 'filter',
                                  filterapi: 'master-data/status',
                                  sublistFilterField: 'status',
                                  filterClassName: 'app-l-condensed-column',
                                })}
                              />
                              <DataColHead
                                {...this.setheadproperties({
                                  title: 'Action',
                                  filterfield: 'action',
                                  width: 120,
                                  className: 'action',
                                })}
                              />
                            </TRow>
                          </THead>
                          <TBody>
                            {apiResponse && apiResponse.rows && apiResponse.rows.length > 0
                              ? apiResponse.rows.map((row) => (
                                  <TRow>
                                    <TCol>{row.name}</TCol>
                                    <TCol>{row.phone}</TCol>
                                    <TCol>{row.address}</TCol>
                                    <TCol>{row.email}</TCol>
                                    <TCol>
                                      {access && access.edit ? (
                                        <StatusSwitch
                                          isListIcon
                                          value={row.statusId}
                                          name="statusId"
                                          rowData={row}
                                          updateApi={`shop/${row.id}`}
                                          apiResponse={apiResponse}
                                          setParentState={this.setParentState}
                                          valueObj={{ on: 1, off: 2 }}
                                        />
                                      ) : (
                                        row.statusTitle
                                      )}
                                    </TCol>

                                    <TCol>
                                      <div className="action-col">
                                        {access && access.edit && <ActionButton value="edit" rowId={row.id} url="manage-shops/" />}
                                        <ActionButton value="view" rowId={row.id} url="view-shops/" />
                                        {access && access.delete && (
                                          <ActionButton
                                            value="delete"
                                            rowId={row.id}
                                            url="list-shops"
                                            setJsonUrl={this.setJsonUrl}
                                            setpredicates={this.setpredicates}
                                            api="shop/"
                                          />
                                        )}
                                      </div>
                                    </TCol>
                                  </TRow>
                                ))
                              : null}
                          </TBody>
                        </DataTable>
                        {apiResponse.rows && apiResponse.rows.length === 0 && <div className="text-center no-content-block">No results found </div>}
                      </DataTableHolder>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ListShopContainer;
