import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, FormGroup, Row } from 'reactstrap';

import Loader from '../../Components/loader';
import { get, post, put } from '../../Libraries/Ajax/httpService';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';
import { setComponentPrivilege } from '../../SharedComponents/accessPrivilegeHelper';
import DragAndDrop from '../../SharedComponents/FormHelpers/Controls/dragAndDrop';
import DropDown from '../../SharedComponents/FormHelpers/Controls/dropDown';
import FormInput from '../../SharedComponents/FormHelpers/Controls/formInput';
import FormComponent from '../../SharedComponents/FormHelpers/Form/formComponent';
import SubmitForm from '../../SharedComponents/FormHelpers/Form/submitForm';
import { DeleteRow } from '../../SharedComponents/ListComponents/manageComponent';
import Modal from '../../SharedComponents/modal';

class ManageProducts extends FormComponent {
  constructor(props) {
    super(props);
    this.setProperties = this.setProperties.bind(this);
    this.setFormProperties = this.setFormProperties.bind(this);
    this.state = {
      isSubmitted: true,
      singleRow: {},
      action: 'Add',
      editFlag: false,
      multiSelect: false,
      id: {
        label: 'Id',
        type: 'hidden',
      },
      name: {
        label: 'Name',
        type: 'text',
        placeholder: 'Enter name',
        rules: {
          required: true,
          maxLength: 100,
        },
      },
      categoryId: {
        label: 'Category',
        renderapi: 'product-category?page=0&take=10',
        placeholder: 'Enter category',
        className: 'mb-0',
        controlId: 'exampleForm.ControlSelect1',
        name: 'categoryId',
        optionvalue: 'id',
        optiontext: 'name',
        rules: {
          required: true,
        },
      },
      shopId: {
        label: 'Shop',
        renderapi: 'shop?isActive=true&isAll=true',
        placeholder: 'Enter shop',
        className: 'mb-0',
        controlId: 'exampleForm.ControlSelect1',
        name: 'shopId',
        optionvalue: 'id',
        optiontext: 'name',
        rules: {
          required: true,
        },
      },
      price: {
        label: 'Price',
        type: 'text',
        placeholder: 'Enter price',
        rules: {
          required: true,
          number: true,
          currency: true,
          maxLength: 20,
        },
        className: 'currency',
        mainDivClass: 'input-icon',
      },
      description: {
        label: 'Description',
        type: 'text',
        placeholder: 'Enter description',
        rules: {
          required: true,
          maxLength: 10000,
        },
      },
      sourceUrl: {
        label: 'Source Url',
        type: 'text',
        placeholder: 'Enter source url',
        rules: {
          url: true,
          maxLength: 500,
        },
      },
      image: {
        label: 'Image',
        type: 'text',
        placeholder: 'Enter image',
        rules: {
          required: true,
        },
        name: 'image',
        fileUploadUrl: 'product/upload',
      },
      statusId: {
        label: 'status',
        name: 'statusId',
        className: 'float-right mt-1',
        defaultValue: 1,
        valueObj: { on: 1, off: 2 },
      },
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ editFlag: true });
      this.setState({ action: 'Edit' });
      get(`product/${this.props.match.params.id}`).then((response) => {
        this.setState({ singleRow: response.data.data });
        this.mapJsonToState(response.data.data);
      });
    }
    this.setState({ access: setComponentPrivilege('Manage Products') });
  }

  validExtensions = ['jpeg', 'jpg', 'png'];

  toast = new ToastNotification();

  onCancelButtonClick = () => {
    this.props.history.push('/list-products');
  };

  confirmButtonClick = () => {
    this.setState({ showDeleteConfirmation: false });
    this.setState({ isLoaderActive: true });
    if (this.props.match.params.id) {
      put(`product/${this.props.match.params.id}`, this.state.models).then(() => {
        this.setState({ isLoaderActive: false });
        this.props.history.push('/list-products');
      });
    } else {
      post('product', this.state.models).then(() => {
        this.setState({ isLoaderActive: false });
        this.props.history.push('/list-products');
      });
    }
  };

  onModalCancelButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      showDeleteConfirmation: false,
    });
  };

  onSaveButtonClick = (model) => {
    this.setState({ models: model });
    if (model.price > 300) {
      this.setState({
        showDeleteConfirmation: true,
      });
    } else {
      this.setState({ isLoaderActive: true });
      if (this.props.match.params.id) {
        put(`product/${this.props.match.params.id}`, model).then((response) => {
          this.setState({ isLoaderActive: false });
          if (response.data?.success) {
            this.toast.show('update_success', 'SUCCESS', response.data?.message ? response.data.message : 'Product successfully updated');
            this.props.history.push('/list-products');
          } else this.toast.show('update_error', 'ERROR', response.data?.message ? response.data.message : 'Something went wrong');
        });
      } else {
        post('product', model).then((response) => {
          this.setState({ isLoaderActive: false });
          if (response.data?.success) {
            this.toast.show('create_success', 'SUCCESS', response.data?.message ? response.data.message : 'Product created successfully');
            this.props.history.push('/list-products');
          } else {
            this.toast.show('create_error', 'ERROR', response.data?.message ? response.data.message : 'Something went wrong');
          }
        });
      }
    }
  };

  render() {
    let { editFlag, isLoaderActive, singleRow, multiSelect, access } = this.state;
    let { history } = this.props;
    return (
      <React.Fragment>
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Product Details</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/list-products">Products</Link>
                  </li>
                  <li className="breadcrumb-item active">{this.state.action} Product</li>
                </ol>
              </div>
            </Col>
            <Col sm={6}>
              {this.state.editFlag && access && access.delete && (
                <div className="float-right d-md-block mr-2">
                  <DeleteRow
                    renderapi={`product/${this.props.match.params.id}`}
                    model={singleRow}
                    redirecturl="/list-products"
                    deleteText="Delete"
                    history={history}
                    isCardViewTrue="True"
                  />{' '}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Container fluid className="px-0">
              <div className="col-12">
                <Card>
                  <CardBody>
                    <SubmitForm onSubmit={this.onSaveButtonClick} {...this.setFormProperties({ disableReactiveForm: true })}>
                      <Row>
                        <Col xs={12} md={6}>
                          <FormInput {...this.setProperties(this.state.name)} />
                        </Col>
                        <Col xs={12} md={6}>
                          <DropDown {...this.setProperties(this.state.categoryId)} />
                        </Col>
                        <Col xs={12} md={6}>
                          <DropDown {...this.setProperties(this.state.shopId)} />
                        </Col>
                        <Col xs={12} md={6}>
                          <FormInput {...this.setProperties(this.state.sourceUrl)} />
                        </Col>
                        <Col xs={12} md={6}>
                          <FormInput {...this.setProperties(this.state.price)} />
                        </Col>
                        <Col xs={12} md={12}>
                          <FormInput rows={5} as="textarea" {...this.setProperties(this.state.description)} />
                        </Col>
                      </Row>
                      <Row>
                        <div className="col-12">
                          <DragAndDrop
                            validExtensions={this.validExtensions}
                            mediaUrl={singleRow.mediaUrl}
                            editFlag={editFlag}
                            allowCrop
                            aspectRatio={`${1 / 1}`}
                            {...this.setProperties(this.state.image)}
                          />
                        </div>
                      </Row>
                      <FormGroup className="mb-0">
                        <div className="float-right d-md-block">
                          <br />
                          <Button type="submit" color="primary" className="mr-1">
                            Save
                          </Button>{' '}
                          <Button className="btn-secondary" type="reset" onClick={this.onCancelButtonClick}>
                            <i className="icon-close"> </i> <span> Cancel </span>
                          </Button>
                        </div>
                      </FormGroup>
                    </SubmitForm>
                  </CardBody>
                </Card>
              </div>
            </Container>
          </Row>
        </div>
        {this.state.showDeleteConfirmation ? (
          <Modal
            title="Confirmation"
            cancelButtonName="Cancel"
            confirmButtonName="OK"
            cancelButtonClick={this.onModalCancelButtonClick}
            confirmButtonClick={this.confirmButtonClick}
          >
            <div>
              {multiSelect === true
                ? 'You have entered a value above the normal price range. Do you wish to continue?'
                : 'You have entered a value above the normal price range(€300). Do you wish to continue?'}
            </div>
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}
export default ManageProducts;
