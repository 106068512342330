import React, { Component } from 'react';
import { Input, Modal } from 'reactstrap';

import { get, put } from '../../Libraries/Ajax/httpService';
import Loader from '../../Components/loader';
import ValidationMessage from '../../SharedComponents/FormHelpers/Validation/validationMessage';
import ToastNotification from '../../Libraries/ToastNotifications/toastNotification';

export default class FormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toast = new ToastNotification();

  onSaveButton = (model) => {
    // this.setState({ isLoaderActive: true });
    model = {
      note: this.state.note,
      orderId: this.props.order.id,
      statusId: this.props.order.statusId,
    };
    if (!model.note) {
      this.setState({ note: '' });
    } else {
      put(`order/${model.orderId}`, model).then(() => {
        this.toast.show('create_success', 'SUCCESS', 'Order Status Updated');
        if (this.props.isViewPage) {
          get(`order/order-details/${model.orderId}`).then((res) => {
            this.props.setParentState({
              singleRow: res.data?.data,
              reloadList: true,
            });
          });
        }
        this.setState({ isLoaderActive: false, note: undefined });
      });
      this.props.onToggle(false);
    }
  };

  onChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  };

  render() {
    let { showModal, onToggle } = this.props;
    let { isLoaderActive } = this.state;
    return (
      <div className="row">
        {isLoaderActive === true ? <Loader /> : ''}
        <div className="col-sm-6 col-md-3 mt-4">
          <Modal
            isOpen={showModal}
            toggle={() => {
              onToggle(false);
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Update Order Status
              </h5>
            </div>
            <div className="modal-body">
              <Input
                type="textarea"
                id="textarea"
                placeholder="Reference Note"
                label="Message"
                maxLength="255"
                rows="8"
                name="note"
                onChange={this.onChange}
              />
              {this.state.note !== undefined && this.state.note.length === 0 ? (
                <ValidationMessage message="Message is required" />
              ) : (
                <span className="app-c-validation-message" />
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  this.setState({ note: undefined });
                  onToggle(true);
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={this.onSaveButton}
                disabled={!!(this.state.note !== undefined && this.state.note.length === 0)}
              >
                Update status
              </button>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
