import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const PIXEL_RATIO = 1;
const ImageCropper = (props) => {
  // states
  let [crop, setCrop] = useState();
  let [src, setSrc] = useState();
  let [completedCrop, setCompletedCrop] = useState();

  // Refs
  let previewCanvasRef = useRef(null);
  let imgRef = useRef(null);

  let onLoad = useCallback(
    (img) => {
      imgRef.current = img;
      setCrop({ unit: '%', width: img.width, aspect: props.aspectRatio });
      return false;
    },
    [props.aspectRatio],
  );

  useEffect(() => {
    if (props.selectedFile) {
      let reader = new FileReader();
      reader.addEventListener('load', () => setSrc(reader.result));
      reader.readAsDataURL(props.selectedFile);
    }
  }, [props.selectedFile]);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    let image = imgRef.current;
    let canvas = previewCanvasRef.current;
    let cropped = completedCrop;

    let scaleX = image.naturalWidth / image.width;
    let scaleY = image.naturalHeight / image.height;

    let ctx = canvas.getContext('2d');

    canvas.width = cropped.width * PIXEL_RATIO;
    canvas.height = cropped.height * PIXEL_RATIO;

    ctx.setTransform(PIXEL_RATIO, 0, 0, PIXEL_RATIO, 0, 0);
    ctx.drawImage(
      image,
      cropped.x * scaleX,
      cropped.y * scaleY,
      cropped.width * scaleX,
      cropped.height * scaleY,
      0,
      0,
      cropped.width,
      cropped.height,
    );
    ctx.imageSmoothingQuality = 'medium';
    canvas.toBlob(
      (blob) => {
        let previewUrl = URL.createObjectURL(blob);
        let newFile = new File([blob], props.selectedFile.name, { type: blob.type });
        if (props.onCropDone) {
          props.onCropDone(newFile);
        } else {
          props.setParentState({ croppedImage: newFile, previewUrl });
        }
      },
      'image/jpeg',
      0.95,
    );
  }, [completedCrop, props]);

  return (
    <React.Fragment>
      <div className="uploadedImage">
        <h5 className="cropper-heading">Uploaded Image</h5>
        <div>
          <ReactCrop
            minHeight={40}
            minWidth={40}
            keepSelection
            src={src}
            style={{ maxHeight: '550px' }}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
            onImageLoaded={onLoad}
          />
        </div>
      </div>
      <div className="croppedPreview">
        <h5 className="cropper-heading">Cropped Preview</h5>
        <div>
          <canvas ref={previewCanvasRef} style={{ width: Math.round(completedCrop?.width ?? 0), height: Math.round(completedCrop?.height ?? 0) }} />
        </div>
      </div>
    </React.Fragment>
  );
};
export default ImageCropper;
