import React, { Component } from 'react';
import { Row, Col, Card } from 'reactstrap';
import { Link } from 'react-router-dom';

// import images
import bg from '../../assets/images/bg.jpg';
import logoDark from '../../assets/images/logo-dark.png';

class PagesLogin2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  handlePassword = () => {
    let obj = this.state.showPassword;
    this.setState({ showPassword: !obj });
  };

  render() {
    return (
      <React.Fragment>
        {' '}
        <div
          className="accountbg"
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${bg})`,
          }}
        />
        <div className="wrapper-page account-page-full">
          <Card className="shadow-none">
            <div className="card-block">
              <div className="account-box">
                <div className="card-box shadow-none p-4">
                  <div className="p-2">
                    <div className="text-center mt-4">
                      <Link to="/">
                        <img src={logoDark} height="22" alt="logo" />
                      </Link>
                    </div>

                    <h4 className="font-size-18 mt-5 text-center">Welcome Back !</h4>
                    <p className="text-muted text-center">Sign in to continue to Veltrix.</p>

                    <form className="mt-4" action="#">
                      <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input type="text" className="form-control" id="username" placeholder="Enter username" />
                      </div>

                      <div className="form-group">
                        <label htmlFor="userpassword">Password</label>
                        <input
                          type={this.state.showPassword ? 'text' : 'password'}
                          className="form-control"
                          id="userpassword"
                          placeholder="Enter password"
                        />
                        <span className="icon-label">
                          <em className={this.state.showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'} onClick={this.handlePassword} />
                        </span>
                      </div>

                      <Row className="form-group">
                        <Col sm={6}>
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customControlInline" />
                            <label className="custom-control-label" htmlFor="customControlInline">
                              Remember me
                            </label>
                          </div>
                        </Col>

                        <Col sm="6" className="text-right">
                          <button className="btn btn-primary w-md waves-effect waves-light" type="submit">
                            Log In
                          </button>
                        </Col>
                      </Row>

                      <Row className="form-group mt-2 mb-0">
                        <div className="col-12 mt-3">
                          <Link to="pages-recoverpw-2">
                            <i className="mdi mdi-lock" /> Forgot your password?
                          </Link>
                        </div>
                      </Row>
                    </form>

                    <div className="mt-5 pt-4 text-center">
                      <p>
                        Don't have an account ?{' '}
                        <Link to="pages-register-2" className="font-weight-medium text-primary">
                          {' '}
                          Signup now{' '}
                        </Link>{' '}
                      </p>
                      <p>
                        © {new Date().getFullYear()} Veltrix. Crafted with <i className="mdi mdi-heart text-danger" /> by Themesbrand
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default PagesLogin2;
